import React, { useState } from 'react';
import { PlatformIdentifier } from '../../../constants';
import TelegramLoginButton from 'react-telegram-login';
import GoogleSignInIconNormal from 'assets/images/btn_google_signin_dark_normal_web.png';
import GoogleSignInIconFocused from 'assets/images/btn_google_signin_dark_focus_web.png';
import GoogleSignInIconPressed from 'assets/images/btn_google_signin_dark_pressed_web.png';
import { Button } from 'react-bootstrap';
import InfoBar from 'components/common/InfoBar';

export default function PlatformConnectView(props) {

    const {
        platform,
        processing,
        handleTelegramResponse,
        connectAccount,
        height = '80vh'
    } = props;
    const [googleSignInIcon, setGoogleSignInIcon] = useState(GoogleSignInIconNormal);

    const vowels = () => {
        return ['a', 'e', 'i', 'o', 'u'];
    }

    const startsWithAnyOf = (string, anyOf) => {
        if (!Array.isArray(anyOf)) {
            anyOf = anyOf.split("");
        }
        let hitFound = false;
        for (let char of anyOf) {
            hitFound = string.toLowerCase().startsWith(char.toString());
            if (hitFound) {
                break;
            }
        }
        return hitFound;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: height,
                position: 'relative'
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 10
                }}>
                    <span style={{
                        fontSize: 30,
                        fontWeight: 600,
                        textAlign: 'center'
                    }} textAlign={'center'}>{platform.name}
                    </span>
                </div>
                {
                    platform.identifier === PlatformIdentifier.TELEGRAM
                        ?
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 10
                            }}>
                            <TelegramLoginButton
                                dataOnauth={handleTelegramResponse}
                                botName="reachable_bot"
                            />
                            <InfoBar info={`Your Telegram account(s) <b>MUST</b> have a <b>username</b> for Postly to Work!`} />
                        </div>
                        : (platform.identifier === PlatformIdentifier.YOUTUBE
                            || platform.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS)
                            ? <img
                                onMouseEnter={() => {
                                    if (processing) {
                                        setGoogleSignInIcon(GoogleSignInIconPressed);
                                    } else {
                                        setGoogleSignInIcon(GoogleSignInIconFocused);
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (!processing) {
                                        setGoogleSignInIcon(GoogleSignInIconNormal);
                                    } else {
                                        setGoogleSignInIcon(GoogleSignInIconPressed);
                                    }
                                }}
                                onClick={() => {
                                    setGoogleSignInIcon(GoogleSignInIconPressed);
                                    connectAccount();
                                }}
                                src={googleSignInIcon}
                                alt={"Google Sign In"}
                                style={{
                                    cursor: 'pointer'
                                }}
                            />
                            : <Button
                                variant={'primary'}
                                disabled={processing}
                                isWorking={processing}
                                onClick={connectAccount}
                                style={{
                                    color: 'white',
                                    minWidth: 200,
                                    display: 'flex',
                                    fontSize: 20,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                {processing ? "Please wait..." : "Connect"}
                            </Button>
                }
            </div>
            <span
                style={{
                    padding: 30,
                    fontSize: 14,
                    textAlign: 'center'
                }}>{`${processing ? 'Connecting' : `Connect ${startsWithAnyOf(platform.name, vowels()) ? 'an' : 'a'}`} ${platform.name?.toLowerCase().includes('instagram') ? 'Instagram Business' : platform.name} account ${processing ? '...' : ''}`}
            </span>
            {
                platform.identifier === PlatformIdentifier.INSTAGRAM &&
                <InfoBar info={`Instagram business account connection will happen via Facebook`} />
            }
        </div>
    );
}