import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FalconDropzone from 'components/common/FalconDropzone';
import Avatar from 'components/common/Avatar';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from 'components/common/Flex';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import CircularButton from 'components/common/circularbutton';

const LogoForm = ({ setValue }) => {

  const [avatar, setAvatar] = useState();

  return (
    <>
      <Row className="mb-3">
        <Col md="auto">
          {
            avatar ?
              <Avatar
                size="4xl"
                src={avatar.base64}
              /> :
              <CircularButton style={{ width: 100, height: 100 }}>
                <FontAwesomeIcon icon={faImage} />
              </CircularButton>
          }
        </Col>
        <Col md>
          <FalconDropzone
            files={avatar}
            onChange={files => {
              setAvatar(files[0]);
              setValue('avatar', files[0]);
            }}
            multiple={false}
            accept="image/*"
            placeholder={
              <>
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">
                    Upload a Brand Logo
                  </p>
                </Flex>
                <p className="mb-0 w-75 mx-auto text-400">
                  Upload a 300x300 jpg image with a maximum size of 400KB
                </p>
              </>
            }
          />
        </Col>
      </Row>
    </>
  );
};

LogoForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default LogoForm;
