import Flex from "components/common/Flex";
import {
    Card, Form,
    FormControl, FormGroup,
    FormLabel
} from "react-bootstrap";
import { promptData } from "../../data";

export default function NewTemplateAdvancedOptions(props) {

    const handleFormDataChange = props.handleFormDataChange;
    const handleManualTemplateChanges = props.handleManualTemplateChanges;
    const categories = [...new Set(promptData.map(prompt => prompt.category))];
    const template = {
        "category": categories[0],
        "isPublic": false,
        ...(props.template ?? {})
    };

    const updateInputs = (data) => {
        let inputs = template["inputs"] ?? [
            {
                type: 'input',
                label: 'Put your company name here',
                name: "Company Name",
                value: 'companyName',
                ...data
            },
        ];
        let input = { ...inputs[0], ...data };
        inputs[0] = input;
        let prevTemplate = { ...template };
        prevTemplate["inputs"] = inputs;
        handleManualTemplateChanges(prevTemplate);
    }

    const updateVariables = (data) => {
        let variables = template["variables"] ?? [
            {
                type: 'input',
                goal: 'Description',
                name: 'goal',
                label: 'Output Column',
                ...data
            },
        ];
        let variable = { ...variables[0], ...data };
        variables[0] = variable;
        let prevTemplate = { ...template };
        prevTemplate["variables"] = variables;
        handleManualTemplateChanges(prevTemplate);
    }

    return (
        <Flex direction={'column'} className={'gap-4'}>
            <FormGroup>
                <FormLabel>Name <span className="text-danger">*</span></FormLabel>
                <FormControl
                    value={template['title']}
                    placeholder="Template Name. E.g Generate Company Description from URL"
                    onChange={(e) => {
                        handleFormDataChange('title', e.target.value);
                    }}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>Description <span className="text-danger">*</span></FormLabel>
                <FormControl
                    as="textarea"
                    value={template['description']}
                    placeholder="Template Description"
                    onChange={(e) => {
                        handleFormDataChange('description', e.target.value);
                    }}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>Category <span className="text-danger">*</span></FormLabel>
                <Form.Select
                    aria-label="Prompt Category"
                    value={template['category']}
                    onChange={(e) => {
                        handleFormDataChange('category', e.target.value);
                    }}
                >
                    {
                        categories.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))
                    }
                </Form.Select>
            </FormGroup>
            <FormGroup>
                <FormLabel>Is Public</FormLabel>
                <Form.Check
                    type="switch"
                    id="public-switch"
                    checked={template['isPublic']}
                    onChange={(e) => {
                        handleFormDataChange('isPublic', e.target.checked);
                    }}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>Input</FormLabel>
                <Card
                    className='my-2 shadow-none border cursor-pointer'
                    style={{
                        borderRadius: 5,
                    }}>
                    <Card.Header className="bg-light">
                        {"{{Description}}"}
                    </Card.Header>
                    <Card.Body>
                        <FormControl
                            placeholder="E.g Company Name"
                            onChange={(e) => {
                                updateInputs({
                                    "label": e.target.value,
                                });
                            }}
                        />
                    </Card.Body>
                </Card>
                <Card
                    className='my-2 shadow-none border cursor-pointer'
                    style={{
                        borderRadius: 5,
                    }}>
                    <Card.Header className="bg-light">
                        {"{{value}}"}
                    </Card.Header>
                    <Card.Body>
                        <FormControl
                            placeholder="E.g The real company name"
                            onChange={(e) => {
                                updateInputs({ "value": e.target.value });
                            }}
                        />
                    </Card.Body>
                </Card>
            </FormGroup>
            <FormGroup>
                <FormLabel>Variables...</FormLabel>
                <Card
                    className='my-2 shadow-none border cursor-pointer'
                    style={{
                        borderRadius: 5,
                    }}>
                    <Card.Header className="bg-light">
                        {"{{name}}"}
                    </Card.Header>
                    <Card.Body>
                        <FormControl
                            placeholder="E.g Output Column"
                            onChange={(e) => {
                                updateVariables({
                                    "label": e.target.value,
                                });
                            }}
                        />
                    </Card.Body>
                </Card>
                <Card
                    className='my-2 shadow-none border cursor-pointer'
                    style={{
                        borderRadius: 5,
                    }}>
                    <Card.Header className="bg-light">
                        {"{{goal}}"}
                    </Card.Header>
                    <Card.Body>
                        <FormControl
                            placeholder="E.g Generate"
                            onChange={(e) => {
                                updateVariables({ "goal": e.target.value });
                            }}
                        />
                    </Card.Body>
                </Card>
            </FormGroup>
        </Flex>
    );
}