import React, { useState } from "react"
import {
  Row, Col,
  Card, Button,
  ListGroup, Modal,
  Form, Spinner
} from "react-bootstrap"
import StringUtils from "utils/string"
import LeadsAnimation from "assets/animations/import_leads.json"
import Lottie from "lottie-react"
import Flex from "components/common/Flex"
import APIService from "http/api_service"
import WorkSpaceManager from "utils/workspace_manager"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import { RoutePaths } from "constants"
import { toast } from "react-toastify"
import FalconCardHeader from "components/common/FalconCardHeader"
import useSWR from "swr"

const GetStartedView = React.forwardRef((props, ref) => {
  return (
    <Flex
      ref={ref}
      className={"gap-2"}
      alignItems={"center"}
      justifyContent={"center"}
      style={{
        height: "auto",
        width: "auto",
      }}
    >
      <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
        <Lottie
          animationData={LeadsAnimation}
          style={{
            width: 300,
            height: 300,
          }}
        />
        <h5>👋 Add 1 or more lists to get started</h5>
        <Button
          style={{
            marginTop: "2rem",
          }}
          variant={"primary"}
          onClick={() => {
            props.getStartedClickHandler()
          }}
        >
          <Flex alignItems={"center"} className={"gap-1"}>
            <span>ADD</span>
          </Flex>
        </Button>
      </Flex>
    </Flex>
  )
})

export default function SequenceRecipients(props) {

  const [sequence, setSequence] = useState(props.sequence)
  const [lists, setLists] = useState(sequence.contact_lists ?? [])
  const [importableLists, setImportableLists] = useState([])
  const [showImportModal, setShowImportModal] = useState(false)
  const [importedListsSearchQuery, setImportedListsSearchQuery] = useState("")
  const [importableListsSearchQuery, setImportableListsSearchQuery] = useState("")
  const workspace = WorkSpaceManager.getActiveWorkSpace()
  const [fetchingImportableLists, setFetchingImportableLists] = useState(false)
  const [addingMoreLists, setAddingMoreLists] = useState(false)
  const [selectedImportableLists, setSelectedImportableLists] = useState(sequence.contact_lists ?? [])

  const { data: workspaceLists, isLoading: isWorkspaceListLoading } = useSWR(
    "workspaceLists",
    () => APIService.fetchWorkspaceLists(workspace._id),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  const { data: sequenceLists, isLoading: isSequenceListLoading } = useSWR(
    "sequenceLists",
    () => APIService.fetchSequenceLists(workspace._id, sequence._id),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  const toggleListSelection = (list) => {
    if (selectedImportableLists.includes(list)) {
      setSelectedImportableLists(selectedImportableLists.filter((selectedList) => selectedList !== list))
    } else {
      setSelectedImportableLists([...selectedImportableLists, list])
    }
  }

  const fetchImportableLists = async () => {
    setFetchingImportableLists(true)
    if (importableLists?.length > 0) {
      setImportableLists(
        importableLists.filter((list) => !lists.some((existingList) => existingList._id === list._id)),
      )
    }
    try {
      let { data } = await APIService.fetchWorkspaceLists(workspace._id)
      const uniqueImportableLists = data.filter(
        (list) => !lists.some((existingList) => existingList._id === list._id),
      )
      setImportableLists(uniqueImportableLists)
      setFetchingImportableLists(false)
    } catch (e) {
      setFetchingImportableLists(false)
      console.log(e)
    }
  }

  const handleCloseImportModal = () => {
    setShowImportModal(false)
  }

  const handleShowImportModal = () => {
    if (selectedImportableLists?.length === 0) {
      setSelectedImportableLists(sequence.contact_lists ?? []);
    }
    setShowImportModal(true)
    fetchImportableLists()
  }

  const removeList = (list) => {
    let existingLists = [...lists]
    const updatedLists = lists.filter((item) => item !== list)
    APIService.updateSequence(workspace._id, sequence?._id, { contact_lists: updatedLists }, (response, error) => {
      if (error) {
        setLists(existingLists)
        toast.error(error, { theme: "colored" })
        return
      }
      //TODO: Stop page reloading later
      window.location.reload();
    })
  }

  // const handleSearch = (e) => {
  //   setImportedListsSearchQuery(e.target.value)
  // }

  const addSelectedLists = async () => {
    let updatedLists = Array.from(new Set([...selectedImportableLists]))
    if (updatedLists?.length !== lists?.length) {
      setAddingMoreLists(true)
      APIService.updateSequence(workspace._id, sequence?._id, { contact_lists: updatedLists }, (response, error) => {
        setAddingMoreLists(false)
        if (error) {
          toast.error(error, { theme: "colored" })
          return
        }
        let { message, data } = response
        toast.success(message, { theme: "colored" })
        setSequence(data)
        setLists(updatedLists)
        window.location.reload()
      })
    }
    setSelectedImportableLists([])
    handleCloseImportModal()
  }

  return (
    <>
      <Row className="mt-4">
        {sequence?.contact_lists?.length > 0 && (
          <Col>
            <Card>
              <FalconCardHeader title="Sending to" endEl={<span style={{ color: '#7CB342' }}>
              <div className="text-end">
                    {
                      addingMoreLists ? (
                        <Button variant="primary" className="d-inline-flex flex-center gap-1" disabled>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          Adding lists...
                        </Button>
                      ) : (
                        <Button pill variant="outline-info" className="cursor-pointer" onClick={handleShowImportModal}>
                          Add lists
                        </Button>
                      )}
                  </div>
              </span>} />

              <Card.Body>
                {/* <Form>
                  <Form.Group className="my-3">
                    <Form.Control
                      type="text"
                      placeholder="Search lists"
                      value={importedListsSearchQuery}
                      onChange={handleSearch}
                    />
                  </Form.Group>
                </Form> */}
                <ListGroup>
                  {
                    sequenceLists?.data?.filter(
                      (item) =>
                        item?.name?.toLowerCase().includes(importedListsSearchQuery.toLowerCase()) ||
                        item?.email?.toLowerCase().includes(importedListsSearchQuery.toLowerCase()),
                    )
                      .map((item, index) => (
                        <ListGroup.Item key={index} className="d-flex justify-content-between">
                          <span>{StringUtils.highlightMatchingText(item["name"], importedListsSearchQuery)}</span>{" "}
                          <b>{item.totalItems}</b>
                          <Button
                            variant="outline-danger"
                            className="cursor-pointer rounded-pill"
                            onClick={() => removeList(item._id)}
                          >
                            Remove
                          </Button>
                        </ListGroup.Item>
                      ))}
                </ListGroup>
                {/* <Card.Footer>
                  <div className="text-end">
                    {
                      addingMoreLists ? (
                        <Button variant="primary" className="d-inline-flex flex-center gap-1" disabled>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          Adding lists...
                        </Button>
                      ) : (
                        <Button pill variant="outline-info" className="cursor-pointer" onClick={handleShowImportModal}>
                          Add more lists
                        </Button>
                      )}
                  </div>
                </Card.Footer> */}
              </Card.Body>
            </Card>
          </Col>
        )}
        {
          sequence?.contact_lists?.length < 1 && !addingMoreLists && (
            <Col>
              <GetStartedView getStartedClickHandler={handleShowImportModal} />
            </Col>
          )
        }
        {
          sequence?.contact_lists?.length < 1 && addingMoreLists && (
            <Flex alignItems={"center"} justifyContent={"center"} direction={"column"}>
              <Flex alignItems={"center"} justifyContent={"center"} direction={"column"}>
                <ReactLoading type={"bubbles"} color={"#0052cc"} />
                <span>Adding lists...</span>
              </Flex>
            </Flex>
          )
        }
      </Row>
      <Modal show={showImportModal} onHide={handleCloseImportModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Lists</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Select lists (Send to as many lists as you wish.)</p>
          <Form>
            <Form.Group className="mb-2">
              <Form.Control
                type="text"
                placeholder="Search lists to add"
                value={importableListsSearchQuery}
                onChange={(e) => {
                  setImportableListsSearchQuery(e.target.value)
                }}
              />
            </Form.Group>
            {
              workspaceLists?.data?.length > 0 && (
                <ListGroup>
                  {workspaceLists?.data
                    ?.filter(
                      (item) => {
                        return item?.name?.toLowerCase().includes(importableListsSearchQuery?.toLowerCase())
                      },
                    )
                    .map((item) => (
                      <ListGroup.Item key={item?._id} className="d-flex justify-content-between">
                        <span>{StringUtils.highlightMatchingText(item["name"], importableListsSearchQuery)}</span>{" "}
                        <b>{item.totalItems}</b>
                        {
                          selectedImportableLists.includes(item._id) ? (
                            <Button
                              variant="outline-danger"
                              className="cursor-pointer rounded-pill"
                              onClick={() => toggleListSelection(item._id)}
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              variant="outline-primary"
                              className="cursor-pointer rounded-pill"
                              onClick={() => toggleListSelection(item._id)}
                            >
                              Add
                            </Button>
                          )
                        }
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              )}
            {
              isWorkspaceListLoading && (
                <Flex alignItems={"center"} justifyContent={"center"} direction={"column"}>
                  <Flex alignItems={"center"} justifyContent={"center"} direction={"column"}>
                    <ReactLoading type={"bubbles"} color={"#0052cc"} />
                  </Flex>
                </Flex>
              )
            }
            {
              fetchingImportableLists && (
                <Flex alignItems={"center"} justifyContent={"center"} direction={"column"}>
                  <Flex alignItems={"center"} justifyContent={"center"} direction={"column"}>
                    <ReactLoading type={"bubbles"} color={"#0052cc"} />
                  </Flex>
                </Flex>
              )
            }
            {
              workspaceLists?.data?.length < 1 && (
                <div>
                  <span>No available lists to select from. </span>
                  <Link to={RoutePaths.LISTS} className="flex-1">
                    Manage Lists
                  </Link>
                </div>
              )}
          </Form>
        </Modal.Body>
        {
          importableLists?.length > 0 && (
            <Modal.Footer>
              <p className="text-muted mb-3 fs--1">
                The lists above are sourced from the <Link to={RoutePaths.LISTS} className="flex-1">Lists page</Link>. Use the "Manage Lists" button to create more lists, and
                they will appear here.
              </p>
              <Link to={RoutePaths.LISTS} className="flex-1">
                Manage Lists
              </Link>
              <Button variant="secondary" onClick={handleCloseImportModal}>
                Close
              </Button>
              <Button disabled={selectedImportableLists?.length === 0} variant="primary" onClick={addSelectedLists}>
                Done
              </Button>
            </Modal.Footer>
          )
        }
      </Modal>
    </>
  )
}
