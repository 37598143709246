import React, { useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import WorkSpaceManager from 'utils/workspace_manager';
import APIService from 'http/api_service';
import { PERMISSION_VIEW_AND_MANAGE_PLATFORMS, PlatformIdentifier } from '../../../constants';
import {
    faFacebookF, faTwitter,
    faInstagram, faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import InstagramSvgIcon from 'assets/images/instagram.svg';

export let platforms = [
    {
        name: "Facebook",
        color: "#4267B2",
        icon: faFacebookF,
        identifier: PlatformIdentifier.FACEBOOK,
        selected: false,
        textLimit: 62206,
        metaFamily:true
    },
    {
        name: "Twitter",
        color: "#1DA1F2",
        icon: faTwitter,
        identifier: PlatformIdentifier.TWITTER,
        selected: false
    },
    {
        name: "Instagram",
        color: "#EB4869",
        icon: faInstagram,
        identifier: PlatformIdentifier.INSTAGRAM,
        svg: InstagramSvgIcon,
        selected: false,
        textLimit: 2200,
        metaFamily:true,
    },
    {
        name: "LinkedIn",
        color: '#0077b5',
        icon: faLinkedin,
        identifier: PlatformIdentifier.LINKED_IN,
        selected: false,
        textLimit: 3000,
        pro: true,
    },
];

export function findPlatformByIdentifier(identifier) {
    let platformArrays = Object.entries(platforms).map((entry) => entry);
    let platform = platformArrays.find((x) => x[1]['identifier'] === identifier);
    if (platform) {
        platform = { ...platform[1] };
    }
    return platform;
}

export function findPlatformByName(name) {
    let platformArrays = Object.entries(platforms).map((entry) => entry);
    let platform = platformArrays.find((x) => x[1]['name'].toLowerCase() === name.toLowerCase());
    if (platform) {
        platform = { ...platform[1] };
    }
    return platform;
}

const PlatformPage = () => {

    const workSpace = WorkSpaceManager.getActiveWorkSpace();
    const [connectedPlatformsAccountStat, setConnectedPlatformsAccountStat] = useState();

    const fetchConnectedSocialsCount = () => {
        APIService.fetchConnectedSocialsCount(workSpace._id, (response, error) => {
            if (response) {
                let { data } = response;
                let { connected, total } = data;
                let stats = { total, connected };
                stats['true_connected'] = connected;
                connected = Math.max(connected, 1);
                stats['completed'] = Math.max(connected, 10);
                if (typeof total !== 'number') {
                    stats['max'] = connected;
                } else {
                    stats['max'] = Math.min(total, 100);
                }
                setConnectedPlatformsAccountStat(stats);
            }
        });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchConnectedSocialsCount();
    }, []);

    return (
        <>
            <PageHeader title="Platforms" titleTag="h5" className="mb-3">
                <p className="fs--1 mt-1">Connect your social account(s) to start posting</p>
                {
                    connectedPlatformsAccountStat && WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_AND_MANAGE_PLATFORMS) &&
                    <div>
                        <strong className="me-2">Connected: </strong>
                        <SoftBadge pill bg="success" className="fs--2">
                            {' '}
                            {`${connectedPlatformsAccountStat.connected}/${connectedPlatformsAccountStat.total}`}
                            <FontAwesomeIcon
                                icon="check"
                                className="ms-1"
                                transform="shrink-2"
                            />
                        </SoftBadge>
                    </div>
                }
            </PageHeader>
        </>
    );
};

export default PlatformPage;