import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import UserManager from 'utils/user_manager';
import { RoutePaths } from '../../../constants';
import { useSelector } from 'react-redux';
import { getLoggedInUserAvatar } from '../../../redux/slices/workspaceslice';
import Flex from 'components/common/Flex';
import {
  faArrowRightArrowLeft,
  faGear,
  faRightFromBracket,
  faRotate,
  faUpRightFromSquare,
  faDollarSign,

} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setFlipToChildPages } from '../../../redux/slices/authSlice';

const ProfileDropdown = (props) => {

  const { skipHeader = false, skipSettings = false, avatarSize = 'xl', allowSwitchToAnotherWorkspace = false, onWorkspaceSwitchHandler } = props;
  const loggedInUser = UserManager.getLoggedInUser();
  const loggedInUserAvatar = useSelector(getLoggedInUserAvatar);
  const dispatch = useDispatch();

  return (
    <Dropdown navbar={true}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={'span'}
        id={"profileDropdown"}
        className="pe-0 ps-2 nav-link cursor-pointer"
      >
        {
          loggedInUserAvatar ?
            <Avatar src={loggedInUserAvatar} size={avatarSize} />
            :
            <Avatar name={loggedInUser?.first_name ?? "U"} size={avatarSize} />
        }
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {
            !skipHeader &&
            <Dropdown.Item as={Link} className="fw-bold text-warning" to={RoutePaths.ACCOUNT}>
              <Flex alignItems={'center'} className={'gap-2'}>
                <FontAwesomeIcon icon="user"  className='fs-1' />
                <span className='flex-1'>{`${loggedInUser?.first_name} ${loggedInUser?.last_name}`}</span>
                <FontAwesomeIcon icon={faUpRightFromSquare} />
              </Flex>
            </Dropdown.Item>
          }
          {
            !skipHeader &&
            <Dropdown.Divider />
          }
          {
            !skipSettings &&
            <Dropdown.Item as={Link} to={RoutePaths.ACCOUNT}>
              <Flex alignItems={'center'} className={'gap-2'}>
                <FontAwesomeIcon icon={faGear}  className='fs-1' />
                <span>Settings</span>
              </Flex>
            </Dropdown.Item>
          }
          {
            allowSwitchToAnotherWorkspace &&
            <Dropdown.Item
              onClick={() => {
                onWorkspaceSwitchHandler();
              }}>
              <Flex alignItems={'center'} className={'gap-2'}>
                <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                <span>Switch Workspace</span>
              </Flex>
            </Dropdown.Item>
          }
          {
            allowSwitchToAnotherWorkspace &&
            <Dropdown.Divider />
          }
          <Dropdown.Item
            onClick={() => {
              dispatch(setFlipToChildPages(false));
            }}
            as={Link}
              to={RoutePaths.BILLING}>
            <Flex alignItems={'center'} className={'gap-2'}>
              <FontAwesomeIcon icon={faDollarSign}  className='fs-1' />
              <span>Plans & Billing</span>
            </Flex>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              dispatch(setFlipToChildPages(false));
            }}
            as={Link}
            to={`${RoutePaths.LOGIN}?switch=true`}>
            <Flex alignItems={'center'} className={'gap-2'}>
              <FontAwesomeIcon icon={faRotate} className='fs-1' />
              <span>Switch Accounts</span>
            </Flex>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to={RoutePaths.LOGOUT}>
            <Flex alignItems={'center'} className={'gap-2'}>
              <FontAwesomeIcon icon={faRightFromBracket}  className='fs-1' />
              <span>Logout</span>
            </Flex>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
