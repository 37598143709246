import React from 'react';
import { useParams } from 'react-router-dom';
import { Buffer } from 'buffer';
import loadable from '@loadable/component';
import Flex from 'components/common/Flex';
import { Spinner } from 'react-bootstrap';
import ConnectedPlatformInfoHeader from './connected_platform_info_header';

const LoadConnectedAccountPages = (account) => {
    let Page = loadable(() => import(`../${account.target}`), {
        fallback: <Spinner animation='border' size={20} />
    });
    return <Page platform={account} />
}

export default function ConnectedAccountDataPage() {

    let { accountInfo } = useParams();
    let platform;
    try {
        let hexString = Buffer.from(accountInfo, "hex").toString();
        platform = JSON.parse(hexString);
    } catch (e) { }

    return (
        <>
            {
                platform &&
                <Flex direction={'column'}>
                    <ConnectedPlatformInfoHeader platform={platform} />
                    <Flex alignItems={'center'} direction={'column'}>
                        {LoadConnectedAccountPages(platform)}
                    </Flex>
                </Flex>
            }
        </>
    );

}