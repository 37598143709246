import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import { Alert, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LastMessage from './LastMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChatSidebarDropdownAction from './ChatSidebarDropdownAction';
import { ChatContext } from 'context/Context';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { PlatformIdentifier } from '../../../../constants';
import { whatsApp } from '../setup/MessagingAccountsConnector';
import WorkSpaceManager from 'utils/workspace_manager';
import { getTagForPages } from 'utils/gen';

const incomingPageOrAccountVariantMapping = {
  [PlatformIdentifier.INSTAGRAM]: 'warning',
  [PlatformIdentifier.WHATSAPP]: 'success',
  [PlatformIdentifier.TWITTER]: 'info',
  [PlatformIdentifier.LINKED_IN]: 'dark',
  [PlatformIdentifier.FACEBOOK]: 'primary'
}

const IncomingPageOrAccountPreview = ({ platform }) => {
  if (!platform) {
    return <></>
  }
  let item = findPlatformByIdentifier(platform.target);
  if (platform.target === PlatformIdentifier.WHATSAPP) {
    item = whatsApp;
  }
  let platformName = item.name;
  item = { ...item, ...platform };
  let targetName = getTagForPages(item, false);
  return (
    <OverlayTrigger overlay={<Tooltip>{`This message is associated with your connected ${platformName} ${targetName} (${item.name})`}</Tooltip>}>
      <span>
        <Alert variant={incomingPageOrAccountVariantMapping[platform.target]} className='p-0 px-2 m-0 fs--2' style={{ display: 'inline-block' }}>
          <Flex alignItems={'center'} className={'gap-1'}>
            <FontAwesomeIcon style={{ width: 10, height: 10 }} icon={item?.icon} />
            <span style={{ fontSize: 10 }}>{item.name}</span>
          </Flex>
        </Alert>
      </span>
    </OverlayTrigger>
  )
}


const ConversationView = ({ inbox, thread, index, isLastItem, getAllUnifiedInbox, noMoreMailsToFetch }) => {
  const { getUser, messages } = useContext(ChatContext);
  const user = getUser(thread);
  const mailProps = inbox;

  const getTruncatedSubjectText = (HtmlText) => {
    let truncatedText = HtmlText;
    if (HtmlText) {
      truncatedText = HtmlText.replace(/<[^>]+>/g, '');
      if (truncatedText.length > 40) {
        truncatedText = truncatedText.substring(0, 40) + '...';
      }
    }
    return truncatedText;
  }

  const targetRef = useRef(null);

  useEffect(() => {

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (isLastItem && !noMoreMailsToFetch) {
            getAllUnifiedInbox(true);
          }
        }
      },
      { threshold: 0.5 }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <Nav.Link
      eventKey={index}
      className={classNames(`chat-contact hover-actions-trigger p-3`, {
        'unread-message': !thread.read,
        'read-message': thread.read
      })}
      style={{ height: '5rem' }}
      ref={targetRef}
    >
      <div className="d-md-none d-lg-block">
        <ChatSidebarDropdownAction />
      </div>
      <Flex>
        <Avatar className={user?.status} src={user?.avatarSrc} size="xl" />
        <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
          <Flex justifyContent="between">
            <h6 className="mb-0 chat-contact-title">{mailProps?.owner}</h6>
            <span className="message-time fs--2">
              {' '}
              {/* {!!lastMessage && lastMessage.time.day}{' '} */}
            </span>
          </Flex>
          <div className="min-w-0">
            <div className="chat-contact-content pe-3">
              <LastMessage lastMessage={''} thread={thread} lastMsg={getTruncatedSubjectText(thread?.text)} />
            </div>
          </div>
        </div>
      </Flex>
    </Nav.Link>
  );
};


ConversationView.propTypes = {
  thread: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default ConversationView;
