import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import APIService from "http/api_service";
import WorkSpaceManager from "utils/workspace_manager";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import NewCampaignAnimation from "assets/animations/campaign.json";
import ReactLoading from "react-loading";
import corner6 from "assets/img/illustrations/corner-6.png";
import { RoutePaths } from "../../../../../constants";
import {
  faCalendarPlus,
  faEdit,
  faEllipsisH,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "components/common/ConfirmModal";
import { toast } from "react-toastify";
import useCourses from "hooks/useCourses";
import IconButton from "components/common/IconButton";
import PropTypes from "prop-types";
import Switch from "react-switch";
import usePagination from "hooks/usePagination";
import AddScheduleModal from "./AddScheduleModal";
import AppContext from "context/Context";
import events from "data/calendar/events";

const TicketsCategory = ({ value, label, percentage, icon, badgeColor }) => {
  return (
    <div>
      <h6 className="fs--2 text-600 mb-0 text-nowrap">{label}</h6>
      <h6 className="fs-0 d-flex align-items-center text-700 mb-1">
        {value}
        {/* <Badge pill bg="transparent" className={classNames(badgeColor, "px-0")}>
            <FontAwesomeIcon icon={icon} className="fs--2 ms-2 me-1" />
            {percentage}
            </Badge> */}
      </h6>
    </div>
  );
};

TicketsCategory.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  badgeColor: PropTypes.string,
};

const Sequences = () => {
  const navigate = useNavigate();
  const workspace = WorkSpaceManager.getActiveWorkSpace();
  const [campaigns, setCampaigns] = useState([]);
  const [loadingcampaigns, setLoadingcampaigns] = useState(true);
  const [selectedRowIds, setSelectedRowIds] = useState();
  const [selectedItems, setSelectedItems] = useState();
  const [openCampaignDeletePrompt, setOpenCampaignDeletePrompt] =
    useState(false);
  const [actionableCampaign, setActionableCampaign] = useState();
  const { handleSearch, handleIsAscClick, handleSortByChange, isAsc } =
    useCourses();
  const {
    paginationState: {
      data: paginatedTicket,
      currentPage,
      canNextPage,
      canPreviousPage,
      paginationArray,
    },
    nextPage,
    prevPage,
    goToPage,
  } = usePagination(campaigns, 10);

  const {
    config: { isRTL },
  } = useContext(AppContext);
  const calendarRef = useRef();
  const [title, setTitle] = useState("");
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState("Month View");
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState({});
  const [scheduleStartDate, setScheduleStartDate] = useState();
  const [scheduleEndDate, setScheduleEndDate] = useState();

  const eventList = events.reduce(
    (acc, event) =>
      event.schedules
        ? acc.concat(event.schedules.concat(event))
        : acc.concat(event),
    []
  );

  const eventTimeFormat = {
    hour: "numeric",
    minute: "2-digit",
    omitZeroMinute: true,
    meridiem: true,
  };

  const handleEventClick = (info) => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      setModalEventContent(info);
      setIsOpenEventModal(true);
    }
  };

  const [initialEvents, setInitialEvents] = useState(eventList);
  const viewName = [
    "Month View",
    "Week View",
    "Day View",
    "List View",
    "Year View",
  ];

  const handleFilter = (filter) => {
    setCurrentFilter(filter);
    switch (filter) {
      case "Month View":
        calendarApi.changeView("dayGridMonth");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case "Week View":
        calendarApi.changeView("timeGridWeek");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case "Day View":
        calendarApi.changeView("timeGridDay");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case "List View":
        calendarApi.changeView("listWeek");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView("listYear");
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  useEffect(() => {
    // setCalendarApi(calendarRef.current.getApi());
  }, []);

  useEffect(() => {
    if (workspace) {
      fetchCampaigns();
    }
  }, []);

  const fetchCampaigns = async () => {
    setLoadingcampaigns(true);
    try {
      let { data } = await APIService.fetchCampaigns(workspace._id);
      setCampaigns(data);
    } catch (e) {
      console.log(e);
    }
    setLoadingcampaigns(false);
  };

  const createNewCampaign = () => {
    navigate(RoutePaths.CREATE_NEW_SEQUENCE);
  };

  const GetStartedView = React.forwardRef((props, ref) => {
    return (
      <Flex
        className={"gap-2"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "auto",
          height: "auto",
        }}
      >
        <Lottie
          animationData={NewCampaignAnimation}
          style={{
            width: 300,
            height: 300,
          }}
        />
        <h5>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="30"
            width="30"
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: "rgb(255, 201, 0)", marginTop: "-4px" }}
          >
            <g>
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M9.973 18h4.054c.132-1.202.745-2.194 1.74-3.277.113-.122.832-.867.917-.973a6 6 0 1 0-9.37-.002c.086.107.807.853.918.974.996 1.084 1.609 2.076 1.741 3.278zM14 20h-4v1h4v-1zm-8.246-5a8 8 0 1 1 12.49.002C17.624 15.774 16 17 16 18.5V21a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-2.5C8 17 6.375 15.774 5.754 15zM13 10.004h2.5l-4.5 6v-4H8.5L13 6v4.005z"></path>
            </g>
          </svg>{" "}
          Build an automated process to set meetings
        </h5>
        <Button
          style={{
            marginTop: "2rem",
          }}
          variant={"primary"}
          onClick={() => {
            createNewCampaign();
          }}
        >
          <Flex alignItems={"center"} className={"gap-1"}>
            <FontAwesomeIcon icon={faPlus} />
            <span>Add New Sequence</span>
          </Flex>
        </Button>
      </Flex>
    );
  });

  const deleteCampaign = async (campaign) => {
    let backupcampaigns = [...campaigns];
    setCampaigns([]);
    setLoadingcampaigns(true);
    APIService.deleteCampaign(workspace._id, campaign._id, (res, err) => {
      if (err) {
        setCampaigns(backupcampaigns);
        toast.error(err, { theme: "colored" });
        return;
      }
      fetchCampaigns();
    });
  };

  const CampaignActionTextAndIconOption = ({
    iconColor,
    icon,
    text,
    textColor,
    customIcon,
  }) => {
    return (
      <span className="cursor-pointer">
        <Flex className={"gap-1"} alignItems={"center"}>
          {customIcon ? (
            customIcon
          ) : (
            <FontAwesomeIcon
              className={iconColor}
              icon={icon}
              color={iconColor}
            />
          )}
          <span
            style={{
              color: textColor,
            }}
            className={`fw-medium ${textColor}`}
          >
            {text}
          </span>
        </Flex>
      </span>
    );
  };

  const CustomDropdownMenuItem = ({ title, icon }) => {
    return (
      <Flex alignItems={"center"} className={"gap-3"}>
        <FontAwesomeIcon className="text-600" icon={icon} />
        <span className="fw-medium" style={{ fontSize: 16 }}>
          {title}
        </span>
      </Flex>
    );
  };

  return (
    <>
      <Flex alignItems={"center"} justifyContent={"center"}>
        {campaigns.length < 1 && loadingcampaigns && (
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
            >
              <ReactLoading type={"bubbles"} color={"#0052cc"} />
            </Flex>
          </Flex>
        )}
      </Flex>
      {!loadingcampaigns && campaigns.length < 1 && <GetStartedView />}
      {campaigns.length > 0 && (
        <Card className="mt-4">
          <Card.Header
            className="position-relative"
            style={{
              backgroundImage: corner6,
            }}
          >
            <div className="d-lg-flex justify-content-between">
              <Row className="flex-between-center gy-2 px-x1">
                <Col xs="auto" className="pe-0">
                  <h5 className="mb-0">Schedules</h5>
                </Col>
              </Row>
              <div className="border-bottom border-200 my-3"></div>
              <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="filter"
                  transform="shrink-4"
                  iconAlign="middle"
                  // onClick={handleShow}
                  className="d-xl-none"
                >
                  <span className="d-none d-sm-inline-block ms-1">Filter</span>
                </IconButton>
                <div
                  className="bg-300 mx-3 d-none d-lg-block d-xl-none"
                  style={{ width: "1px", height: "29px" }}
                ></div>
                {(selectedRowIds && Object.keys(selectedRowIds).length > 0) ||
                (selectedItems && selectedItems.length > 0) ? (
                  <div className="d-flex">
                    <Form.Select size="sm" aria-label="Bulk actions">
                      <option>Bulk Actions</option>
                      <option value="refund">Refund</option>
                      <option value="delete">Delete</option>
                      <option value="archive">Archive</option>
                    </Form.Select>
                    <Button
                      type="button"
                      variant="falcon-default"
                      size="sm"
                      className="ms-2"
                    >
                      Apply
                    </Button>
                  </div>
                ) : (
                  <div id="orders-actions">
                    <IconButton
                      onClick={() => {
                        setIsOpenScheduleModal(!isOpenScheduleModal);
                      }}
                      icon={faCalendarPlus}
                      variant="primary"
                      iconClassName="me-2"
                    >
                      Create a new schedule
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          </Card.Header>
          <Card.Body className=" pt-0 pt-md-3">
            {campaigns.map((campaign, index) => (
              <Card className="pt-0 pt-md-3 mb-2">
                <Card.Body className="scrollbar overflow-y-hiden pt-0">
                  <div className={`d-md-flex justify-content-between `}>
                    <div
                      className="d-flex  cursor-pointer"
                      onClick={() => {
                        navigate(`${RoutePaths.SEQUENCES}/${campaign._id}`);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <TicketsCategory
                          label="Name"
                          value="Default schedule"
                          icon="caret-up"
                          badgeColor="text-success"
                        />
                        <div
                          className="bg-200 mx-3"
                          style={{ height: "24px", width: "1px" }}
                        ></div>
                      </div>
                      <div className="d-flex align-items-center">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Active Contacts</Tooltip>}
                        >
                          <div>
                            <TicketsCategory
                              label="Days"
                              value="-"
                              percentage="3"
                              icon="caret-up"
                              badgeColor="text-primary"
                            />
                          </div>
                        </OverlayTrigger>
                        <div
                          className="bg-200 mx-3"
                          style={{ height: "24px", width: "1px" }}
                        ></div>
                      </div>
                      <div className="d-flex align-items-center">
                        <TicketsCategory
                          label="Start at"
                          value="-"
                          percentage="2"
                          icon="caret-down"
                          badgeColor="text-warning"
                        />
                        <div
                          className="bg-200 mx-3"
                          style={{ height: "24px", width: "1px" }}
                        ></div>
                      </div>
                      <div className="d-flex align-items-center">
                        <TicketsCategory
                          label="End at"
                          value="-"
                          percentage="2"
                          icon="caret-down"
                          badgeColor="text-warning"
                        />
                        <div
                          className="bg-200 mx-3"
                          style={{ height: "24px", width: "1px" }}
                        ></div>
                      </div>
                      <div className="d-flex align-items-center">
                        <TicketsCategory
                          label="Send every"
                          value="-"
                          percentage="2"
                          icon="caret-down"
                          badgeColor="text-warning"
                        />
                        <div
                          className="bg-200 mx-3"
                          style={{ height: "24px", width: "1px" }}
                        ></div>
                      </div>
                      <div className="d-flex align-items-center">
                        <TicketsCategory
                          label="Timezone"
                          value="-"
                          percentage="2"
                          icon="caret-down"
                          badgeColor="text-warning"
                        />
                        <div
                          className="bg-200 mx-3"
                          style={{ height: "24px", width: "1px" }}
                        ></div>
                      </div>
                    </div>
                    <Row className="g-md-0">
                      <Col xs="auto" className="d-md-flex">
                        <Dropdown align="end" className="font-sans-serif">
                          <Dropdown.Toggle
                            size="sm"
                            variant="falcon-default"
                            className="dropdown-caret-none"
                          >
                            <FontAwesomeIcon icon="ellipsis-h" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="border py-2">
                            <Dropdown.Item href="#!">
                              Modify this schedule
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#!" className="text-danger">
                              Delete this schedule
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </Card.Body>
          <Card.Footer className="d-flex justify-content-center">
            <div>
              <Button
                variant="falcon-default"
                size="sm"
                className={classNames("me-2", { disabled: !canPreviousPage })}
                onClick={prevPage}
              >
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
            </div>

            <ul className="pagination mb-0">
              {paginationArray.map((page) => (
                <li
                  key={page}
                  className={classNames({ active: currentPage === page })}
                >
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="page me-2"
                    onClick={() => goToPage(page)}
                  >
                    {page}
                  </Button>
                </li>
              ))}
            </ul>
            <div>
              <Button
                variant="falcon-default"
                size="sm"
                className={classNames({ disabled: !canNextPage })}
                onClick={nextPage}
              >
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
            </div>
          </Card.Footer>
        </Card>
      )}
      <AddScheduleModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        initialEvents={initialEvents}
        setInitialEvents={setInitialEvents}
        scheduleStartDate={scheduleStartDate}
        scheduleEndDate={scheduleEndDate}
        setScheduleStartDate={setScheduleStartDate}
        setScheduleEndDate={setScheduleEndDate}
      />
      <ConfirmModal
        open={openCampaignDeletePrompt}
        message={`Are you sure about deleting this campaign?`}
        title={`Delete ${actionableCampaign?.name}`}
        confirmText={"DELETE"}
        cancelText={"CANCEL"}
        onConfirm={() => {
          deleteCampaign(actionableCampaign);
          setOpenCampaignDeletePrompt(false);
        }}
        onCancel={() => {
          setOpenCampaignDeletePrompt(false);
          setActionableCampaign(undefined);
        }}
      />
    </>
  );
};

export default Sequences;
