import React, { useContext, useEffect, useState } from "react";
import APIService from "../../../../http/api_service";
import { toast } from "react-toastify";
import UserManager from "utils/user_manager";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import WorkSpaceManager from 'utils/workspace_manager';
import DataTable, { createTheme } from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../../constants";
import AppContext from "context/Context";
import RichInfoBar from "components/common/RichInfoBar";
import { Button, Card, Col, Form, Modal, Spinner, Row } from "react-bootstrap";
import Flex from "components/common/Flex";

export default function CreditsPage(props) {

    const [redeemableCode, setRedeemableCode] = useState();
    const [accountId, setAccountId] = useState();
    const [vouchers, setVouchers] = useState([]);
    const [loadingvouchers, setLoadingVouchers] = useState(true);
    const [redeeming, setRedeeming] = useState(false);
    const workSpace = WorkSpaceManager.getActiveWorkSpace();
    const workSpaceId = workSpace._id;
    const [headsUp, setHeadsUp] = useState(localStorage.getItem('life_time_deal_text'));
    const loggedInUser = UserManager.getLoggedInUser();
    const navigate = useNavigate();
    const { config } = useContext(AppContext);
    const [openHeadsUp, setOpenHeadsUp] = useState(false);

    const creditColumns = [
        {
            name: 'Plan',
            selector: row => row.issuer,
        },
        {
            name: 'Code Used',
            selector: row => row.code,
        },
        {
            name: 'Access',
            selector: row => row.access
        }
    ];

    useEffect(() => {
        if (config.isDark) {
            createTheme('solarized', {
                text: {
                    primary: '#9da9bb'
                },
                background: {
                    default: '',
                },
            }, 'light');
        }
    }, [config]);

    useEffect(() => {
        if (headsUp) {
            setTimeout(() => {
                localStorage.removeItem('life_time_deal_text');
            }, 500);
        }
    }, [headsUp]);

    const initializeData = () => {
        APIService.fetchWorkSpaceOwner(workSpaceId, (response, err) => {
            if (err) {
                toast.error(err, { theme: 'colored' });
                if (err.toLowerCase().includes("owner not found")) {
                    WorkSpaceManager.deleteActiveWorkSpace();
                    navigate(RoutePaths.REDIRECT);
                }
                return;
            }
            let { _id: account_id } = response.data;
            setAccountId(account_id);
            fetchVouchersByAccount(account_id);
        });
    }

    const fetchVouchersByAccount = (accId) => {
        if (loggedInUser) {
            if (loggedInUser._id === accId) {
                setLoadingVouchers(true);
                APIService.showAttachedVoucherCode(accId, (response1, err) => {
                    if (err) {
                        toast.error(err, { theme: 'colored' });
                        return;
                    }
                    let { data } = response1;
                    data = data.map((x) => {
                        x['access'] = 'Premium';
                        return x;
                    });
                    setVouchers(data);
                    setLoadingVouchers(false);
                });
            } else {
                setLoadingVouchers(false);
            }
        } else {
            setLoadingVouchers(false);
        }
    };

    useEffect(() => {
        if (!accountId) {
            initializeData();
        }
    }, []);

    const redeemVoucher = (e) => {
        if (redeemableCode) {
            setRedeeming(true);
            APIService.fetchWorkSpaceOwner(workSpaceId, (response, err) => {
                if (err) {
                    toast.error(err, { theme: 'colored' });
                    return;
                }
                let { _id: account_id } = response.data;
                APIService.redeemVoucher(redeemableCode, account_id, (voucherValidationResponse, error) => {
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        setRedeeming(false);
                        return;
                    }
                    APIService.fetchLatestMe((response, error) => {
                        if (error) {
                            toast.error(error, { theme: 'colored' });
                            return;
                        }
                        let { data } = response;
                        UserManager.setLoggedInUser(data);
                        setRedeeming(false);
                        let validationResponseData = voucherValidationResponse.data;
                        let validationMessage = voucherValidationResponse.message;
                        let { meta } = validationResponseData;
                        if (meta) {
                            let destinationUrl = window.location.origin;
                            window.location.href = `${destinationUrl}/billing?meta=${meta}`;
                        } else {
                            setRedeemableCode("");
                            fetchVouchersByAccount(accountId);
                            toast.success(validationMessage, { theme: 'colored' });
                        }
                    });
                });
            });
        }
    }

    const CreditsTable = React.forwardRef((props, ref) => {
        return (
            <DataTable
                columns={creditColumns}
                data={vouchers}
                theme={config.isDark ? "solarized" : null}
            />
        )
    })

    return (
        <Card elevation={0}>
            <Card.Header>
        <Row className="align-items-center">
          <Col>
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon="crown" className={`text-success`} />
              </div>
              <div className="flex-1">
                <h5 className="mt-1">{`Redeem your Vouchers or Credits`}</h5>
              </div>
            </Flex>
          </Col>
        </Row>
      </Card.Header>
            <Card.Body className="p-0  border-top">
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 40,
                marginRight: 40,
                marginTop: 10,
            }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10,
            }}>
                {/* <Lottie
                    animationData={GoPremiumAnimation}
                    loop={false}
                    style={{
                        width: 50,
                        maxWidth: 150,
                        height: 50
                    }}
                /> */}
                
            </div>
            
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 10,
                }}>
                    <Row className="justify-content-center">
                            <Col md={12}>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        redeemVoucher(e)
                                    }} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap',
                                        gap: 10,
                                    }}>
                                    <Col lg>
                                        <Form.Control
                                            type="text"
                                            disabled={redeeming}
                                            onChange={(e) => setRedeemableCode(e.target.value)}
                                            value={redeemableCode}
                                            placeholder='Enter redeemable code'
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 600
                                            }}
                                        />
                                    </Col>
                                    <Col sm="auto">
                                        <Button
                                            isWorking={redeeming}
                                            variant={'primary'}
                                            disabled={redeeming || !redeemableCode}
                                            type={'submit'}>
                                            {`${redeeming ? 'Redeeming Code...' : 'Redeem Code'}`}
                                        </Button>
                                    </Col>
                                </form>
                            </Col>
                        </Row>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5
                        }}>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{
                                width: 15,
                                height: 15,
                            }}
                        />
                        <span
                            style={{
                                fontSize: 11,
                                textAlign: 'center'
                            }}>{'Redeemable codes apply strictly to your own Workspace(s) and not Workspaces you were invited to'}
                        </span>
                    </div>
                </div>
            
            <h5
                style={{
                    paddingTop: 20,
                    marginTop: 40,
                }}>Redeemed Codes &amp; Vouchers
            </h5>
            
                {
                    loadingvouchers ?
                        <p
                            type="p"
                            onClick={() => fetchVouchersByAccount(accountId)}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: "14px",
                                color: "#90A4AE",
                                textAlign: 'center',
                                cursor: "pointer",
                            }}
                        >
                            <span>Retrieving redeemed codes...</span>
                            <Spinner animation="border" size={20} />
                        </p>
                        : <>
                            {
                                vouchers.length === 0 ? (
                                    <p
                                        onClick={() => fetchVouchersByAccount(accountId)}
                                        style={{
                                            fontSize: "16px",
                                            textAlign: 'center',
                                            cursor: "pointer",
                                        }}
                                    >
                                        You've not redeemed any voucher yet
                                    </p>
                                ) : (
                                    <CreditsTable />
                                )
                            }
                        </>
                }
           
            <Modal show={openHeadsUp}
                onHide={() => setOpenHeadsUp(false)}
                onExit={() => setOpenHeadsUp(false)}
                centered
                size='lg'>
                <Modal.Body>
                    <RichInfoBar
                        title={"Great!"}
                        message={headsUp}
                        actionMessage={"Okay"}
                        action={() => {
                            setOpenHeadsUp(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
        </div>
        </Card.Body>
        </Card>
    );
}
