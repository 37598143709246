import FalconCardHeader from "components/common/FalconCardHeader"
import APIService from "http/api_service"
import React, { useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import Select from "react-select"
import { toast } from "react-toastify"
import useSWR from "swr"
import StringUtils from "utils/string"
import WorkSpaceManager from "utils/workspace_manager"
import Flex from "components/common/Flex"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ConfirmModal from "components/common/ConfirmModal"
import Divider from "components/common/Divider"
import { RoutePaths } from "constants"
import ReactLoading from "react-loading"
import { useNavigate } from "react-router-dom"
import Switch from "react-switch"

const fetcher = (id) =>
  APIService.fetchConnectedEmailAccounts(id).then((res) => res.data)

const WorkspaceSettings = ({ sequence }) => {
  const [activeWorkspace] = useState(WorkSpaceManager.getActiveWorkSpace())
  const [emailAccounts, setEmailAccounts] = useState(
    sequence?.sendingEmailAccounts || [],
  )
  const [deletingSequence, setDeletingSequence] = useState(false)
  const workspace = WorkSpaceManager.getActiveWorkSpace();
  const [openSequenceDeletePrompt, setOpenSequenceDeletePrompt] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const { data } = useSWR(
    activeWorkspace?._id ? activeWorkspace._id : null,
    fetcher,
  )
  const navigate = useNavigate()
  const [allowOpenRateTracking, setAllowOpenRateTracking] = useState(sequence?.allow_open_rate_tracking)
  const [stopOnReply, setStopOnReply] = useState(sequence?.stop_on_reply)

  const [formData, setFormData] = useState({
    name: sequence?.name,
    id: sequence?._id,
    role: StringUtils.capitalizeString(activeWorkspace?.role),
  })

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const updateOpenRateTracking = async (value, property) => {
    sequence[property] = value;
    const data = {
      allow_open_rate_tracking: value,
    }
    await APIService.updateSequence(
      activeWorkspace?._id,
      sequence?._id,
      data,
      (response, error) => {
        if (error) {
          if (property === "allow_open_rate_tracking") {
            setAllowOpenRateTracking(!value)
          } else {
            setStopOnReply(!value)
          }
          toast.error(error, { theme: "colored" })
          return
        }
        toast.success(response["message"], { theme: "colored" })
        // window.location.reload()
      },
    )
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const data = {
      sendingEmailAccounts: emailAccounts,
      name: formData.name,
    }
    try {
      await APIService.updateSequence(
        activeWorkspace?._id,
        sequence?._id,
        data,
        (response, error) => {
          if (error) {
            console.log("error", error)
            // toast.error(error, { theme: "colored" })
            return
          }
          toast.success(response["message"], { theme: "colored" })
          window.location.reload()
        },
      )
    } catch (error) {
      //  toast.error(error, { theme: "colored" })
      console.log("error", error)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteSequence = async () => {
    setDeletingSequence(true)
    APIService.deleteSequence(workspace._id, sequence._id, (res, err) => {
      if (err) {
        toast.error(err, { theme: "colored" });
        setDeletingSequence(false);
        return;
      }
      toast.success(res.message, { theme: "colored" });
      setDeletingSequence(false);
      navigate(RoutePaths.SEQUENCES)
    });
  };

  const emailOptions = data?.map((item) => ({
    value: item._id,
    label: item.email,
  }))
  return (
    <>
      <Card className="mt-4 shadow-none">
        <Form>
          <Card className="m-3 shadow-none border">
            <FalconCardHeader title="Campaign settings" />
            <Card.Body className="bg-light">
              <Row className="mb-2">
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Campaign name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Sequence Name"
                      value={formData.name}
                      name="name"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Created by</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Sequence Name"
                      value={`${sequence?.created_by?.first_name} ${sequence?.created_by?.last_name}`}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {emailOptions ? (
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>
                    Accounts to use (select one or more accounts for sending mail)
                  </Form.Label>
                  {emailOptions && (
                    <Select
                      closeMenuOnSelect={false}
                      // defaultValue={[emailOptions[0], emailOptions[1]]}
                      value={emailAccounts}
                      isMulti
                      options={emailOptions}
                      onChange={(e) => {
                        setEmailAccounts(e)
                      }}
                    />
                  )}
                </Form.Group>
              ) : (
                <Flex justifyContent={"left"} alignItems={"left"}>
                  Loading email sending accounts{" "}
                  <ReactLoading color={"teal"} width={20} heieght={20} />
                </Flex>
              )}

              <Divider />

              <h5 className="mb-3">Safety settings</h5>
              <Row>
                <Col lg={6}>
                  <div className="border border-1 border-300 rounded-2 p-3 ps-2 ask-analytics-item position-relative mb-3">
                    <Flex alignItems="center" className="mb-3">
                      <Switch
                        checked={stopOnReply}
                        onChange={(checked) => {
                          setStopOnReply(checked);
                          updateOpenRateTracking(checked, "stop_on_reply")
                        }}
                        height={20}
                        width={40}
                      />
                      <h5 className="fs-0 text-800 mb--4 ps-2">
                        Stop sending email on reply?
                      </h5>
                    </Flex>
                    <Flex alignItems="center" className="mb-0">
                      <FontAwesomeIcon
                        icon="info-circle"
                        className="text-primary"
                      />
                      <small className="fs--1 text-600 ps-2">
                        If a lead responds, any follow-up emails in the sequence
                        will not be sent to that specific lead.
                      </small>
                    </Flex>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="border border-1 border-300 rounded-2 p-3 pe-1 ps-2 ask-analytics-item position-relative mb-3">
                    <Flex alignItems="center" className="mb-3">
                      <Switch
                        checked={allowOpenRateTracking}
                        onChange={(checked) => {
                          setAllowOpenRateTracking(checked);
                          updateOpenRateTracking(checked, "allow_open_rate_tracking")
                        }}
                        height={20}
                        width={40}
                      />
                      <h5 className="fs-0 text-800 mb--4 ps-2">
                        {
                          allowOpenRateTracking ? "Disable open rate tracking?" : "Enable open rate tracking?"
                        }
                      </h5>
                    </Flex>
                    <Flex alignItems="center" className="mb-0">
                      <FontAwesomeIcon
                        icon="info-circle"
                        className="text-primary"
                      />
                      <small className="fs--1 text-600 ps-2">
                        {
                          allowOpenRateTracking ? "You will receive email open rate stats." : "You won't receive email open rate stats, but it enhances deliverability by removing tracking links, reducing the risk of being marked as spam."
                        }
                      </small>
                    </Flex>
                  </div>
                </Col>
              </Row>

              <Divider />

              <Button
                disabled={isLoading}
                className="mt-3"
                onClick={handleSubmit}
                variant="primary"
              >
                {isLoading ? "Saving..." : "Save"}
              </Button>
            </Card.Body>
          </Card>
        </Form>
      </Card>
      {/* Delete Sequence Button */}
      <Button
        disabled={isLoading}
        className="mt-3 mx-auto"
        onClick={() => {
          setOpenSequenceDeletePrompt(true);
        }}
        variant="danger"
      >
        {deletingSequence ? (
          <ReactLoading type="spin" color="#FFF" height={20} width={20} />
        ) : (
          "Delete Sequence"
        )}
      </Button>
      <ConfirmModal
        open={openSequenceDeletePrompt}
        message={`Are you sure about deleting this sequence?`}
        title={`Delete ${sequence?.name}`}
        confirmText={"DELETE"}
        cancelText={"CANCEL"}
        onConfirm={() => {
          deleteSequence();
          setOpenSequenceDeletePrompt(false);
        }}
        onCancel={() => {
          setOpenSequenceDeletePrompt(false);
        }}
      />
    </>

  )
}

export default WorkspaceSettings
