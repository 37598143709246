import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { useEffect, useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import TemplateListItem from "../template-list-item";
import { promptData } from "../data";

export default function TemplateHub(props) {

    const sequence = props.sequence;
    const handleSelectedPromptTemplate = props.handleSelectedPromptTemplate;
    const [searchText, setSearchText] = useState("");
    const popularity = [
        {
            value: 'most_views',
            label: 'Most Views'
        },
        {
            value: 'most_likes',
            label: 'Most Likes'
        },
        {
            value: 'most_comments',
            label: 'Most Comments'
        },
        {
            value: 'most_shares',
            label: 'Most Shares'
        },
        {
            value: 'highest_rating',
            label: 'Highest Rating'
        },
        {
            value: 'trending',
            label: 'Trending'
        },
        {
            value: 'most_downloads',
            label: 'Most Downloads'
        },
        {
            value: 'most_purchased',
            label: 'Most Purchased'
        },
        {
            value: 'most_favorited',
            label: 'Most Favorited'
        },
        {
            value: 'most_recommended',
            label: 'Most Recommended'
        }
    ];
    const [prompts, setPrompts] = useState(promptData);
    const [promptsCopy, setPromptsCopy] = useState([]);

    const filterPrompts = () => {
        if (searchText.length === 0) {
            if (promptsCopy.length !== 0) {
                setPrompts([...promptsCopy]);
                setPromptsCopy([]);
            }
            return;
        }
        if (promptsCopy.length === 0) {
            setPromptsCopy([...prompts]);
        }
        let searchedPrompts = promptsCopy.filter(prompt => {
            let safeSearchSearch = searchText?.toLowerCase();
            let titleSearch = prompt?.title?.toLowerCase().includes(safeSearchSearch)
            let descriptionSearch = prompt?.description?.toLowerCase().includes(safeSearchSearch);
            let categorySearch = prompt?.category?.toLowerCase().includes(safeSearchSearch);
            let authorSearch = prompt?.author?.name?.toLowerCase()?.includes(safeSearchSearch);
            let runsSearch = `${prompt.runs}`.includes(safeSearchSearch);
            return titleSearch || descriptionSearch || categorySearch || authorSearch || runsSearch;
        });
        setPrompts([...searchedPrompts]);
    }

    useEffect(() => {
        filterPrompts();
    }, [searchText]);

    return (
        <Flex direction={'column'} className={'py-4'}>
            <Flex direction={'row'}>
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <FormControl
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => {
                            let value = e.target.value;
                            setSearchText(value);
                        }}
                        style={{
                            boxShadow: 'none',
                            outline: 'none'
                        }}
                    />
                </InputGroup>
                <Form.Select
                    className="ms-3"
                    aria-label="Sort Template"
                >
                    <option value="" disabled selected>
                        Popularity
                    </option>
                    {
                        popularity.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))
                    }
                </Form.Select>
            </Flex>
            <div style={{ height: 10 }}></div>
            {
                prompts.map(x => {
                    return (
                        <div
                            key={x.title}
                            onClick={() => {
                                handleSelectedPromptTemplate(x);
                            }}>
                            <TemplateListItem
                                {...x}
                                searchText={searchText}
                            />
                        </div>
                    )
                })
            }
        </Flex>
    );
}