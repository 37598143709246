import { createSlice } from '@reduxjs/toolkit';
import WorkSpaceManager from '../../utils/workspace_manager';
import StringUtils from "../../utils/string";

const activeWorkSpace = WorkSpaceManager.getActiveWorkSpace();

const workSpaceSelectedTimeZone = () => {
    if (activeWorkSpace?.default_time_zone_for_publishing) {
        if (typeof activeWorkSpace?.default_time_zone_for_publishing !== 'string') {
            return Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        return activeWorkSpace?.default_time_zone_for_publishing;
    }
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const getCachedPostsFromLocalStorage = () => {
    try {
        return localStorage.getItem("cached_posts") ? JSON.parse(localStorage.getItem("cached_posts")) : [];
    } catch (e) {
        return []
    }
}

const initialState = {
    value: 'all',
    postSearchTerm: "",
    filters: {},
    postMediaObjects: [],
    postTextBody: "",
    contentPublishingInProgress: false,
    timeToPostContent: new Date(),
    timeZoneToPostContent: workSpaceSelectedTimeZone(),
    post_group_identifier: StringUtils.generateRandomString(64),
    revertChanges: false,
    draft: {},
    draftCount: 0,
    composerFocused: false,
    editablePost: null,
    cachedPosts: getCachedPostsFromLocalStorage(),
    forceFetchPosts: false,
    canExpandPublisherActions: false,
    openPostDetailsDialog: { open: false },
    deletedPost: null,
    persistedAccounts: null,
    postNow: true,
    munchedPostTargetIds: [],
    updatedPlatform: {},
    advanced_content_creation_options: {},
    youTubeVideoTitle: null,
    youTubeVideoThumbnail: null,
    youTubeVideoPrivacy: 'public',
    redditTitle: null,
    pinterestTitle: null,
    wordpressTitle: null,
    wordpressSlug: null,
    wordpressFeaturedImageThumbnail: null,
    wordpressPostTags: [],
    wordpressPostPassword: null,
    wordpressPostVisibility: "public",
    wordpressSiteUrl: null,
    wordpressCategories: [],
    wordpressFormat: null,
    pinterestDestinationLink: null,
    pinterestAltText: null,
    pinterestVideoThumbnail: null,
    twitterMediaAltText: null,
    openExternalFilePickerDialog: false,
    openPixelFilePickerDialog: false,
    openPixelFilePickerDialogForWriteTab: false,
    externalFilePickerDialogOptions: {},
    externalMediaFiles: [],
    openThumbnailPreviewDialog: false,
    thumbnailPreviewData: null,
    addNewPollChoice: false,
    pollChoiceValue: {},
    choiceData: {},
    publishedPollIdentifier: null,
    notifyYouTubeSubscribers: true,
    uploadingBulkContent: false,
    openComposer: false,
    displayGetStarted: false,
    instagramVideoThumbnail: null,
    refreshPopularDays: true,
    refreshTrends: true,
    updatableDay: null,
    exactTimeForRecurringPost: new Date(),
    failedPostData: null,
    contentUploadingProgress: null,
    drafting: false,
    loadingPostData: false,
    calendarPostTime: null,
    refreshCalendar: null,
    selectedPlatforms: [],
    showPreview: false,
    firstAltAddedForTwitter: false,
    fullScreenComposer: false,
    displayWeekNumbers: false,
    twitterThreadEdited: false,
    facebookMediaEdited: false,
    linkedinMediaEdited: false,
    telegramMediaEdited: false,
    instagramMediaEdited: false,
    manuallyEditedPlatforms: {},
    gmbMediaEdited: false,
    tiktokMediaEdited: false,
    redditMediaEdited: false,
    youtubeMediaEdited: false,
    pinterestMediaEdited: false,
    selectingPhotoFromHome: false,
    shouldForceUpdateTwitterThread: false,
    unhideThreadEdit: null,
    unhideThreadTextEdit: null,
    unhideAltEdit: null,
    twitterEditedThreadTexts: [],
    mediaStore: {
        facebook: [],
        twitter: [],
        instagram: [],
        linked_in: [],
        telegram: [],
        youtube: [],
        reddit: [],
        tiktok: [],
        google_my_business: [],
        pinterest: [],
    },
    media_items_arrangement: [],
    blockTwitterShallowUpdate: {
        response: false,
        threadCount: null,
        createdIndex: null
    },
    loggerTracker: [],
    formattedMediaForTwitter: [],
    formattedMediaForReddit: [],
    twitterMediaObjectForAltMemory: [],
    canUpdateFirstTweet: 0,
    altSaveTrigger: 0,
    altSaveObject: {
        altValue: '',
        currentUrlToCompare: '',
        storedIndex: ''
    },
    canFixAllImagesStore: true,
    triggerComponentRemount: 0,
    altCancelTrigger: 0,
    mediaAddedViaSpecificPlaformUrlMethod: [],
    specificMediaPlatformTied: 'global',
    twitterCurrentThreadIndexToAddMedia: 0,
    altTextBackup: [],
    draftRestored: false,
    gmbPostType: "STANDARD",
    gmbEventTitle: null,
    gmbEventSchedule: null,
    gmbEventSummary: null,
    gmbCTAActionType: null,
    gmbCTAActionUrl: null,
    gmbCouponCode: null,
    gmbRedeemUrl: null,
    gmbTermsAndConditions: null,
    publishIGVideoAsReel: false,
    openMediaEditor: false,
    igVideoCoverThumbOffset: 0,
    uploadableBulkContent: [],
    firstComment: {},
    suggestPopularHashTags: false,
    hashTagsSuggested: false,
    countries: [],
    regionCode: 'US',
    youTubeVideoCategories: [],
    youTubeVideoCategoryId: undefined,
    youTubeVideoLicense: "youtube",
    youTubeVideoTags: [],
    youTubeVideoMadeForKids: false,
    youTubeVideoEmbeddable: true,
    drafts: [],
    openDraftPrompt: true,
    draftUnderEditing: localStorage.getItem("draft_under_editing") ? JSON.parse(localStorage.getItem("draft_under_editing")) : null,
    postCategories: [],
    selectedPostCategory: { name: "Category" },
    disablePostButton: false,
    userTags: [],
    singlePostType: false,
    fetchRounds: 0,
    selectAllAudience: false,

};

export const postSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        setSelectAllAudience: (state, action) => {
            state.selectAllAudience = action.payload;
        },
        setSelectedPostType: (state, action) => {
            state.value = action.payload
        },
        setPostSearchTerm: (state, action) => {
            state.postSearchTerm = action.payload;
        },
        setPostsFilters: (state, action) => {
            state.filters = action.payload;
        },
        setPostMediaObjects: (state, action) => {
            state.postMediaObjects = action.payload;
        },
        setPostTextBody: (state, action) => {
            state.postTextBody = action.payload;
        },
        setPostGroupIdentifier: (state, action) => {
            state.post_group_identifier = action.payload;
        },
        setContentPublishingInProgress: (state, action) => {
            state.contentPublishingInProgress = action.payload;
        },
        setTimeToPostContent: (state, action) => {
            state.timeToPostContent = action.payload;
        },
        setTimeZoneToPostContent: (state, action) => {
            state.timeZoneToPostContent = action.payload;
        },
        revertChanges: (state, action) => {
            state.revertChanges = action.payload;
        },
        setDraft: (state, action) => {
            state.draft = action.payload;
        },
        setDraftCount: (state, action) => {
            state.draftCount = action.payload;
        },
        setFirstAltAddedForTwitter: (state, action) => {
            state.firstAltAddedForTwitter = action.payload;
        },
        setComposerFocused: (state, action) => {
            state.composerFocused = action.payload;
        },
        setTwitterMediaObjectForAltMemory: (state, action) => {
            state.twitterMediaObjectForAltMemory = action.payload;
        },
        setBlockTwitterShallowUpdate: (state, action) => {
            state.blockTwitterShallowUpdate = action.payload;
        },
        setEditablePost: (state, action) => {
            state.editablePost = action.payload;
        },
        cachePosts: (state, action) => {
            state.cachedPosts = action.payload;
            try {
                localStorage.setItem('cached_posts', JSON.stringify(action.payload));
            } catch (e) {
                console.log(e);
            }
        },
        setForceFetchPosts: (state, action) => {
            state.forceFetchPosts = action.payload;
        },
        setCanExpandPublisherActions: (state, action) => {
            state.canExpandPublisherActions = action.payload;
        },
        setOpenPostDetailsDialog: (state, action) => {
            state.openPostDetailsDialog = action.payload;
        },
        setDeletedPost: (state, action) => {
            state.deletedPost = action.payload;
        },
        setPersistedAudienceAccounts: (state, action) => {
            state.persistedAccounts = action.payload;
        },
        setPostNow: (state, action) => {
            state.postNow = action.payload;
        },
        setMunchedPostTargetIds: (state, action) => {
            state.munchedPostTargetIds = action.payload;
        },
        setUpdatedPlatform: (state, action) => {
            state.updatedPlatform = action.payload;
        },
        setAdvancedContentCreationOptions: (state, action) => {
            state.advanced_content_creation_options = action.payload;
        },
        setYouTubeVideoTitle: (state, action) => {
            state.youTubeVideoTitle = action.payload;
        },
        setYouTubeVideoThumbnail: (state, action) => {
            state.youTubeVideoThumbnail = action.payload;
        },
        setYouTubeVideoPrivacy: (state, action) => {
            state.youTubeVideoPrivacy = action.payload;
        },
        setPinterestTitle: (state, action) => {
            state.pinterestTitle = action.payload;
        },
        setPinterestDestinationLink: (state, action) => {
            state.pinterestDestinationLink = action.payload;
        },
        setPinterestAltText: (state, action) => {
            state.pinterestAltText = action.payload;
        },
        setTwitterMediaAltText: (state, action) => {
            state.twitterMediaAltText = action.payload;
        },
        setRedditTitle: (state, action) => {
            state.redditTitle = action.payload;
        },
        setWordpressTitle: (state, action) => {
            state.wordpressTitle = action.payload;
        },
        setWordpressFeaturedImageThumbnail: (state, action) => {
            state.wordpressFeaturedImageThumbnail = action.payload;
        },
        setWordpressSlug: (state, action) => {
            state.wordpressSlug = action.payload;
        },
        setWordpressPostTags: (state, action) => {
            state.wordpressPostTags = action.payload;
        },
        setWordpressPostPassword: (state, action) => {
            state.wordpressPostPassword = action.payload;
        },
        setWordpressCategories: (state, action) => {
            state.wordpressCategories = action.payload;
        },
        setWordpressPostVisibility: (state, action) => {
            state.wordpressPostVisibility = action.payload;
        },
        setWordpressSiteUrl: (state, action) => {
            state.wordpressSiteUrl = action.payload;
        },
        setWordpressFormat: (state, action) => {
            state.wordpressFormat = action.payload;
        },
        setTriggerComponentRemount: (state, action) => {
            state.triggerComponentRemount = action.payload;
        },
        setPinterestVideoThumbnail: (state, action) => {
            state.pinterestVideoThumbnail = action.payload;
        },
        setOpenExternalFilePickerDialog: (state, action) => {
            state.openExternalFilePickerDialog = action.payload;
        },
        setOpenPixelFilePickerDialog: (state, action) => {
            state.openPixelFilePickerDialog = action.payload;
        },
        
        setOpenPixelFilePickerDialogForWriteTab: (state, action) => {
            state.openPixelFilePickerDialogForWriteTab = action.payload;
        },
        setExternalFilePickerDialogOptions: (state, action) => {
            state.externalFilePickerDialogOptions = action.payload;
        },
        setExternalMediaFiles: (state, action) => {
            state.externalMediaFiles = action.payload;
        },
        setCanOpenThumbnailPreviewDialog: (state, action) => {
            state.openThumbnailPreviewDialog = action.payload;
        },
        setThumbnailPreviewData: (state, action) => {
            state.thumbnailPreviewData = action.payload;
        },
        setShouldAddNewPollChoice: (state, action) => {
            state.addNewPollChoice = action.payload;
        },
        setPollChoiceValue: (state, action) => {
            state.pollChoiceValue = action.payload;
        },
        setChoiceData: (state, action) => {
            state.choiceData = action.payload;
        },
        setPublishedPollIdentifier: (state, action) => {
            state.publishedPollIdentifier = action.payload;
        },
        setShouldNotifyYouTubeSubscribers: (state, action) => {
            state.notifyYouTubeSubscribers = action.payload;
        },
        setUploadingBulkContent: (state, action) => {
            state.uploadingBulkContent = action.payload;
        },
        setOpenComposer: (state, action) => {
            state.openComposer = action.payload;
        },
        setDisplayGetStarted: (state, action) => {
            state.displayGetStarted = action.payload;
        },
        setInstagramVideoThumbnail: (state, action) => {
            state.instagramVideoThumbnail = action.payload;
        },
        setRefreshPopularDays: (state, action) => {
            state.refreshPopularDays = action.payload;
        },
        setRefreshTrends: (state, action) => {
            state.refreshTrends = action.payload;
        },
        setUpdatableEventDay: (state, action) => {
            state.updatableDay = action.payload;
        },
        setExactTimeForRecurringPost: (state, action) => {
            state.exactTimeForRecurringPost = action.payload;
        },
        setFailedPostData: (state, action) => {
            state.failedPostData = action.payload;
        },
        setDrafting: (state, action) => {
            state.drafting = action.payload;
        },
        setContentUploadingProgress: (state, action) => {
            state.contentUploadingProgress = action.payload;
        },
        setLoadingPostData: (state, action) => {
            state.loadingPostData = action.payload;
        },
        setCalendarPostTime: (state, action) => {
            state.calendarPostTime = action.payload;
        },
        setRefreshCalendar: (state, action) => {
            state.refreshCalendar = action.payload;
        },
        setSelectedPlatforms: (state, action) => {
            state.selectedPlatforms = action.payload;
        },
        setShowPreview: (state, action) => {
            state.showPreview = action.payload;
        },
        setAltSaveTrigger: (state, action) => {
            state.altSaveTrigger = action.payload;
        },
        setAltSaveObject: (state, action) => {
            state.altSaveObject = action.payload;
        },
        setAltCancelTrigger: (state, action) => {
            state.altCancelTrigger = action.payload;
        },
        setDisplayWeekNumbers: (state, action) => {
            state.displayWeekNumbers = action.payload;
        },
        makeComposerFullScreen: (state, action) => {
            state.fullScreenComposer = action.payload;
        },
        setTwitterThreadEdited: (state, action) => {
            state.twitterThreadEdited = action.payload;
        },
        setFacebookMediaEdited: (state, action) => {
            state.facebookMediaEdited = action.payload;
        },
        setInstagramMediaEdited: (state, action) => {
            state.instagramMediaEdited = action.payload;
        },
        setTiktokMediaEdited: (state, action) => {
            state.tiktokMediaEdited = action.payload;
        },
        setRedditMediaEdited: (state, action) => {
            state.redditMediaEdited = action.payload;
        },
        setLinkedinMediaEdited: (state, action) => {
            state.linkedinMediaEdited = action.payload;
        },
        setGmbMediaEdited: (state, action) => {
            state.gmbMediaEdited = action.payload;
        },
        setTelegramMediaEdited: (state, action) => {
            state.telegramMediaEdited = action.payload;
        },
        setYoutubeMediaEdited: (state, action) => {
            state.youtubeMediaEdited = action.payload;
        },
        setTwitterEditedThreadTexts: (state, action) => {
            state.twitterEditedThreadTexts = action.payload;
        },
        setPinterestMediaEdited: (state, action) => {
            state.pinterestMediaEdited = action.payload;
        },
        setSelectingPhotoFromHome: (state, action) => {
            state.selectingPhotoFromHome = action.payload;
        },
        setShouldForceUpdateTwitterThread: (state, action) => {
            state.shouldForceUpdateTwitterThread = action.payload;
        },
        setUnhideThreadEdit: (state, action) => {
            state.unhideThreadEdit = action.payload;
        },
        setUnhideThreadTextEdit: (state, action) => {
            state.unhideThreadTextEdit = action.payload;
        },
        setUnhideAltEdit: (state, action) => {
            state.unhideAltEdit = action.payload;
        },
        setCanFixAllImagesStore: (state, action) => {
            state.canFixAllImagesStore = action.payload;
        },
        setMediaItemsArrangement: (state, action) => {
            state.media_items_arrangement = action.payload;
        },
        setMediaStore: (state, action) => {
            state.mediaStore = action.payload;
        },
        setCanUpdateFirstTweet: (state, action) => {
            state.canUpdateFirstTweet = action.payload;
        },
        setFormattedMediaForTwitter: (state, action) => {
            state.formattedMediaForTwitter = action.payload;
        },
        setFormattedMediaForReddit: (state, action) => {
            state.formattedMediaForReddit = action.payload;
        },
        setMediaAddedViaSpecificPlaformUrlMethod: (state, action) => {
            state.mediaAddedViaSpecificPlaformUrlMethod = action.payload;
        },
        setSpecificMediaPlatformTied: (state, action) => {
            state.specificMediaPlatformTied = action.payload;
        },
        setTwitterCurrentThreadIndexToAddMedia: (state, action) => {
            state.twitterCurrentThreadIndexToAddMedia = action.payload;
        },
        setAltTextBackup: (state, action) => {
            state.altTextBackup = action.payload;
        },
        setDraftRestored: (state, action) => {
            state.draftRestored = action.payload;
        },
        setLoggerTracker: (state, action) => {
            state.loggerTracker = action.payload;
        },
        setGMBPostType: (state, action) => {
            state.gmbPostType = action.payload;
        },
        setGMBEventTitle: (state, action) => {
            state.gmbEventTitle = action.payload;
        },
        setGMBEventSummary: (state, action) => {
            state.gmbEventSummary = action.payload;
        },
        setGMBEventSchedule: (state, action) => {
            state.gmbEventSchedule = action.payload;
        },
        setGMBCTAActionType: (state, action) => {
            state.gmbCTAActionType = action.payload;
        },
        setGMBCTAActionURL: (state, action) => {
            state.gmbCTAActionUrl = action.payload;
        },
        setGMBCouponCode: (state, action) => {
            state.gmbCouponCode = action.payload;
        },
        setGMBRedeemURL: (state, action) => {
            state.gmbRedeemUrl = action.payload;
        },
        setGMBTermsAndConditions: (state, action) => {
            state.gmbTermsAndConditions = action.payload;
        },
        setPublishIGVideoAsReel: (state, action) => {
            state.publishIGVideoAsReel = action.payload;
        },
        setOpenMediaEditor: (state, action) => {
            state.openMediaEditor = action.payload;
        },
        setIGVideoCoverThumbOffset: (state, action) => {
            state.igVideoCoverThumbOffset = action.payload;
        },
        setUploadableBulkContent: (state, action) => {
            state.uploadableBulkContent = action.payload;
        },
        setFirstComment: (state, action) => {
            state.firstComment = action.payload;
        },
        setCanSuggestPopularHashTags: (state, action) => {
            state.suggestPopularHashTags = action.payload;
        },
        setHashTagsSuggested: (state, action) => {
            state.hashTagsSuggested = action.payload;
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setYouTubeVideoCategories: (state, action) => {
            state.youTubeVideoCategories = action.payload;
        },
        setYouTubeVideoCategoryId: (state, action) => {
            state.youTubeVideoCategoryId = action.payload;
        },
        setRegionCode: (state, action) => {
            state.regionCode = action.payload;
        },
        setYouTubeVideoEmbeddable: (state, action) => {
            state.youTubeVideoEmbeddable = action.payload;
        },
        setYouTubeVideoLicense: (state, action) => {
            state.youTubeVideoLicense = action.payload;
        },
        setIsYouTubeVideoMadeForKids: (state, action) => {
            state.youTubeVideoMadeForKids = action.payload;
        },
        setYouTubeVideoTags: (state, action) => {
            state.youTubeVideoTags = action.payload;
        },
        setManuallyEditedPlatforms: (state, action) => {
            state.manuallyEditedPlatforms = action.payload;
        },
        setDrafts: (state, action) => {
            state.drafts = action.payload;
        },
        setOpenDraftPrompt: (state, action) => {
            state.openDraftPrompt = action.payload;
        },
        setDraftUnderEditing: (state, action) => {
            try {
                localStorage.setItem("draft_under_editing", JSON.stringify(action.payload));
            } catch (e) { }
            state.draftUnderEditing = action.payload;
        },
        setPostCategories: (state, action) => {
            state.postCategories = action.payload;
        },
        setSelectedPostCategory: (state, action) => {
            state.selectedPostCategory = action.payload;
        },
        setDisablePostButton: (state, action) => {
            state.disablePostButton = action.payload;
        },
        setUserTags: (state, action) => {
            state.userTags = action.payload;
        },
        setSinglePostType: (state, action) => {
            state.singlePostType = action.payload;
        },
        setFetchRounds: (state, action) => {
            state.fetchRounds = action.payload;
        }
    }
});
export const getSelectedPostType = (state) => state.posts.value;
export const getShowPreview = (state) => state.posts.showPreview;
export const getSelectingPhotoFromHome = (state) => state.posts.selectingPhotoFromHome;
export const getShouldForceUpdateTwitterThread = (state) => state.posts.shouldForceUpdateTwitterThread;
export const getUnhideThreadEdit = (state) => state.posts.unhideThreadEdit;
export const getUnhideThreadTextEdit = (state) => state.posts.unhideThreadTextEdit;
export const getUnhideAltEdit = (state) => state.posts.unhideAltEdit;
export const getCanFixAllImagesStore = (state) => state.posts.canFixAllImagesStore;
export const getTwitterThreadEdited = (state) => state.posts.twitterThreadEdited;
export const getLinkedinMediaEdited = (state) => state.posts.linkedinMediaEdited;
export const getTelegramMediaEdited = (state) => state.posts.telegramMediaEdited;
export const getFacebookMediaEdited = (state) => state.posts.facebookMediaEdited;
export const getTwitterEditedThreadTexts = (state) => state.posts.twitterEditedThreadTexts;
export const getGmbMediaEdited = (state) => state.posts.gmbMediaEdited;
export const getInstagramMediaEdited = (state) => state.posts.instagramMediaEdited;
export const getFormattedMediaForTwitter = (state) => state.posts.formattedMediaForTwitter;
export const getFormattedMediaForReddit = (state) => state.posts.formattedMediaForReddit;
export const getFirstAltAddedForTwitter = (state) => state.posts.firstAltAddedForTwitter;
export const getAltSaveTrigger = (state) => state.posts.altSaveTrigger;
export const getLoggerTracker = (state) => state.posts.loggerTracker;
export const getAltSaveObject = (state) => state.posts.altSaveObject;
export const getTwitterMediaObjectForAltMemory = (state) => state.posts.twitterMediaObjectForAltMemory;
export const getBlockTwitterShallowUpdate = (state) => state.posts.blockTwitterShallowUpdate;
export const getAltCancelTrigger = (state) => state.posts.altCancelTrigger;
export const getTiktokMediaEdited = (state) => state.posts.tiktokMediaEdited;
export const getRedditMediaEdited = (state) => state.posts.redditMediaEdited;
export const getYoutubeMediaEdited = (state) => state.posts.youtubeMediaEdited;
export const getPinterestMediaEdited = (state) => state.posts.pinterestMediaEdited;
export const getTriggerComponentRemount = (state) => state.posts.triggerComponentRemount;
export const getPostSearchTerm = (state) => state.posts.postSearchTerm;
export const getPostMediaObjects = (state) => state.posts.postMediaObjects;
export const getPostFilters = (state) => state.posts.filters;
export const getPostTextBody = (state) => state.posts.postTextBody;
export const isContentPublishingOngoing = (state) => state.posts.contentPublishingInProgress;
export const getTimeToPostContent = (state) => state.posts.timeToPostContent;
export const getRevertChanges = (state) => state.posts.revertChanges;
export const getDraft = (state) => state.posts.draft;
export const getDraftCount = (state) => state.posts.draftCount;
export const getEditablePost = (state) => state.posts.editablePost;
export const isComposerFocused = (state) => state.posts.composerFocused;
export const getCachedPosts = (state) => state.posts.cachedPosts;
export const canForceFetchPosts = (state) => state.posts.forceFetchPosts;
export const canExpandPublisherActions = (state) => state.posts.canExpandPublisherActions;
export const canOpenPostDetailsDialog = (state) => state.posts.openPostDetailsDialog;
export const getDeletedPost = (state) => state.posts.deletedPost;
export const getPersistedAccounts = (state) => state.posts.persistedAccounts;
export const canPostNow = (state) => state.posts.postNow;
export const getMunchedPostTargetIds = (state) => state.posts.munchedPostTargetIds;
export const getUpdatedPlatform = (state) => state.posts.updatedPlatform;
export const getAdvancedContentCreationOptions = (state) => state.posts.advanced_content_creation_options;
export const getYouTubeVideoTitle = (state) => state.posts.youTubeVideoTitle;
export const getYouTubeVideoThumbnail = (state) => state.posts.youTubeVideoThumbnail;
export const getWordPressTitle = (state) => state.posts.wordpressTitle;
export const getWordpressFeaturedImageThumbnail = (state) => state.posts.wordpressFeaturedImageThumbnail;
export const getWordpressSlug = (state) => state.posts.wordpressSlug;
export const getWordpressPostTags = (state) => state.posts.wordpressPostTags;
export const getWordpressPostPassword = (state) => state.posts.wordpressPostPassword;
export const getWordpressSiteUrl = (state) => state.posts.wordpressSiteUrl;
export const getWordpressCategories = (state) => state.posts.wordpressCategories;
export const getWordpressFormat = (state) => state.posts.wordpressFormat;
export const getRedditTitle = (state) => state.posts.redditTitle;
export const getPinterestTitle = (state) => state.posts.pinterestTitle;
export const getPinterestDestinationLink = (state) => state.posts.pinterestDestinationLink;
export const getWordpressPostVisibility = (state) => state.posts.wordpressPostVisibility;
export const getPinterestVideoThumbnail = (state) => state.posts.pinterestVideoThumbnail;
export const getYouTubeVideoPrivacy = (state) => state.posts.youTubeVideoPrivacy;
export const canOpenExternalFilePickerDialog = (state) => state.posts.openExternalFilePickerDialog;
export const canOpenPixelFilePickerDialog = (state) => state.posts.openPixelFilePickerDialog;
export const canOpenPixelFilePickerDialogForWriteTab = (state) => state.posts.openPixelFilePickerDialogForWriteTab;
export const getExternalFilePickerDialogOptions = (state) => state.posts.externalFilePickerDialogOptions;
export const getExternalMediaFiles = (state) => state.posts.externalMediaFiles;
export const canOpenThumbnailPreviewDialog = (state) => state.posts.openThumbnailPreviewDialog;
export const getThumbnailPreviewData = (state) => state.posts.thumbnailPreviewData;
export const shouldAddNewPollChoice = (state) => state.posts.addNewPollChoice;
export const getPollChoiceValue = (state) => state.posts.pollChoiceValue;
export const getChoiceData = (state) => state.posts.choiceData;
export const getPublishedPollIdentifier = (state) => state.posts.publishedPollIdentifier;
export const shouldNotifyYouTubeSubscribers = (state) => state.posts.notifyYouTubeSubscribers;
export const isUploadingBulkContent = (state) => state.posts.uploadingBulkContent;
export const canOpenComposer = (state) => state.posts.openComposer;
export const canDisplayGetStarted = (state) => state.posts.displayGetStarted;
export const getInstagramVideoThumbnail = (state) => state.posts.instagramVideoThumbnail;
export const canRefreshPopularDays = (state) => state.posts.refreshPopularDays;
export const getUpdatableEventDay = (state) => state.posts.updatableDay;
export const getPinterestAltText = (state) => state.posts.pinterestAltText;
export const getTimeZoneToPostContent = (state) => state.posts.timeZoneToPostContent;
export const getExactTimeForRecurringPost = (state) => state.posts.exactTimeForRecurringPost;
export const getPostGroupIdentifier = (state) => state.posts.post_group_identifier;
export const getTwitterMediaAltText = (state) => state.posts.twitterMediaAltText;
export const getFailedPostData = (state) => state.posts.failedPostData;
export const isDrafting = (state) => state.posts.drafting;
export const getContentUploadingProgress = (state) => state.posts.contentUploadingProgress;
export const getLoadingPostData = (state) => state.posts.loadingPostData;
export const getCalendarPostTime = (state) => state.posts.calendarPostTime;
export const getRefreshedCalendarData = (state) => state.posts.refreshCalendar;
export const getSelectedPlatforms = (state) => state.posts.selectedPlatforms;
export const getMediaAddedViaSpecificPlaformUrlMethod = (state) => state.posts.mediaAddedViaSpecificPlaformUrlMethod;
export const isComposerFullScreen = (state) => state.posts.fullScreenComposer;
export const canDisplayWeekNumbers = (state) => state.posts.displayWeekNumbers;
export const getMediaItemsArrangement = (state) => state.posts.media_items_arrangement;
export const getMediaStore = (state) => state.posts.mediaStore;
export const getCanUpdateFirstTweet = (state) => state.posts.canUpdateFirstTweet;
export const getTwitterCurrentThreadIndexToAddMedia = (state) => state.posts.twitterCurrentThreadIndexToAddMedia;
export const getSpecificMediaPlatformTied = (state) => state.posts.specificMediaPlatformTied;
export const getAltTextBackup = (state) => state.posts.altTextBackup;
export const getDraftRestored = (state) => state.posts.draftRestored;
export const getGMBPostType = (state) => state.posts.gmbPostType;
export const getGMBEventTitle = (state) => state.posts.gmbEventTitle;
export const getGMBEventSchedule = (state) => state.posts.gmbEventSchedule;
export const getGMBEventSummary = (state) => state.posts.gmbEventSummary;
export const getGMBCTAActionType = (state) => state.posts.gmbCTAActionType;
export const getGMBCTAActionURL = (state) => state.posts.gmbCTAActionUrl;
export const getGMBCouponCode = (state) => state.posts.gmbCouponCode;
export const getGMBRedeemURL = (state) => state.posts.gmbRedeemUrl;
export const getGMBTermsAndConditions = (state) => state.posts.gmbTermsAndConditions;
export const canPublishIGVideoAsReel = (state) => state.posts.publishIGVideoAsReel;
export const canOpenMediaEditor = (state) => state.posts.openMediaEditor;
export const getIGVideoCoverThumbOffset = (state) => state.posts.igVideoCoverThumbOffset;
export const getUploadableBulkContent = (state) => state.posts.uploadableBulkContent;
export const getFirstComment = (state) => state.posts.firstComment;
export const getCanSuggestPopularHashTags = (state) => state.posts.suggestPopularHashTags;
export const getHashTagsSuggested = (state) => state.posts.hashTagsSuggested;
export const getCountries = (state) => state.posts.countries;
export const getRegionCode = (state) => state.posts.regionCode;
export const getYouTubeVideoCategories = (state) => state.posts.youTubeVideoCategories;
export const getYouTubeVideoCategoryId = (state) => state.posts.youTubeVideoCategoryId;
export const getYouTubeVideoLicense = (state) => state.posts.youTubeVideoLicense;
export const getIsYouTubeVideoMadeForKids = (state) => state.posts.youTubeVideoMadeForKids;
export const getYouTubeVideoTags = (state) => state.posts.youTubeVideoTags;
export const getIsYouTubeVideoEmbeddable = (state) => state.posts.youTubeVideoEmbeddable;
export const getManuallyEditedPlatforms = (state) => state.posts.manuallyEditedPlatforms;
export const getCanRefreshTrends = (state) => state.posts.refreshTrends;
export const getDrafts = (state) => state.posts.drafts;
export const getOpenDraftPrompt = (state) => state.posts.openDraftPrompt;
export const getDraftUnderEditing = (state) => state.posts.draftUnderEditing;
export const getPostCategories = (state) => state.posts.postCategories;
export const getSelectedPostCategory = (state) => state.posts.selectedPostCategory;
export const canDisablePostButton = (state) => state.posts.disablePostButton;
export const getUserTags = (state) => state.posts.userTags;
export const getSinglePostType = (state) => state.posts.singlePostType;
export const getFetchRounds = (state) => state.posts.fetchRounds;
export const canSelectAllAudience = (state) => state.posts.selectAllAudience;
export const {
    setSelectedPostType,
    setPostSearchTerm,
    setPostsFilters,
    setPostMediaObjects,
    setPostTextBody,
    setContentPublishingInProgress,
    setTimeToPostContent,
    setPostGroupIdentifier,
    setTimeZoneToPostContent,
    revertChanges,
    setDraft,
    setDraftCount,
    setComposerFocused,
    setEditablePost,
    cachePosts,
    setForceFetchPosts,
    setCanExpandPublisherActions,
    setOpenPostDetailsDialog,
    setDeletedPost,
    setPersistedAudienceAccounts,
    setPostNow,
    setMunchedPostTargetIds,
    setUpdatedPlatform,
    setAdvancedContentCreationOptions,
    setYouTubeVideoTitle,
    setYouTubeVideoThumbnail,
    setPinterestTitle,
    setPinterestDestinationLink,
    setTwitterMediaObjectForAltMemory,
    setBlockTwitterShallowUpdate,
    setPinterestAltText,
    setPinterestVideoThumbnail,
    setRedditTitle,
    setYouTubeVideoPrivacy,
    setOpenExternalFilePickerDialog,
    setExternalFilePickerDialogOptions,
    setOpenPixelFilePickerDialog,
    setOpenPixelFilePickerDialogForWriteTab,
    setExternalMediaFiles,
    setCanOpenThumbnailPreviewDialog,
    setThumbnailPreviewData,
    setShouldAddNewPollChoice,
    setPollChoiceValue,
    setChoiceData,
    setPublishedPollIdentifier,
    setShouldNotifyYouTubeSubscribers,
    setCanFixAllImagesStore,
    setUploadingBulkContent,
    setOpenComposer,
    setDisplayGetStarted,
    setInstagramVideoThumbnail,
    setRefreshPopularDays,
    setUpdatableEventDay,
    setExactTimeForRecurringPost,
    setTwitterMediaAltText,
    setFailedPostData,
    setContentUploadingProgress,
    setFirstAltAddedForTwitter,
    setDrafting,
    setLoadingPostData,
    setCalendarPostTime,
    setRefreshCalendar,
    setSelectedPlatforms,
    setShowPreview,
    makeComposerFullScreen,
    setDisplayWeekNumbers,
    setAltSaveTrigger,
    setAltSaveObject,
    setAltCancelTrigger,
    setTwitterThreadEdited,
    setTriggerComponentRemount,
    setFacebookMediaEdited,
    setLinkedinMediaEdited,
    setInstagramMediaEdited,
    setRedditMediaEdited,
    setTelegramMediaEdited,
    setTiktokMediaEdited,
    setYoutubeMediaEdited,
    setPinterestMediaEdited,
    setGmbMediaEdited,
    setSelectingPhotoFromHome,
    setShouldForceUpdateTwitterThread,
    setUnhideThreadEdit,
    setLoggerTracker,
    setUnhideThreadTextEdit,
    setMediaItemsArrangement,
    setMediaStore,
    setCanUpdateFirstTweet,
    setMediaAddedViaSpecificPlaformUrlMethod,
    setSpecificMediaPlatformTied,
    setTwitterCurrentThreadIndexToAddMedia,
    setFormattedMediaForTwitter,
    setFormattedMediaForReddit,
    setUnhideAltEdit,
    setTwitterEditedThreadTexts,
    setAltTextBackup,
    setDraftRestored,
    setGMBPostType,
    setGMBEventTitle,
    setGMBEventSummary,
    setGMBEventSchedule,
    setGMBCTAActionType,
    setGMBCTAActionURL,
    setGMBCouponCode,
    setGMBRedeemURL,
    setGMBTermsAndConditions,
    setPublishIGVideoAsReel,
    setOpenMediaEditor,
    setIGVideoCoverThumbOffset,
    setUploadableBulkContent,
    setFirstComment,
    setCanSuggestPopularHashTags,
    setHashTagsSuggested,
    setCountries,
    setRegionCode,
    setYouTubeVideoCategories,
    setYouTubeVideoCategoryId,
    setYouTubeVideoLicense,
    setIsYouTubeVideoMadeForKids,
    setYouTubeVideoTags,
    setYouTubeVideoEmbeddable,
    setManuallyEditedPlatforms,
    setRefreshTrends,
    setDrafts,
    setOpenDraftPrompt,
    setDraftUnderEditing,
    setPostCategories,
    setSelectedPostCategory,
    setDisablePostButton,
    setUserTags,
    setSinglePostType,
    setFetchRounds,
    setSelectAllAudience,
    setWordpressTitle,
    setWordpressFeaturedImageThumbnail,
    setWordpressSlug,
    setWordpressPostTags,
    setWordpressPostPassword,
    setWordpressPostVisibility,
    setWordpressSiteUrl,
    setWordpressCategories,
    setWordpressFormat,
} = postSlice.actions;
export default postSlice.reducer;