import IconAlert from "./IconAlert";

const InfoBar = (props) => {
    const { info, variant = 'info' } = props;
    return (
        <IconAlert variant={variant} className={'mt-3'}>
            <span dangerouslySetInnerHTML={{ __html: info }}>
            </span>
        </IconAlert>
    )
}

export default InfoBar;