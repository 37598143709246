import freeIcon from 'assets/img/icons/free.svg';
import proIcon from 'assets/img/icons/pro.svg';

export const pricingData = [
  {
    id: 0,
    title: 'Basic',
    subTitle: 'For small sales teams focused on email outreach',
    plan: "Basic",
    price: {
      Month: 37,
      Year: 31
    },
    billedAmount: {
      Monthly: 37,
      Yearly: 372
    },
    currencySign: '$',
    periodical: true,
    url: '/Basic',
    buttonText: 'Upgrade now',
    isFeatured: false,
    // featureTitle: 'Everything in Professional, plus:',
    features: [
      { id: 1, title: 'Custom number of workspaces' },
      { id: 2, title: 'Send custom number of emails monthly' },
      { id: 3, title: 'Unlimited email warmups' },
      { id: 4, title: 'Store unlimited leads' },
      { id: 5, title: 'Premium support' },
    ]
  },
  {
    id: 1,
    title: 'Growth',
    plan: "Growth",
    subTitle: 'For growing teams looking to close deals faster',
    price: {
      Month: 97,
      Year: 81
    },
    billedAmount: {
      Monthly: 97,
      Yearly: 972
    },
    currencySign: '$',
    periodical: true,
    url: '/growth',
    buttonText: 'Upgrade now',
    isFeatured: true,
    // featureTitle: 'Everything in Growth, plus:',
    features: [
      { id: 1, title: '5 Workspace' },
      { id: 2, title: 'Unlimited email sending accounts' },
      { id: 3, title: 'Unlimited email warmups' },
      { id: 4, title: 'Up to 3 team members' },
      { id: 5, title: 'Store unlimited leads' },
      { id: 6, title: 'Send 100,000 emails per month' },
      { id: 7, title: 'Unified inbox' },
    ]
  },
  {
    id: 2,
    title: 'Custom',
    plan: "Custom",
    subTitle: 'Let us customize the right plan for you',
    price: {
      Month: "Contact us",
      Year: "Contact us"
    },
    billedAmount: {
      Monthly: 19,
      Yearly: 948
    },
    // currencySign: '$',
    periodical: false,
    url: '/scale',
    buttonText: 'Contact us',
    isFeatured: false,
    featureTitle: 'Everything in Growth, plus:',
    features: [
      { id: 1, title: '1 Workspace' },
      { id: 2, title: 'Unlimited email sending accounts' },
      { id: 3, title: 'Unlimited email warmups' },
      { id: 4, title: 'Store unlimited leads' },
      { id: 5, title: 'Send 25,000 emails per month' },
      { id: 5, title: 'Unified Inbox(coming soon)' },
    ]
  },
];


export const pricingDataForLeadFinder = [
  {
    id: 0,
    title: 'Basic Leads',
    subTitle: 'For small sales teams focused on email outreach',
    plan: "BasicLeads",
    price: {
      Month: 37,
      Year: 31
    },
    billedAmount: {
      Monthly: 37,
      Yearly: 372
    },
    currencySign: '$',
    periodical: true,
    url: '/Basic',
    buttonText: 'Upgrade now',
    isFeatured: false,
    // featureTitle: 'Everything in Professional, plus:',
    features: [
      { id: 1, title: 'Custom number of workspaces' },
      { id: 2, title: 'Send custom number of emails monthly' },
      { id: 3, title: 'Unlimited email warmups' },
      { id: 4, title: 'Store unlimited leads' },
      { id: 5, title: 'Premium support' },
    ]
  },
  {
    id: 1,
    title: 'Growth Leads',
    plan: "GrowthLeads",
    subTitle: 'For businesses, sellers, recruiters, and agencies',
    price: {
      Month: 197,
      Year: 167
    },
    billedAmount: {
      Monthly: 197,
      Yearly: 2004
    },
    currencySign: '$',
    periodical: true,
    url: '/growth',
    buttonText: 'Upgrade now',
    isFeatured: true,
    // featureTitle: 'Everything in Growth, plus:',
    features: [
      { id: 1, title: '5 Workspace' },
      { id: 2, title: 'Unlimited email sending accounts' },
      { id: 3, title: 'Unlimited email warmups' },
      { id: 4, title: 'Up to 3 team members' },
      { id: 5, title: 'Store unlimited leads' },
      { id: 6, title: 'Send 100,000 emails per month' },
      { id: 7, title: 'Unified inbox' },
    ]
  },
  {
    id: 2,
    title: 'Custom Leads',
    plan: "CustomLeads",
    subTitle: 'Let us customize the right plan for you',
    price: {
      Month: "Contact us",
      Year: "Contact us"
    },
    billedAmount: {
      Monthly: 19,
      Yearly: 948
    },
    // currencySign: '$',
    periodical: false,
    url: '/scale',
    buttonText: 'Contact us',
    isFeatured: false,
    featureTitle: 'Everything in Growth, plus:',
    features: [
      { id: 1, title: '1 Workspace' },
      { id: 2, title: 'Unlimited email sending accounts' },
      { id: 3, title: 'Unlimited email warmups' },
      { id: 4, title: 'Store unlimited leads' },
      { id: 5, title: 'Send 25,000 emails per month' },
      { id: 5, title: 'Unified Inbox(coming soon)' },
    ]
  },
];

export const pricingAltData = [
  {
    id: 0,
    title: 'Basic',
    price: 0,
    period: 'm',
    icon: freeIcon,
    url: '#!',
    buttonText: 'Start free trial',
    isFeatured: false,
    features: [
      { id: 0, title: 'Unlimited Broadcasts', isActive: true },
      { id: 1, title: 'Unlimited Sequences', isActive: true },
      { id: 2, title: 'Advanced marketing', isActive: true },
      { id: 3, title: 'Api & Developer Tools', isActive: true },
      { id: 4, title: 'Integrations', isActive: false },
      { id: 5, title: 'Payments', isActive: false },
      { id: 6, title: 'Unlimted Tags', isActive: false },
      { id: 7, title: 'Custom Fields', isActive: false }
    ]
  },
  {
    id: 1,
    title: 'Growth',
    price: 99,
    period: 'm',
    icon: proIcon,
    url: '#!',
    buttonText: 'Purchase Now',
    isFeatured: true,
    features: [
      { id: 0, title: 'Unlimited Broadcasts', isActive: true },
      { id: 1, title: 'Unlimited Sequences', isActive: true },
      { id: 2, title: 'Advanced marketing', isActive: true },
      { id: 3, title: 'Api & Developer Tools', isActive: true },
      { id: 4, title: 'Integrations', isActive: true },
      { id: 5, title: 'Payments', isActive: true },
      { id: 6, title: 'Unlimted Tags', isActive: true },
      { id: 7, title: 'Custom Fields', isActive: true }
    ]
  }
];

export const ltdUpgradePricing = {
  plan: 'LTD UPGRADE',
  title: 'LTD Upgrade',
  MonthlySub: "790756",
  AnnualSub: "790756",
  price: '$999'
}

export const addonsPricing = [
  {
    id: 1,
    title: 'Unlimited AI Writer',
    plan: 'Unlimited AI Writer',
    subTitle: 'Unlimited',
    price: {
      Month: 29,
      Year: 276
    },
    url: '/ai-writer',
    buttonText: 'Subscribe',
    isFeatured: false,
  }
]