export const countries = [
  { value: '1', label: 'Afghanistan' },
  { value: '2', label: 'Albania' },
  { value: '3', label: 'Algeria' },
  { value: '4', label: 'American Samoa' },
  { value: '5', label: 'Andorra' },
  { value: '6', label: 'Angola' },
  { value: '7', label: 'Anguilla' },
  { value: '8', label: 'Antarctica' },
  { value: '9', label: 'Antigua and Barbuda' },
  { value: '10', label: 'Argentina' },
  { value: '11', label: 'Armenia' },
  { value: '12', label: 'Aruba' },
  { value: '13', label: 'Australia' },
  { value: '14', label: 'Austria' },
  { value: '15', label: 'Azerbaijan' },
  { value: '16', label: 'Bahamas' },
  { value: '17', label: 'Bahrain' },
  { value: '18', label: 'Bangladesh' },
  { value: '19', label: 'Barbados' },
  { value: '20', label: 'Belarus' },
  { value: '21', label: 'Belgium' },
  { value: '22', label: 'Belize' },
  { value: '23', label: 'Benin' },
  { value: '24', label: 'Bermuda' },
  { value: '25', label: 'Bhutan' },
  { value: '26', label: 'Bolivia' },
  { value: '27', label: 'Bosnia and Herzegowina' },
  { value: '28', label: 'Botswana' },
  { value: '29', label: 'Bouvet Island' },
  { value: '30', label: 'Brazil' },
  { value: '31', label: 'British Indian Ocean Territory' },
  { value: '32', label: 'Brunei Darussalam' },
  { value: '33', label: 'Bulgaria' },
  { value: '34', label: 'Burkina Faso' },
  { value: '35', label: 'Burundi' },
  { value: '36', label: 'Cambodia' },
  { value: '37', label: 'Cameroon' },
  { value: '38', label: 'Canada' },
  { value: '39', label: 'Cape Verde' },
  { value: '40', label: 'Cayman Islands' },
  { value: '41', label: 'Central African Republic' },
  { value: '42', label: 'Chad' },
  { value: '43', label: 'Chile' },
  { value: '44', label: 'China' },
  { value: '45', label: 'Christmas Island' },
  { value: '46', label: 'Cocos (Keeling) Islands' },
  { value: '47', label: 'Colombia' },
  { value: '48', label: 'Comoros' },
  { value: '49', label: 'Congo' },
  { value: '50', label: 'Congo, the Democratic Republic of the' },
  { value: '51', label: 'Cook Islands' },
  { value: '52', label: 'Costa Rica' },
  { value: '53', label: "Cote d'Ivoire" },
  { value: '54', label: 'Croatia (Hrvatska)' },
  { value: '55', label: 'Cuba' },
  { value: '56', label: 'Cyprus' },
  { value: '57', label: 'Czech Republic' },
  { value: '58', label: 'Denmark' },
  { value: '59', label: 'Djibouti' },
  { value: '60', label: 'Dominica' },
  { value: '61', label: 'Dominican Republic' },
  { value: '62', label: 'East Timor' },
  { value: '63', label: 'Ecuador' },
  { value: '64', label: 'Egypt' },
  { value: '65', label: 'El Salvador' },
  { value: '66', label: 'Equatorial Guinea' },
  { value: '67', label: 'Eritrea' },
  { value: '68', label: 'Estonia' },
  { value: '69', label: 'Ethiopia' },
  { value: '70', label: 'Falkland Islands (Malvinas)' },
  { value: '71', label: 'Faroe Islands' },
  { value: '72', label: 'Fiji' },
  { value: '73', label: 'Finland' },
  { value: '74', label: 'France' },
  { value: '75', label: 'France Metropolitan' },
  { value: '76', label: 'French Guiana' },
  { value: '77', label: 'French Polynesia' },
  { value: '78', label: 'French Southern Territories' },
  { value: '79', label: 'Gabon' },
  { value: '80', label: 'Gambia' },
  { value: '81', label: 'Georgia' },
  { value: '82', label: 'Germany' },
  { value: '83', label: 'Ghana' },
  { value: '84', label: 'Gibraltar' },
  { value: '85', label: 'Greece' },
  { value: '86', label: 'Greenland' },
  { value: '87', label: 'Grenada' },
  { value: '88', label: 'Guadeloupe' },
  { value: '89', label: 'Guam' },
  { value: '90', label: 'Guatemala' },
  { value: '91', label: 'Guinea' },
  { value: '92', label: 'Guinea-Bissau' },
  { value: '93', label: 'Guyana' },
  { value: '94', label: 'Haiti' },
  { value: '95', label: 'Heard and Mc Donald Islands' },
  { value: '96', label: 'Holy See (Vatican City State)' },
  { value: '97', label: 'Honduras' },
  { value: '98', label: 'Hong Kong' },
  { value: '99', label: 'Hungary' },
  { value: '100', label: 'Iceland' },
  { value: '101', label: 'India' },
  { value: '102', label: 'Indonesia' },
  { value: '103', label: 'Iran (Islamic Republic of)' },
  { value: '104', label: 'Iraq' },
  { value: '105', label: 'Ireland' },
  { value: '106', label: 'Israel' },
  { value: '107', label: 'Italy' },
  { value: '108', label: 'Jamaica' },
  { value: '109', label: 'Japan' },
  { value: '110', label: 'Jordan' },
  { value: '111', label: 'Kazakhstan' },
  { value: '112', label: 'Kenya' },
  { value: '113', label: 'Kiribati' },
  { value: '114', label: "Korea, Democratic People's Republic of" },
  { value: '115', label: 'Korea, Republic of' },
  { value: '116', label: 'Kuwait' },
  { value: '117', label: 'Kyrgyzstan' },
  { value: '118', label: "Lao, People's Democratic Republic" },
  { value: '119', label: 'Latvia' },
  { value: '120', label: 'Lebanon' },
  { value: '121', label: 'Lesotho' },
  { value: '122', label: 'Liberia' },
  { value: '123', label: 'Libyan Arab Jamahiriya' },
  { value: '124', label: 'Liechtenstein' },
  { value: '125', label: 'Lithuania' },
  { value: '126', label: 'Luxembourg' },
  { value: '127', label: 'Macau' },
  { value: '128', label: 'Macedonia, The Former Yugoslav Republic of' },
  { value: '129', label: 'Madagascar' },
  { value: '130', label: 'Malawi' },
  { value: '131', label: 'Malaysia' },
  { value: '132', label: 'Maldives' },
  { value: '133', label: 'Mali' },
  { value: '134', label: 'Malta' },
  { value: '135', label: 'Marshall Islands' },
  { value: '136', label: 'Martinique' },
  { value: '137', label: 'Mauritania' },
  { value: '138', label: 'Mauritius' },
  { value: '139', label: 'Mayotte' },
  { value: '140', label: 'Mexico' },
  { value: '141', label: 'Micronesia, Federated States of' },
  { value: '142', label: 'Moldova, Republic of' },
  { value: '143', label: 'Monaco' },
  { value: '144', label: 'Mongolia' },
  { value: '145', label: 'Montserrat' },
  { value: '146', label: 'Morocco' },
  { value: '147', label: 'Mozambique' },
  { value: '148', label: 'Myanmar' },
  { value: '149', label: 'Namibia' },
  { value: '150', label: 'Nauru' },
  { value: '151', label: 'Nepal' },
  { value: '152', label: 'Netherlands' },
  { value: '153', label: 'Netherlands Antilles' },
  { value: '154', label: 'New Caledonia' },
  { value: '155', label: 'New Zealand' },
  { value: '156', label: 'Nicaragua' },
  { value: '157', label: 'Niger' },
  { value: '158', label: 'Nigeria' },
  { value: '159', label: 'Niue' },
  { value: '160', label: 'Norfolk Island' },
  { value: '161', label: 'Northern Mariana Islands' },
  { value: '162', label: 'Norway' },
  { value: '163', label: 'Oman' },
  { value: '164', label: 'Pakistan' },
  { value: '165', label: 'Palau' },
  { value: '166', label: 'Panama' },
  { value: '167', label: 'Papua New Guinea' },
  { value: '168', label: 'Paraguay' },
  { value: '169', label: 'Peru' },
  { value: '170', label: 'Philippines' },
  { value: '171', label: 'Pitcairn' },
  { value: '172', label: 'Poland' },
  { value: '173', label: 'Portugal' },
  { value: '174', label: 'Puerto Rico' },
  { value: '175', label: 'Qatar' },
  { value: '176', label: 'Reunion' },
  { value: '177', label: 'Romania' },
  { value: '178', label: 'Russian Federation' },
  { value: '179', label: 'Rwanda' },
  { value: '180', label: 'Saint Kitts and Nevis' },
  { value: '181', label: 'Saint Lucia' },
  { value: '182', label: 'Saint Vincent and the Grenadines' },
  { value: '183', label: 'Samoa' },
  { value: '184', label: 'San Marino' },
  { value: '185', label: 'Sao Tome and Principe' },
  { value: '186', label: 'Saudi Arabia' },
  { value: '187', label: 'Senegal' },
  { value: '188', label: 'Seychelles' },
  { value: '189', label: 'Sierra Leone' },
  { value: '190', label: 'Singapore' },
  { value: '191', label: 'Slovakia (Slovak Republic)' },
  { value: '192', label: 'Slovenia' },
  { value: '193', label: 'Solomon Islands' },
  { value: '194', label: 'Somalia' },
  { value: '195', label: 'South Africa' },
  { value: '196', label: 'South Georgia and the South Sandwich Islands' },
  { value: '197', label: 'Spain' },
  { value: '198', label: 'Sri Lanka' },
  { value: '199', label: 'St. Helena' },
  { value: '200', label: 'St. Pierre and Miquelon' },
  { value: '201', label: 'Sudan' },
  { value: '202', label: 'Suriname' },
  { value: '203', label: 'Svalbard and Jan Mayen Islands' },
  { value: '204', label: 'Swaziland' },
  { value: '205', label: 'Sweden' },
  { value: '206', label: 'Switzerland' },
  { value: '207', label: 'Syrian Arab Republic' },
  { value: '208', label: 'Taiwan, Province of China' },
  { value: '209', label: 'Tajikistan' },
  { value: '210', label: 'Tanzania, United Republic of' },
  { value: '211', label: 'Thailand' },
  { value: '212', label: 'Togo' },
  { value: '213', label: 'Tokelau' },
  { value: '214', label: 'Tonga' },
  { value: '215', label: 'Trinidad and Tobago' },
  { value: '216', label: 'Tunisia' },
  { value: '217', label: 'Turkey' },
  { value: '218', label: 'Turkmenistan' },
  { value: '219', label: 'Turks and Caicos Islands' },
  { value: '220', label: 'Tuvalu' },
  { value: '221', label: 'Uganda' },
  { value: '222', label: 'Ukraine' },
  { value: '223', label: 'United Arab Emirates' },
  { value: '224', label: 'United Kingdom' },
  { value: '225', label: 'United States' },
  { value: '226', label: 'United States Minor Outlying Islands' },
  { value: '227', label: 'Uruguay' },
  { value: '228', label: 'Uzbekistan' },
  { value: '229', label: 'Vanuatu' },
  { value: '230', label: 'Venezuela' },
  { value: '231', label: 'Vietnam' },
  { value: '232', label: 'Virgin Islands (British)' },
  { value: '233', label: 'Virgin Islands (U.S.)' },
  { value: '234', label: 'Wallis and Futuna Islands' },
  { value: '235', label: 'Western Sahara' },
  { value: '236', label: 'Yemen' },
  { value: '237', label: 'Yugoslavia' },
  { value: '238', label: 'Zambia' },
  { value: '239', label: 'Zimbabwe' }
]

export const companySizeOptions = [
  { value: '1', label: '0 - 1 employees' },
  { value: '2', label: '2 - 10 employees' },
  { value: '3', label: '11 - 50 employees' },
  { value: '4', label: '51 - 200 employees' },
  { value: '5', label: '201 - 500 employees' },
  { value: '6', label: '501 - 1000 employees' },
  { value: '8', label: '1,001 - 5,000 employees' },
  { value: '9', label: '5,001 - 10,000 employees' },
  { value: '10', label: '10,000+ employees' },
];

export const revenueOptions = [
  { value: '1', label: '$0 - 1M' },
  { value: '2', label: '$1m - 10M' },
  { value: '3', label: '$10m - 50M' },
  { value: '4', label: '$50m - 100M' },
  { value: '5', label: '$100m - 250M' },
  { value: '6', label: '$250m - 500M' },
  { value: '7', label: '$500M - 1B' },
  { value: '8', label: '$1B+' },
];

export const industryOptions = [
  { value: '1', label: 'Agriculture & Mining' },
  { value: '2', label: 'Business Service' },
  { value: '3', label: 'Computers & Electronics' },
  { value: '4', label: 'Consumer Services' },
  { value: '5', label: 'Education' },
  { value: '6', label: 'Financial Services' },
  { value: '7', label: 'Government' },
  { value: '8', label: 'Healthcare, Pharmaceuticals & Biotech' },
  { value: '9', label: 'Manufacturing' },
  { value: '10', label: 'Others' },
  { value: '11', label: 'Real Estate & Contstructions' },
  { value: '12', label: 'Retail' },
  { value: '13', label: 'Software & Internet' },
  { value: '14', label: 'Transportation & Storage' },
  { value: '15', label: 'Travel Recreation & Leisure' },
  { value: '16', label: 'Wholesale & Distribution' },
];