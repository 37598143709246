import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Button, Card, Col, Form, FormControl, FormGroup, Row } from "react-bootstrap";
import { useState } from "react";
import ReactLoading from 'react-loading';
import { toast } from "react-toastify";
import WorkSpaceManager from "utils/workspace_manager";
import APIService from "http/api_service";
import moment from "moment";
import { CardBody, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { RoutePaths } from "constants";

export default function CreateSequence() {

    const getDefaultSchedule = () => {
        let weekdays = Array.from({ length: 1 }, (_, index) => index + 1);
        let schedule = {};
        for (const i in weekdays) {
            const startTime = moment().hour(8).minute(0).second(0);
            const endTime = moment().days(30).minute(0).second(0);
            schedule[i] = {
                start_time: startTime.toDate(),
                end_time: endTime.toDate()
            };
        }
        return schedule;
    }

    const navigate = useNavigate();
    const [name, setName] = useState("My sequence");
    const [creatingSequence, setCreatingSequence] = useState(false);
    const activeWorkspace = WorkSpaceManager.getActiveWorkSpace();
    const [schedule, setSchedule] = useState(getDefaultSchedule());
    const scheduleOptions = [
        {
            "label": "Normal Business Hours",
            "value": getDefaultSchedule()
        }
    ];

    const createSequence = (e) => {
        e.preventDefault();
        if (!name) {
            toast.error("Please provide a sequence name", { theme: 'colored' });
            return;
        }
        setCreatingSequence(true);
        APIService.createSequence(activeWorkspace._id, { name, schedule }, (response, error) => {
            setCreatingSequence(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { message } = response;
            toast.success(message, { theme: 'colored' });
            setTimeout(() => {
                navigate(-1);
            }, 1000);
        });
    }

    const ScheduleEndDateAndTimesPreview = ({ schedule }) => {
        const getScheduleOutput = () => {
            const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
            let outputString = "";
            for (const day in schedule) {
                const start = new Date(schedule[day].start_time).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true });
                const end = new Date(schedule[day].end_time).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true });
                outputString += `<b>${daysOfWeek[day]}</b>: ${start}-${end}<br/>`;
            }
            return outputString;
        }
        return (<div style={{ fontSize: 12 }} dangerouslySetInnerHTML={{ __html: getScheduleOutput() }}></div>);
    }

    return (
        <Flex
            direction={'column'}
            className={'bg-light'}
            style={{
                height: '100vh',
                // width: '80%'
            }}>
            <Card
                style={{
                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
                    borderRadius: 0
                }}>
                <Flex direction={'column'}>
                    <Flex
                        onClick={() => {
                            navigate(-1)
                        }}
                        className={'cursor-pointer p-2 pt-3 pb-3 ms-10 gap-2'}
                        alignItems={'center'}>
                        <IconButton
                            className="rounded-pill me-1 mb-1 outline-0"
                            variant="light"
                            style={{
                                borderRadius: '100%',
                                width: 41,
                                height: 41,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <FontAwesomeIcon
                                style={{
                                    width: 20,
                                    height: 20
                                }}
                                icon={faChevronLeft}
                            />
                        </IconButton>
                        <h6 className="cursor-pointer text-600 p-0 m-0" style={{ fontSize: 15 }}>Back</h6>
                    </Flex>
                </Flex>
            </Card>
            <Flex
                alignItems={'center'}
                justifyContent={'center'}
                style={{ height: '100vh' }}>
                <Flex
                    style={{
                        width: '60%'
                    }}
                    className={'gap-1 mx-2'}
                    direction={'column'}
                    alignItems={'start'}
                    justifyContent={'center'}>
                    <h3>New sequence</h3>
                    {/* <span className="mb-5">Customize this sequence’s name, permission settings, sending schedule, and more</span> */}
                    <Form onSubmit={createSequence}>
                        <Row>
                            <Col xs={12}>
                                <FormGroup controlId="nameControl">
                                    <Label>Sequence name:</Label>
                                    <FormControl
                                        type="text"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup controlId="nameControl">
                                    <Label className="pt-3">Schedule:</Label>
                                    <Card>
                                        <CardBody>
                                            <Flex alignItems={'start'} justifyContent={'start'}>
                                                <Flex direction={'column'}>
                                                    <Form.Select
                                                        style={{
                                                            flex: 1
                                                        }}
                                                        value={schedule}
                                                        onChange={(e) => {
                                                            setSchedule(e.target.value);
                                                        }}
                                                    >
                                                        {
                                                            scheduleOptions.map((scheduleOption) => {
                                                                return (<option value={scheduleOption.value}>{scheduleOption.label}</option>)
                                                            })
                                                        }
                                                    </Form.Select>
                                                    <span
                                                        style={{
                                                            fontSize: 14
                                                        }}>
                                                        You may edit or create new schedules <Link to={RoutePaths.EMAIL_SENDING_SCHEDULE}>here</Link>
                                                    </span>
                                                </Flex>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginLeft: 20
                                                    }}>
                                                    <ScheduleEndDateAndTimesPreview schedule={schedule} />
                                                </div>
                                            </Flex>
                                        </CardBody>
                                    </Card>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Flex className={'mt-4'}>
                            <Button
                                variant={"text"}
                                onClick={() => {
                                    navigate(-1);
                                }}>Cancel
                            </Button>
                            <Button
                                style={{
                                    marginLeft: 15,
                                }}
                                disabled={creatingSequence}
                                type="submit"
                                variant={"primary"}>
                                {
                                    creatingSequence ?
                                        <Flex className={'gap-2'} alignItems={'center'}>
                                            <ReactLoading width={30} height={30} type={'bubbles'} />
                                            <span>Creating...</span>
                                        </Flex>
                                        :
                                        <Flex
                                            className={'gap-2'}
                                            alignItems={'center'}>
                                            <span>Continue</span>
                                        </Flex>
                                }
                            </Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Flex>
    );
}