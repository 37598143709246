import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import ReactCodeInput from 'react-verification-code-input';
import VerificationInput from 'react-verification-input';
import UserManager from '../../utils/user_manager';
import APIService from '../../http/api_service';
import { toast } from 'react-toastify';
import { getMagicEmail } from '../../redux/slices/authSlice';
import { RoutePaths, TermsAndPolicies } from '../../constants';
import GmailIcon from 'assets/img/gmail.png';
import OutlookIcon from 'assets/img/outlook.png';
import SuccessGifIcon from 'assets/img/success_feedback.gif';
import FadeLoader from 'react-spinners/FadeLoader';

export default function PasswordLessConfirmation(props) {

    const [pinCode, setPinCode] = useState("");
    const [processing, setProcessing] = useState(false);
    const [canDisplaySuccess, setCanDisplaySuccess] = useState(false);
    const navigate = useNavigate();
    const magicLinkEmail = useSelector(getMagicEmail);

    const validateEnteredCode = (enteredCode) => {
        setPinCode(enteredCode);
        setProcessing(enteredCode.length === 6);
        if (enteredCode.length === 6) {
            APIService.validatePasswordLessConfirmationCode(enteredCode, magicLinkEmail, (response, error) => {
                setProcessing(false);
                if (response) {
                    let userData = response['data'];
                    localStorage.clear();
                    UserManager.setLoggedInUser(userData);
                    setCanDisplaySuccess(true);
                    setTimeout(() => {
                        navigate(RoutePaths.REDIRECT);
                    }, 760);
                } else {
                    toast.error(error, {
                        theme: 'colored'
                    });
                }
            });
        }
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: 15,
            overflowY: 'auto'
        }}>
            <h4
                fontWeight={700}
                style={{
                    padding: 16,
                    fontSize: 25,
                    fontWeight: 600,
                    textAlign: 'center',
                }}>Check your email for a code
            </h4>
            <p
                style={{
                    fontWeight: 300,
                    textAlign: 'center',
                    fontSize: 14,
                    paddingBottom: 5,
                }}
                dangerouslySetInnerHTML={{
                    __html: `We've sent a 6-character code to <strong>${magicLinkEmail}</strong>. The code expires shortly, so please enter it soon.`
                }}>
            </p>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                <div
                    style={{
                        flex: 1
                    }}>
                    <VerificationInput
                        value={pinCode}
                        disabled={processing}
                        type='number'
                        fields={6}
                        onChange={validateEnteredCode}
                    />
                </div>
                {
                    processing &&
                    <div
                        style={{
                            marginTop: 29
                        }}>
                        <FadeLoader
                            style={{
                                position: 'relative',
                            }}
                        />
                    </div>
                }
                {
                    canDisplaySuccess &&
                    <img
                        src={SuccessGifIcon}
                        alt="Success Feedback"
                        width={100} height={100}
                    />
                }
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 300,
                    alignItems: 'center',
                    height: 10,
                    marginTop: processing ? 100 : 30,
                    justifyContent: 'space-evenly',
                    fontSize: 14,
                    textDecoration: 'none'
                }}>
                <a href="https://gmail.com"
                    target={'_blank'}
                    rel={'noreferrer'}
                    style={{
                        textDecoration: 'none',
                    }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <img src={GmailIcon}
                            alt={"GMAIL"}
                            width={18} 
                            height={15} />
                        <span style={{
                            paddingLeft: 5,
                            fontWeight: 400,
                            fontSize: 13
                        }}>Open Gmail</span>
                    </div>
                </a>
                <a href="https://outlook.live.com/"
                    target={'_blank'}
                    rel={'noreferrer'}
                    style={{
                        textDecoration: 'none',
                    }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <img src={OutlookIcon}
                            alt={"Outlook"}
                            width={20} 
                            height={20} 
                            />
                        <span style={{
                            paddingLeft: 5,
                            fontWeight: 400,
                            fontSize: 13
                        }}>Open Outlook</span>
                    </div>
                </a>
            </div>
            <span
                style={{
                    padding: 20,
                    fontSize: 13,
                    textAlign: 'center',
                    fontWeight: 400
                }}>Can't find your code? Check your spam folder!
            </span>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 200,
                    alignItems: 'center',
                    height: 10,
                    marginTop: processing ? 3 : 3,
                    justifyContent: 'space-evenly',
                    fontSize: 14,
                    textDecoration: 'none'
                }}>
                <a href={TermsAndPolicies.PRIVACY_POLICY}
                target={'_blank'}
                rel='noreferrer'
                style={{
                    fontSize: 13,
                    textDecoration: 'none',
                    fontWeight: 400
                }}>Privacy Policy
            </a>
            <a href={TermsAndPolicies.TERMS_OF_SERVICE}
                target={'_blank'}
                rel='noreferrer'
                style={{
                    fontSize: 13,
                    textDecoration: 'none',
                    fontWeight: 400
                }}>Terms
            </a>
            </div>

            
            
        </div>
    );
}