import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import ChatThread from './ConversationView';
import SimpleBarReact from 'simplebar-react';
// import 'simplebar/dist/simplebar.min.css';
import UnifiedInboxBottomNav from './UnifiedInboxBottomNav';
import classNames from 'classnames';
import { ChatContext } from 'context/Context';

const ChatSidebar = ({ hideSidebar, connectAccounts, allInboxes, getAllUnifiedInbox, noMoreMailsToFetch }) => {
  const { threads } = useContext(ChatContext);

  return (
    <div className={classNames('chat-sidebar', { 'start-0': hideSidebar })}>
      <div className="contacts-list">
        <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
          <Nav className="border-0">
            {allInboxes.map((inbox, index) => {
              const isLastItem = index === allInboxes.length - 1;
              return <ChatThread
                key={threads[0].id}
                inbox={inbox}
                thread={inbox}
                index={index}
                isLastItem={isLastItem}
                getAllUnifiedInbox={getAllUnifiedInbox}
                noMoreMailsToFetch={noMoreMailsToFetch}
              />
            }
            )}
          </Nav>
        </SimpleBarReact>
      </div>
      <UnifiedInboxBottomNav connectAccounts={connectAccounts} />
    </div>
  );
};

ChatSidebar.propTypes = {
  hideSidebar: PropTypes.bool,
};

export default ChatSidebar;
