import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import themeReducer from '../slices/themeSlice';
import workSpaceReducer from '../slices/workspaceslice';
import postsReducer from '../slices/postslice';
import platformSlice from '../slices/platformSlice';
import aiWriterSlice from '../slices/ai_writer_slice';
import leadsSlice from '../slices/leadslice';
import userSlice from '../slices/user_slice';
import sequenceSlice from '../slices/sequence_slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        theme: themeReducer,
        workspace: workSpaceReducer,
        posts: postsReducer,
        platform: platformSlice,
        aiwriter: aiWriterSlice,
        leads: leadsSlice,
        users: userSlice,
        sequence: sequenceSlice,
    }
});
