import React, { useState } from "react"
import {
    Button
} from "react-bootstrap"
import { useSelector } from 'react-redux'
import { getSequencesFromRedux } from "../../../../../redux/slices/sequence_slice"
import UpsertStepModal from "./upsert/UpsertStepModal"

const NewStep = (props) => {
    const {openStepModal, setOpenStepModal, updatableStep, setUpdatableStep} = props
    const sequence = useSelector(getSequencesFromRedux)
    // const [openStepModal, setOpenStepModal] = useState(false)
    // const [updatableStep, setUpdatableStep] = useState({})
 
    return (
        <>
            <Button
                icon="crown"
                variant={sequence?.steps?.length === 0 ? "primary" : "secondary"}
                iconClassName="me-2"
                className="w-100"
                onClick={() => {
                    setUpdatableStep({})
                    setOpenStepModal(!openStepModal)
                }}
            >
                {sequence?.steps ? "Add sequence step" : "Add another step"}
            </Button>
            <UpsertStepModal
                updatableStep={updatableStep}
                sequence={sequence}
                openModal={openStepModal}
                setOpenModal={setOpenStepModal}
            />
        </>
    )
}

export default NewStep