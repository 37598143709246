import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchText: ''
};

export const leadSlice = createSlice({
    name: 'leads',
    initialState,
    reducers: {
        setLeadsSearchText: (state, action) => {
            state.searchText = action.payload;
        },
    }
});

export const getLeadsSearchText = (state) => state.leads.searchText;
export const {
    setLeadsSearchText
} = leadSlice.actions;
export default leadSlice.reducer;