export const promptData = [
    {
        title: "Company Description From URL",
        description: "Generates a description for a Company based on URL only",
        category: "Sales",
        runs: 256,
        author: {
            name: "Onu Emeka"
        },
        inputs: [
            {
                type: 'input',
                label: 'Website/URL',
                name: 'website',
                value: 'website'
            },
        ],
        variables: [
            {
                type: 'input',
                goal: 'Description',
                name: 'goal',
                label: 'Output Column'
            }
        ],
        prompt: "Create a compelling company description based on the URLs."
    },
    {
        title: "Company Name Cleanup",
        description: "This is a prompt to cleanup all company names to prepare them for outreach and reference",
        category: "Cleaning",
        runs: 1252,
        author: {
            name: "Patrick"
        },
        inputs: [
            {
                type: 'input',
                label: 'Put your company name here',
                name: 'company_name',
                value: 'companyName'
            },
        ],
        variables: [
            {
                type: 'input',
                goal: 'Clean Company Name',
                name: 'goal',
                label: 'Output Column'
            }
        ],
        prompt: "Your goal is to {goal}. Clean up the company names to make them suitable for outreach and reference purposes"
    },
    {
        title: "Email Outreach Campaign",
        description: "Craft a personalized email outreach campaign targeting potential clients in the healthcare industry.",
        category: "Sales",
        runs: 420,
        author: {
            name: "Emma Turner"
        },
        prompt: "Develop a highly personalized email outreach campaign aimed at potential clients in the healthcare sector."
    },
    {
        title: "Lead Generation Strategy",
        description: "Create a strategy for generating leads through email marketing for a B2B software company.",
        category: "Marketing",
        runs: 615,
        author: {
            name: "Ryan Mitchell"
        },
        prompt: "Formulate an effective email marketing strategy for lead generation targeting B2B software companies."
    },
    {
        title: "Email Content Optimization",
        description: "Optimize the content of a series of outreach emails to improve open and response rates.",
        category: "Marketing",
        runs: 892,
        author: {
            name: "Sophie Adams"
        },
        prompt: "Enhance the content of a set of outreach emails to boost open and response rates from potential clients."
    },
    {
        title: "Subject Line A/B Testing",
        description: "Create A/B test variations for email subject lines to determine the most effective approach for a fashion retailer's email campaign.",
        category: "Marketing",
        runs: 324,
        author: {
            name: "Nathan Patel"
        },
        prompt: "Develop A/B test variations for email subject lines to identify the most engaging approach for a fashion retailer's email campaign."
    },
    {
        title: "Email Campaign Analytics",
        description: "Analyze the performance data of a recent email marketing campaign and provide insights for optimization.",
        category: "Data Analysis",
        runs: 756,
        author: {
            name: "Isabella Garcia"
        },
        prompt: "Examine the performance metrics of a recent email marketing campaign and offer actionable insights for further optimization."
    },
    {
        title: "Email Personalization Strategy",
        description: "Create a strategy for personalizing email content and recommendations for an e-commerce company.",
        category: "Marketing",
        runs: 498,
        author: {
            name: "Lucas Rodriguez"
        },
        prompt: "Devise a comprehensive strategy for personalizing email content and product recommendations for an e-commerce company."
    },
    {
        title: "Email Drip Campaign",
        description: "Design an email drip campaign with a series of follow-up messages to nurture leads for a SaaS startup.",
        category: "Marketing",
        runs: 587,
        author: {
            name: "Aria Thompson"
        },
        prompt: "Create an effective email drip campaign with a sequence of follow-up messages to nurture leads for a SaaS startup."
    },
    {
        title: "Customer Feedback Email",
        description: "Draft a customer feedback email to gather insights and improve services for a hospitality company.",
        category: "Customer Relations",
        runs: 312,
        author: {
            name: "Elijah Baker"
        },
        prompt: "Compose a customer feedback email to collect valuable insights and enhance services for a hospitality company."
    },
    {
        title: "Email Campaign ROI Analysis",
        description: "Analyze the return on investment (ROI) for a recent email marketing campaign for an e-commerce retailer.",
        category: "Data Analysis",
        runs: 655,
        author: {
            name: "Zoe Foster"
        },
        prompt: "Evaluate the return on investment (ROI) for a recent email marketing campaign conducted by an e-commerce retailer."
    },
    {
        title: "Email Newsletter Content",
        description: "Generate engaging content ideas for an upcoming email newsletter for a technology news website.",
        category: "Content Creation",
        runs: 425,
        author: {
            name: "Henry Ward"
        },
        prompt: "Generate creative and engaging content ideas for an upcoming email newsletter for a technology news website."
    },
    {
        title: "Email List Segmentation",
        description: "Develop a strategy for segmenting an email list to deliver targeted messages for a financial services company.",
        category: "Marketing",
        runs: 502,
        author: {
            name: "Lily Chen"
        },
        prompt: "Create a strategic plan for segmenting an email list to deliver highly targeted messages for a financial services company."
    },
    {
        title: "Email Campaign Timing Analysis",
        description: "Analyze the best time of day and day of the week to send email campaigns for a travel agency.",
        category: "Marketing",
        runs: 732,
        author: {
            name: "Noah Kim"
        },
        prompt: "Determine the optimal time of day and day of the week for sending email campaigns to maximize engagement for a travel agency."
    },
    {
        title: "Email Subject Line Personalization",
        description: "Create personalized email subject lines for an email marketing campaign targeting subscribers in the fashion industry.",
        category: "Marketing",
        runs: 589,
        author: {
            name: "Eva Turner"
        },
        prompt: "Craft personalized email subject lines for an email marketing campaign targeting subscribers in the fashion industry."
    },
    {
        title: "Email Campaign Conversion Analysis",
        description: "Analyze the conversion rates of a recent email marketing campaign for an online education platform.",
        category: "Data Analysis",
        runs: 476,
        author: {
            name: "Liam O'Connor"
        },
        prompt: "Examine the conversion rates of a recent email marketing campaign conducted by an online education platform and provide insights."
    },
    {
        title: "Email Follow-Up Sequence",
        description: "Create a series of follow-up emails to nurture leads and encourage sign-ups for a subscription box service.",
        category: "Marketing",
        runs: 631,
        author: {
            name: "Nina Patel"
        },
        prompt: "Develop a sequence of follow-up emails designed to nurture leads and drive sign-ups for a subscription box service."
    },
    {
        title: "Email Campaign A/B Testing",
        description: "Design A/B test variations for the content and layout of an email campaign promoting a software product launch.",
        category: "Marketing",
        runs: 584,
        author: {
            name: "Lucas Rodriguez"
        },
        prompt: "Create A/B test variations for the content and layout of an email campaign promoting the launch of a software product."
    },
    {
        title: "Email Campaign Analytics",
        description: "Analyze the performance data of a recent email marketing campaign for a nonprofit organization and provide recommendations for improvement.",
        category: "Data Analysis",
        runs: 765,
        author: {
            name: "Isabella Garcia"
        },
        prompt: "Evaluate the performance metrics of a recent email marketing campaign conducted by a nonprofit organization and offer recommendations for enhancement."
    },
];