import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import StringUtils from 'utils/string';

export default function TemplateListItem(props) {

    const {
        title,
        description,
        author,
        runs,
        category,
        searchText
    } = props;
    const { config } = useContext(AppContext);

    const authorAvatar = author.avatar ? (
        <Avatar
            src={author.avatar}
            alt={author.name}
        />
    ) : (
        <Avatar
            className="flex-shrink-0"
            rounded="soft"
            name={author.name}
        />
    );

    return (
        <Card
            className='my-2 shadow-none border cursor-pointer'
            style={{
                borderRadius: 5,
            }}>
            <Card.Body>
                <Card.Title style={{ fontSize: 16 }}>{StringUtils.highlightMatchingText(title, searchText)}</Card.Title>
                <Card.Text style={{ fontSize: 14 }}>{StringUtils.highlightMatchingText(description, searchText)}</Card.Text>
                <Flex>
                    <SoftBadge bg='success' className='me-2'>
                        <Flex alignItems={'center'}>
                            <span>{StringUtils.highlightMatchingText(`${runs}`, searchText)}
                            </span>
                            <span className='ms-1' style={{ paddingBottom: 2 }}>runs</span>
                        </Flex>
                    </SoftBadge>
                    <SoftBadge bg='info' className='me-1'>{StringUtils.highlightMatchingText(category, searchText)}</SoftBadge>
                </Flex>
            </Card.Body>
            <div
                style={{
                    border: `.1px solid ${config.isDark ? '#111' : '#dddddd'}`
                }}
            />
            <Card.Footer className='ps-3 py-2'>
                <Flex alignItems={'center'}>
                    {authorAvatar}
                    <small className="ms-3 fw-semi-bold">{StringUtils.highlightMatchingText(author.name, searchText)}</small>
                </Flex>
            </Card.Footer>
        </Card>
    );
}
