import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Flex from "components/common/Flex"
import { useEffect, useState } from "react"
import { Offcanvas } from "react-bootstrap"
import EmailSettings from "./EmailSettings"

export default function EmailAccountSettings(props) {
  const [showDrawer, setShowDrawer] = useState(props.open)

  const handleClose = () => {
    setShowDrawer(!showDrawer)
    props.closeDrawer()
  }

  useEffect(() => {
    setShowDrawer(props.open)
  }, [props.open])

  return (
    <Offcanvas
      style={{ maxWidth: "40rem", width: "100%" }}
      scroll={true}
      // backdrop={false}
      fullscreen={true}
      placement="end"
      show={showDrawer}
      onHide={handleClose}
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        className="bg-shape settings-panel-header pl-4 pr-14"
      >
        <Offcanvas.Title as="div" className="py-1 z-index-1 light">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h5 className="text-white">
              <FontAwesomeIcon icon="envelope" className="me-2 fs-0" />
              {props?.id?.email}
            </h5>
            {/* <Button
              variant="primary"
              size="sm"
              className="rounded-pill mt-0 mb-0"
              style={{ fontSize: '12px' }}
              onClick={() => {
                // configDispatch({ type: 'RESET' });
              }}
            >
              <FontAwesomeIcon
                icon="redo-alt"
                style={{ fontSize: '10px' }}
                className="me-1"
              />
              Reset
            </Button> */}
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pl-4 pr-4">
        
        <Flex justifyContent={"center"}>
          <EmailSettings {...props} />
        </Flex>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
