import React, { useState, useEffect } from 'react';

const AttachmentViewer = ({ attachments }) => {
  const [dataUrl, setDataUrl] = useState(null);

  useEffect(() => {
    const fetchDataUrl = async () => {
      const attachment = attachments[0];

      // Convert the array of binary data to a Blob
      const blob = new Blob([Uint8Array.from(attachment.content.data)], {
        type: attachment.contentType,
      });

      // Use FileReader to read the Blob and convert it to a data URL or base64 encoding
      const reader = new FileReader();
      reader.onloadend = () => {
        setDataUrl(reader.result);
      };
      reader.readAsDataURL(blob);
    };

    fetchDataUrl();
  }, [attachments]);

  return (
    <div>
      {dataUrl ? (
        <embed src={dataUrl} type={attachments[0].contentType} width="100%" height="600px" />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default AttachmentViewer;
