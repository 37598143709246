import React from 'react';
import PropTypes from 'prop-types';
import WizardLayout from './WizardLayout';
import WhiteLabelWizardProvider from './WhiteLabelWizardProvider';
import { Container } from 'react-bootstrap';

const Wizard = () => {

  return (
    <WhiteLabelWizardProvider>
      <Container>
        <WizardLayout
          validation={true}
        />
      </Container>
    </WhiteLabelWizardProvider>
  );
};

Wizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default Wizard;
