import React, { useEffect, useState } from "react"
import { Button, Card, Modal } from "react-bootstrap"
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper"
import AdvanceTable from "components/common/advance-table/AdvanceTable"
import FalconCardHeader from "components/common/FalconCardHeader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { useSelector } from "react-redux"
import { getBulkCSVLeads } from "../../../redux/slices/workspaceslice"
import BulkContentEditBox from "./LeadBulkContentEditBox"
import StringUtils from "utils/string"
import { toast } from "react-toastify"
import UserManager from "utils/user_manager"
import useSocket from "socket_io"
import APIService from "http/api_service"
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter"
import Flex from "components/common/Flex"
import { UpgradeToPremiumAccountCard } from "components/app/new-workspace"
import WorkSpaceManager from "utils/workspace_manager"
import { faUpload } from "@fortawesome/free-solid-svg-icons" // Assuming you have the Font Awesome 'upload' icon imported

const columns = [
  {
    accessor: "name",
    Header: "Name",
    Cell: (rowData) => {
      const { id } = rowData.row
      return <BulkContentEditBox rowIndex={parseInt(id)} property={"name"} />
    },
  },
  {
    accessor: "email",
    Header: "Email",
    Cell: (rowData) => {
      const { id } = rowData.row
      return <BulkContentEditBox rowIndex={parseInt(id)} property={"email"} />
    },
  },
]

const LeadBulkUploadPreviewer = (props) => {
  const skipPick = props.skipPick ?? false
  const skipLimits = props.skipLimits ?? false
  const { pickNewFile, campaignUpdateHandler, campaign } = props
  const emailAccounts = useSelector(getBulkCSVLeads)
  const [creatingEmailAccounts, setCreatingEmailAccounts] = useState(false)
  const loggedInUser = UserManager.getLoggedInUser()
  const [openCreationSuccessDialog, setOpenCreationSuccessDialog] =
    useState(false)
  const [successMessage, setSuccessMessage] = useState(
    "Bulk leads connected successfully",
  )
  const [openUpgradeCard, setOpenUpgradeCard] = useState(false)
  const activeWorkSpace = WorkSpaceManager.getActiveWorkSpace()
  const { addSocketIOEventListener, removeSocketIOEventListener } = useSocket()

  useEffect(() => {
    let emailAccountsSpacesEventListener = (response) => {
      let { error, message, data } = response
      setCreatingEmailAccounts(false)
      if (error) {
        // if (error?.toLowerCase().includes('upgrade'.toLowerCase())) {
        //     setOpenUpgradeCard(true);
        // } else {
        //     toast.error(error, { theme: 'colored' });
        // }
        console.log(error)
        return
      }
      setSuccessMessage(message)
      setOpenCreationSuccessDialog(true)
      campaignUpdateHandler(data)
    }
    addSocketIOEventListener(
      `${loggedInUser?._id}_bulk_leads_status`,
      emailAccountsSpacesEventListener,
    )
    return () => {
      removeSocketIOEventListener(
        `${loggedInUser?._id}_bulk_leads_status`,
        emailAccountsSpacesEventListener,
      )
    }
  }, [])

  const connectLeads = async () => {
    setCreatingEmailAccounts(true)
    const bulkContentStringified = JSON.stringify(emailAccounts)
    const bulkContentBlob = new Blob([bulkContentStringified], {
      type: "application/json;charset=utf-8",
    })
    let requestBody = new FormData()
    let bulkContentUploadFile = new File(
      [bulkContentBlob],
      `${StringUtils.generateRandomString(10)}.json`,
    )
    requestBody.append("file", bulkContentUploadFile)
    let response = await APIService.uploadNewFile(
      activeWorkSpace?._id,
      requestBody,
      0,
      null,
      1,
    )
    let result = response.data
    setTimeout(async () => {
      await APIService.uploadBulkCSVLeads(
        activeWorkSpace?._id,
        { bulk_email_accounts_url: result },
        campaign._id,
        (_response, error) => {
          if (error) {
            setCreatingEmailAccounts(false)
            toast.error(error, { theme: "colored" })
            return
          }
        },
      )
    }, 3000)
  }
  // console.log("this one")
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={emailAccounts}
      pagination
      perPage={50}
    >
      <Card>
        <FalconCardHeader
          title={props?.leadTitle || "Leads to connect"}
          endEl={
            <div
              style={{
                display: "flex",
                gap: 10,
              }}
            >
              {!skipPick && (
                <Button
                  type="button"
                  icon="plus"
                  size="sm"
                  className="ms-2"
                  variant="falcon-default"
                  onClick={() => {
                    pickNewFile()
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon icon={"plus"} />
                    <span className="d-none d-sm-inline-block ms-1">
                      Pick a New CSV File
                    </span>
                  </div>
                </Button>
              )}
              <Button
                disabled={props?.loadingFromProps || creatingEmailAccounts || emailAccounts.length < 2}
                size="sm"
                className="ms-2"
                variant="falcon-primary"
                onClick={() => {
                  props?.customHandler() || connectLeads()
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faUpload} />
                  <span className="d-none d-sm-inline-block ms-1">
                    {(props?.loadingFromProps || creatingEmailAccounts)
                      ? (props?.uploadBtnInProgress || "Uploading leads...")
                      : (props?.uploadBtn || "Upload leads")}
                  </span>
                </div>
              </Button>
            </div>
          }
        >
          {!skipLimits && (
            <span className="fs--1">Min number of rows must be 2</span>
          )}
        </FalconCardHeader>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: "sm",
              striped: true,
              className: "fs--1 mb-0 overflow-hidden",
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={emailAccounts.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </Card.Footer>
      </Card>
      <Modal
        backdrop="static"
        show={openCreationSuccessDialog}
        onHide={() => setOpenCreationSuccessDialog(false)}
        onExit={() => setOpenCreationSuccessDialog(false)}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Leads imported</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex direction={"column"} className={"gap-3"}>
            <span className="text-success">{successMessage}</span>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenCreationSuccessDialog(false)
            }}
          >
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openUpgradeCard}
        onHide={() => setOpenUpgradeCard(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <UpgradeToPremiumAccountCard
            subTitle={"👋 You've discovered a premium feature!"}
            message={"Upgrade to one of the paid plans to unlock this feature."}
            upgradeBtnText={"Upgrade Plan"}
          />
        </Modal.Body>
      </Modal>
    </AdvanceTableWrapper>
  )
}

export default LeadBulkUploadPreviewer
