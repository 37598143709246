import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
    return (
        <Outlet />
    );
};

AuthLayout.propTypes = {
    match: PropTypes.object
};
export default AuthLayout;
