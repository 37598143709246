import { CircularButtonBase } from './styles';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from 'context/Context';

const CircularButton = React.forwardRef((props, ref) => {

    const [canHover, setCanHover] = useState(props.canHover ?? true);
    const { config } = useContext(AppContext);

    useEffect(() => {
        setCanHover(props.canHover ?? true);
    }, [props.canHover]);

    return (
        <CircularButtonBase
            color={config.isDark ? '#0b1727' : '#ECEFF1'}
            canHover={canHover}
            title={props.title}
            onClick={props.onClick}
            {...props}>{props.children}
        </CircularButtonBase>
    )

});

export default CircularButton