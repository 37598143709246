import Flex from "components/common/Flex";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import LeadsAnimation from "assets/animations/import_leads.json";
import Lottie from "lottie-react";
import LeadsImporter from "./leads_importer";
import ImportedLeads from "./imported_leads";
import APIService from "http/api_service";
import WorkSpaceManager from "utils/workspace_manager";
import Reactloading from 'react-loading';

const GetStartedView = React.forwardRef((props, ref) => {

    return (
        <Flex
            ref={ref}
            className={'gap-2'}
            alignItems={'center'}
            justifyContent={'center'}
            style={{
                height: 'auto',
                width: 'auto'
            }}
        >
            <Flex
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Lottie
                    animationData={LeadsAnimation}
                    style={{
                        width: 300,
                        height: 300
                    }}
                />
                <h5>
                    👋 Add some leads to get started
                </h5>
                <Button
                    style={{
                        marginTop: '2rem'
                    }}
                    variant={"primary"}
                    onClick={() => {
                        props.getStartedClickHandler();
                    }}
                >
                    <Flex alignItems={'center'} className={'gap-1'}>
                        <span>IMPORT</span>
                    </Flex>
                </Button>
            </Flex>
        </Flex>
    );
});

export default function Leads(props) {

    const [sequence, setSequence] = useState(props.sequence);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [leads, setLeads] = useState(props?.sequence?.leads ?? []);
    const [loadingLeads, setLoadingLeads] = useState(true);
    const [refreshingLeads, setRefreshingLeads] = useState();
    const workspace = WorkSpaceManager.getActiveWorkSpace();

    const fetchLeads = async () => {
        setRefreshingLeads(true);
        try {
            let { data } = await APIService.fetchLeads(workspace._id);
            setLeads(data);
            setLoadingLeads(false);
            setRefreshingLeads(false);
        } catch (e) {
            setLoadingLeads(false);
            setRefreshingLeads(false);
            console.log(e);
        }
    }

    useEffect(() => {
        fetchLeads();
    }, []);

    return (
        <Flex direction={'column'}>
            {
                leads.length > 0 &&
                <ImportedLeads
                    leads={leads}
                    importLeads={() => {
                        setOpenDrawer(true);
                    }}
                    refreshingLeads={refreshingLeads}
                    sequence={sequence}
                    onSequenceChanged={(updatedSequence, updateRemotely) => {
                        setSequence({ ...updatedSequence });
                        if (updateRemotely) {
                            //Run remote update
                        }
                    }}
                />
            }
            {
                leads.length < 1 && !loadingLeads &&
                <GetStartedView
                    getStartedClickHandler={() => {
                        setOpenDrawer(true);
                    }}
                />
            }
            {
                !loadingLeads &&
                <LeadsImporter
                    onSequenceChanged={(updatedSequence) => {
                        setSequence(updatedSequence);
                    }}
                    sequence={sequence}
                    open={openDrawer}
                    closeDrawer={() => {
                        setOpenDrawer(false);
                        fetchLeads();
                    }}
                />
            }
            {
                loadingLeads &&
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={'w-100 h-100 pt-5'}>
                    <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={'w-100 h-100 pt-5'}>
                        <Reactloading width={30} type="spinningBubbles" color="teal" />
                    </Flex>
                </Flex>
            }
        </Flex>
    );
}