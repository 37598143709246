import React, {
  useEffect,
  useMemo,
  useState
} from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Row,
  Col,
  Form,
  Card,
  Spinner
} from "react-bootstrap";
import APIService from "http/api_service";
import WorkSpaceManager from "utils/workspace_manager";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain, faPlus } from "@fortawesome/free-solid-svg-icons";
import AIPrompts from "./ai/prompts";
import StringUtils from "utils/string";
import Reactloading from 'react-loading';
import IconButton from "components/common/IconButton";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import Flex from "components/common/Flex";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";

const ImportedLeads = ({ sequence, onSequenceChanged, ...rest }) => {

  const [leads, setLeads] = useState(sequence?.leads ?? rest.leads ?? []);
  const [searchText, setSearchText] = useState("");
  const activeWorkspace = WorkSpaceManager.getActiveWorkSpace();
  const [openAIPrompts, setOpenAIPrompts] = useState(false);
  const [bulkActionSelectedOption, setBulkActionsSelectedOption] = useState('');
  const [performingBulkOperation, setPerformingBulkOperation] = useState(false);

  useEffect(() => {
    setLeads(sequence?.leads ?? rest.leads ?? []);
  }, [sequence]);

  useEffect(() => {
    if (rest.leads) {
      setLeads(rest.leads);
    }
  }, [rest.leads]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => StringUtils.highlightMatchingText(row.original.name, searchText)
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => StringUtils.highlightMatchingText(row.original.email, searchText)
      }
    ],
    [leads, searchText]
  );

  const handleSelectChange = (e) => {
    setBulkActionsSelectedOption(e.target.value);
  };

  const applyBulkAction = (selectedRowIndices) => {
    if (selectedRowIndices.length === 0) {
      return;
    }
    switch (bulkActionSelectedOption) {
      case 'delete':
        let updatedLeads = leads.filter((_, index) => !selectedRowIndices.includes(`${index}`));
        setPerformingBulkOperation(true);
        if (sequence) {
          sequence.leads = updatedLeads;
          onSequenceChanged(sequence, true);
          APIService.updateSequence(activeWorkspace._id, sequence._id, sequence, (res, error) => {
            setPerformingBulkOperation(false);
            if (error) {
              toast.error(error, { theme: 'colored' });
              return;
            }
            let { message } = res;
            toast.success(message, { theme: 'colored' });
            setLeads(updatedLeads);
          });
        } else {
          const leadsToDelete = leads.filter((_, index) => selectedRowIndices.includes(`${index}`)).map(lead => lead._id);
          APIService.removeLeadsFromWorkspace(activeWorkspace._id, leadsToDelete, (res, error) => {
            setPerformingBulkOperation(false);
            if (error) {
              toast.error(error, { theme: 'colored' });
              return;
            }
            let { message } = res;
            toast.success(message, { theme: 'colored' });
            setLeads(updatedLeads);
          });
        }
        break;
      default:
        return;
    }
  }

  function BulkAction({ selectedRowIds }) {
    return (
      <Row className="flex-between-center mb-3">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {
              Object.keys(selectedRowIds).length > 0 ?
                'You have selected ' + Object.keys(selectedRowIds).length + ' rows'
                :
                'Imported Leads'
            }
          </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {
            Object.keys(selectedRowIds).length > 0 ? (
              <div className="d-flex">
                <Form.Select
                  value={bulkActionSelectedOption}
                  size="sm"
                  aria-label="Bulk actions"
                  onChange={handleSelectChange}
                >
                  <option value={'bulk-actions'}>Bulk Actions</option>
                  <option value="delete">Delete</option>
                </Form.Select>
                <Button
                  type="button"
                  variant="falcon-default"
                  size="sm"
                  disabled={!bulkActionSelectedOption || bulkActionSelectedOption === 'bulk-actions' || performingBulkOperation}
                  className="ms-2"
                  onClick={(e) => {
                    applyBulkAction(Object.keys(selectedRowIds));
                  }}
                >
                  {
                    performingBulkOperation ?
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      : <span>Apply</span>
                  }
                </Button>
              </div>
            ) : (
              <Flex
                alignItems={'center'}
                className={'gap-3'}
                justifyContent={'center'}>
                {
                  rest.refreshingLeads ?
                    <Reactloading className="mt-4" width={30} type="spinningBubbles" color="teal" />
                    :
                    <Button
                      variant="falcon-default"
                      size="sm"
                      transform="shrink-3"
                      // className='me-2'
                      onClick={() => {
                        if (rest.importLeads) {
                          rest.importLeads();
                        }
                      }}
                    >
                      <Flex alignItems={'center'} justifyContent={'center'}>
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="d-none d-sm-inline-block ms-1">IMPORT</span>
                      </Flex>
                    </Button>
                }
                {/* <OverlayTrigger
                  overlay={<Tooltip>{`Make the most of your sequence using AI`}</Tooltip>}
                >
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    onClick={() => {
                      setOpenAIPrompts(true);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBrain}
                      className="mx-2 cursor-pointer text-primary"
                    />
                  </IconButton>
                </OverlayTrigger> */}
              </Flex>
            )
          }
        </Col>
      </Row>
    );
  };

  return (
    <Card>
      <Card.Body>
        <AdvanceTableWrapper
          columns={columns}
          data={leads}
          sortable
          pagination
          perPage={50}
          selection
          selectionColumnWidth={30}
        >
          <BulkAction table />
          <AdvanceTableSearchBox
            table
            placeholder="Search leads"
            onSearchTextChanged={(changedSearchText) => {
              setSearchText(changedSearchText);
            }}
          />
          <div className="mb-3"></div>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <Modal show={openAIPrompts}
            onHide={() => {
              setOpenAIPrompts(false);
            }}
            onExit={() => {
              setOpenAIPrompts(false);
            }}>
            <Modal.Header closeButton>
              <Modal.Title>AI Prompts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AIPrompts sequence={sequence} />
            </Modal.Body>
          </Modal>
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={leads.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default ImportedLeads;
