import React from 'react';
// import { Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
// import { faEnvelope, faList, faEllipsisH, faCheckSquare } from '@fortawesome/free-solid-svg-icons'; // Import required icons
// import { NavLink } from 'react-router-dom'; // You might need to replace this line with your routing library

const UnifiedInboxBottomNav = ({ connectAccounts }) => (
  <Flex className="contacts-search-wrapper" onSubmit={e => e.preventDefault()}>
    {/* <div className='mb-3' style={{ bottom: 0, left: 0, width: '100%', backgroundColor: '#f2f2f2', padding: '10px 0', display: 'flex', justifyContent: 'space-around' }}> */}
      {/* <NavLink to="/email-accounts" style={{ textDecoration: 'none', color: 'black', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }} activeStyle={{ color: 'blue' }}>
        <FontAwesomeIcon icon={faEnvelope}  />
        <small>Accounts</small>
      </NavLink>

      <NavLink to="/sequences" style={{ textDecoration: 'none', color: 'black', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }} activeStyle={{ color: 'blue' }}>
        <FontAwesomeIcon icon={faList} />
        <small>Sequences</small>
      </NavLink>

      <NavLink to="/status" style={{ textDecoration: 'none', color: 'black', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }} activeStyle={{ color: 'blue' }}>
        <FontAwesomeIcon icon={faEllipsisH} />
        <small>More</small>
      </NavLink> */}

      {/* <NavLink to="/checkbox" style={{ textDecoration: 'none', color: 'black', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }} activeStyle={{ color: 'blue' }}>
        <FontAwesomeIcon icon={faCheckSquare} />
        <small>Checkbox</small>
      </NavLink> */}
    {/* </div> */}
  </Flex>
);

export default UnifiedInboxBottomNav;
