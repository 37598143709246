import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import ReactLoading from 'react-loading';
import APIService from "http/api_service";
import WorkSpaceManager from "utils/workspace_manager";

export default function ManualEmailsImporter(props) {

    const { sequence, sequenceUpdateHandler } = props;
    const workSpace = WorkSpaceManager.getActiveWorkSpace();
    const [checkForDuplicates, setCheckForDuplicates] = useState(true);
    const [leadText, setLeadText] = useState();
    const [importingSequences, setImportingSequences] = useState(false);

    const extractContacts = (lines) => {
        const emailRegex = /[\w\d._%+-]+@[\w\d.-]+\.[\w]{2,}/;
        const contacts = lines.map(line => {
            const emailMatch = line.match(emailRegex);
            const email = emailMatch ? emailMatch[0] : null;
            const name = `${email ? line.replace(emailRegex, "").trim() : line.trim()}`.replace(/,/g, '');
            return { name, email };
        }).filter(contact => contact !== null);
        return contacts;
    }

    const importEmails = () => {
        if (!leadText) {
            toast.error("Please import some emails", { theme: 'colored' });
            return;
        }
        const lines = leadText.trim().split('\n');
        const leads = extractContacts(lines);
        setImportingSequences(true);
        APIService.updateSequenceLeads(workSpace._id, sequence?._id, leads, (response, error) => {
            setImportingSequences(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { message, data } = response;
            toast.success(message, { theme: 'colored' });
            if (sequenceUpdateHandler) {
                sequenceUpdateHandler(data);
            }
        });
    }

    return (
        <Flex
            direction={'column'}
            style={{
                width: '75vw'
            }}>
            <h3 style={{ fontWeight: 600 }}>Bulk Insert Manually</h3>
            <Card bg="light">
                <Card.Body>
                    <Flex direction={'column'}>
                        <strong>💡 To add emails with name, you can use one of the following formats:</strong>
                        <br />
                        <b>{"John Doe <john@doe.com>"}</b>
                        <b>{"Jane Smith jane@smith.com"}</b>
                    </Flex>
                </Card.Body>
            </Card>
            <span className="pt-5 pb-2">Type or paste email addresses (one email per line)</span>
            <Form.Control
                value={leadText}
                as="textarea"
                onChange={(e) => {
                    setLeadText(e.target.value);
                }}
                rows={9}
                placeholder="Add your emails here"
                style={{
                    boxShadow: '0 10px 25px rgba(60,72,88,.15)',
                    padding: '1rem',
                    outline: 'none',
                    border: 'none'
                }}
            />
            <Flex className={'pt-4'} alignItems={'center'} justifyContent={'center'}>
                <Flex alignItems={'center'} justifyContent={'center'} direction={'column'}>
                    <Form.Group>
                        <Form.Check
                            checked={checkForDuplicates}
                            onChange={(e) => {
                                setCheckForDuplicates(e.target.checked);
                            }}
                            type="checkbox"
                            label="Check for duplicates across all sequences"
                        />
                    </Form.Group>
                    <Button disabled={importingSequences} onClick={() => importEmails()} style={{ width: 200 }}>
                        {
                            importingSequences ?
                                <Flex className={'gap-3'} alignItems={'center'} justifyContent={'center'}>
                                    <ReactLoading type="bubbles" color="white" width={30} height={30} />
                                    <span>Importing...</span>
                                </Flex>
                                : <Flex className={'gap-3'} alignItems={'center'} justifyContent={'center'}>
                                    <span>Import emails</span>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </Flex>
                        }
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
}