import Flex from "components/common/Flex";

const ThreadTile = ({ title, subTitle, alignment = "start" }) => {

    return (
        <Flex direction={"column"} justifyContent={"center"}>
            <h6
                className={`fs-0 d-flex align-items-center text-700 mb-1 align-self-${alignment}`}
                dangerouslySetInnerHTML={{ __html: title }}
            ></h6>
            <h6
                className="fs--2 text-600 mb-0 text-nowrap"
                dangerouslySetInnerHTML={{
                    __html: subTitle,
                }}
            ></h6>
        </Flex>
    )
}

export default ThreadTile;