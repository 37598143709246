import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';
import IconButton from 'components/common/IconButton';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import FalconLightBox from 'components/common/FalconLightBox';
import { simulateMouseClick } from 'utils/gen';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoggedInUserAvatar } from '../../../../redux/slices/workspaceslice';
import APIService from 'http/api_service';
import WorkSpaceManager from 'utils/workspace_manager';

const ProfileBannerHeader = ({ coverSrc, className, ...others }) => {

  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [selectedPhotoForUpload, setSelectedPhotoForUpload] = useState();
  const [avatar, setAvatar] = useState(others.avatar);
  const dispatch = useDispatch();
  const activeWorkspace = WorkSpaceManager.getActiveWorkSpace();

  const uploadUserAvatar = async () => {
    if (uploadingAvatar) {
      return;
    }
    setUploadingAvatar(true);
    let requestBody = new FormData();
    requestBody.append("file", selectedPhotoForUpload);
    let response = await APIService.uploadNewFile(activeWorkspace?._id, requestBody, 0, null, 1);
    let result = response.data;
    let data = {};
    data.uploaded_logo = result;
    APIService.updateUserAvatar(data, (response, error) => {
      setUploadingAvatar(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { message, data } = response;
      if (data) {
        toast.success(message, { theme: 'colored' });
        dispatch(setLoggedInUserAvatar(data.avatar))
        setSelectedPhotoForUpload(undefined);
      }
    });
  }

  return (
    <Card.Header
      className={classNames(className, 'position-relative min-vh-25 mb-7')}
    >
      <Background image={coverSrc} className="rounded-3 rounded-bottom-0" />
      <div
        className='avatar-profile'
        style={{ position: 'relative' }}>
        {
          selectedPhotoForUpload &&
          <Button
            onClick={() => {
              uploadUserAvatar();
            }}
            disabled={uploadingAvatar}
            variant='primary'
            className='rounded-pill'
            size='sm'
            style={{
              position: 'absolute',
              zIndex: 1,
              top: 65,
              left: 12
            }}>{uploadingAvatar ? "Uploading..." : "Upload Selection"}
          </Button>
        }
        <FalconLightBox image={avatar}>
          <Avatar
            size="5xl"
            className="cursor-pointer"
            src={avatar}
            mediaClass="img-thumbnail shadow-sm"
          />
        </FalconLightBox>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id="profile-overlay-trigger">
              Click to select and upload your avatar
            </Tooltip>
          }>
          <span
            style={{
              position: 'absolute',
              bottom: -3,
              left: 49,
              paddingLeft: 10,
            }}>
            <IconButton
              variant={'info'}
              className={'rounded-pill'}
              icon={faEdit}
              onClick={() => {
                simulateMouseClick(document.getElementById("local-avatar-file-selection-button"));
              }}>
            </IconButton>
          </span>
        </OverlayTrigger>
      </div>
      <input
        id="local-avatar-file-selection-button"
        style={{
          display: 'none'
        }}
        accept={`image/*`}
        type="file"
        onChange={(e) => {
          let files = e.target.files;
          let file = files[0];
          let fileUrl = URL.createObjectURL(file);
          setSelectedPhotoForUpload(file);
          setAvatar(fileUrl);
        }}
      />
    </Card.Header>
  );
};

const ProfileBannerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const ProfileBanner = ({ children }) => {
  return <Card className="mb-3">{children}</Card>;
};

ProfileBanner.Header = ProfileBannerHeader;
ProfileBanner.Body = ProfileBannerBody;

ProfileBannerHeader.propTypes = {
  avatar: PropTypes.string.isRequired,
  coverSrc: PropTypes.string.isRequired,
  className: PropTypes.string
};

ProfileBannerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileBanner;
