import { PlatformIdentifier } from "../constants";
import UserManager from "./user_manager";
import Color from 'color';
import { extractLinksFromTweet } from "./url";

const refinedStati = (originalTweet, statusParts) => {
    let allWords = originalTweet.split(" ");
    let lastFilteredOutWord = [];
    for (let i = 0; i < statusParts.length; i++) {
        let status = statusParts[i];
        let wordsInStatus = `${lastFilteredOutWord.join("")}${status}`.split(" ");
        let validWords = wordsInStatus.filter(x => allWords.includes(x));
        statusParts[i] = validWords.join(" ");
        let invalidWord = wordsInStatus.filter(x => !allWords.includes(x));
        lastFilteredOutWord = invalidWord;
    }
    statusParts.push(lastFilteredOutWord);
    statusParts = statusParts.flat();
    return statusParts.filter(x => x.length);
}

export const chunkTweetIntoThreads = (tweet) => {
    let breakPoint = 279;
    let statusParts = [];
    let canContinueChunking = true;
    let originalTweet = tweet;
    if (tweet.length <= breakPoint) {
        return [tweet];
    }
    let resolvedLinksInTweet = extractLinksFromTweet(originalTweet);
    if (resolvedLinksInTweet.length > 0) {
        for (let link of resolvedLinksInTweet) {
            tweet = tweet.replace(link, `<link>${link}</link>`);
        }
    }
    do {
        try {
            if (tweet.length <= breakPoint) {
                let cleanedTweet = tweet.replace("<link>", "").replace("</link>", "");
                if (!statusParts.includes(cleanedTweet) && cleanedTweet.length > 0) {
                    statusParts.push(cleanedTweet);
                }
                canContinueChunking = false;
            } else {
                let threadEntry = tweet.substring(0, breakPoint);
                let lastIndexOfPeriod = threadEntry.lastIndexOf(".");
                let canSkipJumper = lastIndexOfPeriod === -1;
                if (threadEntry.includes("<link>")) {
                    lastIndexOfPeriod = threadEntry.indexOf("<link>");
                    lastIndexOfPeriod = Math.max(0, lastIndexOfPeriod - 1);
                    if (lastIndexOfPeriod === 0) {
                        lastIndexOfPeriod = breakPoint;
                        canSkipJumper = true;
                    }
                }
                if (lastIndexOfPeriod !== -1) {
                    threadEntry = threadEntry.substring(0, lastIndexOfPeriod + 1);
                    let cleanThreadEntry = threadEntry.replace("<link>", "").replace("</link>", "");
                    tweet = tweet.substr(lastIndexOfPeriod + (canSkipJumper ? 0 : 1));
                    if (!statusParts.includes(cleanThreadEntry)) {
                        statusParts.push(cleanThreadEntry);
                    }
                } else {
                    let cleanThreadEntry = threadEntry.replace("<link>", "").replace("</link>", "");
                    if (!statusParts.includes(cleanThreadEntry)) {
                        statusParts.push(cleanThreadEntry);
                    }
                    let indexOfThreadEntry = tweet.indexOf(threadEntry);
                    indexOfThreadEntry = indexOfThreadEntry + threadEntry.length;
                    tweet = tweet.substr(indexOfThreadEntry);
                }
            }
        } catch (e) {
            canContinueChunking = false;
        }
    } while (canContinueChunking);
    return refinedStati(originalTweet, statusParts);
}

export const getTagForPages = (platform, pluralize = true) => {
    let tag = `page${pluralize ? 's' : ''}`
    if (platform.identifier === PlatformIdentifier.YOUTUBE) {
        tag = `channel${pluralize ? 's' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.PINTEREST) {
        tag = `board${pluralize ? 's' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.REDDIT) {
        tag = `subreddit${pluralize ? 's' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.TELEGRAM) {
        tag = `group(s) & channel(s)`;
    } else if (platform.identifier === PlatformIdentifier.FACEBOOK) {
        tag = `page(s)/group(s)`;
    } else if (platform.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
        tag = `location${pluralize ? '(s)' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.TWITTER) {
        tag = `account${pluralize ? '(s)' : ''}`;
    } else if (platform.identifier === PlatformIdentifier.WHATSAPP) {
        tag = `account${pluralize ? '(s)' : ''}`;
    }
    return tag;
}

export const formatTrendVolume = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
export function simulateMouseClick(element) {
    if (element) {
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                })
            )
        );
    }
}

export const svgWhiteList = (existingPlatform) => {
    let whiteList = [PlatformIdentifier.GOOGLE_MY_BUSINESS];
    return whiteList.includes(existingPlatform?.identifier);
}

export const getLoggedInUserFullName = () => {
    let loggedInUser = UserManager?.getLoggedInUser();
    let firstName = loggedInUser?.first_name;
    let lastName = loggedInUser?.last_name ?? "";
    if (!firstName) {
        firstName = loggedInUser?.email.split("@")[0]
    }
    return `${firstName} ${lastName}`;
}

export const getLoggedInUserAvatar = () => {
    let loggedInUser = UserManager?.getLoggedInUser();
    return loggedInUser?.avatar;
}

export const mixin = {
    darken: (colorValue, amount) =>
        Color(colorValue)
            .darken(amount)
            .string(),
    lighten: (colorValue, amount) =>
        Color(colorValue)
            .lighten(amount)
            .string(),
    rgba: (colorValue, opacity) =>
        Color(colorValue)
            .alpha(opacity)
            .string()
};

export const ordinalSuffixOf = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}