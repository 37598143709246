import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { Provider } from 'react-redux';
import { store } from './redux/store';

// Ensure that the 'root' element exists and is used as the root container.
const rootElement = document.getElementById('root');

// Check if rootElement is found, then use createRoot.
if (rootElement) {
    const root = createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <Main>
                    <App />
                </Main>
            </Provider>
        </React.StrictMode>
    );
} else {
    console.error("Root element not found. Make sure the element with id 'root' exists in your HTML.");
}
