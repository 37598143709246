import React from "react";
import { Link } from "react-router-dom";
import LoginForm from "components/authentication/LoginForm";
import AuthSplitLayout from "layouts/AuthSplitLayout";
import bgImg from "assets/img/generic/14.jpg";
import Flex from "components/common/Flex";
import { Helmet } from "react-helmet";

const Login = () => {
  return (
    <>
      <Helmet>
        <title>Login - Reachable</title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ReachableHQ " />
        <meta name="twitter:creator" content="@ReachableHQ " />
        <meta
          property="og:description"
          content="Login - Reachable"
        />
        <meta
          content="https://twitter.com/ReachableHQ"
          property="og:see_also"
        />
        <meta name="twitter:title" content="Login - Reachable" />
        <meta name="twitter:description" content="" />
        {/* <meta name="twitter:image" content="https://promoteapp.s3.amazonaws.com/promote-twitter-card.jpeg" /> */}
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachable.to/" rel="canonical " />
        <link href="https://reachable.to/" rel="home " />
      </Helmet>

      <AuthSplitLayout bgProps={{ image: bgImg, position: "50% 20%" }}>
        <Flex alignItems="center" justifyContent="between">
          <h3>Login</h3>
          <p className="mb-0 fs--1">
            <span className="fw-semi-bold">New User? </span>
            <Link to="/register">Create account</Link>
          </p>
        </Flex>
        <LoginForm layout="split" hasLabel />
      </AuthSplitLayout>
    </>
  );
};

export default Login;
