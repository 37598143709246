import PageHeader from 'components/common/PageHeader';
import { Card, ProgressBar } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FileUploader from '../file-uploader';
import { useState } from 'react';
import { BulkWorkspacesUploadKnowlegdeLink } from 'constants';
import { toast } from 'react-toastify';
import BulkWorkspacesPreviewer from './BulkWorkspacesPreviewer';
import { useDispatch } from 'react-redux';
import { setBulkWorkSpacesToCreate } from '../../../redux/slices/workspaceslice';

const BulkWorkspacesCreator = () => {

    const [processing, setProcessing] = useState(false);
    const [openPreviewer, setOpenPreviewer] = useState(false);
    const dispatch = useDispatch();

    const processFile = (data) => {
        setProcessing(true);
        let dataEntries = Object.entries(data).map((entry) => entry);
        if (dataEntries.length > 0) {
            let expectedDataFormat = dataEntries[0][1];
            if (expectedDataFormat) {
                let name = expectedDataFormat['name'];
                if (!name) {
                    toast.error("compulsory name column not found", { theme: 'colored' });
                    setProcessing(false);
                    return;
                }
                setProcessing(false);
                dispatch(setBulkWorkSpacesToCreate(data));
                setOpenPreviewer(true);
            }
        } else {
            setProcessing(false);
            toast.error("CSV With empty columns submitted", { theme: 'colored' });
        }
    }

    return (
        <>
            <PageHeader title="Create Multiple Workspaces" titleTag="h5" className="mb-3">
                <p className="fs--1 mt-1">Create Multiple Workspaces at once from a CSV File</p>
            </PageHeader>
            {
                !openPreviewer &&
                <Card>
                    <FalconCardHeader title="Pick CSV File" />
                    <Card.Body className="bg-light">
                        {
                            !processing &&
                            <FileUploader
                                hint={"Select or Drag a CSV file here"}
                                onFileLoaded={(data) => {
                                    processFile(data);
                                }}
                            />
                        }
                        {
                            processing &&
                            <div
                                style={{
                                    minHeight: 150
                                }}>
                                <ProgressBar variant='primary' className='fs-1' style={{ marginTop: '10%' }} label='Processing File' animated={true} now={45} />
                            </div>
                        }
                    </Card.Body>
                </Card>
            }
            {
                openPreviewer &&
                <BulkWorkspacesPreviewer
                    pickNewFile={() => {
                        setOpenPreviewer(false);
                        setProcessing(false);
                    }}
                />
            }
            <p
                style={{
                    textAlign: 'center'
                }}
                className={'p-1'}>You CSV Must adhere to
                <a href={BulkWorkspacesUploadKnowlegdeLink}
                    target={'_blank'}
                    rel='noreferrer'
                    style={{
                        paddingLeft: 5,
                        textDecoration: 'none'
                    }}>this format
                </a></p>
        </>
    )
}

export default BulkWorkspacesCreator;