import PageHeader from 'components/common/PageHeader';
import { Card, ProgressBar } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FileUploader from 'components/app/file-uploader';
import { useState } from 'react';
import { BulkEmailAccountsUploadKnowlegdeLink } from 'constants';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setBulkEmailAccountsToCreate } from '../../../../redux/slices/workspaceslice';
import AnyProviderBulkUploadPreviewer from './AnyProviderBulkUploadPreviewer';

const AnyProviderBulkUploader = () => {

    const [processing, setProcessing] = useState(false);
    const [openPreviewer, setOpenPreviewer] = useState(false);
    const dispatch = useDispatch();

    const processFile = (data) => {
        setProcessing(true);
        let dataEntries = Object.entries(data).map((entry) => entry);
        if (dataEntries.length > 0) {
            let expectedDataFormat = dataEntries[0][1];
            if (expectedDataFormat) {
                setProcessing(false);
                dispatch(setBulkEmailAccountsToCreate(data));
                setOpenPreviewer(true);
            }
        } else {
            setProcessing(false);
            toast.error("CSV With empty columns submitted", { theme: 'colored' });
        }
    }

    return (
        <>
            <PageHeader title="Connect multiple emails" titleTag="h5" className="mb-3">
                <p className="fs--1 mt-1">Connect multiple email accounts from any provider at once by uploading a CSV File</p>
            </PageHeader>
            {
                !openPreviewer &&
                <Card>
                    <FalconCardHeader title="Pick CSV File" />
                    <Card.Body className="bg-light">
                        {
                            !processing &&
                            <FileUploader
                                hint={"Select or Drag a CSV file here"}
                                onFileLoaded={(data) => {
                                    processFile(data);
                                }}
                            />
                        }
                        {
                            processing &&
                            <div
                                style={{
                                    minHeight: 150
                                }}>
                                <ProgressBar variant='primary' className='fs-1' style={{ marginTop: '10%' }} label='Processing File' animated={true} now={45} />
                            </div>
                        }
                    </Card.Body>
                </Card>
            }
            {
                openPreviewer &&
                <AnyProviderBulkUploadPreviewer
                    pickNewFile={() => {
                        setOpenPreviewer(false);
                        setProcessing(false);
                    }}
                />
            }
            <p
                style={{
                    textAlign: 'center'
                }}
                className={'p-1'}>Your CSV Must adhere to
                <a href={BulkEmailAccountsUploadKnowlegdeLink}
                    target={'_blank'}
                    rel='noreferrer'
                    style={{
                        paddingLeft: 5,
                        textDecoration: 'none'
                    }}>this format
                </a></p>
        </>
    )
}

export default AnyProviderBulkUploader;