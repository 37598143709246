/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PulseLoader from "react-spinners/PulseLoader";
import Flex from 'components/common/Flex';
import UserManager from 'utils/user_manager';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RoutePaths } from '../constants';
import WorkSpaceManager from 'utils/workspace_manager';
import APIService from 'http/api_service';
import { cachePosts, setSelectedPostType } from '../redux/slices/postslice';
import { toast } from 'react-toastify';
import {
    setFlipToChildPages,
    setSwitchMode
} from '../redux/slices/authSlice';
import { Button, Modal } from 'react-bootstrap';
import PaymentFailedAnimation from '../assets/animations/payment_failed.json';
import Lottie from 'lottie-react';

export const attachOccupationToWorkspace = (loggedInUser, firstWorkspace) => {
    if (loggedInUser) {
        if (loggedInUser.occupation) {
            if (!firstWorkspace.title) {
                firstWorkspace.title = loggedInUser.occupation;
            }
        }
    }
}

const SplashLayout = () => {

    const altableUser = UserManager.getAltableUser();
    const altableUsers = UserManager.getLoggedInAltUsers();
    const loggedInUser = UserManager.getLoggedInUser();
    const activeWorkspace = WorkSpaceManager.getActiveWorkSpace();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pausedAccount, setPausedAccount] = useState();

    const reconcileAltUsersAndReload = (users) => {
        UserManager.setLoggedInUser(altableUser);
        UserManager.setAltableUsers(users);
        dispatch(cachePosts([]));
        dispatch(setSwitchMode(false));
        dispatch(setFlipToChildPages(false));
        UserManager.deleteAltableUser();
        window.location.reload();
    }

    const checkAndOrganizeSwitchableAccounts = () => {
        localStorage.clear();
        let newAltableUsers = altableUsers ?? [];
        let existingAltableUser = newAltableUsers.find(x => x._id === altableUser._id);
        if (!existingAltableUser) {
            newAltableUsers.unshift(altableUser);
            reconcileAltUsersAndReload(newAltableUsers)
        } else {
            reconcileAltUsersAndReload(altableUsers)
        }
    }

    const initApp = () => {
        if (activeWorkspace) {
            if (altableUser) {
                if (altableUser._id !== loggedInUser._id) {
                    checkAndOrganizeSwitchableAccounts();
                    return;
                }
            }
            APIService.fetchWorkSpace(activeWorkspace['_id'], (response, error) => {
                if (error) {
                    if (error.toLowerCase().includes('belong here')) {
                        WorkSpaceManager.deleteActiveWorkSpace();
                        navigate(RoutePaths.NOT_FOUND);
                    } else {
                        toast.error(error, { theme: 'colored' });
                        if (error.toLowerCase().includes("Workspace not found".toLowerCase())) {
                            WorkSpaceManager.deleteActiveWorkSpace();
                            navigate(RoutePaths.DEFAULT);
                            window.location.reload();
                        }
                    }
                    return;
                }
                if (response) {
                    let responseData = response['data'];
                    let workSpace = responseData['workspace'];
                    workSpace['role'] = responseData['role'];
                    workSpace['value'] = workSpace['name'];
                    workSpace['title'] = responseData['title'];
                    workSpace['label'] = workSpace['name'];
                    workSpace['workspace_owner'] = responseData['workspace_owner'];
                    attachOccupationToWorkspace(loggedInUser, workSpace);
                    WorkSpaceManager.setActiveWorkSpace(workSpace);
                }
            });
            fetchUserWorkSpaces();
        } else {
            if (altableUser) {
                if (altableUser._id !== loggedInUser._id) {
                    checkAndOrganizeSwitchableAccounts();
                    return;
                }
            }
            fetchUserWorkSpaces();
        }
    }

    const fetchUserWorkSpaces = () => {
        APIService.fetchLatestMe((latestMeResponse, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                if (error.toLowerCase().includes('Invalid credentials provided'.toLowerCase())) {
                    window.localStorage.clear();
                    setTimeout(() => {
                        navigate(RoutePaths.LOGIN);
                    }, 100);
                }
                return;
            }
            let latestUserData = latestMeResponse.data;
            UserManager.setLoggedInUser(latestUserData);
            APIService.checkUserWorkSpaces(loggedInUser['api_key'], (userStateResponse, userStateError) => {
                if (userStateError) {
                    if (!latestUserData.paused) {
                        toast.error(userStateError, { theme: 'colored' });
                    }
                    window.localStorage.clear();
                    setTimeout(() => {
                        if (latestUserData.paused) {
                            setPausedAccount({ data: latestUserData, message: userStateError });
                        } else {
                            window.location.reload();
                        }
                    }, 500);
                    return;
                }
                let workSpaceData = userStateResponse.data;
                if (!workSpaceData) {
                    loggedInUser['has_workspaces'] = false;
                    UserManager.setLoggedInUser(loggedInUser);
                    navigate(RoutePaths.NEW_WORKSPACE);
                } else {
                    APIService.fetchUserWorkSpaces((response, error) => {
                        if (error) {
                            toast.error(error, {
                                theme: 'colored'
                            });
                            return;
                        }
                        if (response) {
                            let responseData = response['data'].filter(x => x.workspace);
                            if (responseData.length === 0) {
                                navigate(RoutePaths.NEW_WORKSPACE);
                                return;
                            }
                            responseData = responseData.map((e) => {
                                let workSpace = e['workspace'];
                                workSpace['role'] = e['role'];
                                workSpace['title'] = e['title'];
                                workSpace['value'] = workSpace['name'];
                                workSpace['label'] = workSpace['name'];
                                return workSpace;
                            });
                            let uniqueWorkSpaces = [];
                            for (let workSpaceEntry of responseData) {
                                let existingWorkSpaceEntry = uniqueWorkSpaces.find((x) => x['name'] === workSpaceEntry['name']);
                                if (!existingWorkSpaceEntry) {
                                    uniqueWorkSpaces.push(workSpaceEntry);
                                }
                            }
                            let lastViewedWorkspace = loggedInUser.last_viewed_workspace;
                            let firstActive;
                            if (lastViewedWorkspace) {
                                firstActive = responseData.find(x => x._id === lastViewedWorkspace);
                            }
                            if (firstActive) {
                                if (firstActive.archived) {
                                    firstActive = responseData.find(x => !x.archived);
                                }
                            } else {
                                firstActive = responseData.find(x => !x.archived);
                            }
                            if (!firstActive) {
                                navigate(RoutePaths.NEW_WORKSPACE);
                                return;
                            }
                            WorkSpaceManager.cacheWorkSpaces(uniqueWorkSpaces);
                            dispatch(setSelectedPostType("all"));
                            if (!activeWorkspace) {
                                WorkSpaceManager.setActiveWorkSpace(firstActive);
                                APIService.updateLastViewedWorkSpace(firstActive._id, (_res, _err) => {
                                    window.location.reload();
                                });
                            } else {
                                if (activeWorkspace.archived) {
                                    WorkSpaceManager.deleteActiveWorkSpace(activeWorkspace);
                                    window.location.reload();
                                    return;
                                }
                                APIService.updateLastViewedWorkSpace(activeWorkspace._id, (res, err) => {
                                    navigate(RoutePaths.POSTS);
                                })
                            }
                        }
                    });
                }
            });
        });
    }

    useEffect(() => {
        localStorage.setItem("visited_splash", true);
        if (loggedInUser) {
            let altableUsersCopy = altableUsers ?? [];
            let user = altableUsersCopy.find(x => x._id === loggedInUser._id);
            if (!user) {
                altableUsersCopy.unshift(loggedInUser);
                UserManager.setAltableUsers(altableUsersCopy);
            }
            initApp();
        } else {
            navigate(RoutePaths.LOGIN);
        }
    }, []);

    return (
        <Flex className="min-vh-100" alignItems={'center'} justifyContent={'center'}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <PulseLoader color="#2c7be5" />
            </Flex>
            <Modal
                backdrop={'static'}
                show={pausedAccount}
                onHide={() => setPausedAccount(null)}
                onExit={() => {
                    setPausedAccount(null);
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title>Account Temporarily Paused</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Flex direction={'column'} className={'p-5'} alignItems={'center'} justifyContent={'center'}>
                        <Lottie
                            animationData={PaymentFailedAnimation}
                            loop={false}
                        />
                        <p
                            style={{
                                textAlign: 'center'
                            }}>{pausedAccount?.message}
                        </p>
                        <Button
                            variant='falcon-warning'
                            onClick={() => {
                                window.location = pausedAccount?.data?.active_plans?.update_url
                            }}>Update Payment Information
                        </Button>
                    </Flex>
                </Modal.Body>
            </Modal>
        </Flex>
    );
};

SplashLayout.propTypes = {
    match: PropTypes.object
};
export default SplashLayout;
