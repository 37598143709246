import React, { useContext } from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import APIService from 'http/api_service';
import UserManager from 'utils/user_manager';
import WorkSpaceManager from 'utils/workspace_manager';

const TopNavRightSideNavItem = () => {

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const loggedInUser = UserManager.getLoggedInUser();
  const activeWorkSpace = WorkSpaceManager.getActiveWorkSpace();

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => {
            let darkNow = !isDark;
            setConfig('isDark', darkNow)
            let themeChoice = darkNow ? 'dark' : 'light';
            APIService.updateWorkspaceTheme(activeWorkSpace._id, themeChoice, (response, error) => {
              let themeChoices = activeWorkSpace.theme_choices ?? {}
              let userId = loggedInUser._id;
              themeChoices[userId] = themeChoice;
              activeWorkSpace.theme_choices = themeChoices;
              WorkSpaceManager.setActiveWorkSpace(activeWorkSpace);
            });
          }}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      {/* <NotificationDropdown /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
