const baseStyle = {
    height: '42px',
    width: '240px',
    border: '2px solid #4285f4',
    borderRadius: '4px',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color .218s, border-color .218s, box-shadow .218s',
    fontFamily: 'Roboto,arial,sans-serif',
    cursor: 'pointer',
    userSelect: 'none'
}

export const darkStyle = {
    backgroundColor: '#4285f4',
    color: '#fff',
    ...baseStyle
}

export const lightStyle = {
    backgroundColor: '#fff',
    color: 'rgba(0,0,0,.54)',
    ...baseStyle
}

export const iconStyle = {
    width: '48px',
    textAlign: 'center',
    display: 'block',
    borderRadius: '1px',
}

export const svgStyle = {
    width: '48px',
    height: '40px',
    display: 'block'
}

export const hoverStyle = {
    boxShadow: '0 0 3px 3px rgba(66,133,244,.3)',
    transition: 'background-color .218s, border-color .218s, box-shadow .218s'
}

export const disabledStyle = {
    backgroundColor: 'rgba(37, 5, 5, .08)',
    color: 'rgba(0, 0, 0, .40)',
    cursor: 'not-allowed'
}

export const disabledIconStyle = {
    backgroundColor: 'transparent'
}