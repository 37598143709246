import styled, { css } from 'styled-components';
import { mixin } from 'utils/theme';

export const CircularButtonBase = styled.div`
width:${props => props.width ?? 30}px;
height:${props => props.height ?? 30}px;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
color:${props => props.theme.textColor};
border-radius:100%;
background-color:${props => props.color ?? props.theme.surfaceColor};
${props => props.canHover && css`
    &:hover{background-color:${props => mixin.darken(props.color ?? props.theme.surfaceColor, .1)};}
`}
`;