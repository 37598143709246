import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    changedPlatform: null,
    targetPlatforms: [],
    updatedAccount: {},
    selectedPlatformForPreview: null,
    disconnectedPlatforms: null,
};

export const platformSlice = createSlice({
    name: 'platform',
    initialState,
    reducers: {
        setChangedPlatform: (state, action) => {
            state.changedPlatform = action.payload
        },
        setChangedTargetPlatforms: (state, action) => {
            state.targetPlatforms = action.payload;
        },
        setUpdatedAccount: (state, action) => {
            state.updatedAccount = action.payload;
        },
        setSelectedPlatformForPreview: (state, action) => {
            state.selectedPlatformForPreview = action.payload;
        },
        setDisconnectedPlatforms: (state, action) => {
            state.disconnectedPlatforms = action.payload;
        }
    }
});

export const getChangedPlatform = (state) => state.platform.changedPlatform;
export const getTargetPlatforms = (state) => state.platform.targetPlatforms;
export const getUpdatedAccount = (state) => state.platform.updatedAccount;
export const getSelectedPlatformForPreview = (state) => state.platform.selectedPlatformForPreview;
export const getDisconnectedPlatforms = (state) => state.platform.disconnectedPlatforms;
export const {
    setChangedPlatform,
    setChangedTargetPlatforms,
    setUpdatedAccount,
    setSelectedPlatformForPreview,
    setDisconnectedPlatforms
} = platformSlice.actions;
export default platformSlice.reducer;