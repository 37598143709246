import { faRandom, faUserMinus } from "@fortawesome/free-solid-svg-icons"
import Divider from "components/common/Divider"
import Flex from "components/common/Flex"
import IconButton from "components/common/IconButton"
import EndPoints from "http/endpoints"
import React, { useEffect, useRef, useState } from "react"
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import "react-quill/dist/quill.snow.css"
import { Input } from "reactstrap"
import CustomVariables from "./CustomVariables"
import Editor from "./Editor"
import "./index.css"


export default function EmailComposeEditor(props) {
  //too much state handlig in this component. this can kill performance. work on improving
  const quillRef = useRef(null)
  const customRef = useRef(null);
  const [subject, setSubject] = useState(props.subject)
  const [body, setBody] = useState(props.body)
  const [editorState, setEditorState] = useState();
  const [unsubscribeLinkContent, setUnsubscribeContent] = useState(
    "Click here to unsubscribe",
  )
  const [currentlyEditing, setCurrentlyEditing] = useState("body")
  const [openInsertUnsubscribeLink, setOpenInsertUnsubscribeLink] =
    useState(false)
  const [updateEditorContent, setUpdateEditorContent] = useState(null)

  const handleInsertCharacter = (characterToAdd, spintax = false) => {
    if (spintax) {
      setUpdateEditorContent({
        spintax: true,
        value: `{this | that}`,
        originalBody: body,
      });
      return;
    }
    if (currentlyEditing === "subject") {
      setSubject(subject + characterToAdd)
      return
    }
    setUpdateEditorContent({
      value: characterToAdd,
      originalBody: body,
    });
  }

  //add unsubscribe link to editor
  const addUnsubscribeLink = () => {
    setUpdateEditorContent({
      value: `<a href="${EndPoints.BASE}/v1/unsubscribe-email" target="_blank" rel="noopener noreferrer">${unsubscribeLinkContent}</a>`,
      originalBody: body,
    });
    setOpenInsertUnsubscribeLink(false)
  }

  useEffect(() => {
    if (props.updateHandler) {
      props.updateHandler({ body, subject })
    }
  }, [body, subject])

  const handleSubjectChange = (event) => {
    if (currentlyEditing === "body") {
      setCurrentlyEditing("subject")
    }
    setSubject(event.target.value)
  }

  const handleBodyChange = (value) => {
    if (currentlyEditing === "subject") {
      setCurrentlyEditing("body")
    }
    setBody(value)
  }

  // const handleSend = () => {
  //   setSubject("")
  //   setBody("")
  // }

  const toolbarOptions = {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
    handlers: {},

  }

  return (
    <div style={{ height: "112vh", width: "60vw" }}>
      <Card
        elevation={2}
        style={{
          borderRadius: 10,
          width: "100%",
          height: "75%",
          position: "relative",
          overflowY: "scroll",
        }}
      >
        <Flex
          direction={"column"}
          style={{
            marginTop: 20,
            borderRadius: 0,
          }}
        >
          <Flex
            className={"gap-2"}
            alignItems={"center"}
            style={{
              borderRadius: 10,
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <h5 className="text-600">
              <strong>Subject</strong>
            </h5>
            <Input
              type="text"
              id="subject"
              value={subject}
              placeholder="Your subject"
              onChange={handleSubjectChange}
              className="outline-none border-none"
              style={{
                outline: "none",
                border: "none",
                fontWeight: 600,
                background: "transparent",
              }}
            />
          </Flex>
          <Divider />
          <Row className="">
            <Col className="d-flex align-items-center">
              <CustomVariables handleInsertCharacter={handleInsertCharacter} />

              <IconButton
                variant="falcon-default"
                size="sm"
                icon={faUserMinus}
                onClick={() => {
                  setOpenInsertUnsubscribeLink(true)
                }}
                // iconClassName="me-2"
                className="ms-0 me-2 d-none d-md-block"
                style={{ boxShadow: "none", border: "1px solid #d8e2ef" }}
              >
                Insert unsub link
              </IconButton>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon={faRandom}
                onClick={() => {
                  handleInsertCharacter(null, true)
                }}
                // iconClassName="me-2"
                className="ms-0 me-2 d-none d-md-block"
                style={{ boxShadow: "none", border: "1px solid #d8e2ef" }}
              >
                Insert spintax
              </IconButton>
              {/* <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="magic"
                                // iconClassName="me-2"
                                className="ms-0 me-2 d-none d-md-block"
                                style={{ boxShadow: 'none', border:"1px solid #d8e2ef" }}
                                >
                                AI mail writer
                            </IconButton> */}
            </Col>
            {/* <Col xs="auto" as={Flex} alignItems="center">
                            <Dropdown align="end" className="font-sans-serif">
                            <Dropdown.Toggle
                                size="sm"
                                variant="falcon-default"
                                className="dropdown-caret-none" >
                                <FontAwesomeIcon icon="ellipsis-h" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="border py-2">
                                <Dropdown.Item href="#!">Copy link</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#!">Settings</Dropdown.Item>
                                <Dropdown.Item href="#!">Themes</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#!" className="text-danger">
                                Remove
                                </Dropdown.Item>
                            </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
          </Row>
          <Divider />
          {/* <ReactQuill
            ref={quillRef}
            value={body}
            onChange={handleBodyChange}
            style={{ marginBottom: '110px' }}
            modules={{ toolbar: toolbarOptions }}
            className={"editor"}
            placeholder="Start typing..."
          /> */}
          <Editor
            value={body}
            setValue={setBody}
            currentlyEditing={currentlyEditing}
            setCurrentlyEditing={setCurrentlyEditing}
            updateEditorContent={updateEditorContent}
            setUpdateEditorContent={setUpdateEditorContent}
          />
        </Flex>
        <Modal
          show={openInsertUnsubscribeLink}
          onHide={() => setOpenInsertUnsubscribeLink(false)}
          onExit={() => setOpenInsertUnsubscribeLink(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Insert Unsubscribe Links</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="oldPassword">
              <Form.Label>Display as</Form.Label>
              <Form.Control
                type="text"
                value={unsubscribeLinkContent}
                // value="Click here to unsubscribe"
                name="oldPassword"
                onChange={(e) => setUnsubscribeContent(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              onClick={addUnsubscribeLink}
              variant="primary"
              className="px-4 mx-0"
            >
              Insert Unsubscribe Link
            </Button>
            <div style={{ width: 10 }}></div>
            <Button variant="text">Cancel</Button>
          </Modal.Footer>
        </Modal>
      </Card>
    </div>
  )
}
