import { faChevronLeft, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import AppContext from "context/Context";
import { useContext, useState } from "react";
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import TextareaAutoSize from 'react-textarea-autosize';
import NewTemplateAdvancedOptions from "./new_template_advanced_options.js.js";
import { getLoggedInUserAvatar, getLoggedInUserFullName } from "utils/gen.js";
import { promptData } from "../../data/index.js";
import WorkSpaceManager from "utils/workspace_manager.js";
import APIService from "http/api_service.js";
import { toast } from "react-toastify";

export default function CreateNewPromptTemplate(props) {

    const { onBackPressed, handleCreatedTemplate } = props;
    const { config } = useContext(AppContext);
    const [displayAdvancedTemplateOptions, setDisplayAdvancedTemplateOptions] = useState(false);
    const [displayPromptTextOutputPreview, setDisplayPromptTestOutputPreview] = useState();
    const [promptTestResults, setPromptTestResults] = useState([]);
    const [template, setTemplate] = useState({});
    const [creatingTemplate, setCreatingTemplate] = useState(false);
    const workspace = WorkSpaceManager.getActiveWorkSpace();
    const sequence = props.sequence;

    const templateAuthor = () => {
        return {
            "name": getLoggedInUserFullName(),
            "avatar": getLoggedInUserAvatar()
        }
    }

    const handleFormDataChange = (key, value) => {
        setTemplate((prevTemplateData) => {
            prevTemplateData[key] = value;
            return { ...prevTemplateData };
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.entries(template).length < 3) {
            setDisplayAdvancedTemplateOptions(true);
            return;
        }
        let finalTemplate = {
            "category": [...new Set(promptData.map(prompt => prompt.category))][0],
            "author": templateAuthor(),
            "workspace": workspace._id,
            "sequence": sequence._id,
            ...template,
        };
        setDisplayAdvancedTemplateOptions(false);
        setCreatingTemplate(true);
        APIService.upsertPromptTemplate(workspace._id, sequence._id, finalTemplate, (response, error) => {
            setCreatingTemplate(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            handleCreatedTemplate(data);
        });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Flex
                direction={'column'}
                className={'mt-4'}
                style={{
                    transition: 'all .2s'
                }}>
                <Flex
                    direction={'row'}
                    alignItems={'center'}>
                    <FontAwesomeIcon
                        onClick={() => {
                            onBackPressed();
                        }}
                        style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            marginRight: 10
                        }}
                        icon={faChevronLeft}
                    />
                    <h1
                        style={{
                            flex: 1,
                            fontSize: 16,
                            margin: 0,
                            fontWeight: 'bold'
                        }}>Write AI Prompt
                    </h1>
                    {
                        template["prompt"] && <Button
                            disabled={creatingTemplate}
                            type="submit"
                            variant="outline-primary pill"
                            className="d-inline-flex flex-center gap-1"
                        >  {creatingTemplate && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" />}
                            {creatingTemplate ? "Saving..." : "Save"}
                        </Button>
                    }
                </Flex>
                <Divider />
                <Flex
                    style={{
                        marginTop: 10,
                    }}>
                    <h1
                        style={{
                            fontSize: 14,
                            margin: 0,
                            flex: 1,
                            fontWeight: '600'
                        }}>Prompt <span className="text-danger">*</span>
                    </h1>
                    <OverlayTrigger
                        overlay={<Tooltip>{`Add more template information`}</Tooltip>}
                    >
                        <FontAwesomeIcon
                            className="cursor-pointer me-3"
                            icon={faEllipsisH}
                            onClick={(e) => {
                                setDisplayAdvancedTemplateOptions(!displayAdvancedTemplateOptions);
                                setDisplayPromptTestOutputPreview(false);
                            }}
                        />
                    </OverlayTrigger>
                </Flex>
                <TextareaAutoSize
                    id='compose-box'
                    minRows={4}
                    value={template['prompt']}
                    as="textarea"
                    required
                    placeholder={"Enter prompt here"}
                    onChange={e => {
                        let value = e.target.value;
                        handleFormDataChange('prompt', value);
                    }}
                    style={{
                        width: '100%',
                        outline: 'none',
                        whiteSpace: 'pre-wrap',
                        display: 'inherit',
                        borderRadius: 5,
                        borderColor: '#CFD8DC',
                        outlineColor: '#CFD8DC',
                        background: config.isDark ? "#0b1727" : "white",
                        color: 'inherit',
                        fontSize: 'inherit',
                        marginTop: 10
                    }}
                    maxRows={20}
                />
                <Button variant="outline-primary rounded-pill mt-4 mb-2">Generate test output</Button>
                {
                    displayAdvancedTemplateOptions &&
                    <div className="mt-3">
                        <NewTemplateAdvancedOptions
                            template={template}
                            handleFormDataChange={handleFormDataChange}
                            handleManualTemplateChanges={(updatedTemplate) => {
                                setTemplate((prevTemplateData) => {
                                    return {
                                        ...prevTemplateData,
                                        ...updatedTemplate
                                    };
                                });
                            }}
                        />
                    </div>
                }
            </Flex>
        </Form>
    );
}