import React, { useEffect, useState } from "react"
import {
  Button, Card,
  Modal, ProgressBar,
  Spinner
} from "react-bootstrap"
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper"
import AdvanceTable from "components/common/advance-table/AdvanceTable"
import FalconCardHeader from "components/common/FalconCardHeader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelector } from "react-redux"
import { getBulkCSVLeads } from "../../../../redux/slices/workspaceslice"
import BulkContactEditBox from "./BulkContactEditBox"
import StringUtils from "utils/string"
import { toast } from "react-toastify"
import UserManager from "utils/user_manager"
import useSocket from "socket_io"
import APIService from "http/api_service"
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter"
import Flex from "components/common/Flex"
import { UpgradeToPremiumAccountCard } from "components/app/new-workspace"
import WorkSpaceManager from "utils/workspace_manager"
import { useParams } from "react-router-dom"
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const columns = [
  {
    accessor: "first_name",
    Header: "First Name",
    Cell: (rowData) => {
      const { id } = rowData.row
      return <BulkContactEditBox
        rowIndex={parseInt(id)}
        property={"first_name"}
        initialValue={"first_name"}
      />
    },
  },
  {
    accessor: "last_name",
    Header: "Last Name",
    Cell: (rowData) => {
      const { id } = rowData.row
      return <BulkContactEditBox
        rowIndex={parseInt(id)}
        property={"last_name"}
        initialValue={"last_name"}
      />
    },
  },
  {
    accessor: "email",
    Header: "Email",
    Cell: (rowData) => {
      const { id } = rowData.row
      return <BulkContactEditBox rowIndex={parseInt(id)} property={"email"} />
    },
  },
  {
    accessor: "company",
    Header: "Company",
    Cell: (rowData) => {
      const { id } = rowData.row
      return <BulkContactEditBox rowIndex={parseInt(id)} property={"company"} />
    },
  },
  {
    accessor: "title",
    Header: "Title",
    Cell: (rowData) => {
      const { id } = rowData.row
      return <BulkContactEditBox rowIndex={parseInt(id)} property={"title"} />
    },
  },
]

const BulkContactUploadPreviewer = (props) => {

  const { listId } = useParams()
  const skipPick = props.skipPick ?? false
  const skipLimits = props.skipLimits ?? false
  const { pickNewFile } = props
  const emailAccounts = useSelector(getBulkCSVLeads)
  const [creatingLeads, setCreatingLeads] = useState(false)
  const [uploadProgress, setUploadProgress] = useState();
  const [processingLeads, setProcessingLeads] = useState();
  const loggedInUser = UserManager.getLoggedInUser()
  const [openCreationSuccessDialog, setOpenCreationSuccessDialog] = useState(false)
  const [successMessage, setSuccessMessage] = useState("Bulk leads connected successfully")
  const [openUpgradeCard, setOpenUpgradeCard] = useState(false)
  const activeWorkSpace = WorkSpaceManager.getActiveWorkSpace()
  const { addSocketIOEventListener, removeSocketIOEventListener } = useSocket()

  useEffect(() => {
    let leadsUpdateEventListener = (response) => {
      let { error, message, data = {} } = response
      setCreatingLeads(false)
      setProcessingLeads(false);
      setUploadProgress(undefined);
      if (error) {
        if (error.toLowerCase().includes("upgrade".toLowerCase())) {
          setOpenUpgradeCard(true)
        } else {
          toast.error(error, { theme: "colored" })
        }
        return
      }
      setSuccessMessage(message)
      setOpenCreationSuccessDialog(true)
    }
    addSocketIOEventListener(`${loggedInUser?._id}_bulk_leads_status`, leadsUpdateEventListener)
    return () => {
      removeSocketIOEventListener(`${loggedInUser?._id}_bulk_leads_status`, leadsUpdateEventListener)
    }
  }, [])

  const uploadLeads = async () => {
    setCreatingLeads(true)
    const bulkContentStringified = JSON.stringify(emailAccounts, null);
    const bulkContentBlob = new Blob([bulkContentStringified], {
      type: "application/json;charset=utf-8",
    })
    let requestBody = new FormData()
    let bulkContentUploadFile = new File([bulkContentBlob], `${StringUtils.generateRandomString(10)}.json`)
    requestBody.append("file", bulkContentUploadFile)
    APIService.uploadFileToFirebase({
      file: bulkContentUploadFile,
      filename: bulkContentUploadFile.name,
      progressCallback: (progress) => {
        if (progress < 101) {
          setUploadProgress(progress);
        } else {
          setCreatingLeads(false);
          setProcessingLeads(true);
        }
      },
      errorCallback: (errorMessage) => {
        setCreatingLeads(false);
        setProcessingLeads(false);
        setUploadProgress(undefined);
        toast.error(errorMessage, { theme: 'colored' });
      },
      doneCallback: async (result) => {
        setProcessingLeads(true);
        setCreatingLeads(false);
        try {
          await APIService.uploadBulkCSVLeads(
            activeWorkSpace?._id,
            { leads_download_url: result },
            listId,
          )
        } catch (error) {
          console.error("Bulk Content Upload Error", error)
          setCreatingLeads(false)
          toast.error(error, { theme: "colored" })
        }
      }
    })
  }

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={emailAccounts}
      pagination
      perPage={50}>
      <Card>
        <FalconCardHeader
          title="Contacts to import"
          endEl={
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: 'center'
              }}
            >
              {!skipPick && (
                <Button
                  type="button"
                  icon="plus"
                  size="sm"
                  className="ms-2"
                  variant="falcon-default"
                  onClick={() => {
                    pickNewFile()
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon icon={"plus"} />
                    <span className="d-none d-sm-inline-block ms-1">Pick a New CSV File</span>
                  </div>
                </Button>
              )}
              <Flex
                direction={'column'}
                justifyContent={'center'}
                className={'gap-1 ms-2'}
              >
                <Button
                  disabled={
                    creatingLeads
                    || emailAccounts.length < 2
                    || processingLeads
                  }
                  size="sm"
                  variant="falcon-primary"
                  onClick={() => {
                    uploadLeads()
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    {
                      !creatingLeads && !processingLeads &&
                      <FontAwesomeIcon icon={faUpload} />
                    }
                    {
                      (creatingLeads || processingLeads) &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                    <span className="d-none d-sm-inline-block ms-1">
                      {creatingLeads ? "Uploading contacts..." : processingLeads ? "Processing..." : "Upload contacts"}
                    </span>
                  </div>
                </Button>
                {
                  uploadProgress &&
                  <ProgressBar
                    now={uploadProgress}
                    style={{ height: '15px' }}
                    label={`${uploadProgress}%`}
                  />
                }
              </Flex>
            </div>
          }
        >
          {!skipLimits && <span className="fs--1">Min number of rows must be 2</span>}
        </FalconCardHeader>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: "sm",
              striped: true,
              className: "fs--1 mb-0 overflow-hidden",
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter rowCount={emailAccounts.length} table rowInfo navButtons rowsPerPageSelection />
        </Card.Footer>
      </Card>
      <Modal
        backdrop="static"
        show={openCreationSuccessDialog}
        onHide={() => setOpenCreationSuccessDialog(false)}
        onExit={() => setOpenCreationSuccessDialog(false)}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Leads imported</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex direction={"column"} className={"gap-3"}>
            <span className="text-success">{successMessage}</span>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenCreationSuccessDialog(false)
            }}
          >
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openUpgradeCard}
        onHide={() => setOpenUpgradeCard(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <UpgradeToPremiumAccountCard
            subTitle={"👋 You've discovered a premium feature!"}
            message={"Upgrade to one of the paid plans to unlock this feature."}
            upgradeBtnText={"Upgrade Plan"}
          />
        </Modal.Body>
      </Modal>
    </AdvanceTableWrapper>
  )
}

export default BulkContactUploadPreviewer