import React, { useState, useEffect } from 'react'
import Flex from 'components/common/Flex';
import Lottie from 'lottie-react';
import HourGlass from "assets/animations/hour_glass.json";
import { Button, Card, ProgressBar } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FileUploader from '../file-uploader';
import StringUtils from 'utils/string';
import { toast } from 'react-toastify';
import LeadBulkUploadPreviewer from '../leads/LeadBulkUploadPreviewer';
import { BulkCSVsUploadKnowlegdeLink } from 'constants';
import { useDispatch, useSelector } from "react-redux"
import { getBulkCSVLeads, setBulkCSVLeads } from "../../../redux/slices/workspaceslice"
import APIService from 'http/api_service';
import useSocket from "socket_io"
import WorkSpaceManager from 'utils/workspace_manager';

const ContactsVerificationPage = (props) => {
  const [open, setOpen] = useState(false)
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch()
  const emailAccounts = useSelector(getBulkCSVLeads)
  const workSpace = WorkSpaceManager.getActiveWorkSpace()
  const [scanInProgress, setScanInProgress] = useState(false);
  const [loading, setLoading] = useState(false)
  const [uploadBtnInProgress, setUploadBtnInProgress] = useState(null)
  const { socket } = useSocket()
  const [progressData, setProgressData] = useState(null);

  const handleProgressSetter = (data) => {
    if (data) {
      setProgressData(data)
    } else {
      setProgressData(null)
    }
  }

  useEffect(() => {
    socket.on(`${workSpace?._id}_email_import_real_time_updates`, (data) => {
      if (data) {
        handleProgressSetter(data?.data);
      }
    });
  }, [socket])

  const validateEmails = async () => {
    let emails = [];
    setLoading(true)
    setUploadBtnInProgress('Processing...')
    if (emailAccounts?.length < 1) {
      toast.error(`Please upload CSV with up to 2 or more entries`, { theme: 'coloured' })
      return
    }
    try {
      await APIService.verifySpecificDomainRecords(
        workSpace?._id,
        { data: emailAccounts },
      )
      setLoading(false)
      setUploadBtnInProgress(null)
      setScanInProgress(true);
    } catch (error) {
      setLoading(false)
      setUploadBtnInProgress(null)
      return {
        allValid: false,
        error: true,
        errorMsg: error
      }
    }
  }

  const processFile = (data) => {
    setProcessing(true)
    let dataEntries = Object.entries(data).map((entry) => entry)
    if (dataEntries.length > 0) {
      let expectedDataFormat = dataEntries[0][1]
      if (expectedDataFormat) {
        setProcessing(false)
        const transformedData = data.map((item) => {
          const newItem = {};
          const keys = Object.keys(item);
          keys.forEach((key) => {
            const snakeCaseKey = StringUtils.camelToSnake(key);
            newItem[snakeCaseKey] = item[key];
          });
          let firstName = newItem.firstName ?? newItem.first_name ?? newItem.firstname;
          let lastName = newItem.lastName ?? newItem.last_name ?? newItem.lastname;
          if (firstName && lastName) {
            newItem.name = `${firstName} ${lastName}`;
          } else if (newItem.full_name) {
            newItem.name = newItem.full_name;
          }
          return newItem;
        });
        dispatch(setBulkCSVLeads(transformedData))
        setOpen(true)
      }
    } else {
      setProcessing(false)
      toast.error("CSV With empty columns submitted", { theme: "colored" })
    }
  }

  if (scanInProgress || progressData) {
  return <Flex
    className={'gap-2'}
    alignItems={'center'}
    justifyContent={'center'}
    style={{
      height: 'auto',
      width: 'auto'
    }}
  >
    <Flex
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Lottie
        animationData={HourGlass}
        style={{
          width: 200,
          height: 200
        }}
      />
      <h5>
        👋HI, YOUR CONTACT IS BEING VERIFIED.
      </h5>
      <br />
      <h5>
        WE WILL SEND EMAIL TO YOU AS SOON AS IT IS COMPLETED.
      </h5>
      <br />
      <h5>
        CURRENT SCAN RESULT IS:
      </h5>
      <br />
      {
        progressData?.percentageScanned < 99 ?
          <div
            style={{
              width: '100%'
            }}
          >

            <h4>
              Total emails to scan: {progressData?.totalLength}
            </h4>
            <br />
            <h4>
              Emails scanned: {progressData?.emailsScanned}
            </h4>
            <br />
            <h4>
              Failed Emails: {progressData?.failed}
            </h4>
            <br />
            <h4>
              Passed Emails: {progressData?.passed}
            </h4>
            <br />
            <h4>
              Percentage scanned: {progressData?.percentageScanned?.toFixed(2)}%
            </h4>
            <br />
          </div>
          : null
      }
    </Flex>
  </Flex>
  }

  return (
    <Flex
      direction={"column"}
    >
      <PageHeader title="Upload Contacts' CSV for verification" titleTag="h5" className="mb-3"></PageHeader>

      {!open && (
        <Card>
          <FalconCardHeader title="Pick CSV File" />
          <Card.Body className="bg-light">
            {!processing && (
              <FileUploader
                hint={"Select or Drag a CSV file here"}
                onFileLoaded={(data) => {
                  processFile(data)
                }}
              />
            )}
            {processing && (
              <div
                style={{
                  minHeight: 150,
                }}
              >
                <ProgressBar
                  variant="primary"
                  className="fs-1"
                  style={{ marginTop: "10%" }}
                  label="Processing File"
                  animated={true}
                  now={45}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      )}
      {open && (
        <LeadBulkUploadPreviewer
          // sequence={sequence}
          // sequenceUpdateHandler={sequenceUpdateHandler}
          customHandler={validateEmails}
          pickNewFile={() => {
            setOpen(false)
            setProcessing(false)
          }}
          leadTitle="Contacts to verify"
          uploadBtn="Verify contacts"
          uploadBtnInProgress="Processing contacts..."
          loadingFromProps={loading}
          {...props}
        />
      )}
      <p
        style={{
          textAlign: 'center'
        }}
        className={'p-1'}>Your CSV Must adhere to
        <a href={BulkCSVsUploadKnowlegdeLink}
          target={'_blank'}
          rel='noreferrer'
          style={{
            paddingLeft: 5,
            textDecoration: 'none'
          }}>this format
        </a>
      </p>
    </Flex>
  )
}

export default ContactsVerificationPage