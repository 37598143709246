import WorkSpaceManager from "utils/workspace_manager"
import {
  useEffect,
  useState
} from "react"
import {
  Button,
  Col, Form,
  Offcanvas, Row, Spinner
} from "react-bootstrap"
import "./leads.css"
import APIService from "http/api_service"
import { useSWRConfig } from "swr"
import { toast } from "react-toastify"
import EndPoints from "http/endpoints"

export default function CreateNewList(props) {

  const { mutate } = useSWRConfig()
  const activeWorkspace = WorkSpaceManager.getActiveWorkSpace();
  const [name, setName] = useState()
  const [showDrawer, setShowDrawer] = useState(props.open)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setShowDrawer(props.open)
  }, [props.open])

  const handleClose = () => {
    setShowDrawer(!showDrawer)
    props?.closeDrawer()
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      const payload = { name }
      let { message } = await APIService.upsertList(activeWorkspace._id, payload)
      toast(message, { theme: 'colored' });
      handleClose()
      window.location.reload()
    } catch (error) {
      toast.error(error, { theme: 'colored' });
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Offcanvas
      style={{
        width: "40%",
      }}
      fullscreen={true}
      placement="end"
      show={showDrawer}
      onHide={handleClose}
    >
      <Offcanvas.Header closeButton closeVariant="white" className="bg-shape settings-panel-header pl-4 pr-14">
        <Offcanvas.Title as="div" className="py-1 z-index-1 light">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h5 className="text-white">Create a list</h5>
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="name">
              <Form.Label>Name of the list*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name of list"
                value={name}
                name="name"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Button
            disabled={isLoading || !name}
            style={{ marginTop: "30px" }}
            className="d-inline-flex flex-center gap-1"
            type="submit">
            {
              isLoading &&
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            }
            {
              isLoading ? "Creating list..." : "Create list"
            }
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );

}
