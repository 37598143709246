import React from 'react';
import PropTypes from 'prop-types';
import { users } from 'data/dashboard/default';

const LastMessage = ({ lastMessage, thread, lastMsg }) => {
  const user = users.find(({ id }) => id === lastMessage?.senderUserId);
  const name = user?.name.split(' ');

  // const lastMassagePreview =
  //   lastMessage?.messageType === 'attachment'
  //     ? `${name[0]} sent ${lastMessage.attachment}`
  //     : lastMessage?.message.split('<br>');

  // if (lastMessage) {
  //   if (lastMessage.senderUserId === 3) {
  //     return <span className='me-1'>{`You: ${lastMsg}`}</span>;
  //   }
  //   if (thread.type === 'group') {
  //     return (
  //       <div
  //         className="chat-contact-content"
  //         dangerouslySetInnerHTML={{
  //           __html: `${name[0]}: ${lastMsg}`
  //         }}
  //       />
  //     );
  //   }

  //   return (
  //     <div
  //       className="chat-contact-content"
  //       dangerouslySetInnerHTML={{ __html: lastMsg }}
  //     />
  //   );
  // }

  return (
    <div
      className="mb-0 text-truncate fs--2"
      dangerouslySetInnerHTML={{ __html: lastMsg }}
    />
  );
};
LastMessage.propTypes = {
  thread: PropTypes.object.isRequired,
  lastMessage: PropTypes.object
};

export default LastMessage;
