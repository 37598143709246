import { Strings } from "../constants"

export default class UserManager {
  static getLoggedInUser() {
    let loggedInUser = localStorage.getItem(Strings.LOGGED_IN_USER)
    if (!loggedInUser) {
      return null
    }
    return JSON.parse(loggedInUser)
  }

  static setLoggedInUser(newData) {
    if (newData.hasOwnProperty("message") && newData.hasOwnProperty("statusCode")) {
      newData = newData["data"]
    }
    localStorage.setItem(Strings.LOGGED_IN_USER, JSON.stringify(newData))
  }

  static getLoggedInAltUsers() {
    let loggedInAltUsers = localStorage.getItem(Strings.LOGGED_IN_ALT_USERS)
    if (!loggedInAltUsers) {
      return null
    }
    return JSON.parse(loggedInAltUsers)
  }

  static setAltableUsers(users) {
    localStorage.setItem(Strings.LOGGED_IN_ALT_USERS, JSON.stringify(users))
  }

  static altableUser(user) {
    localStorage.setItem(Strings.ALTABLE_USER, JSON.stringify(user))
  }

  static getAltableUser() {
    let altableUser = localStorage.getItem(Strings.ALTABLE_USER)
    if (!altableUser) {
      return null
    }
    return JSON.parse(altableUser)
  }

  static deleteAltableUser() {
    localStorage.removeItem(Strings.ALTABLE_USER)
  }

  static isDemoAccount() {
    let loggedInUser = UserManager.getLoggedInUser()
    return (
      loggedInUser?.email === "juoemeka@gmail.com" ||
      loggedInUser?.email === "collinsrollins07@gmail.com" ||
      loggedInUser?.email === "princewhyte02@gmail.com" ||
      loggedInUser?.email === "emeka@saleshighersolutions.com" ||
      loggedInUser?.email === "cryptoboss41@gmail.com" || 
      loggedInUser?.email === "husseinmubarak500@gmail.com"
    )
  }

  static isLTDUserAndRequiresUpgrade() {
    let loggedInUser = UserManager.getLoggedInUser()
    let activePlan = loggedInUser.active_plans
    let upgraded = activePlan.Upgraded ?? false
    if (activePlan.LTD) {
      return true
    }
    if ((activePlan.Name || activePlan.Key).toLowerCase().includes("appsumo")) {
      return true && !upgraded
    }
    if ((activePlan.Name || activePlan.Key).toLowerCase().includes("pitchground")) {
      return true && !upgraded
    }
    return false
  }

  static isUserFreeAccount() {
    let loggedInUser = UserManager.getLoggedInUser()
    let activePlan = loggedInUser.active_plans
    return activePlan?.Name === "Free" || activePlan?.Key === "Free"
  }
}
