/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import classNames from 'classnames';
import UserManager from 'utils/user_manager';
import { RoutePaths } from '../constants';
import {
  canOpenExternalFilePickerDialog, canOpenPostDetailsDialog,
  getExternalFilePickerDialogOptions,
  setOpenExternalFilePickerDialog, setOpenPostDetailsDialog
} from '../redux/slices/postslice';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ExternalFilePickerDialog from 'components/common/ExternalFilePickerDialog';
import useSocket from 'socket_io';
import {
  canOpenWorkspaceSearch, getOpenBulkWorkspaceCreator,
  getReloadPage, searchWorkSpace,
  setOpenBulkWorkspaceCreator
} from '../redux/slices/workspaceslice';
import WorkSpaceSearch from 'components/app/workspace-search/WorkSpaceSearch';
import StringUtils from 'utils/string';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { auth } from '../firebase';
import '../App.css';
// import Logo from 'components/common/Logo';

const MainLayout = () => {

  const {
    addSocketIOEventListener,
    putUserOnlineViaSocketIO,
    removeSocketIOEventListener
  } = useSocket();

  const { hash, pathname } = useLocation();
  const { config: { isFluid, navbarPosition } } = useContext(AppContext);
  const loggedInUser = UserManager.getLoggedInUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openExternalFilePickerDialog = useSelector(canOpenExternalFilePickerDialog);
  const externalFilePickerDialogOptions = useSelector(getExternalFilePickerDialogOptions);
  const openWorkSpaceSearch = useSelector(canOpenWorkspaceSearch);
  const location = useLocation();
  const canReloadPage = useSelector(getReloadPage);
  const openBulkWorkspaceCreator = useSelector(getOpenBulkWorkspaceCreator);
  const openPostDialog = useSelector(canOpenPostDetailsDialog);
  const [logOut, setLogOut] = useState(false);
  const [navigateToDefault, setNavigateToDefault] = useState(false);
  const [clearLocalStorage, setClearLocalStorage] = useState(false);

  const checkAuthUser = () => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        signInAnonymously(auth).catch((error) => {
          // Handle sign-in error
        });
      }
    });
  }

  useEffect(() => {
    checkAuthUser();
  }, []);

  useEffect(() => {
    if (logOut) {
      if (clearLocalStorage) {
        localStorage.clear();
      }
      setTimeout(() => {
        navigate(RoutePaths.LOGIN);
      }, 1000);
    }
  }, [logOut, clearLocalStorage]);

  useEffect(() => {
    if (navigateToDefault) {
      navigate(RoutePaths.DEFAULT);
    }
  }, [navigateToDefault]);

  useEffect(() => {
    if (openBulkWorkspaceCreator) {
      dispatch(searchWorkSpace(false));
      navigate(RoutePaths.BULK_WORKSPACES);
      dispatch(setOpenBulkWorkspaceCreator(false));
    }
  }, [openBulkWorkspaceCreator]);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    let instantActionsHandler = (data) => {
      let { action } = data;
      switch (action) {
        case "force_logout":
          setClearLocalStorage(true);
          setNavigateToDefault(true);
          break
        default:
          break;
      }
    }
    if (!loggedInUser) {
      setLogOut(true);
    } else {
      let visitedSplash = localStorage.getItem("visited_splash");
      if (!visitedSplash) {
        setNavigateToDefault(true);
      }
    }
    addSocketIOEventListener('instant-action', instantActionsHandler);
    return () => {
      removeSocketIOEventListener('instant-action', instantActionsHandler)
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    let search = location.search;
    if (search.includes('billing')) {
      dispatch(searchWorkSpace(false));
    }
  }, [location]);

  useEffect(() => {
    putUserOnlineViaSocketIO();
  }, []);

  const dropPostDetailsDialog = () => {
    let currentWindowLocation = window.location.href;
    let newLocation = StringUtils.substringBeforeLast(currentWindowLocation, "/");
    window.history.pushState("", "", newLocation);
    dispatch(setOpenPostDetailsDialog({ open: false }));
  }

  return (
    <div>
      <div
        className='force-refresh-reachable-logo'
        style={{
          position: 'fixed',
          // margin: 5,
          marginTop: 10,
          marginLeft: 4,
          left: 0,
          top: 0,
          bottom: 0,
        }}>
        {/* <Logo width={35} height={32} fontSize={16} /> */}
      </div>
      <div className={isFluid ? 'container-fluid' : 'container'}>
        {
          (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
          (
            <NavbarVertical />
          )
        }
        <div className={classNames('content', { 'pb-0': false })}>
          <NavbarTop />
          <Modal
            show={openExternalFilePickerDialog}
            onHide={() => dispatch(setOpenExternalFilePickerDialog(false))}
            onExit={() => setOpenExternalFilePickerDialog(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title>{externalFilePickerDialogOptions?.externalFilePickerTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ExternalFilePickerDialog
                options={externalFilePickerDialogOptions}
                modalClose={() => {
                  dispatch(setOpenExternalFilePickerDialog(false));
                }}
              />
            </Modal.Body>
          </Modal>
          <Modal
            show={openWorkSpaceSearch}
            onHide={() => dispatch(searchWorkSpace(false))}
            onExit={() => {
              dispatch(searchWorkSpace(false));
              if (canReloadPage) {
                navigate(RoutePaths.DEFAULT);
                window.location.reload();
              }
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <WorkSpaceSearch />
          </Modal>
        
          <Modal
            centered
            size='lg'
            show={openPostDialog.open}
            onHide={() => {
              dropPostDetailsDialog();
            }}
          >
          </Modal>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
