/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import is from "is_js"
import MainLayout from "./MainLayout"
import ErrorLayout from "./ErrorLayout"
import AccountLogIn from "components/authentication/split/Login"
import AccountLogOut from "components/authentication/card/Logout"
import AccountRegister from "components/authentication/split/Registration"
import ForgetPassword from "components/authentication/split/ForgetPassword"
import PasswordLessLogIn from "components/authentication/card/PasswordLess"
import CardPasswordReset from "components/authentication/card/PasswordReset"
import WorkspaceInvitations from "components/authentication/invitations"
import VerifyEmail from "components/authentication/email/verify_email"
import { toast, ToastContainer } from "react-toastify"
import { CloseButton } from "components/common/Toast"
import Error404 from "components/errors/Error404"
import Error500 from "components/errors/Error500"
import AppContext from "context/Context"
import { RoutePaths } from "../constants"
import UserManager from "utils/user_manager"
import WorkSpaceManager from "utils/workspace_manager"
import SplashLayout from "./SplashLayout"
import WorkspaceSwitcher from "components/app/switch"
import AuthLayout from "./AuthLayout"
import NewWorkspace from "components/app/new-workspace"
import WorkspaceSettings from "components/pages/workspace/settings/Settings"
import PlatformsPage from "components/app/platforms"
import ConnectedAccountsPage from "components/app/platforms/connected_accounts"
import ConnectedAccountsDataPage from "components/app/platforms/connected_accounts/connected_account_data_page"
import TeamPage from "components/app/team"
import BillingPage from "components/app/billing"
import AccountPage from "components/app/account"
import ProfilePage from "components/app/account/profile/UserProfile.js"
import BulkWorkspacesCreator from "components/app/bulk-workspaces"
import UnifiedInbox from "components/app/unified-inbox"
import WhiteLabelSetup from "components/app/account/whitelabel/WhiteLabelWizard"
import AnalyticsPage from "components/app/analytics"
import ModalAuth from "components/authentication/modal/ModalAuth"
import Dashboard from "components/app/dashboard"
import EmailAccounts from "components/app/email-accounts/index"
import BetaFeatures from "components/app/labs"
import SequencesPage from "components/app/sequences"
import LeadsPage from "components/app/leads"
import ListPage from "components/app/lists"
import ListIntemsPage from "components/app/lists/list_items"
import EmailAccountConnectionPage from "components/app/email-accounts/connect_email_account"
import CreateNewSequencePage from "components/app/sequences/create_new_sequence"
import Sequence from "components/app/sequences/sequence"
import Kanban from "components/app/kanban/Kanban"
import EmailSendingSchedule from "components/app/sequences/sequence/schedule/index"
import Changelog from "components/app/changelog/Faq"
// import MaintenancePage from "components/app/maintenace"
import UnsubscribeSequence from "Unsubscribe"
import LeadFinder from 'components/app/lead-finder/course/LeadFinder';
import ContactsVerificationPage from "components/app/contacts"
// import LinkedInPage from "components/app/platforms/linked_in/index";

const Layout = () => {
  const [meta, setMeta] = useState("")
  const [canRoute, setCanRoute] = useState(false)
  // const [maintenanceMode, setMaintenaceMode] = useState(false)
  const activeWorkSpace = WorkSpaceManager.getActiveWorkSpace()
  const loggedInUser = UserManager.getLoggedInUser()
  const HTMLClassList = document.getElementsByTagName("html")[0].classList
  const { setConfig } = useContext(AppContext)

  const initUserThemeChoice = () => {
    let themeChoices = activeWorkSpace?.theme_choices ?? {}
    if (loggedInUser) {
      let userId = loggedInUser._id
      let userThemeChoice = themeChoices[userId]
      if (userThemeChoice) {
        setConfig("isDark", userThemeChoice === "dark")
      }
    }
  }

  useEffect(() => {
    initUserThemeChoice()
    let params = new URL(document.location).searchParams
    let metaParams = params.get("meta")
    if (metaParams) {
      setMeta(metaParams)
    }
    setCanRoute(false)
  }, [])

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows")
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome")
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox")
    }
  }, [HTMLClassList])

  return (
    <>
      {/* {
        maintenanceMode && <MaintenancePage />
      } */}
      {/* {canRoute && ( */}
      <Routes>
        {/* <Route path="/" element={<Landing />} /> */}
        <Route path="roadmap" element={<Kanban />} />
        <Route path={RoutePaths.DEFAULT} element={<SplashLayout />} />
        <Route element={<ErrorLayout />}>
          <Route path={RoutePaths.NOT_FOUND} element={<Error404 />} />
          <Route path={RoutePaths.BROKEN_PAGE} element={<Error500 />} />
        </Route>
        <Route
          path={RoutePaths.SWITCH_WORKSPACE}
          element={<WorkspaceSwitcher />}
        />
        {/* Authentication Start */}
        <Route element={<AuthLayout />}>
          <Route path={RoutePaths.LOGIN} element={<AccountLogIn />} />
          <Route path={RoutePaths.REGISTER} element={<AccountRegister />} />
          <Route
            path={RoutePaths.FORGOT_PASSWORD}
            element={<ForgetPassword />}
          />
          <Route
            path={RoutePaths.RESET_PASSWORD}
            element={<CardPasswordReset />}
          />
          <Route
            path={RoutePaths.PASSWORD_LESS_SIGN_IN}
            element={<PasswordLessLogIn />}
          />
          <Route path={RoutePaths.LOGOUT} element={<AccountLogOut />} />
        </Route>
        {/* Authentication End */}
        <Route
          path={RoutePaths.INVITATIONS}
          element={<WorkspaceInvitations />}
        />
        <Route path={RoutePaths.VERIFY_NEW_EMAIL} element={<VerifyEmail />} />
        <Route path={RoutePaths.NEW_WORKSPACE} element={<NewWorkspace />} />
        <Route
          path={"/billing"}
          element={<Navigate to={`${RoutePaths.BILLING}?meta=${meta}`} />}
        />
        <Route
          path={"/ai_writer"}
          element={<Navigate to={`${RoutePaths.AI_WRITER}?meta=${meta}`} />}
        />
        <Route
          path={"/new"}
          element={<Navigate to={RoutePaths.NEW_WORKSPACE} />}
        />
        <Route
          path={"/platforms/telegram"}
          element={
            <Navigate
              to={`${RoutePaths.PLATFORMS}/telegram${
                localStorage.getItem("buy_customized_bot")
                  ? `/${localStorage.getItem("buy_customized_bot")}`
                  : ""
              }`}
            />
          }
        />
        <Route
          path={RoutePaths.WHITELABEL_SETUP}
          element={<WhiteLabelSetup />}
        />
        <Route
          path={RoutePaths.CONNECT_EMAIL_ACCOUNT}
          element={<EmailAccountConnectionPage />}
        />
        <Route
          path={RoutePaths.CREATE_NEW_SEQUENCE}
          element={<CreateNewSequencePage />}
        />

        <Route element={<MainLayout />}>
          <Route path={RoutePaths.DASHBOARD} element={<Dashboard />} />
          <Route path={RoutePaths.EMAIL_ACCOUNTS} element={<EmailAccounts />} />
          <Route path={RoutePaths.CONTACT_VERIFICATION} element={<ContactsVerificationPage />} />
          <Route path={RoutePaths.SEQUENCES} element={<SequencesPage />} />
          <Route path={RoutePaths.LISTS} element={<ListPage />} />
          <Route path={`${RoutePaths.LISTS}/:listId`} element={<ListIntemsPage />} />
          <Route path={RoutePaths.LEADS} element={<LeadsPage />} />
          <Route path={`${RoutePaths.SEQUENCES}/:sequence_id`} element={<Sequence />} />
          <Route path={`${RoutePaths.SEQUENCES}/:sequence_id/:option`} element={<Sequence />} />
          <Route path={RoutePaths.ANALYTICS} element={<AnalyticsPage />} />
          <Route path={RoutePaths.UNIFIED_INBOX} element={<UnifiedInbox />} />
          <Route path={RoutePaths.WORKSPACE_SETTINGS} element={<WorkspaceSettings />} />
          <Route path={RoutePaths.LINKEDIN_ACCOUNTS} element={<ConnectedAccountsPage identifier="linked_in" />} /> 
          {/* <Route path={`${RoutePaths.PLATFORMS}/`} element={<ConnectedAccountsPage />} /> */}
          <Route path={`${RoutePaths.LINKEDIN_ACCOUNTS}/:accountInfo`} element={<ConnectedAccountsDataPage  identifier="linked_in" />} />
          <Route path={RoutePaths.BETA_FEATURES} element={<BetaFeatures />} />
          <Route path={RoutePaths.PLATFORMS} element={<PlatformsPage />} />
          <Route
            path={`${RoutePaths.PLATFORMS}/:identifier`}
            element={<ConnectedAccountsPage />}
          />
          <Route
            path={`${RoutePaths.PLATFORMS}/:identifier/:accountInfo`}
            element={<ConnectedAccountsDataPage />}
          />
           {/* Lead Finder */}
           <Route path="lead-finder" element={<LeadFinder />} />
          <Route path={RoutePaths.TEAM} element={<TeamPage />} />
          <Route
            path={RoutePaths.EMAIL_SENDING_SCHEDULE}
            element={<EmailSendingSchedule />}
          />
          <Route path={RoutePaths.ACCOUNT} element={<AccountPage />} />
          <Route path={RoutePaths.BILLING} element={<BillingPage />} />
          <Route path={RoutePaths.PROFILE} element={<ProfilePage />} />
          <Route
            path={RoutePaths.BULK_WORKSPACES}
            element={<BulkWorkspacesCreator />}
          />
          <Route
            path={RoutePaths.OUTLOOK_CALLBACK_URL}
            element={<Navigate to={RoutePaths.DEFAULT} />}
          />
          <Route path={`/changelog`} element={<Changelog />} />
          <Route path="*" element={<Navigate to={RoutePaths.DASHBOARD} />} />
          <Route path="authentication-modal" element={<ModalAuth />} />
        </Route>
        <Route path="*" element={<MainLayout />} />
        <Route path="/unsubscribe-sequence" element={<UnsubscribeSequence />} />
      </Routes>
      {/* )} */}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        // position={toast.POSITION.TOP_RIGHT}
      />
    </>
  )
}
export default Layout
