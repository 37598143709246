import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TemplateHub from "./template-hub";
import MyTemplates from "./my-templates";
import PromptSettings from "./settings";
import SelectedTemplatePreview from "./selected-template-preview";
import CreateNewPromptTemplate from "./my-templates/new-template/create_new_prompt_template";

export default function AIPrompts(props) {

    const sequence = props.sequence;
    const [activeTab, setActiveTab] = useState('template-hub');
    const [selectedPromptTemplate, setSelectedPromptTemplate] = useState();
    const [createNewPromptTemplate, setCreateNewPromptTemplate] = useState(false);
    const [initialCustomTemplates, setInitialCustomTemplates] = useState([]);

    const pageMaps = {
        "template-hub":
            <TemplateHub
                sequence={sequence}
                handleSelectedPromptTemplate={(selected) => {
                    setSelectedPromptTemplate(selected);
                }}
            />,
        "my-templates":
            <MyTemplates
                sequence={sequence}
                initial={initialCustomTemplates}
                handleSelectedPromptTemplate={(selected) => {
                    setSelectedPromptTemplate(selected);
                }}
                handleCreateNewTemplate={(initials) => {
                    setInitialCustomTemplates(initials);
                    setCreateNewPromptTemplate(true);
                }}
            />,
        "settings":
            <PromptSettings
                sequence={sequence}
            />
    };

    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
        setCreateNewPromptTemplate(false);
        setSelectedPromptTemplate(null);
    };

    return (
        <>
            <Tabs
                style={{
                    flex: 1,
                    borderBottom: 'none'
                }}
                activeKey={activeTab}
                onSelect={handleTabChange}>
                <Tab eventKey="template-hub" title="Template Hub" href="/template-hub"></Tab>
                <Tab eventKey="my-templates" title="My Templates" href="/my-templates"></Tab>
                <Tab eventKey="settings" title="Settings" href="/settings"></Tab>
            </Tabs>
            {
                selectedPromptTemplate ?
                    <SelectedTemplatePreview
                        template={selectedPromptTemplate}
                        onBackPressed={() => {
                            setSelectedPromptTemplate(null);
                        }}
                    />
                    : createNewPromptTemplate ?
                        <CreateNewPromptTemplate
                            sequence={sequence}
                            handleCreatedTemplate={(newlyCreatedTemplate) => {
                                setInitialCustomTemplates((prevCustomTemplates) => {
                                    return [newlyCreatedTemplate, ...prevCustomTemplates];
                                });
                                setCreateNewPromptTemplate(false);
                            }}
                            onBackPressed={() => {
                                setCreateNewPromptTemplate(false);
                            }}
                        /> :
                        <>
                            {pageMaps[activeTab]}
                        </>
            }
        </>
    );

}