import React, { useState } from 'react';
import { toast } from 'react-toastify';
import APIService from '../../../http/api_service';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../constants';
import WorkSpaceManager from '../../../utils/workspace_manager';
import { Button, Form } from 'react-bootstrap';
import ConfirmModal from 'components/common/ConfirmModal';

export default function AddTeamMemberView(props) {

    const [formData, setFormData] = useState({
        email: '',
        role: 'owner'
    });
    const roles = [
        { label: 'Owner', value: 'Owner' },
        { label: 'Approver', value: 'Approver' },
        { label: 'Reviewer', value: 'Reviewer' },
        { label: 'Editor', value: 'Editor' },
    ];
    const navigate = useNavigate();
    const workSpace = WorkSpaceManager.getActiveWorkSpace();
    const cancelHandler = props.cancelHandler;
    const defaultBtnMessage = "Invite Member";
    const [buttonMessage, setButtonMessage] = useState(defaultBtnMessage);
    const [inviting, setInviting] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const memberRole = formData.role;
        const memberEmail = formData.email;
        if (!memberRole) {
            toast.error('Please select a role for this member', { theme: 'colored' });
            return;
        }
        let rolesArray = Object.values(roles).map((entry) => entry.value.toLowerCase());
        if (!rolesArray.includes(memberRole.toLowerCase())) {
            toast.error('Please elect a role for this member', { theme: 'colored' });
            return;
        }
        if (memberRole.toLowerCase().includes('role')) {
            toast.error('Please select a role for this member', { theme: 'colored' });
            return;
        }
        if (!memberEmail) {
            toast.error('Enter email address', { theme: 'colored' });
            return;
        }
        setButtonMessage("Please wait...");
        setInviting(true);
        APIService.inviteTeamMember(workSpace['_id'], memberEmail, memberRole, (response, error) => {
            setButtonMessage(defaultBtnMessage);
            setInviting(false);
            if (error) {
                if (error.toLowerCase().includes('upgrade')) {
                    setOpenUpgradeDialog(true);
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            toast.success('Invitation sent successfully!', { theme: 'colored' });
            cancelHandler(true);
        });
    }

    return (
        <div
            style={{
                paddingLeft: 0,
                paddingRight: 0
            }}>
            <h5 style={{ textAlign: 'center' }} className={'mt-4 mb-4'}>Invite Team Member</h5>
            <Form onSubmit={handleSubmit}>
                <Form.Group className='mb-4'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type='email'
                        name='email'
                        value={formData.email}
                        placeholder="Enter email Address"
                        onChange={handleFieldChange}
                        required
                    />
                </Form.Group>
                <Form.Group className='mb-4'>
                    <Form.Label>Select Member Role</Form.Label>
                    <Form.Select
                        name='role'
                        value={formData.role}
                        placeholder="Select Member Role"
                        onChange={handleFieldChange}
                        required
                    >
                        {
                            roles.map((role) => {
                                return <option value={role.value}>{role.label}</option>
                            })
                        }
                    </Form.Select>
                </Form.Group>
                <div
                    className='text-end mb-3'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 10
                    }}>
                    <Button
                        type="button"
                        variant="light" onClick={() => {
                            if (cancelHandler) {
                                cancelHandler(false);
                            }
                        }}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        isWorking={inviting}
                        variant={'primary'}
                        disabled={inviting || !formData.email || !formData.role}>
                        {buttonMessage}
                    </Button>
                </div>
            </Form>
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Action requires Upgrade"}
                message={"Please kindly upgrade your account to add more users. Thank you!"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.ACCOUNT}?billing=true`);
                }}
            />
        </div>
    );
}