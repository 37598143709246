import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import WorkSpaceManager from 'utils/workspace_manager';
import WorkspaceTransferPrompt from './WorkspaceTransferPrompt';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../../constants';
import UserManager from 'utils/user_manager';

const DangerZone = (props) => {

  const { workSpaceOwnerDetails } = props;
  const [openTransferOwnershipDialog, setOpenTransferOwnershipDialog] = useState(false);
  const [openWorkspaceDeletePrompt, setOpenWorkspaceDeletePrompt] = useState(false);
  const [deletingWorkspace, setDeletingWorkspace] = useState(false);
  const workSpace = WorkSpaceManager.getActiveWorkSpace();
  const navigate = useNavigate();

  const deleteWorkspace = () => {
    setDeletingWorkspace(true);
    toast.info("Deleting Workspace", { theme: 'colored' });
    APIService.deleteWorkSpace(workSpace._id, (_, error) => {
      setDeletingWorkspace(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      WorkSpaceManager.deleteActiveWorkSpace();
      APIService.fetchLatestMe((res, err) => {
        if (err) {
          toast.error(err, { theme: 'colored' });
          if (err.toLowerCase().includes('Invalid credentials provided'.toLowerCase())) {
            window.localStorage.clear();
            setTimeout(() => {
              navigate(RoutePaths.LOGIN);
            }, 100);
          }
          return;
        }
        let latestUserData = res.data;
        UserManager.setLoggedInUser(latestUserData);
        toast.success("Workspace Deleted", { theme: 'colored' });
        setTimeout(() => {
          navigate(RoutePaths.REDIRECT);
          window.location.reload();
        }, 2000);
      });
    });
  }

  return (
    <Card>
      <FalconCardHeader title="Danger Zone" />
      <Card.Body className="bg-light">
        <h5 className="mb-0">Transfer Ownership</h5>
        <p className="fs--1">
          Transfer this organization to another user or organization
        </p>
        <Button variant="falcon-warning"
          className="w-100"
          onClick={() => {
            setOpenTransferOwnershipDialog(true);
          }}>
          Transfer
        </Button>
        <div className="border-dashed-bottom my-4" />
        <h5 className="mb-0">Delete this organization</h5>
        <p className="fs--1">
          Once you delete this organization, there is no going back. Please be certain.
        </p>
        <Button
          disabled={deletingWorkspace}
          variant="falcon-danger"
          className="w-100"
          onClick={() => {
            setOpenWorkspaceDeletePrompt(true);
          }}>
          {deletingWorkspace ? "Please wait..." : "Delete Workspace"}
        </Button>
      </Card.Body>
      <Modal
        show={openTransferOwnershipDialog}
        onHide={() => setOpenTransferOwnershipDialog(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{"Transfer Workspace"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorkspaceTransferPrompt ownerDetails={workSpaceOwnerDetails} />
        </Modal.Body>
      </Modal>
      <Modal
        show={openWorkspaceDeletePrompt}
        onHide={() => setOpenWorkspaceDeletePrompt(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{"Delete Workspace"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex direction={'column'} className={'gap-2'}>
            <p>Your workspace <b>{workSpace['name']}</b> would be <b>permanently</b> deleted. Your leads, connected emails, and sequences in this workspace will be deleted too. This is a non-reversible action. Are you sure you still want to proceed?</p>
            <div className='text-end'>
              <Button onClick={() => {
                setOpenWorkspaceDeletePrompt(false);
                deleteWorkspace();
              }}>Delete</Button>
            </div>
          </Flex>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default DangerZone;
