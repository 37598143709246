import Flex from "components/common/Flex"
import AppContext from "context/Context"
import APIService from "http/api_service"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import {
  Col,
  Row
} from "react-bootstrap"
import { useSelector, useDispatch } from 'react-redux'
import { toast } from "react-toastify"
import { getSequencesFromRedux, setSequencesFromRedux } from "../../../../../redux/slices/sequence_slice"
import WorkSpaceManager from "utils/workspace_manager"
import NewStep from "./NewStep"
import StepComponent from "./StepComponent"
import UpdateStep from "./UpdateStep"

const SequenceSteps = (props) => {
  const dispatch = useDispatch()
  const sequence = useSelector(getSequencesFromRedux)
  const [openStepModal, setOpenStepModal] = useState(false)
  const [updatableStep, setUpdatableStep] = useState({})


  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig,
  } = useContext(AppContext)
  const [kanbanIsFluid] = useState(isFluid)
  const [kanbanIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed)


  useEffect(() => {
    setConfig("isFluid", true)
    setConfig("isNavbarVerticalCollapsed", true)

    return () => {
      setConfig("isFluid", kanbanIsFluid)
      setConfig("isNavbarVerticalCollapsed", kanbanIsNavbarVerticalCollapsed)
    }
  }, [])

  const GetStartedView = () => {
    return (
      <Flex alignItems={"center"} justifyContent={"center"}>
        <Flex
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          className={"p-5 gap-2"}
        >
          {/* <img src="https://assets.apollo.io/imgs/c4d00fc957a8e145831e..svg" /> */}
          <h5 className="pt-3">Create your campaign sequences</h5>
          <span
            className="pb-3"
            style={{
              textAlign: "center",
              width: "60%",
            }}
          >
            Create a series of sequence steps to boost how you reach out to
            people, which helps get more meetings and close many successful
            deals.
          </span>
          <div>
            <NewStep
              openStepModal={openStepModal}
              setOpenStepModal={setOpenStepModal}
              updatableStep={updatableStep}
              setUpdatableStep={setUpdatableStep}
            />
          </div>
        </Flex>
      </Flex>
    )
  }

  const handleDragEnd = (result) => {
    let _sequence = { ...sequence }
    if ((_sequence["steps"] ?? [])?.length < 2) {
      return
    }
    if (!result.destination) return
    const newSteps = Array.from(_sequence["steps"])
    const [reorderedStep] = newSteps.splice(result.source.index, 1)
    newSteps.splice(result.destination.index, 0, reorderedStep)
    _sequence["steps"] = [...newSteps]
    const workspace = WorkSpaceManager.getActiveWorkSpace()
    APIService.updateSequence(
      workspace._id,
      sequence._id,
      sequence,
      (response, error) => {
        if (error) {
          toast.error(error, { theme: "colored" })
          return
        }
        dispatch(setSequencesFromRedux(response["data"]));
      },
    )
  }

  const renderSteps = sequence["steps"]?.map((step, index) => (
    <Draggable key={step.id} draggableId={step.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <StepComponent
            key={step["id"]}
            index={index}
            step={step}
            sequence={sequence}
            updatableStep={updatableStep}
            setUpdatableStep={setUpdatableStep}
            setOpenStepModal={setOpenStepModal}
            openStepModal={openStepModal}
          />
        </div>
      )}
    </Draggable>
  ))

  const NonEmptyStepsView = () => {
    return (
      <>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="steps" direction="vertical">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {renderSteps}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Row className="flex-between-center g-0 mt-4 mb-4">
          <Col xs="auto">
            <UpdateStep
              openStepModal={openStepModal}
              setOpenStepModal={setOpenStepModal}
              updatableStep={updatableStep}
              setUpdatableStep={setUpdatableStep}
            />
          </Col>
        </Row>
      </>
    )
  }

  return (sequence.steps ?? []).length > 0 ? (
    <NonEmptyStepsView />
  ) : (
    <GetStartedView />
  )
}

SequenceSteps.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
}

export default SequenceSteps
