import React, { useContext, useEffect, useState } from 'react';
import KanbanHeader from './KanbanHeader';
import AppContext from 'context/Context';
import KanbanContainer from './KanbanContainer';
import KanbanProvider from './KanbanProvider';
import NavbarStandard from '../../pages/landing/NavbarStandard';
import { Container } from 'react-bootstrap';

const Kanban = () => {
  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const [kanbanIsFluid] = useState(isFluid);
  const [kanbanIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);

  useEffect(() => {
    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', true);

    return () => {
      setConfig('isFluid', kanbanIsFluid);
      setConfig('isNavbarVerticalCollapsed', kanbanIsNavbarVerticalCollapsed);
    };
  }, []);

  return (
    <>

      <div
      className="container centered-content"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <div>
        <p>We have moved our roadmap to <a href="https://feedback.reachable.to/" target='_blank' rel="noreferrer">https://feedback.reachable.to/</a></p>
      </div>
    </div>
      {/* <NavbarStandard />
      <Container>
        <br />
        <br />
        <br />
        <KanbanProvider>
          <KanbanHeader />
          <KanbanContainer />
        </KanbanProvider>
      </Container> */}
    </>
  );
};

export default Kanban;
