import FalconCardHeader from 'components/common/FalconCardHeader';
import { AuthMethod } from '../../../../constants';
import React from 'react';
import { Card } from 'react-bootstrap';
import UserManager from 'utils/user_manager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faMagic } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import Flex from 'components/common/Flex';

const AuthenticationMethodCard = () => {

    const loggedInUser = UserManager.getLoggedInUser();

    const getLoggedInOption = () => {
        let userLoggedInOption = loggedInUser.auth_type;
        if (userLoggedInOption === AuthMethod.PASSWORD_LESS) {
            return (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    alignSelf: 'flex-start',
                    padding: 10,
                    paddingBottom: 10,
                    borderRadius: 100,
                    color: 'white',
                    backgroundColor: '#9C27B0'
                }}>
                    <FontAwesomeIcon icon={faMagic} />
                    <span style={{
                        color: 'white'
                    }}>Password-Less Sign in</span>
                </div>
            );
        }
        if (userLoggedInOption === AuthMethod.GOOGLE_AUTH) {
            return (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    alignSelf: 'flex-start',
                    padding: 10,
                    paddingBottom: 10,
                    borderRadius: 100,
                    color: 'white',
                    backgroundColor: '#C62828'
                }}>
                    <FontAwesomeIcon icon={faGoogle} />
                    <span style={{
                        color: 'white'
                    }}>Google Sign In</span>
                </div>
            );
        }
        if (userLoggedInOption === AuthMethod.FACEBOOK_AUTH) {
            return (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    alignSelf: 'flex-start',
                    padding: 10,
                    paddingBottom: 10,
                    borderRadius: 100,
                    color: 'white',
                    backgroundColor: '#0D47A1'
                }}>
                    <FontAwesomeIcon icon={faFacebook} />
                    <span style={{
                        color: 'white'
                    }}>Facebook Sign In</span>
                </div>
            );
        }
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                alignSelf: 'flex-start',
                padding: 10,
                paddingBottom: 10,
                borderRadius: 100,
                color: 'white',
                backgroundColor: '#0097A7'
            }}>
                <FontAwesomeIcon icon={faLock} />
                <span style={{
                    color: 'white'
                }}>Email Address &amp; Password</span>
            </div>
        );
    }

    return (
        <Card className="mb-3">
            <FalconCardHeader title="Mode of Authentication" />
            <Card.Body className="bg-light">
                <p>You Logged in with:</p>
                <Flex alignItems={'start'}>
                    {getLoggedInOption()}
                </Flex>
            </Card.Body>
        </Card>
    );
};

export default AuthenticationMethodCard;
