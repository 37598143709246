import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmModal from 'components/common/ConfirmModal'
import APIService from 'http/api_service'
import { useEffect, useRef, useState } from 'react'
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveThread, getSequencesFromRedux, setActiveThread } from '../../../../../redux/slices/sequence_slice'
import UserManager from 'utils/user_manager'
import WorkSpaceManager from 'utils/workspace_manager'
import ThreadEmailComposer from './ThreadEmailComposer'
import Switch from "react-switch"
import ThreadTile from './ThreadTile'
import Flex from 'components/common/Flex'

const ThreadEntry = (props) => {
    const { thread, step, refreshStep, threadToOpen, setThreadToOpen, processing } = props
    const dispatch = useDispatch()
    const sequenceFromRedux = useSelector(getSequencesFromRedux)
    const [activateThread, setActivateThread] = useState(thread["activated"])
    const [openThreadNameEditor, setOpenThreadNameEditor] = useState(false)
    const [isDelete, setIsDelete] = useState("")
    const [nameOfThread, setNameOfThread] = useState(thread["name"])
    const [openDrawer, setOpenDrawer] = useState(false)
    const loggedInUser = UserManager.getLoggedInUser()
    const [testEmailRecipient, setTestEmailRecipient] = useState(loggedInUser.email);
    const workspace = WorkSpaceManager.getActiveWorkSpace()
    // const activeThread = useSelector(getActiveThread)
    let [activeThread, setActiveThread] = useState(threadToOpen ?? thread);
    // let activeThread = threadToOpen ?? thread;
    const [reRenderer, setReRenderer] = useState(0)

    useEffect(() => {
        // activeThreadRef.current.value = threadToOpen
        if (threadToOpen?.id === thread?.id) {
            setOpenDrawer(true)
        } else {
            setOpenDrawer(false)
        }
        setActiveThread(threadToOpen)
    }, [threadToOpen])


    const checkIfEmailAddressIsValid = (email) => {
        const emailRegex = /\S+@\S+\.\S+/
        return emailRegex.test(email)
    }

    const sendTestEmail = async (recipient) => {
        if (!checkIfEmailAddressIsValid(recipient)) {
            return { message: "Invalid email address", ok: false }
        }
        try {
            let { message } = await APIService.sendTestEmailToSequence(
                workspace._id,
                props?.sequence._id,
                step.id,
                thread.id,
                recipient,
            )
            return { message, ok: true }
        } catch (e) {
            return { message: e }
        }
    }

    const updateThread = () => {
        let threads = step["threads"]
        const threadIndex = threads.findIndex((x) => x["id"] === thread["id"])
        threads[threadIndex] = thread
        step["threads"] = threads
        refreshStep(step)
    }

    const generateThreadSubTitle = () => {
        let threadBody = thread["body"]
        let threadSubTitle = `${threadBody.substring(
            0,
            Math.min(threadBody.length, 50),
        )}`
        return threadBody.length > 50 ? `${threadSubTitle}...` : threadSubTitle
    }

    const generateThreadTitle = () => {
        let threadTitle = thread["title"]
        let trimmedTitle = `${threadTitle.substring(
            0,
            Math.min(threadTitle.length, 50),
        )}`
        return threadTitle.length > 50 ? `${trimmedTitle}...` : trimmedTitle
    }

    const deleteThread = () => {
        let _step = structuredClone(step)
        let threads = structuredClone(_step["threads"]) ?? []
        const currentThreadIndex = threads.findIndex((x) => x.id === thread.id)
        if (currentThreadIndex !== -1) {
            threads.splice(currentThreadIndex, 1)
            _step["threads"] = threads
            refreshStep(_step)
        }
    }

    const handleSave = () => {
        setThreadToOpen(null)
        let _step = structuredClone(step)
        let threads = structuredClone(_step["threads"])
        const threadIndex = threads.findIndex((x) => x["id"] === activeThread["id"])
        threads[threadIndex] = activeThread
        _step["threads"] = threads
        refreshStep(_step)
    }

    const ThreadDropdownOptions = () => {
        return (
            <Row className="g-md-0">
                <Col xs="auto" className="d-md-flex">
                    <Dropdown align="end" className="font-sans-serif">
                        <Dropdown.Toggle
                            size="sm"
                            variant="falcon-default"
                            className="dropdown-caret-none"
                        >
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="border py-2">
                            <Dropdown.Item
                                as={"button"}
                                onClick={() => {
                                    props?.cloneThread()
                                }}
                            >
                                Clone
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                                as={"button"}
                                onClick={() => {
                                    setIsDelete(true)
                                }}
                                className="text-danger"
                            >
                                Delete this
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <ConfirmModal
                        open={isDelete}
                        title={`Remove this Thread`}
                        message={`Are you certain about removing this Thread`}
                        confirmText={`Remove thread`}
                        cancelText={"Cancel"}
                        onCancel={() => {
                            setIsDelete(false)
                        }}
                        onConfirm={() => {
                            setIsDelete(false)
                            deleteThread()
                        }}
                    />
                </Col>
                <ThreadEmailComposer
                    thread={activeThread}
                    updateHandler={({ body, subject }) => {
                        let cloneActiveThread = structuredClone(activeThread)
                        cloneActiveThread["title"] = subject
                        cloneActiveThread["body"] = body
                        activeThread = cloneActiveThread
                    }}
                    sequence={props?.sequence}
                    testEmailHandler={sendTestEmail}
                    setTestEmailRecipient={setTestEmailRecipient}
                    testEmailRecipient={testEmailRecipient}
                    onSave={() => {
                        updateThread()
                    }}
                    step={step}
                    index={props?.index}
                    workspace={workspace}
                    setThreadToOpen={setThreadToOpen}
                    open={openDrawer}
                    handleSave={handleSave}
                    closeDrawer={() => {
                        setOpenDrawer(false)
                        setThreadToOpen(null);
                    }}
                />
            </Row>
        )
    }

    return (
        <>
            <div className="d-md-flex justify-content-between py-3 py-md-2">
                <div
                    className="d-flex  cursor-pointer"
                    onClick={() => {
                        setThreadToOpen(thread)
                    }}
                >
                    <div className="d-flex align-items-center">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Activate Variant</Tooltip>}
                        >
                            <Switch
                                checked={activateThread}
                                onChange={(checked, e) => {
                                    e.stopPropagation()
                                    setActivateThread(checked)
                                    thread["activated"] = checked
                                    updateThread(thread)
                                }}
                                height={20} // Adjust the height as needed
                                width={40} // Adjust the height as needed
                            />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Click to edit name of variant</Tooltip>}
                        >
                            <h6
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setOpenThreadNameEditor(true)
                                }}
                                className="ms-4 me-4 mb-2 mb-md-0 py-md-2"
                            >
                                {thread["name"]}
                            </h6>
                        </OverlayTrigger>
                        <ThreadTile
                            subTitle={generateThreadSubTitle()}
                            title={generateThreadTitle()}
                        />
                        <div
                            className="bg-200 mx-3"
                            style={{ height: "24px", width: "1px" }}
                        ></div>
                    </div>
                    <Flex>
                        {thread["stats"]?.map((stat, index) => {
                            return (
                                <div className="d-flex align-items-center">
                                    <ThreadTile
                                        alignment="center"
                                        key={index}
                                        title={stat["value"]}
                                        subTitle={stat["name"]}
                                    />
                                    <div
                                        className="bg-200 mx-3"
                                        style={{ height: "24px", width: "1px" }}
                                    ></div>
                                </div>
                            )
                        })}
                    </Flex>
                </div>
                <ThreadDropdownOptions />
            </div>
            <hr />
            <Modal
                show={openThreadNameEditor}
                size={"lg"}
                centered
                onHide={() => setOpenThreadNameEditor(false)}
                onExit={() => setOpenThreadNameEditor(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Name of Variant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        value={nameOfThread}
                        onChange={(e) => {
                            setNameOfThread(e.target.value)
                        }}
                    />
                </Modal.Body>
                <Modal.Footer className="bg-light px-card border-top-0">
                    <Button
                        variant="text"
                        onClick={(e) => {
                            setOpenThreadNameEditor(false)
                        }}
                        style={processing ? { pointerEvents: "none" } : {}}
                    >
                        Cancel
                    </Button>
                    <div style={{ width: 10 }}></div>
                    <Button
                        variant="primary"
                        onClick={() => {
                            thread["name"] = nameOfThread
                            updateThread()
                            setOpenThreadNameEditor(false)
                        }}
                        disabled={processing}
                        className="px-4 mx-0"
                    >
                        {processing ? "Updating..." : "Update"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ThreadEntry