import React, { useEffect, useState } from "react";
import Flex from "components/common/Flex";
import { Button, Card, Col, Offcanvas, Row } from "react-bootstrap";
import UserManager from "utils/user_manager";
import ConfirmModal from "components/common/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveWorkSpace,
} from "../../../../redux/slices/workspaceslice";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { getLoggedInUser, setLoggedInUser } from "../../../../redux/slices/user_slice";
import { RoutePaths } from "../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkspaceStats from "../../../../utils/workspace_stats";
import classNames from "classnames";
import { Link } from "react-router-dom";
import createMarkup from "helpers/createMarkup";
import {
  searchWorkSpace,
} from "../../../../redux/slices/workspaceslice";
import IconButton from 'components/common/IconButton';
import { faBackward, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { useQuery } from "utils/url";
import WorkSpaceManager from "utils/workspace_manager";
import { getBilledYearly } from "../../../../redux/slices/workspaceslice";
import PricingDefaultHeader from "../PricingDefaultHeader";
import PricingAltCard from "../PricingAltCard";
import PricingDefaultCard from "../PricingDefaultCard";
import { pricingData, pricingAltData } from "../pricingData";

const SalesPlatformSubscriptionCard = () => {
  const loggedInUser = UserManager.getLoggedInUser();
  const dispatch = useDispatch();
  const [activePlan, setActivePlan] = useState(loggedInUser.active_plans);
  const [openSubscriptionCancelPrompt, setOpenSubscriptionCancelPrompt] =
    useState(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [openSalesPlatformPricingPageDrawer, setOpenSalesPlatformPricingPageDrawer] = useState(false);

  console.log("activePlan", activePlan)

  const navigate = useNavigate();
  const [workSpaceStats, setWorkSpaceStats] = useState({});

  const initWorkSpaceStats = async () => {
    try {
      let stats = await WorkspaceStats.getSync();
      setWorkSpaceStats(stats);
    } catch (e) {}
  };

  useEffect(() => {
    initWorkSpaceStats();
  }, []);

  const cancelSubscription = (e) => {
    let activePlan = workspace.active_plans;
    if (activePlan.cancel_url) {
      window.location = activePlan.cancel_url;
    } else {
      APIService.cancelSubscription((response, error) => {
        if (error) {
          setCancellingSubscription(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        let { message, data } = response;
        dispatch(setLoggedInUser({ ...data }));
        checkAndLazyUpdateActiveWorkspace(data);
        toast.success(message, { theme: "colored" });
        setCancellingSubscription(false);
        setTimeout(() => {
          window.location = window.location.href.split("?")[0];
          window.location.reload();
        }, 500);
      });
    }
  };

  const checkAndLazyUpdateActiveWorkspace = (latestUserData) => {
    if (!latestUserData) {
      return;
    }
    if (latestUserData._id === workspace.owner_id) {
    }
  };


  const handleCloseSalesPlatformPricingPage = () => {
    setOpenSalesPlatformPricingPageDrawer(!openSalesPlatformPricingPageDrawer)
  }



  const workspace = WorkSpaceManager.getActiveWorkSpace();
  const billedYearly = useSelector(getBilledYearly);
  const [upgradingIndex, setupgradingIndex] = useState(-1);
  const [paymentForNewWorkspace] = useState(false);
  const [cancellingAIWriterSubscription, setCancellingAIWriterSubscription] =
    useState(false);
  const [openCheckoutCompletedPopup, setOpenCheckoutCompletedPopup] =
    useState(false);
  const [checkoutCompletedMessage, setCheckoutCompletedMessage] = useState();
  const [processingPayment, setProcessingPayment] = useState(false);
  const [
    openJoinedAIWriterCancellationPrompt,
    setOpenJoinedAIWriterCancellationPrompt,
  ] = useState(false);
  const query = useQuery();

  const refreshLoggedInUserData = () => {
    APIService.fetchLatestMe((latestMeResponse, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        if (
          error
            .toLowerCase()
            .includes("Invalid credentials provided".toLowerCase())
        ) {
          window.localStorage.clear();
          setTimeout(() => {
            navigate(RoutePaths.AUTHENTICATION);
          }, 100);
        }
        return;
      }
      let latestUserData = latestMeResponse.data;
      setActivePlan(latestUserData.active_plans);
      UserManager.setLoggedInUser(latestUserData);
    });
  };

  const handleSubscription = (item, index) => {
    setupgradingIndex(index);
    APIService.billUser(
      billedYearly,
      paymentForNewWorkspace,
      null,
      false,
      item.plan,
      (response, error) => {
        if (error) {
          setupgradingIndex(-1);
          toast.error(error);
          return;
        }
        let { data } = response;
        window.location = data;
        setupgradingIndex(-1);
      }
    );
  };

  const openCrisp = (event) => {
    event.preventDefault();
    window.$crisp.push(["do", "chat:open"]);
  };

  useEffect(() => {
    let paymentFor = query.get("payment_for");
    if (paymentFor) {
      try {
        let data = JSON.parse(decodeURIComponent(paymentFor));
        let { pricingData, coupon, user } = data;
        if (coupon) {
          pricingData.coupon = coupon;
        }
        if (pricingData) {
          console.log(pricingData);
          handleSubscription(pricingData, user);
        }
      } catch (e) {}
    }
  }, [query]);

  useEffect(() => {
    refreshLoggedInUserData();
  }, []);

  useEffect(() => {
    let checkoutSessionListener = (data) => {
      let { error, one_off, cancellation, message, plan = "Premium" } = data;
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      if (!cancellation) {
        setProcessingPayment(false);
        setCheckoutCompletedMessage(
          one_off
            ? message
            : `You have successfully subscribed to our ${plan} plan`
        );
        setOpenCheckoutCompletedPopup(true);
      } else {
        navigate(RoutePaths.DEFAULT);
        window.location.reload();
      }
    };
    // socket.on(`${loggedInUser?._id}-billing-status`, checkoutSessionListener);
    // return () => {
    //   socket.off(
    //     `${loggedInUser?._id}-billing-status`,
    //     checkoutSessionListener
    //   );
    // };
  }, []);

  
  const hashUpdatableUrl = () => {
    let cancelUrl = activePlan.cancel_url;
    let updateUrl = activePlan.update_url;
    if (updateUrl) {
      return updateUrl;
    }
    if (cancelUrl) {
      return cancelUrl.replace("cancel", "update");
    }
    return null;
  };

  const updatableUrl = hashUpdatableUrl();


  return (
    <>


<Card className="mb-3">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
          <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon="paper-plane" className={`text-success fs-0`} />
              </div>
              <div className="flex-1">
                  <h5 className="mt-1">Sales Platform</h5>
              </div>
            </Flex>
          </Col>
          <Col xs="auto">
            <IconButton
              onClick={ () => setOpenSalesPlatformPricingPageDrawer(true)}
              iconClassName="fs--2 me-1"
              variant="falcon-default"
              size="sm"
              // icon="pencil-alt"
            >
             Select a plan
            </IconButton>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-white border-top">
        <Row>
        <div className="position-relative z-index-2">
            <div>
              <p className="text-700">
                <b>{activePlan?.sales_platform?.Name} Plan</b>
              </p>
              {/* {!isFreeUser(loggedInUser) && (
              <Button
                variant="text"
                className="btn btn-sm btn-link ps-0 text-danger mb-2"
                style={{
                  textDecoration: "none",
                }}
                onClick={() => {
                  setOpenSubscriptionCancelPrompt(true);
                }}
              >
                {cancellingSubscription
                  ? "Cancelling subscription..."
                  : "Contact us to cancel your subscription"}
              </Button>
            )} */}
            </div>
            <Flex className="py-2">
              <div className="pe-3">
                <p className="fs--1 fw-medium">
                  <FontAwesomeIcon
                    icon="check"
                    className="me-1 text-success"
                  />
                  {activePlan?.sales_platform?.Contacts} leads
                </p>
                {/* <h4 className="text-800 mb-0">14,209</h4> */}
              </div>
              <div className="pe-3">
                <p className="fs--1 fw-medium">
                  <FontAwesomeIcon
                    icon="check"
                    className="me-1 text-success"
                  />
                  {activePlan?.sales_platform?.Email_Warmups} email warm-ups
                </p>
                {/* <h4 className="text-800 mb-0">14,209</h4> */}
              </div>
              
            </Flex>
            <Flex>
              <div className="pe-3">
                <p className=" fs--1">
                  <FontAwesomeIcon
                    icon="check"
                    className="me-1 text-success"
                  />
                  {activePlan.sales_platform?.MonthlyEmailSendingLimit} email sending limits
                  / month
                </p>
                {/* <h4 className="text-800 mb-0">$21,349.29</h4> */}
              </div>
              <div className="pe-3">
                <p className="fs--1 fw-medium">
                  <FontAwesomeIcon
                    icon="check"
                    className="me-1 text-success"
                  />
                  {/*  */}
                  {activePlan?.sales_platform?.Workspace} Workspaces
                  {/* {workSpaceStats?.totalCreatables}  */}

                </p>
                {/* <h4 className="text-800 mb-0">14,209</h4> */}
              </div>
            </Flex>
          </div>
        </Row>
        <ul className="mb-0 list-unstyled">
            <li className="mt-2">
              <Row className="flex-between-center">
                <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="check"
                      className={classNames("text-success mt-1 fs--1")}
                    />
                    <p
                      className="fs--1 ps-2 mb-0"
                      dangerouslySetInnerHTML={createMarkup("Users")}
                    ></p>
                  </Flex>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <Link
                    to={RoutePaths.TEAM}
                    className="alert-link fs--1 fw-medium"
                  >
                    {`Manage users`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                </Col>
              </Row>
            </li>
            <li className="mt-4"
            >
              <Row className="flex-between-center">
                <Col>
                  <Flex>
                  <FontAwesomeIcon
                      icon="check"
                      className={classNames("text-success mt-1 fs--1")}
                    />
                    <p
                      className="fs--1 ps-2 mb-0"
                      dangerouslySetInnerHTML={createMarkup(
                        `${workSpaceStats?.created}/${activePlan?.sales_platform?.Workspace} workspaces`
                      )}
                    ></p>
                  </Flex>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <Link
                    to="#"
                    onClick={() => {
                      dispatch(searchWorkSpace(true));
                    }}
                    className="alert-link fs--1 fw-medium"
                  >
                    {`View workspace`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                </Col>
              </Row>
            </li>
          </ul>

      </Card.Body>
      {/* <Card.Footer className="border-top text-end">
        <IconButton
          iconClassName="fs--2 me-1"
          variant="falcon-default"
          size="sm"
          icon="dollar-sign"
        >
          Refund
        </IconButton>
        <IconButton
          className="ms-2"
          iconClassName="fs--2 me-1"
          variant="falcon-default"
          size="sm"
          icon="check"
        >
          Save changes
        </IconButton>
      </Card.Footer> */}
    </Card>

    <Card className="mb-3">
        
        <Card.Body className="p-0">
          
        </Card.Body>
      </Card>
      <ConfirmModal
        open={openSubscriptionCancelPrompt}
        onCancel={() => {
          setOpenSubscriptionCancelPrompt(false);
        }}
        title="Cancel your subscription?"
        message="This will cancel your current subscription and downgrade your account to the free plan."
        cancelText="NO"
        confirmText="CONTINUE"
        onConfirm={() => {
          setOpenSubscriptionCancelPrompt(false);
          cancelSubscription();
        }}
      />
<Offcanvas
            style={{
                width: '80%',
            }}
            fullscreen={true}
            placement="end"
            show={openSalesPlatformPricingPageDrawer}
            onHide={handleCloseSalesPlatformPricingPage}>
            <Offcanvas.Header>
                <Offcanvas.Title>
                    <Button
                        onClick={() => {
                          handleCloseSalesPlatformPricingPage();
                        }}
                        icon={faBackward}
                        variant={'link'}
                        style={{
                            textDecoration: 'none'
                        }}>
                        <Flex
                            alignItems={'center'}
                            className={'gap-2'}>
                            <FontAwesomeIcon
                                color={'#B0BEC5'}
                                icon={faChevronLeft}
                            />
                            <span>Close</span>
                        </Flex>
                    </Button>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <Card className="mb-3">
              <Card.Body>
                <Row className="g-3" >
                  <Col xs={12}>
                    <Col lg={10}>
                      <Flex direction={"column"} alignItems={"start"} wrap={"wrap"}>
                        
                        {updatableUrl && (
                          <a href={updatableUrl} className={"text-decoration-none"}>
                            <Button
                              size="sm"
                              className="rounded-pill mb-2 mt-3"
                              variant="outline-warning"
                            >
                              <Flex className={"gap-1"} alignItems={"center"}>
                                <FontAwesomeIcon
                                  style={{ width: 10, height: 10 }}
                                  // icon={faPen}
                                />
                                <span className="fs--1">Update Payment Details</span>
                              </Flex>
                            </Button>
                          </a>
                        )}
                      </Flex>
                    </Col>
                    

                    <div style={{ flex: 1 }}></div>
                  </Col>
                  <PricingDefaultHeader />
                  {pricingData
                    .sort((x) => (x.activePlan ? -1 : 1))
                    .map((pricing) => {
                      if (activePlan?.Name === pricing?.title) {
                        pricing.activePlan = true;
                      }
                      return (
                        <PricingDefaultCard
                          key={pricing.id}
                          pricing={pricing}
                          handleBilling={(data) => {
                            handleSubscription(data, {
                              ...loggedInUser,
                              workSpaceId: workspace._id,
                            });
                          }}
                        />
                      );
                    })}
                </Row>
              </Card.Body>
            </Card>
            </Offcanvas.Body>
        </Offcanvas>
    </>
  );
};

export default SalesPlatformSubscriptionCard;
