/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import io from "socket.io-client"
import { SocketEvents, Strings } from "./constants"
import EndPoints from "./http/endpoints"
import UserManager from "./utils/user_manager"
import { getSocketIOEventListeners, setSocketIOEventListeners } from "./redux/slices/workspaceslice"
import { useSelector, useDispatch } from "react-redux"
import { isContentPublishingOngoing, setContentUploadingProgress } from "./redux/slices/postslice"

const defaultUrl = EndPoints.SOCKET_BASE
const defaultOptions = { transports: ["websocket"] }

let defaultSocketIO
if (!defaultSocketIO) {
  defaultSocketIO = io(defaultUrl, defaultOptions)
}

const useSocket = (_url = defaultUrl, _options = defaultOptions) => {
  const loggedInUser = UserManager.getLoggedInUser()
  const [socket] = useState(defaultSocketIO)
  const listeners = useSelector(getSocketIOEventListeners)
  const dispatch = useDispatch()
  const posting = useSelector(isContentPublishingOngoing)
  const [disconnected, setDisconnected] = useState(false)

  const contentUploadListener = () => {
    let contentUploadProgress = localStorage.getItem("content_upload_progress")
    if (contentUploadProgress) {
      let { message, progress } = JSON.parse(contentUploadProgress)
      dispatch(
        setContentUploadingProgress({
          completed: Math.min(progress, 101),
          message: `${message} ${Math.min(parseInt(`${progress}`), 101)}%`,
        }),
      )
      if (progress >= 100) {
        let timeout = setTimeout(() => {
          dispatch(setContentUploadingProgress(null))
          localStorage.removeItem("content_upload_progress")
          clearTimeout(timeout)
        }, 3000)
      }
    }
  }

  const observeLocalStorage = () => {
    window.addEventListener("storage", contentUploadListener)
  }

  const unObserveLocalStorage = () => {
    window.removeEventListener("storage", contentUploadListener)
  }

  const connectWithSingularity = (listener) => {
    if (!socket?.hasListeners(listener.event)) {
      socket?.on(listener.event, listener.callback)
    }
  }

  const connectListeners = () => {
    socket?.offAny()
    listeners.forEach((listener) => connectWithSingularity(listener))
  }

  useEffect(() => {
    observeLocalStorage()
    connectListeners()
    return () => {
      socket?.offAny()
      unObserveLocalStorage()
    }
  }, [listeners])

  useEffect(() => {
    if (!socket?.hasListeners("connect")) {
      socket?.on("connect", () => {
        if (disconnected && !posting) {
          window.location.reload()
        } else {
          console.log("===Connected===")
          connectListeners()
        }
      })
    }
    if (!socket?.hasListeners("disconnect")) {
      socket?.on("disconnect", () => {
        setDisconnected(true)
        console.log("===Disconnected===")
        socket?.offAny()
      })
    }
    if (!socket?.hasListeners("reconnect")) {
      socket?.on("reconnect", () => {
        console.log("===Reconnected===")
        connectListeners()
      })
    }
  }, [socket])

  const addSocketIOEventListener = (event, callback, more) => {
    let listenersCopy = [...listeners]
    let existingEvent = listenersCopy.find((x) => x?.event === event)
    if (!existingEvent) {
      listenersCopy.push({ event, callback })
      if (more) {
        for (let item of more) {
          let existingItem = listenersCopy.find((x) => x?.event === item.event)
          if (!existingItem) {
            listenersCopy.push(item)
          }
        }
      }
      dispatch(setSocketIOEventListeners(listenersCopy))
    }
  }

  const removeSocketIOEventListener = (event, callback, more) => {
    socket?.off(event, callback)
    let listenersCopy = [...listeners]
    let filtered = listenersCopy.filter((listener) => listener.event !== event)
    if (more) {
      for (let item of more) {
        socket?.off(item.event, item.callback)
        filtered = filtered.filter((listener) => listener.event !== item.event)
      }
    }
    dispatch(setSocketIOEventListeners(filtered))
  }

  const putUserOnlineViaSocketIO = () => {
    if (loggedInUser) {
      socket?.emit(SocketEvents.AUTHENTICATION_STATE_CHANGED_EVENT, {
        [Strings.API_KEY]: loggedInUser["api_key"],
        [Strings.LOGGED_IN]: true,
      })
    }
  }

  return {
    socket,
    addSocketIOEventListener,
    removeSocketIOEventListener,
    putUserOnlineViaSocketIO,
  }
}

export default useSocket
