import {
    faCheckCircle, faChevronLeft,
    faChevronRight, faCircleInfo, faGear
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import UserManager from "utils/user_manager";
import ReactLoading from 'react-loading';
import { toast } from "react-toastify";
import WorkSpaceManager from "utils/workspace_manager";
import APIService from "http/api_service";
import { useNavigate } from "react-router-dom";

const GoogleConnectionHeader = ({ headerIcon }) => {
    return (
        <Flex direction={'column'}>
            <Flex
                className={'gap-2 px-1'}
                alignItems={'center'}
                justifyContent={'center'}>
                <img src={headerIcon} width={40} />
                <Flex direction={'column'}>
                    <h2
                        className="p-0 m-0 fw-900"
                        style={{
                            fontSize: '1.4rem'
                        }}>Connect Your Google Account
                    </h2>
                    <span className="text-500 fw-medium">Gmail / G-Suite</span>
                </Flex>
            </Flex>
        </Flex>
    )
}

const IMAPConnectionPrompt = ({ promptHandler }) => {
    return (
        <Flex direction={'column'}>
            <span
                className="py-4 pb-2 text-dark"
                style={{
                    fontSize: '0.9rem',
                    maxWidth: 300,
                    alignSelf: 'center',
                    textAlign: 'center'
                }}>First, let's <b className="text-primary">enable IMAP</b> access
                for your Google account.
            </span>
            <div
                className="mx-3">
                <Divider />
            </div>
            <ol
                className="text-dark"
                style={{
                    lineHeight: '2.4rem'
                }}>
                <li>On your computer, open Gmail.</li>
                <li>Click the <FontAwesomeIcon icon={faGear} /> gear icon in the top right corner.</li>
                <li>Click <b>All Settings</b></li>
                <li>Click the <b><a target={'_blank'} rel="noreferrer" href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
                    style={{
                        textDecoration: 'none'
                    }}>Forwarding and POP/IMAP</a></b> tab.</li>
                <li>In the "IMAP access" section, select <b>Enable IMAP</b>.</li>
                <li>Click <b>Save Changes</b>.</li>
            </ol>
            {/* <a
                style={{
                    alignSelf: 'center',
                    textDecoration: 'none'
                }}
                target={'_blank'}
                rel="noreferrer" 
                href="https://help.reachable.ai/en/articles/6241817-how-to-connect-your-google-accounts">
                <span className="text-500 cursor-pointer">Show me how <FontAwesomeIcon icon={faChevronRight} /></span>
            </a> */}
            <Button
                onClick={() => {
                    promptHandler(1)
                }}
                className="mx-3 mt-3"
                variant={'primary'}><b>Yes, IMAP has been enabled</b>
            </Button>
        </Flex>
    );
}

const ConnectWithAppPasswordPenultimatePrompt = ({ nextButtonHandler }) => {

    const loggedInUser = UserManager.getLoggedInUser();
    const activeWorkspace = WorkSpaceManager.getActiveWorkSpace();

    const [formData, setFormData] = useState({
        first_name: loggedInUser?.first_name ?? '',
        last_name: loggedInUser?.last_name ?? '',
        email: loggedInUser?.email ?? '',
        password: '',
        provider: 'gmail'
    });

    const [processing, setProcessing] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const navigate = useNavigate();

    const handleSubmit = e => {
        e?.preventDefault();
        if (!formData.email) {
            toast.error(`Please enter your email`, { theme: 'colored' });
            return;
        }
        if (!formData.password) {
            toast.error(`Please enter the app password you generated in the previous step`, { theme: 'colored' });
            return;
        }
        setProcessing(true);
        setErrorMsg(undefined);
        APIService.connectEmailAccount(activeWorkspace._id, formData, (res, err) => {
            if (err) {
                setProcessing(false);
                toast.error(err, { theme: 'colored' });
                setErrorMsg(err);
                return;
            }
            let { message } = res;
            toast.success(message, { theme: 'colored' });
            setTimeout(() => {
                setProcessing(false);
                navigate(-1);
            }, 1000);
        });
    }

    useEffect(() => {
        nextButtonHandler(
            <Button
                disabled={processing}
                onClick={() => {
                    handleSubmit();
                }}
                variant={'success'}>
                {
                    processing ?
                        <Flex className={'gap-2'} alignItems={'center'}>
                            <ReactLoading width={30} height={30} type={'bubbles'} />
                            <span>Connecting</span>
                        </Flex>
                        :
                        <Flex className={'gap-2'} alignItems={'center'}>
                            <span>Connect</span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Flex>
                }
            </Button>
        )
        return () => {
            nextButtonHandler(undefined);
        }
    }, [formData, processing]);

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    return (
        <Form onSubmit={handleSubmit} className={'p-6'}>
            <Row className="g-2 mb-3">
                <Form.Group as={Col} sm={6}>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        disabled={processing}
                        placeholder={'First Name'}
                        value={formData.first_name}
                        name="first_name"
                        onChange={handleFieldChange}
                        type="text"
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        disabled={processing}
                        placeholder={'Last Name'}
                        value={formData.last_name}
                        name="last_name"
                        onChange={handleFieldChange}
                        type="text"
                    />
                </Form.Group>
            </Row>
            <Form.Group>
                <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                <Form.Control
                    required
                    disabled={processing}
                    placeholder={'Email'}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                />
            </Form.Group>
            <Form.Group>
                <Form.Label className={'pt-3'}>App Password<span className="text-danger">*</span></Form.Label>
                <Flex direction={'column'} className={'pb-3'}>
                    <span>Enter your 16 character app password</span>
                    <span className="text-primary fw-bold">without any spaces</span>
                </Flex>
                <Form.Control
                    required
                    disabled={processing}
                    placeholder={'Password'}
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                />
            </Form.Group>
            {
                errorMsg &&
                <Flex
                    className={'p-2'}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <span
                        className="text-danger"
                        style={{
                            fontSize: '.9rem',
                            maxWidth: 250
                        }}>{errorMsg}
                    </span>
                </Flex>
            }
        </Form>
    );
}

const AppPasswordConnectionPrompt = () => {
    return (
        <Flex direction={'column'} className={'p-4 gap-2'}>
            <h3
                className="text-600 fw-bold"
                style={{
                    fontSize: '.9rem'
                }}>Enable 2-step verification & generate App password</h3>
            <b className="fw-bold">
                <a style={{
                    textDecoration: 'none'
                }} href={"https://youtu.be/J4CtP1MBtOE?t=10"} target={'_blank'} rel="noreferrer">
                    <Flex alignItems={'center'} justifyContent={'center'} className={'gap-2'}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" class="mr-2" height="30" width="30" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M9 7v8l7-4zm12-4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z"></path></svg>
                        <span>See tutorial video</span>
                    </Flex>
                </a>
            </b>
            <Divider />
            <ol
                className="text-dark"
                style={{
                    lineHeight: '2.4rem'
                }}>
                <li>
                    Go to your Google Account's <a style={{ textDecoration: 'none' }} href={"https://myaccount.google.com/security"} target={'_blank'}>Security Settings</a>
                </li>
                <li>
                    Enable <a style={{ textDecoration: 'none' }} href={"https://myaccount.google.com/signinoptions/two-step-verification"} target={'_blank'}>2-step verification</a>
                </li>
                <li>
                    <Flex direction={'column'}>
                        <span>Create an <a style={{ textDecoration: 'none' }} href={"https://myaccount.google.com/apppasswords"} target={'_blank'}>App password</a></span>
                        <span
                            className={"text-500 fw-medium"}
                            style={{ lineHeight: 1 }}>Select 'Other' for both App and Device
                        </span>
                    </Flex>
                </li>
                <li>
                    <Flex
                        direction={'column'}>
                        <span style={{
                            marginTop: 10,
                            maxWidth: 400
                        }}>If prompted to <b>&lt;Select the app and device you want to generate the app password for&gt;</b> simply input <b>Reachable</b> as the name and click generate</span>
                    </Flex>
                </li>
            </ol>
        </Flex>
    )
}

const ConnectionOptionsPrompt = ({ promptHandler }) => {

    const ConnectionOptionCard = React.forwardRef((props, ref) => {

        const { title, bg, features = [], prompt } = props;

        const getIcon = (x) => {
            if (x.checked) {
                return { icon: faCheckCircle };
            }
            if (x.warn) {
                return { icon: faCircleInfo, color: '#E53935' }
            }
            return { icon: undefined }
        }

        return (
            <Card
                onClick={() => {
                    promptHandler(prompt);
                }}
                className={'cursor-pointer'}
                ref={ref}
                bg={bg}
                text={bg === 'light' ? 'dark' : 'white'}>
                <Card.Body>
                    <Card.Title
                        as="div"
                        style={{ textAlign: 'center' }}>{title}
                    </Card.Title>
                    <Flex
                        direction={'column'}
                        className={'gap-2 mt-3'}>
                        {
                            features.map((x) => {
                                return (
                                    <Flex
                                        key={x.title}
                                        className={'gap-2'}
                                        alignItems={'center'}>
                                        <FontAwesomeIcon icon={getIcon(x).icon} color={getIcon(x).color} />
                                        <span>{x.title}</span>
                                    </Flex>
                                )
                            })
                        }
                    </Flex>
                </Card.Body>
            </Card >
        )
    });

    const connectionChoices = [
        // {
        //     bg: 'primary',
        //     prompt: 2,
        //     title: "Option 1: oAuth",
        //     features: [
        //         {
        //             title: "Easier to setup",
        //             checked: true
        //         },
        //         {
        //             title: "More stable and less disconnects",
        //             checked: true
        //         },
        //         {
        //             title: "Available for GSuite accounts",
        //             checked: true
        //         }
        //     ]
        // },
        {
            title: "App Password",
            bg: 'light',
            prompt: 3,
            features: [
                {
                    title: "Available for personal accounts",
                    checked: true
                },
                {
                    title: "Requires 2-factor authentication",
                    warn: true
                },
                {
                    title: "More prone to disconnects",
                    warn: true
                }
            ]
        }
    ];

    return (
        <Flex
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            className={'gap-4 mt-5'}>
            <h5 className="p-0 m-0 fw-medium text-primary">Select a connection option</h5>
            <Flex
                wrap={'wrap'}
                className={'gap-2'}
                alignItems={'center'}
                justifyContent={'center'}>
                {
                    connectionChoices.map((x) => {
                        return (
                            <ConnectionOptionCard {...x} />
                        )
                    })
                }
            </Flex>
        </Flex>
    )

}

export default function GoogleConnector({ headerIcon }) {

    const [prompt, setPrompt] = useState(0);
    const [nextPrompt, setNextPrompt] = useState();
    const [nextButton, setNextButton] = useState();

    const promptHandler = (newPrompt) => {
        setPrompt(newPrompt);
    }

    const nextButtonHandler = (btn) => {
        setNextButton(btn);
    }

    const [promptMap] = useState({
        0: <IMAPConnectionPrompt promptHandler={promptHandler} />,
        1: <ConnectionOptionsPrompt promptHandler={promptHandler} />,
        3: <AppPasswordConnectionPrompt />,
        4: <ConnectWithAppPasswordPenultimatePrompt
            prompt={prompt}
            nextButtonHandler={nextButtonHandler}
            promptHandler={promptHandler}
        />
    });

    return (
        <Flex
            direction={'column'}>
            <GoogleConnectionHeader headerIcon={headerIcon} />
            {promptMap[Math.max(0, prompt)]}
            {
                prompt > 0 &&
                <Flex
                    className={`gap-4 mx-3 pb-1`}
                    style={{
                        paddingTop: prompt > 1 ? 1 : '1rem',
                        marginTop: prompt > 1 && '-2rem'
                    }}
                    alignItems={'center'}
                    justifyContent={prompt > 1 && 'center'}
                >
                    <Flex
                        className={'gap-2 cursor-pointer'}
                        alignItems={'center'}
                        onClick={() => {
                            setPrompt(Math.max(0, prompt - 1));
                        }}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <span className="fw-medium">Back</span>
                    </Flex>
                    {
                        prompt > 1 &&
                        <div>
                            {
                                nextButton ? nextButton :
                                    <Button
                                        onClick={() => {
                                            promptHandler(nextPrompt ? nextPrompt : prompt + 1);
                                        }}
                                        variant={'primary'}>
                                        <Flex className={'gap-2'} alignItems={'center'}>
                                            <span>Next</span>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </Flex>
                                    </Button>
                            }
                        </div>
                    }
                </Flex>
            }
        </Flex>
    );

}
