import React, { useState, useRef } from "react"
import ReactQuill from "react-quill"
import WorkSpaceManager from "utils/workspace_manager"
import "react-quill/dist/quill.snow.css"
import "./index.css"
import { Button, Col, Form, FormControl, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import APIService from "http/api_service"
import Flex from "components/common/Flex"
import IconButton from "components/common/IconButton"
// import { copyToClipBoard } from 'helpers/utils';
import { copyToClipboard } from "utils/browser"

export default function ComposeEmail(props) {
  const inputRef = useRef(null)
  const [activeWorkspace] = useState(WorkSpaceManager.getActiveWorkSpace())
  const [body, setBody] = useState(props?.id?.email_signature || "")
  const [isLoading, setIsLoading] = useState(false)
  const [firstName, setFirstName] = useState(props?.id?.first_name || "")
  const [lastName, setLastName] = useState(props?.id?.last_name || "")
  const [emailWarmUpLimit, setEmailWarmUpLimit] = useState(props?.id?.email_warm_up_limit || 0)
  const [increaseWarmUpEmailLimitPerDay, setIncreaseWarmUpEmailLimitPerDay] = useState(props?.id?.increaseWarmUpEmailLimitPerDay || null)
  const [emailLimit, setEmailLimit] = useState(props.id?.email_campaign_daily_limit || 0)
  const [replyEmail, setReplyEmail] = useState(props.id?.email_campaign_reply_email || "")
  const [minimumWaitTime, setMinimumWaitTime] = useState(props.id?.minimum_email_campaign_wait_time || 0)
  const [tooltipText, setTooltipText] = useState("Copy link to invite")

  const copyTextRef = useRef(activeWorkspace?.email_warmup_filter_tag)
  const copyBtnRef = useRef(null)
  const [cloneIcon, setCloneIcon] = useState("clone")
  const [copyWarmupFilterTagTip, setCopyWarmupFilterTag] = useState("Copy warmup filter tag")

  const handleBodyChange = (value) => {
    setBody(value)
  }

  const toolbarOptions = {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
    handlers: {
      // image: () => inputRef.current.click(),
    },
  }

  const handleSubmit = async () => {
    // const
    // console.log("html checking", body)
    if (Number(emailWarmUpLimit) > 100) return
    setIsLoading(true)

    const data = {
      first_name: firstName,
      last_name: lastName,
      email_signature: body,
      email_warm_up_limit: Number(emailWarmUpLimit),
      email_campaign_daily_limit: Number(emailLimit),
      minimum_email_campaign_wait_time: Number(minimumWaitTime),
      email_campaign_reply_email: replyEmail,
    }
    try {
      const res = await APIService.updateEmailAccountSettings(props.id._id, data)
      props.updateAcn(res.data)

      props.closeDrawer()
      // console.log("res", res)
    } catch (error) {
      console.log("error during signature")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={{ height: "100%", width: "100%", marginBottom: "4rem" }} className="pl-4 pr-4">
      <h1 className="fs-2 mb-3">Sender name & Signature</h1>
      <Form>
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={6} controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              value={firstName}
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              value={lastName}
              name="lastName"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
        </Row>
      </Form>
      <Form.Label>Signature</Form.Label>

      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          console.log("touched")
        }}
      />
      <ReactQuill
        value={body}
        onChange={handleBodyChange}
        modules={{ toolbar: toolbarOptions }}
        style={{
          borderRadius: 5,
          marginTop: 10,
          width: "100%",
          //   height: "20%",
          border: "1px solid #d4e2f0",
          position: "relative",
        }}
        placeholder="Email account signature"
      />
      {/* <br /> */}
      {/* <hr /> */}
      {/* <h1 className="fs-2 mb-3">Campaign email settings </h1> */}
      {/* <Row>
        <Col>
          <Form.Group as={Col} lg={12} controlId="lastName">
            <Form.Label>Daily campaign email sending limit</Form.Label>
            <InputGroup className="position-relative input-search-width">
              <FormControl
                id="emailLimit"
                type="number"
                className="shadow-none"
                value={emailLimit}
                placeholder="campaign email limit"
                onChange={(e) => setEmailLimit(e.target.value)}
              />
              <Button size="sm" variant="outline-secondary" className="border-300 hover-border-secondary">
                emails
              </Button>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Col} lg={12} controlId="lastName">
            <Form.Label>Minimum wait time</Form.Label>
            <InputGroup className="position-relative input-search-width">
              <FormControl
                onChange={(e) => setMinimumWaitTime(e.target.value)}
                id="waitTime"
                type="number"
                value={minimumWaitTime}
                className="shadow-none"
                placeholder="Minimum wait time"
              />
              <Button size="sm" variant="outline-secondary" className="border-300 hover-border-secondary">
                minute(s)
              </Button>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row> */}
      {/* <Row className="mt-4">
        <Col>
          <Form.Group as={Col} lg={12} controlId="email">
            <Form.Label>Reply To</Form.Label>
            <InputGroup className="position-relative input-search-width">
              <FormControl
                id="replyTo"
                value={replyEmail}
                type="email"
                onChange={(e) => setReplyEmail(e.target.value)}
                className="shadow-none"
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row> */}
      <br />
      <hr />
      <h1 className="fs-2 mb-3">Warmup Settings </h1>
      <Row>
        <Col lg={6}>
          <Form.Group as={Col} lg={12} controlId="lastName">
            <Form.Label>Daily Warm up email limit (max:100)</Form.Label>
            <Form.Control
              type="number"
              placeholder="suggested 20"
              max={100}
              value={emailWarmUpLimit}
              name="emailWarmUpLimit"
              onChange={(e) => setEmailWarmUpLimit(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <p style={{ marginBottom: 4 }}>Your warmup email filter tag</p>
          <div className="border rounded-1 fs--2 mb-3">
            <Flex alignItems="center" justifyContent="between" className="border-bottom bg-200">
              <Form.Group as={Col} controlId="email_filter">
                <Form.Control
                  ref={copyTextRef}
                  type="text"
                  placeholder="warmup filter tag "
                  value={activeWorkspace?.email_warmup_filter_tag}
                  disabled
                  readOnly
                  name="emailfilter"
                  // onChange={(e) => setEmailWarmUpLimit(e.target.value)}
                />
              </Form.Group>
              <div className="border-start">
                <OverlayTrigger
                  // trigger={['hover', 'click']}
                  placement="top"
                  trigger="hover"
                  overlay={<Tooltip style={{ position: "fixed" }}>{tooltipText}</Tooltip>}
                >
                  <div>
                    <IconButton
                      onMouseLeave={() => setTooltipText("Click to copy")}
                      ref={copyBtnRef}
                      // onClick={handleCopyText}
                      handleCopy={() => {
                        copyToClipboard(activeWorkspace?.email_warmup_filter_tag)
                        setCloneIcon("check-circle")
                        setCopyWarmupFilterTag("Warmup filter tag Copied!")
                        setTimeout(() => {
                          setCloneIcon("far", "copy")
                          setCopyWarmupFilterTag("Click to copy")
                        }, 2000)
                      }}
                      variant="link"
                      size="sm"
                      icon={cloneIcon}
                      // icon={['far', 'copy']}
                      className="text-decoration-none hover-secondary rounded-0 fs--2"
                      iconClassName="me-2"
                    >
                      Copy tag
                    </IconButton>
                  </div>
                </OverlayTrigger>
              </div>
            </Flex>
          </div>

          {/* <Form.Group as={Col} lg={12} controlId="email_filter">
                <Form.Label>Warm up email filter code</Form.Label>
                <Form.Control
                type="text"
                placeholder="email code"
                value={activeWorkspace?.email_warmup_filter_tag}
                disabled
                name="emailfilter"
                // onChange={(e) => setWarmupLimit(e.target.value)}
                />
            </Form.Group> */}
        </Col>
        <Col>
          <Form.Group as={Col} lg={12} controlId="lastName">
            <Form.Label>Increase daily warm up email limit per day</Form.Label>
            <Form.Control
              type="number"
              placeholder="suggested 1"
              max={100}
              value={increaseWarmUpEmailLimitPerDay}
              name="increaseWarmUpEmailLimitPerDay"
              onChange={(e) => setIncreaseWarmUpEmailLimitPerDay(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Button disabled={isLoading} style={{ marginTop: "30px" }} onClick={handleSubmit}>
        {isLoading ? "Saving..." : "Save"}
      </Button>
    </div>
  )
}
