import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import contactVariables from 'assets/json/email_contact_variable.json';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import { copyToClipBoard } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Col,
  Dropdown,
  Form
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';

const SingleItem = ({ icon, title, description }) => {
  return (
    <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
      <Flex alignItems="center" className="mb-3">
        <FontAwesomeIcon icon={icon} className="text-primary" />
        <Link to="#!" className="stretched-link text-decoration-none">
          <h5 className="fs--1 text-600 mb-0 ps-3">{title}</h5>
        </Link>
      </Flex>
      <h5 className="fs--1 text-800">{description}</h5>
    </div>
  );
};

SingleItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const CustomVariables = (props) => {
  const { handleInsertCharacter } = props;
  const [tooltipText, setTooltipText] = useState('Copy link to invite');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const copyTextRef = useRef(null);
  const copyBtnRef = useRef(null);
  const dropdownRef = useRef(null);


  const {
    config: { isRTL }
  } = useContext(AppContext);

  const handleCopyText = () => {
    copyToClipBoard(copyTextRef);
    setTooltipText('Copied to Clipboard!');
  };

  const handleInsertCharacterAndClose = (value) => {
    handleInsertCharacter(value);
    setDropdownOpen(false);
  };
  const [contactVariablesClone, setContactVariablesClone] = useState(contactVariables);

  const handleSearchVariable = (e) => {
    let searchValue = e.target.value;
    let searchResult = contactVariables.filter((variable) => {
      return variable.variable.toLowerCase().includes(searchValue.toLowerCase());
    });
    setContactVariablesClone(searchResult);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Dropdown show={dropdownOpen} onClose={() => setDropdownOpen(false)} ref={dropdownRef}>
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="ms-3 me-2 dropdown-caret-none"
        style={{ boxShadow: 'none', border: "1px solid #d8e2ef" }}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {'{ }'} Variables
      </Dropdown.Toggle>
      <Dropdown.Menu
        align={isRTL ? 'start' : 'end'}
        className="pt-2 pb-0 text-nowrap end-0"
        style={{ minWidth: '300px' }}
      >
        <h6 className="dropdown-header text-center ls">Basic contact variables</h6>
        <Dropdown.Divider className="mb-0" />
        <div className="p-2">
          {
            <Col>
              <Form className="position-relative">
                <Form.Control
                  type="search"
                  placeholder="Search contact variables "
                  size="sm"
                  aria-label="Search"
                  className="rounded search-input ps-4"
                  onChange={handleSearchVariable}
                />
                <FontAwesomeIcon
                  icon="search"
                  className="fs--1 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
                />
              </Form>
            </Col>
          }
          <SimpleBarReact style={{ maxHeight: "180px" }}>
            <div className="pt-3 px-x1">
              {
                contactVariablesClone.map((variable, index) => {
                  return (
                    <p key={index} style={{ color: "#000" }} onClick={() => handleInsertCharacterAndClose(variable.value)} className="cursor-pointer">
                      <span className='text-600'>{variable.value}</span> - {variable.variable}
                    </p>
                  )
                })
              }
            </div>
          </SimpleBarReact>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomVariables;
