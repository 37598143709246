import React, { useEffect, useState } from 'react';
import AuthCardLayout from 'layouts/AuthCardLayout';
import PasswordLessSignInForm from '../PasswordLessSignInForm';
import PasswordLessConfirmation from '../PasswordLessConfirmation';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import { Helmet } from "react-helmet";

const PasswordLess = () => {

    const [activeState, setActiveState] = useState(0);

    const passwordLessStateHandler = (newState) => {
        setActiveState(newState);
    }

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let state = params.get("state");
        if (state) {
            setActiveState(parseInt(state));
        }
    }, []);

    return (
        <>
        <Helmet>
        <title>Passwordless - Reachable</title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ReachableHQ " />
        <meta name="twitter:creator" content="@ReachableHQ " />
        <meta
          property="og:description"
          content="Passwordless - Reachable"
        />
        <meta
          content="https://twitter.com/ReachableHQ"
          property="og:see_also"
        />
        <meta name="twitter:title" content="Passwordless - Reachable" />
        <meta name="twitter:description" content="" />
        {/* <meta name="twitter:image" content="https://promoteapp.s3.amazonaws.com/promote-twitter-card.jpeg" /> */}
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachable.to/" rel="canonical " />
        <link href="https://reachable.to/" rel="home " />
      </Helmet>
        <AuthSplitLayout>
            {
                activeState === 0 &&
                <h4 className="mb-0">Password free sign In?</h4>
            }
            {
                activeState === 0 &&
                <p className="mb-0">We'll email you a magic code for a password-free
                    sign in.
                </p>
            }
            {
                activeState === 0 ?
                    <PasswordLessSignInForm stateHandler={passwordLessStateHandler} /> :
                    <PasswordLessConfirmation />
            }
        </AuthSplitLayout>
        </>
        
    )
}
export default PasswordLess;
