import React, { useState, useMemo } from 'react';
import {
    Button, Form, TextArea
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import MediaPreviewContainer from './MediaObjectsPreviewContainer';
import { isPhoto, isVideo } from '../../utils/file_utils';
import {
    setExternalMediaFiles,
    setYouTubeVideoThumbnail,
    setPinterestVideoThumbnail,
    setInstagramVideoThumbnail,
    setMediaAddedViaSpecificPlaformUrlMethod,
    getSpecificMediaPlatformTied,
    setWordpressFeaturedImageThumbnail
} from '../../redux/slices/postslice';
import { useDispatch, useSelector } from 'react-redux';
import { PlatformIdentifier } from '../../constants';
import { toast } from 'react-toastify';
import Flex from './Flex';

export default function ExternalFilePickerDialog(props) {
    const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
    const dispatch = useDispatch();
    let { modalClose, options } = props;
    const {
        fileType,
        multiple,
        externalFilePickerTitle,
        externalPickerPromptLabel,
        thumbnail = false,
        platformIdentifier
    } = options;
    const [text, setText] = useState();
    const defaultPrompt = `${externalPickerPromptLabel} must be in a publicly accessible location`;
    const [textEmptyError, setTextEmptyError] = useState();
    const [canPreviewFiles, setCanPreviewFiles] = useState(false);
    const [filesToPreview, setFilesToPreview] = useState();
    const [canContinue, setCanContinue] = useState(false);
    const [errorFiles, setErrorFiles] = useState();

    const prepareFilesToPreview = (files) => {
        let mediaObjects = [];
        if (fileType === 'video') {
            files = files.filter((file) => isVideo(file));
        } else {
            files = files.filter((file) => isPhoto(file));
        }
        for (let file of files) {
            let externalMediaObject = {
                name: file,
                url: file,
                uploaded: true,
                extension: fileType === 'video' ? '.mp4' : '.jpeg',
                is_video: fileType === 'video',
                thumbnail
            }
            if (thumbnail && platformIdentifier) {
                externalMediaObject[`thumbnail_for_${platformIdentifier}`] = true;
            }
            mediaObjects.push(externalMediaObject);
        }
        if (mediaObjects.length > 0) {
            setFilesToPreview(mediaObjects);
            setCanPreviewFiles(true);
            setCanContinue(true);
        }
    }

    const sendBackThumbnail = () => {
        if (platformIdentifier === PlatformIdentifier.YOUTUBE) {
            dispatch(setYouTubeVideoThumbnail(filesToPreview[0]));
        } else if (platformIdentifier === PlatformIdentifier.PINTEREST) {
            dispatch(setPinterestVideoThumbnail(filesToPreview[0]));
        } else if (platformIdentifier === PlatformIdentifier.INSTAGRAM) {
            dispatch(setInstagramVideoThumbnail(filesToPreview[0]));
        } else if (platformIdentifier === PlatformIdentifier.WORDPRESS) {
            dispatch(setWordpressFeaturedImageThumbnail(filesToPreview[0]));
        }
    }

    const ExternalMediaAttachmentsPreview = useMemo(() => {
        if (canPreviewFiles) {
            return <MediaPreviewContainer
                mediaObjects={filesToPreview}
                mediaRemoveHandler={(removedMedia) => {
                    let removedMediaUrl = removedMedia.url;
                    let files = text.trim().split(",");
                    if (files.includes(removedMediaUrl)) {
                        setCanContinue(false);
                        let indexOfMediaUrl = files.indexOf(removedMediaUrl);
                        files.splice(indexOfMediaUrl, 1);
                        setText(files.join(","));
                        if (files.length < 1) {
                            setFilesToPreview(null);
                            setCanPreviewFiles(false);
                            setTextEmptyError(null);
                        } else {
                            prepareFilesToPreview(files);
                        }
                    }
                }}
                errorFilesHandler={(errorFile) => {
                    setErrorFiles(errorFile);
                }}
            />
        }
        return <div></div>
    }, [filesToPreview]);

    const resolveFiles = () => {
        if (canContinue) {
            if (errorFiles) {
                if (Object.entries(errorFiles.length > 0).length < 1) {
                    if (thumbnail) {
                        sendBackThumbnail();
                    } else {
                        let modifiedFilesToPreview = [];
                        filesToPreview.map(file => {
                            modifiedFilesToPreview = [...modifiedFilesToPreview, { ...file, external: true }]
                        })
                        specificMediaPlatformTied === "global" ? dispatch(setExternalMediaFiles(modifiedFilesToPreview)) : dispatch(setMediaAddedViaSpecificPlaformUrlMethod(modifiedFilesToPreview));
                    }
                    modalClose();
                } else {
                    toast.error("Some urls could not be resolved");
                }
            } else {
                if (thumbnail) {
                    sendBackThumbnail();
                } else {
                    let modifiedFilesToPreview = [];
                    filesToPreview.map(file => {
                        modifiedFilesToPreview = [...modifiedFilesToPreview, { ...file, external: true }]
                    })
                    specificMediaPlatformTied === "global" ? dispatch(setExternalMediaFiles(modifiedFilesToPreview)) : dispatch(setMediaAddedViaSpecificPlaformUrlMethod(modifiedFilesToPreview));
                }
                modalClose();
            }
            return;
        }
        if (!text) {
            setCanPreviewFiles(false);
            setTextEmptyError(`Enter valid URL${multiple ? ('s') : ''}. ${multiple ? 'For multiple files, you may separate URLs by commas' : ''}`);
            return;
        }
        setTextEmptyError(null);
        let files = text.trim().split(",");
        if (!multiple) {
            files = [files[0]];
        }
        if (files.length > 0) {
            prepareFilesToPreview(files);
        } else {
            setTextEmptyError(`Enter valid URL${multiple ? ('s') : ''}. ${multiple ? 'For multiple files, you may separate URLs by commas' : ''}`);
            setCanPreviewFiles(false);
        }
    }

    return (
        <Flex direction={'column'}>
            <Flex direction={'column'}>
                <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter URL"
                    className="rounded-2 resize-none border-y-10 px-card border-300 mb-2"
                    value={text}
                    onChange={e => {
                        setText(e.target.value);
                        setCanContinue(false);
                    }}
                />
                {ExternalMediaAttachmentsPreview}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 12,
                        gap: 5
                    }}>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{
                            width: 15,
                            height: 15,
                        }}
                    />
                    <span
                        style={{
                            fontSize: 12,
                        }}>{defaultPrompt}
                    </span>
                </div>
                {
                    textEmptyError &&
                    <span style={{
                        padding: 20,
                        textAlign: 'center',
                        color: 'red'
                    }}>
                        {textEmptyError}
                    </span>
                }
            </Flex>
            <Flex className={'gap-2 mt-2'} style={{ alignSelf: 'flex-end' }}>
                <Button
                    className='btn-light'
                    onClick={() => {
                        modalClose();
                    }}>
                    CANCEL
                </Button>
                <Button
                    className='btn-primary'
                    onClick={() => {
                        resolveFiles();
                    }}
                >
                    {canContinue ? 'CONTINUE' : 'ADD'}
                </Button>
            </Flex>
        </Flex>
    );
}