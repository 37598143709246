import APIService from '../http/api_service';
import { PlatformIdentifier } from '../constants';
import WorkSpaceManager from './workspace_manager';

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

export function initFacebookSdk() {
    return new Promise(resolve => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v13.0'
            });
            window.FB.getLoginStatus(({ _authResponse }) => {
                localStorage.removeItem("fb_down");
                resolve();
            }, true);
        };
        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
            js.onerror = () => {
                localStorage.setItem("fb_down", true);
                resolve();
            }
        }(document, 'script', 'facebook-jssdk'));
    });
}

export const facebookLoginScopes = [
    "email",
    "public_profile",
    "pages_read_engagement",
    "pages_manage_posts",
    "pages_read_user_content",
    "pages_show_list",
    "publish_video",
    "publish_to_groups",
    // "pages_messaging",
    // "pages_manage_metadata"
];

export const instagramLogInScopes = [
    "instagram_basic",
    "instagram_content_publish",
    "pages_show_list",
    "publish_video",
    // "pages_messaging",
    // "pages_manage_metadata",
    "instagram_manage_comments"
]

export async function connectFacebook(workSpace, platformName = "Facebook", identifier = PlatformIdentifier.FACEBOOK, cb) {
    if (localStorage.getItem("fb_down")) {
        cb(null, `Sorry, but Facebook is currently down. Please check back later`);
        return
    }
    window.FB.login(function (response) {
        let { status, authResponse } = response;
        if (!authResponse) {
            cb(null, `${platformName} Platform integration denied`);
        } else {
            if (status === "connected") {
                let userId = authResponse.userID;
                let activeWorkSpace = WorkSpaceManager.getActiveWorkSpace();
                let activeWorkSpaceId = activeWorkSpace['_id'];
                if (activeWorkSpaceId) {
                    APIService.addNewWorkSpacePlatform(activeWorkSpaceId, {
                        name: platformName,
                        identifier: identifier,
                        access_token: authResponse.accessToken,
                        user_id: userId,
                        permissions: facebookLoginScopes
                    }, (apiResponse, error) => {
                        if (error) {
                            cb(null, error);
                            return;
                        }
                        let message = apiResponse['message'];
                        cb(message, null);
                    });
                } else {
                    cb(null, `Oops! something went wrong while integrating ${platformName}`);
                }
            } else {
                cb(null, `${platformName} Platform integration failed`);
            }
        }
    }, {
        scope: facebookLoginScopes.join(","),
        prompt: 'consent',
        return_scopes: true,
    });
}

export async function connectInstagram(workSpace, platformName = "Instagram", identifier = PlatformIdentifier.INSTAGRAM, cb) {
    if (localStorage.getItem("fb_down")) {
        cb(null, `Sorry, but Instagram is currently down. Please check back later`);
        return
    }
    window.FB.login(function (response) {
        let { status, authResponse } = response;
        if (!authResponse) {
            cb(null, `${platformName} Platform integration denied`);
        } else {
            if (status === "connected") {
                let userId = authResponse.userID;
                let activeWorkSpace = WorkSpaceManager.getActiveWorkSpace();
                let activeWorkSpaceId = activeWorkSpace['_id'];
                if (activeWorkSpaceId) {
                    APIService.addNewWorkSpacePlatform(activeWorkSpaceId, {
                        name: platformName,
                        identifier: identifier,
                        access_token: authResponse.accessToken,
                        user_id: userId,
                        permissions: instagramLogInScopes
                    }, (apiResponse, error) => {
                        if (error) {
                            cb(null, error);
                            return;
                        }
                        let message = apiResponse['message'];
                        cb(message, null);
                    });
                } else {
                    cb(null, `Oops! something went wrong while integrating ${platformName}`);
                }
            } else {
                cb(null, `${platformName} Platform integration failed`);
            }
        }
    }, {
        scope: instagramLogInScopes.join(","),
        prompt: 'consent',
        return_scopes: true,
    });
}

export async function disconnectFacebook(cb) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        cb();
    } else {
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                window.FB.logout(function (response) {
                    cb();
                });
            } else {
                cb();
            }
        }, true);
    }
}

export async function connectTwitter(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Twitter",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}

export async function connectYoutube(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Youtube",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}

export async function connectTikTok(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "TikTok",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}

export async function connectLinkedIn(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "LinkedIn",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}

export async function connectPinterest(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Pinterest",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}

export async function connectGMB(workSpace, identifier, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Google My Business",
        identifier: identifier,
        user_id: "adadad"
    }, cb);
}

export async function connectReddit(workSpace, identifier, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Reddit",
        identifier: identifier,
        user_id: "adadad"
    }, cb);
}
export async function connectWordpress(workSpace, identifier, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Wordpress",
        identifier: identifier,
        user_id: "adadad"
    }, cb);
}

export async function connectTelegram(workSpace, user_id, username, user_photo, identifier, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Telegram",
        identifier: identifier,
        user_id,
        username,
        user_photo
    }, cb);
}
