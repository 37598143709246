import React, { useEffect, useState } from 'react';
import { Button, Card, Image, Modal } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { getBulkEmailAccountsToCreate } from '../../../../redux/slices/workspaceslice';
import BulkContentEditBox from './AnyProviderBulkContentEditBox';
import StringUtils from 'utils/string';
import { toast } from 'react-toastify';
import UserManager from 'utils/user_manager';
import useSocket from 'socket_io';
import APIService from 'http/api_service';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import Flex from 'components/common/Flex';
import { UpgradeToPremiumAccountCard } from 'components/app/new-workspace';
import WorkSpaceManager from 'utils/workspace_manager';
import { useNavigate } from 'react-router-dom';

const columns = [
    {
        accessor: 'first_name',
        Header: 'First Name',
        Cell: (rowData) => {
            const { id } = rowData.row;
            return <BulkContentEditBox
                rowIndex={parseInt(id)}
                property={'first_name'}
            />
        }
    },
    {
        accessor: 'last_name',
        Header: 'Last Name',
        Cell: (rowData) => {
            const { id } = rowData.row;
            return <BulkContentEditBox
                rowIndex={parseInt(id)}
                property={'last_name'}
            />
        }
    },
    {
        accessor: 'email',
        Header: 'Email',
        Cell: (rowData) => {
            const { id } = rowData.row;
            return <BulkContentEditBox
                rowIndex={parseInt(id)}
                property={'email'}
            />
        }
    },
    {
        accessor: 'password',
        Header: 'Password',
        Cell: (rowData) => {
            const { id } = rowData.row;
            return <BulkContentEditBox
                rowIndex={parseInt(id)}
                property={'password'}
            />
        }
    },
    {
        accessor: 'imap_host',
        Header: 'IMAP Host',
        Cell: (rowData) => {
            const { id } = rowData.row;
            return <BulkContentEditBox
                rowIndex={parseInt(id)}
                property={'imap_host'}
            />
        }
    },
    {
        accessor: 'imap_port',
        Header: 'IMAP Port',
        Cell: (rowData) => {
            const { id } = rowData.row;
            return <BulkContentEditBox
                rowIndex={parseInt(id)}
                property={'imap_port'}
            />
        }
    },
    {
        accessor: 'smtp_host',
        Header: 'SMTP Host',
        Cell: (rowData) => {
            const { id } = rowData.row;
            return <BulkContentEditBox
                rowIndex={parseInt(id)}
                property={'smtp_host'}
            />
        }
    },
    {
        accessor: 'smtp_port',
        Header: 'SMTP Port',
        Cell: (rowData) => {
            const { id } = rowData.row;
            return <BulkContentEditBox
                rowIndex={parseInt(id)}
                property={'smtp_port'}
            />
        }
    }
];

const AnyProviderBulkUploadPreviewer = (props) => {

    const navigate = useNavigate();
    const { pickNewFile } = props;
    const emailAccounts = useSelector(getBulkEmailAccountsToCreate);
    const [creatingEmailAccounts, setCreatingEmailAccounts] = useState(false);
    const loggedInUser = UserManager.getLoggedInUser();
    const [openCreationSuccessDialog, setOpenCreationSuccessDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState("Bulk email accounts connected successfully");
    const [openUpgradeCard, setOpenUpgradeCard] = useState(false);
    const activeWorkSpace = WorkSpaceManager.getActiveWorkSpace();
    const {
        addSocketIOEventListener,
        removeSocketIOEventListener
    } = useSocket();

    useEffect(() => {
        let emailAccountsSpacesEventListener = (response) => {
            let { error, message } = response;
            setCreatingEmailAccounts(false);
            if (error) {
                if (error.toLowerCase().includes('upgrade'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            setSuccessMessage(message);
            setOpenCreationSuccessDialog(true);
        }
        addSocketIOEventListener(`${loggedInUser?._id}_bulk_email_accounts_status`, emailAccountsSpacesEventListener);
        return () => {
            removeSocketIOEventListener(`${loggedInUser?._id}_bulk_email_accounts_status`, emailAccountsSpacesEventListener);
        }
    }, []);

    const connectEmailAccounts = async () => {
        setCreatingEmailAccounts(true);
        const bulkContentStringified = JSON.stringify(emailAccounts);
        const bulkContentBlob = new Blob([bulkContentStringified], {
            type: 'application/json;charset=utf-8'
        });
        let requestBody = new FormData();
        let bulkContentUploadFile = new File([bulkContentBlob], `${StringUtils.generateRandomString(10)}.json`);
        requestBody.append('file', bulkContentUploadFile);
        let response = await APIService.uploadNewFile(activeWorkSpace?._id, requestBody, 0, null, 1);
        let result = response.data;
        setTimeout(async () => {
            await APIService.uploadBulkEmailAccounts(activeWorkSpace?._id, { bulk_email_accounts_url: result }, (_response, error) => {
                if (error) {
                    setCreatingEmailAccounts(false);
                    toast.error(error, { theme: 'colored' });
                    return;
                }
            });
        }, 3000);
    }

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={emailAccounts}
            pagination
            perPage={50}
        >
            <Card>
                <FalconCardHeader
                    title="Bulk email accounts to connect"
                    endEl={
                        <div
                            style={{
                                display: 'flex',
                                gap: 10
                            }}>
                            <Button
                                type="button"
                                icon="plus"
                                size="sm"
                                className="ms-2"
                                variant="falcon-default"
                                onClick={() => {
                                    pickNewFile();
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 1, alignItems: 'center'
                                    }}>
                                    <FontAwesomeIcon icon={'plus'} />
                                    <span className="d-none d-sm-inline-block ms-1">Pick a New CSV File</span>
                                </div>
                            </Button>
                            <Button
                                disabled={creatingEmailAccounts || emailAccounts.length < 2}
                                size="sm"
                                className="ms-2"
                                variant="falcon-primary"
                                onClick={() => {
                                    connectEmailAccounts();
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 1, alignItems: 'center'
                                    }}>
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                    <span className="d-none d-sm-inline-block ms-1">{creatingEmailAccounts ? "Connecting accounts..." : "Connect accounts"}</span>
                                </div>
                            </Button>
                        </div>
                    }
                ><span className='fs--1'>Min number of rows must be 2</span></FalconCardHeader>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowCount={emailAccounts.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </Card.Footer>
            </Card>
            <Modal
                backdrop="static"
                show={openCreationSuccessDialog}
                onHide={() => setOpenCreationSuccessDialog(false)}
                onExit={() => setOpenCreationSuccessDialog(false)}
                centered={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Email accounts connected
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Flex
                        direction={'column'}
                        className={'gap-3'}>
                        <span className='text-success'>{successMessage}</span>
                    </Flex>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setOpenCreationSuccessDialog(false);
                            navigate(-1);
                        }}>CLOSE
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={openUpgradeCard}
                onHide={() => setOpenUpgradeCard(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <UpgradeToPremiumAccountCard
                        subTitle={"👋 You've discovered a premium feature!"}
                        message={"Upgrade to one of the paid plans to unlock this feature."}
                        upgradeBtnText={"Upgrade Plan"}
                    />
                </Modal.Body>
            </Modal>
        </AdvanceTableWrapper>
    );
};

export default AnyProviderBulkUploadPreviewer;
