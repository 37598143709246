import React, { useEffect, useState } from 'react';
import APIService from '../../../../http/api_service';
import WorkSpaceManager from '../../../../utils/workspace_manager';
import {
    connectFacebook,
    connectTwitter,
    connectYoutube,
    connectTikTok,
    connectLinkedIn,
    connectPinterest,
    connectGMB,
    connectReddit,
    connectTelegram,
    connectInstagram,
    connectWordpress
} from '../../../../utils/platform_utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConnectedAccount from './connected_account';
import { findPlatformByIdentifier } from '../index';
import useSocket from '../../../../socket_io';
import { toast } from 'react-toastify';
import { PERMISSION_VIEW_AND_MANAGE_PLATFORMS, PlatformIdentifier } from '../../../../constants';
import TelegramLoginButton from 'react-telegram-login';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PlatformConnectView from '../platform_connect_view';
import { hasPopupBlocker, recommendUrlToUnblockPopup } from '../../../../utils/url';
import { getDisconnectedPlatforms, setDisconnectedPlatforms } from '../../../../redux/slices/platformSlice';
import { Button, Card, Spinner } from 'react-bootstrap';
import ConfirmModal from 'components/common/ConfirmModal';
import Flex from 'components/common/Flex';
import InfoBar from 'components/common/InfoBar';
import RichInfoBar from 'components/common/RichInfoBar';
import PageHeader from 'components/common/PageHeader';

export default function ConnectedAccounts(props) {

    // Identifier as props due to splitting of social account
    const { identifier } = props;
    const dispatch = useDispatch();
    // const { identifier } = useParams();
    const platform = findPlatformByIdentifier(identifier);
    const workSpace = WorkSpaceManager.getActiveWorkSpace();
    const [fetchingAccounts, setFetchingAccounts] = useState(true);
    const [fetchError, setFetchError] = useState();
    const [connectedAccounts, setConnectedAccounts] = useState();
    const [processing, setProcessing] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [popupBlocked, setPopupBlocked] = useState(false);
    const popUpUnblockHint = recommendUrlToUnblockPopup();
    const disconnectedPlatforms = useSelector(getDisconnectedPlatforms);
    const { addSocketIOEventListener, removeSocketIOEventListener } = useSocket();
    
    const observeWindow = (win) => {
        let isPopupBlocked = hasPopupBlocker(win);
        if (isPopupBlocked) {
            setPopupBlocked(true);
            return;
        }
        let eventHandler = (data) => {
            setProcessing(false);
            if (!win.closed) {
                win.close();
            }
            if (data['oauth_status'] === "cancelled") {
                toast.error("Integration Cancelled", { theme: 'colored' });
            } else {
                toast.success(data['message'], { theme: 'colored' });
                fetchConnectedAccounts();
            }
            removeSocketIOEventListener(`${workSpace['_id']}auth`, eventHandler);
        };
        addSocketIOEventListener(`${workSpace['_id']}auth`, eventHandler);
        const timer = setInterval(() => {
            if (win.closed) {
                setProcessing(false);
                clearInterval(timer);
                removeSocketIOEventListener(`${workSpace['_id']}auth`, eventHandler);
            }
        }, 500);
    }

    const fetchConnectedAccounts = () => {
        setFetchingAccounts(true);
        setFetchError(null);
        APIService.fetchConnectedAccounts(workSpace['_id'], platform.target ?? platform.identifier, (response, error) => {
            setFetchingAccounts(false);
            if (error) {
                setFetchError(error);
                return;
            }
            let { data } = response;
            setFetchError(null);
            if (data && data.length > 0) {
                data = data.map((account, index) => {
                    let { user_id, user_name, user_photo, ...platform } = findPlatformByIdentifier(account.target);
                    let newAccount = { ...account, ...platform };
                    return newAccount;
                })
            }
            setConnectedAccounts(data);
        });
    }

    useEffect(() => {
        if (platform) {
            if (fetchingAccounts) {
                fetchConnectedAccounts();
            }
        }
    }, []);

    useEffect(() => {
        if ((disconnectedPlatforms ?? []).length > 0) {
            let disconnectedPlatformsCopy = [...disconnectedPlatforms];
            let issuedPlatformWithIdentifier = disconnectedPlatformsCopy.filter(issueItem => issueItem.identifier !== platform.identifier);
            if (issuedPlatformWithIdentifier.length > 0) {
                dispatch(setDisconnectedPlatforms(issuedPlatformWithIdentifier));
            } else {
                dispatch(setDisconnectedPlatforms(null));
            }
        }
    }, [connectedAccounts]);

    const addNewAccount = () => {
        if (processing) {
            return;
        }
        setProcessing(true);
        let platformIdentifier = platform.identifier;
        let platformName = platform.name;
        switch (platformIdentifier) {
            case PlatformIdentifier.FACEBOOK:
                connectFacebook(workSpace, platformName, platformIdentifier, (response, error) => {
                    setProcessing(false);
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    toast.success(response, { theme: 'colored' });
                    fetchConnectedAccounts();
                });
                break;
            case PlatformIdentifier.INSTAGRAM:
                connectInstagram(workSpace, platformName, platformIdentifier, (response, error) => {
                    setProcessing(false);
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    toast.success(response, { theme: 'colored' });
                    fetchConnectedAccounts();
                });
                break;
            case PlatformIdentifier.TWITTER:
                connectTwitter(workSpace, PlatformIdentifier.TWITTER, (response, error) => {
                    setProcessing(false);
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            case PlatformIdentifier.YOUTUBE:
                connectYoutube(workSpace, PlatformIdentifier.YOUTUBE, (response, error) => {
                    if (error) {
                        setProcessing(false);
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            case PlatformIdentifier.TIKTOK:
                connectTikTok(workSpace, PlatformIdentifier.TIKTOK, (response, error) => {
                    if (error) {
                        setProcessing(false);
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            case PlatformIdentifier.LINKED_IN:
                connectLinkedIn(workSpace, PlatformIdentifier.LINKED_IN, (response, error) => {
                    if (error) {
                        setProcessing(false);
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            case PlatformIdentifier.PINTEREST:
                connectPinterest(workSpace, PlatformIdentifier.PINTEREST, (response, error) => {
                    if (error) {
                        setProcessing(false);
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            case PlatformIdentifier.GOOGLE_MY_BUSINESS:
                connectGMB(workSpace, PlatformIdentifier.GOOGLE_MY_BUSINESS, (response, error) => {
                    if (error) {
                        setProcessing(false);
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            case PlatformIdentifier.REDDIT:
                connectReddit(workSpace, PlatformIdentifier.REDDIT, (response, error) => {
                    if (error) {
                        setProcessing(false);
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            case PlatformIdentifier.WORDPRESS:
                connectWordpress(workSpace, PlatformIdentifier.WORDPRESS, (response, error) => {
                    console.log('trying to connect wordpress')
                    if (error) {
                        setProcessing(false);
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let win = window.open(response.data, "_blank");
                    observeWindow(win);
                });
                break;
            default:
                break;
        }
    }

    const handleTelegramResponse = response => {
        let { id: userId, photo_url: userPhoto, username } = response;
        connectTelegram(workSpace, userId, username, userPhoto, PlatformIdentifier.TELEGRAM, (response, error) => {
            if (error) {
                setProcessing(false);
                toast.error(error, { theme: 'colored' });
                return;
            }
            setProcessing(false);
            setShowCustom(false);
            fetchConnectedAccounts();
        });
    }

    const customView = () => {
        if (platform.identifier === PlatformIdentifier.TELEGRAM) {
            return (
                <TelegramLoginButton
                    dataOnauth={handleTelegramResponse}
                    botName="postly_bot"
                />
            );
        }
        return ' ';
    }

    const NewAccountConnectionView = React.forwardRef((props, ref) => {
        if (!WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_AND_MANAGE_PLATFORMS)) {
            return null;
        }
        return (
            <Card
                onClick={(e) => {
                    if (platform.identifier === PlatformIdentifier.TELEGRAM) {
                        setShowCustom(true);
                    } else {
                        addNewAccount();
                    }
                }}
                elevation={5}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'center',
                    minHeight: 180,
                    minWidth: 180,
                    cursor: 'pointer',
                }}
            >
                {
                    showCustom ? customView() :
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Button
                                variant='light'
                                className='rounded-circle'
                                style={{
                                    width: 60,
                                    height: 60,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                {
                                    processing ? <Spinner
                                        as={'span'}
                                        role="status"
                                        animation='border'
                                        size="sm"
                                        aria-hidden="true"
                                    />
                                        : <FontAwesomeIcon
                                            icon={'plus'}
                                            size={'2x'}
                                        />
                                }
                            </Button>
                            <span
                                style={{
                                    fontSize: 14,
                                    padding: 20,
                                    fontWeight: 'bold',
                                    maxWidth: 180,
                                    textAlign: 'center'
                                }}
                                textAlign={'center'}>
                                {processing ? 'Please wait...' : `Add another ${platform.name} account`}
                            </span>
                        </div>
                }
            </Card>
        );
    });

    return (
        <div>
            {
                !platform ?
                    <span>Nothing here</span>
                    :
                    <Flex
                        direction={'column'}
                        justifyContent={'center'}>
                        <ConfirmModal
                            open={popupBlocked}
                            title={"Popup Blocker Detected"}
                            message={`Your browser is blocking our pop-ups. As a result you will not be able to connect your <b>${platform.name}</b> account.\n\n${popUpUnblockHint ? popUpUnblockHint : 'Kindly disable pop-up blocking'} and try again.\n\nYou can re-enable pop-up blocking after connecting ${platform.name} and other relevant platforms.\n\nThank you`}
                            confirmText={"OK"}
                            cancelText={"CLOSE"}
                            onConfirm={() => {
                                setPopupBlocked(false);
                                setProcessing(false);
                            }}
                            onCancel={() => {
                                setPopupBlocked(false);
                                setProcessing(false);
                            }}
                        />
                        <PageHeader title={
                            <Flex
                                alignItems={'center'}
                                className={'gap-2'}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: platform.color,
                                    alignSelf: 'center',
                                    background: '#dde7f5',
                                    marginRight: 5,
                                    width: 40,
                                    height: 40,
                                    borderRadius: 100,
                                    justifyContent: 'center'
                                }}
                                >
                                    {
                                        platform.svg ? <img
                                            src={platform.svg}
                                            alt={platform.name}
                                            style={{
                                                width: 25,
                                                height: 25
                                            }} /> :
                                            <FontAwesomeIcon
                                                icon={platform.icon}
                                                style={{
                                                    color: platform.color,
                                                    fontSize: 25,
                                                }}
                                            />
                                    }
                                </div>
                                <h5>{platform.name}</h5>
                            </Flex>
                        }
                            titleTag="h5"
                            className="mb-3">
                            <p className="fs--1 mt-1">{(connectedAccounts ?? []).length > 0 ? "Your connected accounts" : "Connect your account(s) to start posting"}</p>
                        </PageHeader>
                        {
                            fetchingAccounts &&
                            <Flex
                                alignItems={'center'}
                                justifyContent={'center'}
                                className={'vh-100'}
                            >
                                <Spinner animation='border' size={40} />
                            </Flex>
                        }
                        {
                            (connectedAccounts ?? []).length < 1 && !fetchingAccounts && WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_AND_MANAGE_PLATFORMS) &&
                            <PlatformConnectView
                                processing={processing}
                                platform={platform}
                                connectAccount={addNewAccount}
                                handleTelegramResponse={handleTelegramResponse}
                            />
                        }
                        {
                            (connectedAccounts ?? []).length > 0 &&
                            <Flex
                                direction={'column'}
                                justifyContent={'center'}>
                                <Flex
                                    alignItems={'center'}
                                    className={'gap-3 pt-2'}
                                    justifyContent={'center'}
                                    wrap={'wrap'}>
                                    {
                                        connectedAccounts.map((account, index) => {
                                            return <div
                                                key={account._id}
                                                id={account._id}>
                                                <ConnectedAccount
                                                    workSpace={workSpace}
                                                    account={account}
                                                />
                                            </div>
                                        })
                                    }
                                    <NewAccountConnectionView />
                                </Flex>
                                {
                                    platform.identifier === PlatformIdentifier.TELEGRAM &&
                                    <InfoBar info={`Your Telegram account(s) <b>MUST</b> have a <b>username</b> for Postly to Work!`} />
                                }
                            </Flex>
                        }
                        {
                            fetchError &&
                            <RichInfoBar
                                title={'Oh snap!'}
                                message={fetchError}
                                variant={'danger'}
                                action={fetchConnectedAccounts}
                                actionMessage={"Try Again"}
                            />
                        }
                    </Flex >
            }
        </div >
    );
}