import { useState } from "react";
import WorkSpaceManager from "utils/workspace_manager";
import Flex from "components/common/Flex";
import FreePageHeader from "components/common/FreePageHeader";
import { Button } from "react-bootstrap";
import Avatar from "components/common/Avatar";
import ConfirmModal from "components/common/ConfirmModal";
import APIService from "http/api_service";
import { toast } from 'react-toastify';
import { PlatformIdentifier } from "constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConnectedPlatformInfoHeader = (props) => {

    const workSpace = WorkSpaceManager.getActiveWorkSpace();
    const platform = props.platform ?? {};
    const [disconnectMsg, setDisconnectMsg] = useState("DISCONNECT & REVOKE ACCESS");
    const [displayDisconnectPrompt, setDisplayDisconnectPrompt] = useState(false);
    const [disconnectTitle, setDisconnectTitle] = useState();
    const [disconnectPrompt, setDisconnectPrompt] = useState();

    const finalizePlatformRemoval = () => {
        setDisconnectMsg("Disconnecting...");
        APIService.removeWorkSpacePlatform(workSpace['_id'], platform.identifier, platform.user_id, (response, error) => {
            setDisconnectMsg("DISCONNECT & REVOKE ACCESS");
            if (error) {
                setDisconnectMsg("DISCONNECT & REVOKE ACCESS");
                toast.error(error, { theme: 'colored' });
                return;
            }
            let message = response['message'];
            toast.success(message, { theme: 'colored' });
            window.history.back();
        });
    }

    const removePlatform = () => {
        if (platform.identifier === PlatformIdentifier.YOUTUBE) {
            setDisconnectTitle("IMPORTANT!");
            setDisconnectPrompt(`Please note that when you disconnect this YouTube account, we will also disconnect any <b>Google My Business</b> account(s) connected to the <b>${platform.user_name}</b> gmail account`);
            setDisplayDisconnectPrompt(true);
            return;
        }
        if (platform.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
            setDisconnectTitle("IMPORTANT!");
            setDisconnectPrompt(`Please note that when you disconnect this Google My Business account, we will also disconnect any <b>YouTube</b> account(s) connected to the <b>${platform.user_name}</b> gmail account`);
            setDisplayDisconnectPrompt(true);
            return;
        }
        finalizePlatformRemoval();
    }

    return (
        <FreePageHeader
            titleTag="h5"
            className="mb-3">
            <Flex
                alignItems={'center'}
                className={'gap-2 flex-1'}
                wrap={'wrap'}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: platform.color,
                    alignSelf: 'center',
                    background: '#dde7f5',
                    marginRight: 5,
                    width: 40,
                    height: 40,
                    borderRadius: 100,
                    justifyContent: 'center',
                    position: 'relative'
                }}
                >
                    <Avatar
                        src={platform.user_photo}
                        alt={platform.user_name}
                        style={{
                            width: 25,
                            height: 25
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: -2,
                            right: 5,
                            background: '#e5f7ff',
                            borderRadius: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2
                        }}>
                        <FontAwesomeIcon
                            icon={platform.icon}
                            variant={'light'}
                            style={{
                                width: 10,
                                height: 10
                            }}
                        />
                    </div>
                </div>
                <h5>{platform.user_name}</h5>
                <div style={{ flex: 1 }}></div>
                <Button
                    onClick={() => {
                        removePlatform();
                    }}
                    className="fs--1 rounded-pill"
                    variant={'falcon-danger'}>{disconnectMsg}
                </Button>
            </Flex>
            <p className="fs--1 mt-1">{`Your connected ${platform.name} account`}</p>
            <ConfirmModal
                open={displayDisconnectPrompt}
                onClose={() => {
                    setDisplayDisconnectPrompt(false);
                }}
                title={disconnectTitle}
                message={disconnectPrompt}
                cancelText='CANCEL'
                confirmText='CONTINUE'
                onCancel={() => {
                    setDisplayDisconnectPrompt(false);
                }}
                onConfirm={() => {
                    setDisplayDisconnectPrompt(false);
                    finalizePlatformRemoval();
                }}
            />
        </FreePageHeader >
    )
}
export default ConnectedPlatformInfoHeader;