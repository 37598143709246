import UserProfile from './profile/UserProfile';
import { Card, Col, Nav, Row, Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import PricingDefault from '../billing/PricingDefault';
import { useEffect, useState } from 'react';
import StringUtils from 'utils/string';
import { jwtDecode } from 'jwt-decode';
import { useQuery } from 'utils/url';
import RichInfoBar from 'components/common/RichInfoBar';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../constants';
import CreditsPage from './credits';
import InvoicePage from './invoice';

function AccountPage() {

    const [activeTab, setActiveTab] = useState("profile");
    const [canContinueWithWorkspaceCreation, setCanContinueWithWorkspaceCreation] = useState(false);
    const [headsUp, setHeadsUp] = useState();
    const [openHeadsUp, setOpenHeadsUp] = useState(false);
    const navigate = useNavigate();

    const query = useQuery();

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let accountTab = params.get("t");
        if (accountTab) {
            setActiveTab(accountTab)
        }
    }, []);

    useEffect(() => {
        let meta = query.get("meta");
        if (meta) {
            try {
                let metaData = jwtDecode(meta);
                if (metaData) {
                    setActiveTab("billing");
                    stealthUrl("billing");
                    if (metaData.success_message) {
                        setHeadsUp(metaData.success_message);
                        if (metaData.continue_with_workspace_creation) {
                            setCanContinueWithWorkspaceCreation(true);
                        }
                        setOpenHeadsUp(true);
                    }
                }
            } catch (e) {
                //Malformed jwt
            }
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    const stealthUrl = (value) => {
        let currentLocation = window.location.href;
        let queryParam = StringUtils.substringAfterLast(window.location.href, "?t=");
        if (queryParam === window.location.href) {
            queryParam = '';
        }
        let newLocation;
        if (currentLocation.includes("?t=")) {
            newLocation = currentLocation.replace(queryParam, value);
        } else {
            newLocation = `${currentLocation}?t=${value}`;
        }
        window.history.replaceState("", "", newLocation);
    }

    return (
        <>
            <Tab.Container
                id="account-tabs"
                activeKey={activeTab}
                onSelect={(e) => {
                    setActiveTab(e);
                    stealthUrl(e);
                }}>
                <Card className='p-3 pb-0'>
                    <Col>
                        <h5>Profile</h5>
                        <p className="fs--1 mt-1">Manage your Reachable profile.</p>
                    </Col>
                    <Row sm={1} className={'px-3'}>
                        <Nav variant="tabs" style={{ border: 'none' }} className="flex-row">
                            <Nav.Item>
                                <Nav.Link eventKey="profile">Profile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="billing">Billing</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="redeem_code">Redeem Code</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="invoice_history">Invoice History</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Row>
                </Card>
                <Tab.Content className='mt-3'>
                    <Tab.Pane eventKey="profile">
                        <UserProfile />
                    </Tab.Pane>
                    <Tab.Pane eventKey="billing">
                        <PricingDefault />
                    </Tab.Pane>
                    <Tab.Pane eventKey="redeem_code">
                        <CreditsPage />
                    </Tab.Pane>
                    <Tab.Pane eventKey="invoice_history">
                        <InvoicePage />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <Modal show={openHeadsUp}
                onHide={() => setOpenHeadsUp(false)}
                onExit={() => setOpenHeadsUp(false)}
                centered
                size='lg'>
                <Modal.Body>
                    <RichInfoBar
                        title={"Great!"}
                        message={headsUp}
                        actionMessage={canContinueWithWorkspaceCreation ? "Continue with Workspace creation" : "Okay"}
                        action={() => {
                            if (canContinueWithWorkspaceCreation) {
                                navigate(RoutePaths.NEW_WORKSPACE);
                            } else {
                                setOpenHeadsUp(false);
                            }
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AccountPage;