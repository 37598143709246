import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Avatar from 'components/common/Avatar';
import APIService from 'http/api_service';
import WorkSpaceManager from 'utils/workspace_manager';
import { PlatformIdentifier } from 'constants';

const NavbarVerticalMenuItem = ({ route }) => {
  const workSpace = WorkSpaceManager.getActiveWorkSpace()
  const [platform_status, set_platform_status] = useState(null);
  const [platforms, setPlatforms] = useState()

  const getAllPlatformsInWorkspace = () => {
    APIService.fetchWorkSpacePlatforms(workSpace['_id'], (response, error) => {
      if (error) {
        return;
      }
      if (response) {
        let platformsInWorkSpace = response['data'];
        if (platformsInWorkSpace) {
          platformsInWorkSpace = platformsInWorkSpace.filter(x => x.user_id);
          setPlatforms(platformsInWorkSpace);
        }
      }

    });
  }

  const getConnectionExpiryTime = (staticDateToExpire, is_platform_connection_active, defaultExpireWindow) => {
    if (!staticDateToExpire) {
      return false;
    }
    if (is_platform_connection_active) {
      return true;
    } else {
      return false
    }
    // const today = new Date();
    // let staticExpireDate = (new Date() - new Date(staticDateToExpire));
    // staticExpireDate = staticExpireDate ? parseInt((staticExpireDate / (1000 * 60 * 60 * 24))) : 0;
    // if (defaultExpireWindow > staticExpireDate) {
    //   return true;
    // }
    // if (defaultExpireWindow < staticExpireDate) {
    //   return false
    // }

  }

  const checkForPlatformConnectionIssues = async () => {
    const _platforms = [...platforms];
    let foundIssue = false;
    for (let index = 0; index < _platforms.length; index++) {
      const element = _platforms[index];
      if (element?.identifier === PlatformIdentifier.LINKED_IN) {
        if (element?.last_possible_connection_date) {
          const isActive = getConnectionExpiryTime(element?.updated_at
            , element?.is_platform_connection_active, element?.defaultExpireWindow);
          if (!isActive) {
            foundIssue = true;
            set_platform_status('non-active')
            return;
          }
        }
      }
      if (element?.identifier === PlatformIdentifier.FACEBOOK) {
        if (element?.last_possible_connection_date) {
          const isActive = getConnectionExpiryTime(element?.updated_at
            , element?.is_platform_connection_active, element?.defaultExpireWindow);
          if (!isActive) {
            foundIssue = true;
            set_platform_status('non-active')
            return;
          }
        }
      }
      if (element?.identifier === PlatformIdentifier.TIKTOK) {
        if (element?.last_possible_connection_date) {
          const isActive = getConnectionExpiryTime(element?.updated_at
            , element?.is_platform_connection_active, element?.defaultExpireWindow);
          if (!isActive) {
            foundIssue = true;
            set_platform_status('non-active')
            return;
          }
        }
      }
      if (!foundIssue) {
        set_platform_status('active');
        return;
      }
    }

    if (!foundIssue) {
      set_platform_status('active');
      return;
    }
  }

  useEffect(() => {
    route?.name === 'Platforms' && getAllPlatformsInWorkspace();
  }, [])

  useEffect(() => {
    if (platforms?.length) {
      checkForPlatformConnectionIssues()
    }
  }, [platforms])


  return (
    <Flex alignItems="center" style={{ position: 'relative' }}>
      {
        route.icon && (
          <span className="nav-link-icon">
            <FontAwesomeIcon icon={route.icon} />
          </span>
        )
      }
      {
        !route.icon && !route.avatar && route.iconifyName && (
          <Avatar name={route.name} size={'s'} />
        )
      }
      {
        route.avatar &&
        <Avatar src={route.avatar} size={'s'} />
      }
      <span
        className="nav-link-text ps-1">{route.name}</span>
      {route.badge && (
        <SoftBadge pill bg={route.badge.type} className="ms-2">
          {route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
