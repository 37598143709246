import React, { useContext, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import ActiveWorkspace from 'components/common/ActiveWorkspace';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes from 'routes/routes';
import { capitalize } from 'helpers/utils';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
// import Footer from '../footer';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import UserManager from 'utils/user_manager';

const NavbarVertical = ({
  at,
  width,
  height,
  fontSize = 22,
  className,
  textClass,
  clickable=true,
  name = 'Reachable',
  lightColor = '#fff',
  ...rest
}) => {
  const { config } = useContext(AppContext);

  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      // style={{ backgroundColor: config.isDark ? '#121e2d' : lightColor, marginLeft: -15, marginBottom: 30 }}
      expand={navbarBreakPoint}
      elevation={0}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      <Flex alignItems="center" className="mb-3">
        <ToggleButton className="mr-10" />
        <ActiveWorkspace at="navbar-vertical" width={35} height={27}/>
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        
        style={{
          boxShadow: "none",
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : 'none'
        }}
      >
        <div className="navbar-vertical-content scrollbar" >
          <Nav className="flex-column" as="ul">
            {
              routes.filter((x) => {
                let keep = true;
                if (x.visibleToOnlyDemoAccount && !UserManager.isDemoAccount()) {
                  keep = false;
                }
                return keep;
              }).map(route => (
                // <></>
                <Fragment key={route.label}>
                  {!route.labelDisable && (
                    <NavbarLabel label={capitalize(route.label)} />
                  )}
                  <NavbarVerticalMenu
                    canViewMore={route.canViewMore}
                    routes={route.children.filter((x) => {
                      let keep = true;
                      if (x.visibleToOnlyDemoAccount && !UserManager.isDemoAccount()) {
                        keep = false;
                      }
                      return keep;
                    })}
                  />
                </Fragment>
              ))
            }
          </Nav>
          <>
            {
              navbarPosition === 'combo' && (
                <div className={`d-${topNavbarBreakpoint}-none`}>
                  <div className="navbar-vertical-divider">
                    <hr className="navbar-vertical-hr my-2" />
                  </div>
                  <Nav navbar>
                    <NavbarTopDropDownMenus />
                  </Nav>
                </div>
              )
            }
            <div style={{ height: 18, marginBottom: 20 }}><hr/></div>
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Logo width={18} fontSize={20} />
            </Flex>
          </>
          <div style={{ height: 18 }}></div>
          {/* {
            !isNavbarVerticalCollapsed &&
            <Footer alignment={'center'} />
          } */}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
