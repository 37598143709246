export default class HttpErrorHandler {
    static spitHttpErrorMsg(e) {
        if (e !== undefined && e !== null) {
            if (e.hasOwnProperty('response')) {
                let response = e.response;
                if (response !== undefined && response !== null) {
                    if (response.hasOwnProperty('data')) {
                        let responseData = response['data'];
                        if (responseData.hasOwnProperty('message')) {
                            return responseData['message'];
                        }
                    }
                }
            }
        }
        console.log(e);
        return navigator.onLine ? 'Sorry, something went wrong. Please try again later' : "Oops! An error occurred!.\nEnsure your data connection is still right.";
    }
}