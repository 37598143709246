import React, { useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthLoginButton from './AuthLoginButton';
import { FacebookIcon } from './facebook_icon';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../constants';
import { auth } from '../../firebase';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import UserManager from 'utils/user_manager';

const SocialAuthButtons = ({ disabled = false }) => {

  const navigate = useNavigate();
  const [googleAuthStarted, setGoogleAuthStarted] = useState(false);
  const [facebookAuthStarted, setFacebookAuthStarted] = useState(false);

  const finalizeSocialAccountCreation = (response, error) => {
    setFacebookAuthStarted(false);
    setGoogleAuthStarted(false);
    if (error) {
      toast.error(error, {
        theme: 'colored'
      });
      return;
    }
    let userData = response['data'];
    let altUsers = UserManager.getLoggedInAltUsers();
    localStorage.clear();
    UserManager.setLoggedInUser(userData);
    UserManager.setAltableUsers(altUsers);
    setTimeout(() => {
      navigate(RoutePaths.REDIRECT);
    }, 200);
  }

  const handleGoogleSignIn = () => {
    setGoogleAuthStarted(true);
    let provider = new auth.GoogleAuthProvider();
    auth()
      .signInWithPopup(provider)
      .then((result) => {
        let user = result.user;
        let credential = result.credential;
        let token = credential.accessToken;
        let { email, photoURL, providerData } = user;
        let userDisplayName = providerData[0].displayName;
        APIService.createGoogleAccount(email, userDisplayName, photoURL, token, (response, error) => {
          finalizeSocialAccountCreation(response, error);
        });
      }).catch((error) => {
        setGoogleAuthStarted(false);
        let errorMessage = error.message;
        if (errorMessage.toLowerCase().includes("An account already exists with the same email address".toLowerCase())) {
          errorMessage = "Your account is connected to Facebook - use the Facebook button to log in";
        }
        toast.error(errorMessage, {
          theme: 'colored'
        });
      });
  }

  const handleFacebookSignIn = () => {
    setFacebookAuthStarted(true);
    let provider = new auth.FacebookAuthProvider();
    auth()
      .signInWithPopup(provider)
      .then((result) => {
        let user = result.user;
        let credential = result.credential;
        let token = credential.accessToken;
        let { email, photoURL, providerData } = user;
        let userDisplayName = providerData[0].displayName;
        let uid = providerData[0].uid;
        APIService.createFacebookAccount(email, userDisplayName, photoURL, token, uid, (response, error) => {
          finalizeSocialAccountCreation(response, error);
        });
      }).catch((error) => {
        setFacebookAuthStarted(false);
        let errorMessage = error.message;
        if (errorMessage.toLowerCase().includes("An account already exists with the same email address".toLowerCase())) {
          errorMessage = "Your account is connected to Google - use the Google button to log in";
        }
        toast.error(errorMessage, {
          theme: 'colored'
        });
      });
  }

  return (
    <Form.Group className="mb-0">
      {/* <Row>
        <Col sm={6} className="pe-sm-1">
          <AuthLoginButton
            label={googleAuthStarted ? "Please wait..." : "Google"}
            type={"light"}
            color={'rgb(29 28 29)'}
            disabled={googleAuthStarted || disabled}
            className="mt-2 w-100"
            onClick={() => {
              handleGoogleSignIn();
            }}
          />
        </Col>
        <Col sm={6} className="ps-sm-1">
          <AuthLoginButton
            label={facebookAuthStarted ? "Please wait..." : "Facebook"}
            type={"light"}
            icon={<FacebookIcon />}
            disabled={facebookAuthStarted || disabled}
            className="mt-2 w-100"
            onClick={() => {
              handleFacebookSignIn();
            }}
          />
        </Col>
      </Row> */}
      <Button
        disabled={disabled}
        variant=""
        className="btn-outline-secondary mt-2 w-100 h-39"
        onClick={() => {
          navigate(RoutePaths.PASSWORD_LESS_SIGN_IN);
        }}
      >
        <FontAwesomeIcon
          size="xs"
          icon={'magic'}
          transform="grow-8"
          className="me-2"
          style={{ width: 10, height: 10 }}
        />{' '}
        Passwordless
      </Button>
    </Form.Group>
  )
}
export default SocialAuthButtons;