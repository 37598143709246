/* eslint-disable no-unused-vars */
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, Form,
  Row,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import UserManager from 'utils/user_manager';
import WorkspaceStats from '../../../utils/workspace_stats';
// import BulkWorkspaceNamesEditor from './BulkWorkspaceNamesEditor';

const EmailTableHeader = ({
  workSpaces,
  bulkWorkspacesUpdateHandler,
  workSpaceUpdateHandler,
  selectedRowIds,
  ...others
}) => {

  const loggedInUser = UserManager.getLoggedInUser();
  const [processing, setProcessing] = useState(false);
  const [operationKey, setOperationKey] = useState();
  const [operationMessage, setOperationMessage] = useState();
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [openArchivePrompt, setOpenArchivePrompt] = useState(false);
  const [openWorkspaceNamesRenamePrompt, setOpenWorkspaceNamesRenamePrompt] = useState(false);
  const [workSpaceStats, setWorkSpaceStats] = useState({});

  const initWorkSpaceStats = async () => {
    try {
      let stats = await WorkspaceStats.getSync();
      setWorkSpaceStats(stats);
    } catch (e) { }
  }

  useEffect(() => {
    initWorkSpaceStats();
  }, []);

  const selectedWorkSpaces = (ids = []) => {
    let selectedWorkSpaces = [];
    for (let index of ids) {
      let workSpace = workSpaces[index];
      if (workSpace?.owner_id === loggedInUser._id) {
        selectedWorkSpaces.push(workSpace);
      }
    }
    return selectedWorkSpaces;
  }

  const allArchived = () => selectedWorkSpaces(Object.keys(selectedRowIds)).every(x => x.archived);

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <div>
          <h5 className="fs-0 mb-0 text-nowrap py-2 ">All Email Accounts</h5>
        </div>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={(e) => {
                let selectedValue = e.target.value;
                setOperationKey(selectedValue);
                if (selectedValue === 'rename') {
                  setOpenWorkspaceNamesRenamePrompt(true);
                }
              }}>
              <option>Pick an Action</option>
              <option value="delete">Delete</option>
              <option value="archive">{allArchived() ? "Unarchive" : "Archive"}</option>
              <option value="rename">Rename</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              disabled={processing}
              onClick={() => {
                if (operationKey === "delete") {
                  setOpenDeletePrompt(true);
                } else if (operationKey === "archive") {
                  setOpenArchivePrompt(true);
                } else if (operationKey === 'rename') {
                  setOpenWorkspaceNamesRenamePrompt(true);
                } else {
                  toast.error("Please pick an option", { theme: 'colored' });
                }
              }}
            >
              {processing ? operationMessage : "Apply"}
            </Button>
          </div>
        ) : (
          <div
            id="actions"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10
            }}>
            <AdvanceTableSearchBox table {...others} />
            {/* <OverlayTrigger
              placement='left'
              overlay={
                <Tooltip id="bulk-workspaces-overlay-trigger">
                  Connect new email account
                </Tooltip>
              }>
              <Button
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => {
                  dispatch(searchWorkSpace(false));
                  navigate(RoutePaths.NEW_WORKSPACE);
                }}
              >
                <div style={{
                  display: 'flex',
                  gap: 1, alignItems: 'center'
                }}>
                  <FontAwesomeIcon icon={'plus'} />
                  <span className="d-none d-sm-inline-block ms-1">New</span>
                </div>
              </Button>
            </OverlayTrigger> */}
          </div>
        )}
      </Col>
    </Row>
  );
};

EmailTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default EmailTableHeader;
