import APIService from "http/api_service"
import { useState } from "react"
import { useQuery } from "utils/url"
import { useNavigate } from "react-router-dom"

const UnsubscribeSequence = () => {
  const [isLoading, setIsLoading] = useState(false)
  const query = useQuery()
  const listIds = query.get("listIds")
  const leadId = query.get("leadId")
  const navigate = useNavigate()

  const handleUnsub = async () => {
    setIsLoading(true)
    try {
      await APIService.unsubscribeFromEmail(listIds, leadId)
      navigate("/")
      // console.log("res", res)
    } catch (error) {
      //alert an error occured
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div>
      <h1>Are you sure you want to unsubscribe from this email?</h1>
      <button disabled={isLoading} onClick={handleUnsub}>
        Yes
      </button>
      <button>No</button>
    </div>
  )
}

export default UnsubscribeSequence
