import { faBackward, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Flex from "components/common/Flex"
import APIService from "http/api_service"
import { useEffect, useRef, useState } from "react"
import {
  Button,
  Col,
  Form,
  Modal,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap"
import Select from "react-select"
import { toast } from "react-toastify"
import useSWR from "swr"
import WorkSpaceManager from "utils/workspace_manager"
import EmailComposeEditor from "./EmailComposeEditor"
import { getSequencesFromRedux } from "../../../../../redux/slices/sequence_slice"
import { useSelector } from 'react-redux'

const fetcher = (id) =>
  APIService.fetchConnectedEmailAccounts(id).then((res) => res.data)

export default function ThreadEmailComposer(props) {
  const { testEmailRecipient, step, thread, handleSave } = props
  const sequenceFromRedux = useSelector(getSequencesFromRedux)
  const [selectedImportChoice, setSelectedImportChoice] = useState()
  const [showDrawer, setShowDrawer] = useState(props.open)
  const [activeWorkspace] = useState(WorkSpaceManager.getActiveWorkSpace())
  const inputRecipientEmailRef = useRef(testEmailRecipient);
  const [updateOngoing, setUpdateOngoing] = useState(false)

  const [sendingTestEmail, setSendingTestEmail] = useState(false)
  const [openEmailTestDialog, setOpenEmailTestDialog] = useState(false)
  const [emailAccounts, setEmailAccounts] = useState(
    props.sequence?.sendingEmailAccounts || [],
  )
  const { data } = useSWR(
    activeWorkspace?._id ? activeWorkspace._id : null,
    fetcher,
  )

  const sendTestEmail = async () => {
    setSendingTestEmail(true)
    let { message, ok } = await props.testEmailHandler(inputRecipientEmailRef.current.value || testEmailRecipient)
    setSendingTestEmail(false)
    if (ok) {
      toast.success(message, { theme: "colored" })
    } else {
      toast.error(message, { theme: "colored" })
    }
  }

  const handleClose = () => {
    setShowDrawer(!showDrawer)
    props.closeDrawer()
  }

  useEffect(() => {
    setShowDrawer(props.open)
  }, [props.open])

  const emailOptions = data?.map((item) => ({
    value: item._id,
    label: item.email,
  }))

  return (
    <>
      <Offcanvas
        style={{
          width: "60%",
        }}
        fullscreen={true}
        scroll={true}
        backdrop={false}
        placement="end"
        show={showDrawer}
        onHide={handleClose}
      >
        <Offcanvas.Header className="border-bottom border-200">
          <Row className="flex-between-center g-0">
            <Col xs="auto">
              <Button
                onClick={() => {
                  if (selectedImportChoice) {
                    setSelectedImportChoice(undefined)
                  } else {
                    handleClose()
                  }
                }}
                icon={faBackward}
                variant={"link"}
                style={{
                  textDecoration: "none",
                }}
              >
                <Flex alignItems={"center"} className={"gap-2"}>
                  <FontAwesomeIcon color={"#B0BEC5"} icon={faTimes} />
                  <span style={{ color: "grey" }}>Close</span>
                </Flex>
              </Button>
            </Col>
          </Row>
          <Offcanvas.Title>
            <div className="d-md-flex justify-content-between py-3 py-md-2">
              <Row className="g-md-0">
                <Col xs="auto" className="d-md-flex">
                  {/* <h6 className="fs--2 d-flex align-items-center text-700 me-md-3 mt-2">
                  Mailbox: {loggedInUser.email}
                </h6> */}
                  <Col xs="auto">
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className={
                        "ms-sm-0  ms-md-3 d-flex align-items-center mt-2 mt-md-0"
                      }
                      style={{ boxShadow: "none", border: "1px solid #d8e2ef" }}
                      //   onClick={sendTestEmail}
                      onClick={() => {
                        setOpenEmailTestDialog(true)
                      }}
                      disabled={sendingTestEmail}
                    >
                      {sendingTestEmail && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mx-2"
                        />
                      )}
                      <span>
                        {sendingTestEmail ? "Sending..." : " Send test email"}
                      </span>
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => {
                        // if (props.onSave) {
                        // props.onSave()
                        // }
                        handleSave()
                      }}
                      size="sm"
                      variant="primary"
                      iconClassName="me-2"
                      className={
                        "ms-sm-0  ms-md-2 d-flex align-items-center me-md-3 mt-2 mt-md-0"
                      }
                    >
                      {
                        updateOngoing ? 'Saving...' : 'Save changes'
                      }
                    </Button>
                  </Col>
                </Col>
              </Row>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ overflowY: "hidden" }}>
          <Flex justifyContent={"center"}>
            <EmailComposeEditor
              subject={props?.thread?.title}
              body={props?.thread?.body}
              updateHandler={props?.updateHandler}
              sequence={props?.sequence}
            />
          </Flex>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={openEmailTestDialog}
        onHide={() => setOpenEmailTestDialog(false)}
        onExit={() => setOpenEmailTestDialog(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Send test email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="email">
            <Form.Label>From</Form.Label>
            <Select
              closeMenuOnSelect={true}
              defaultValue={emailOptions}
              value={emailAccounts}
              options={emailOptions}
              onChange={(e) => {
                setEmailAccounts(e)
              }}
            />
            {/* <Form.Control.Feedback type="invalid">11</Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group className="mb-3 mt-4" controlId="email">
            <Form.Label>To</Form.Label>
            <Form.Control
              ref={inputRecipientEmailRef}
              defaultValue={testEmailRecipient}
              type="text"
              // value={!inputRecipientEmailRef.current?.value ? testEmailRecipient : inputRecipientEmailRef.current?.value}
              name="email"
            // onChange={(e) => setTestEmailRecipient(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            onClick={sendTestEmail}
            variant="primary"
            className="px-4 mx-0"
            disabled={sendingTestEmail}
          >
            {sendingTestEmail && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mx-2"
              />
            )}
            <span>{sendingTestEmail ? "Sending..." : " Send test email"}</span>
          </Button>
          <div style={{ width: 10 }}></div>
          <Button variant="text" onClick={() => setOpenEmailTestDialog(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
