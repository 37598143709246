export const version = '3.6.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'combo', // combo vertical top
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent' //light card transparent inverted vibrant
};

const configSettings = { version, navbarBreakPoint, topNavbarBreakpoint, settings };
export default configSettings;