import { Strings, RolePermissions } from "../constants";
import UserManager from './user_manager';

export default class WorkSpaceManager {

    static getActiveWorkSpace() {
        try {
            let activeWorkSpace = window.localStorage.getItem(Strings.ACTIVE_WORKSPACE);
            if (activeWorkSpace) {
                return JSON.parse(activeWorkSpace);
            }
            return null;
        } catch (e) {
            return null;
        }
    }

    static setActiveWorkSpace(workSpace) {
        window.localStorage.setItem(Strings.ACTIVE_WORKSPACE, JSON.stringify(workSpace));
    }

    static deleteActiveWorkSpace() {
        window.localStorage.removeItem(Strings.ACTIVE_WORKSPACE);
    }

    static cacheWorkSpaces(workSpaces) {
        let workSpacesString = JSON.stringify(workSpaces);
        window.localStorage.setItem(Strings.CACHED_WORKSPACES, workSpacesString);
    }

    static getCachedWorkSpaces() {
        try {
            let workSpacesString = window.localStorage.getItem(Strings.CACHED_WORKSPACES);
            if (workSpacesString) {
                return JSON.parse(workSpacesString);
            }
            return null;
        } catch (e) {
            return null;
        }
    }

    static userCanPerformActionInWorkspace(workSpace, actionName) {
        let userRoleInWorkspace = workSpace.role.toUpperCase();
        return RolePermissions[userRoleInWorkspace][actionName] ?? false;
    }

    static isLoggedInUserOwnerOfWorkspace() {
        let loggedInUser = UserManager.getLoggedInUser();
        let activeWorkSpace = this.getActiveWorkSpace();
        return loggedInUser._id === activeWorkSpace.owner_id;
    }

    static getRightBarSectionsPositions() {
        let workSpace = WorkSpaceManager.getActiveWorkSpace();
        if (workSpace) {
            let positions = localStorage.getItem(`${workSpace._id}_right_bar_sections_positions`);
            try {
                if (positions) {
                    return JSON.parse(positions);
                }
            } catch (e) {
                return {
                    TRENDS: 0,
                    EVENTS: 1
                }
            }
        }
        return {
            TRENDS: 0,
            EVENTS: 1
        }
    }

    static getPanelVisibilityChoices() {
        let workSpace = WorkSpaceManager.getActiveWorkSpace();
        if (workSpace) {
            return workSpace.panel_visibility_choices;
        }
        return {
            TRENDS: true,
            EVENTS: true
        }
    }

    static getPostEmailNotificationChoices() {
        let workSpace = WorkSpaceManager.getActiveWorkSpace();
        if (workSpace) {
            return workSpace.post_email_notification_choices;
        }
        return {
            PUBLISHED: true,
            FAILED: true
        }
    }

    static updateRightBarSectionsPositions(positions) {
        let workSpace = WorkSpaceManager.getActiveWorkSpace();
        localStorage.setItem(`${workSpace._id}_right_bar_sections_positions`, JSON.stringify(positions));
    }

    static addToDemoPlatforms(pageOrAccount) {
        let existingDemoPlatforms = localStorage.getItem("existing_demo_platforms");
        if (existingDemoPlatforms) {
            existingDemoPlatforms = JSON.parse(existingDemoPlatforms);
        } else {
            existingDemoPlatforms = [];
        }
        existingDemoPlatforms.push(pageOrAccount);
        localStorage.setItem('existing_demo_platforms', JSON.stringify(existingDemoPlatforms));
        return existingDemoPlatforms;
    }

    static getRandomDemoPageOrAccount() {
        let existingDemoPlatforms = localStorage.getItem("existing_demo_platforms");
        if (existingDemoPlatforms) {
            existingDemoPlatforms = JSON.parse(existingDemoPlatforms);
            let randomItem = existingDemoPlatforms[Math.floor(Math.random() * existingDemoPlatforms.length - 1)];
            return randomItem;
        }
        return null;
    }

    static isFreeWorkspace() {
        let workSpace = WorkSpaceManager.getActiveWorkSpace();
        let activePlan = workSpace.active_plans;
        return activePlan?.Name === "Free" || activePlan?.Key === "Free";
    }

}