import WorkSpaceManager from "../utils/workspace_manager";

export const RoutePaths = {
  DEFAULT: "/",
  REDIRECT: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  REGISTER: "/register",
  NOT_FOUND: "/404",
  BROKEN_PAGE: "/500",
  FORGOT_PASSWORD: "/forgotpwd",
  RESET_PASSWORD: "/reset_pwd",
  INVITATIONS: "/invitations",
  PASSWORD_LESS_SIGN_IN: "/passwordless_login",
  VERIFY_NEW_EMAIL: "/verify_new_email",
  SWITCH_WORKSPACE: "/switch",
  WHITELABEL_SETUP: "/white-label-setup",
  IMAGE_EDITOR_FULLSCREEN: "/image_editor",
  VIDEO_EDITOR_FULLSCREEN: "/video_editor",
  MANDATORY_PLAN_UPGRADE: '/pick-a-plan',
  LTD_PURCHASE_PAGE: '/purchase-ltd',
  CONNECT_EMAIL_ACCOUNT: '/account/connect',
  CREATE_NEW_SEQUENCE: "/sequence/create",

  get DASHBOARD() {
    return `${this.WORKSPACE}/dashboard`;
  },
  get EMAIL_ACCOUNTS() {
    return `${this.WORKSPACE}/accounts`;
  },
  get CONTACT_VERIFICATION() {
    return `${this.WORKSPACE}/contacts-verification`;
  },
  get SIGNALS() {
    return `${this.WORKSPACE}/signals`;
  },
  get SEQUENCES() {
    return `${this.WORKSPACE}/sequences`;
  },
  get EMAILMARKETINGCAMPAIGN() {
    return `${this.WORKSPACE}/email-marketing-campaign`;
  },
  get LISTS() {
    return `${this.WORKSPACE}/lists`;
  },
  get LEADS() {
    return `${this.WORKSPACE}/leads`;
  },
  get KNOWLEDGE_BASE() {
    return `${this.WORKSPACE}/knowledge-base`;
  },
  get ACTIVATE_WORKSPACE() {
    const activeWorkSpace = WorkSpaceManager.getActiveWorkSpace();
    return activeWorkSpace ? activeWorkSpace["_id"] : "workspace";
  },
  get WORKSPACE() {
    return `/${this.ACTIVATE_WORKSPACE}`;
  },
  get IDEA_SPACE() {
    return `${this.WORKSPACE}/ideas-space`;
  },
  get GIGS_AND_CYCLES() {
    return `${this.WORKSPACE}/gigs-and-circles`;
  },
  get UNIFIED_INBOX() {
    return `${this.WORKSPACE}/unified-inbox`;
  },
  get REDEEM_CODES() {
    return `${this.WORKSPACE}/credits`;
  },
  get INVOICE_HISTORY() {
    return `${this.WORKSPACE}/invoice_history`;
  },
  get POSTS() {
    return `${this.WORKSPACE}/posts`;
  },
  get PLATFORMS() {
    return `${this.WORKSPACE}/platforms`;
  },
  get PLANNER() {
    return `${this.WORKSPACE}/content-planner`;
  },
  get BULK_WORKSPACES() {
    return `${this.WORKSPACE}/bulk-workspaces`;
  },
  get TEAM() {
    return `${this.WORKSPACE}/team`;
  },
  get EMAIL_SENDING_SCHEDULE() {
    return `${this.WORKSPACE}/email_sending_schedule`;
  },
  get BETA_FEATURES() {
    return `${this.WORKSPACE}/beta`;
  },
  get ANALYTICS() {
    return `${this.WORKSPACE}/analytics`;
  },
  get ACCOUNT() {
    return `${this.WORKSPACE}/account`;
  },
  get PROFILE() {
    return `${this.WORKSPACE}/account/profile`;
  },
  get REFER() {
    return `${this.WORKSPACE}/refer`;
  },
  get CREDIT() {
    return `${this.WORKSPACE}/account/credits`;
  },
  get BILLING() {
    return `${this.WORKSPACE}/billing`;
  },
  get WORKSPACE_SETTINGS() {
    return `${this.WORKSPACE}/settings`;
  },
  get NEW_WORKSPACE() {
    return `/new_workspace`;
  },
  get SUPPORT() {
    return `${this.WORKSPACE}/support`;
  },
  get NOTIFICATIONS() {
    return `${this.WORKSPACE}/notifications`;
  },
  get AI_WRITER() {
    return `${this.WORKSPACE}/ai_writer`;
  },
  get AI_ART() {
    return `${this.WORKSPACE}/ai_art`;
  },
  get IMAGE_EDITOR() {
    return `${this.WORKSPACE}/image-editor`;
  },
  get VIDEO_EDITOR() {
    return `${this.WORKSPACE}/video-editor`;
  },
  get LINK_SHORTENER() {
    return `${this.WORKSPACE}/link-shortener`;
  },
  get OUTLOOK_CALLBACK_URL() {
    return `${this.WORKSPACE}/auth/outlook/callback`;
  },
  get LINKEDIN_ACCOUNTS() {
    return `${this.WORKSPACE}/accounts/linkedin`;
  }
};

export const Strings = {
  ACTIVE_WORKSPACE: "active_workspace_data",
  LOGGED_IN_USER: "_reachable_logged_in_user_",
  LOGGED_IN_ALT_USERS: "_reachable_logged_in_alt_users_",
  CACHED_WORKSPACES: "_reachable_cached_workspaces_list",
  API_KEY: "X-API-Key", //Important, Only use this as request key not for reading
  LOGGED_IN: "logged_in",
  CHATS: `_reachable_chats`,
  ALTABLE_USER: '_altable_reachable_user',
  POST_CATEGORY_MAPPINGS: "_reachable_post_category_mappings_"
};

export const KeyCodes = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  M: 77,
};

export const AuthMethod = {
  PASSWORD_LESS: "password_less",
  PASSWORD: "password",
  GOOGLE_AUTH: "google_auth",
  FACEBOOK_AUTH: "facebook_auth",
};

export const PlatformIdentifier = {
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  INSTAGRAM: "instagram",
  YOUTUBE: "youtube",
  TIKTOK: "tiktok",
  GOOGLE_MY_BUSINESS: "google_my_business",
  LINKED_IN: "linked_in",
  PINTEREST: "pinterest",
  REDDIT: "reddit",
  TELEGRAM: "telegram",
  GOOGLE: "google",
  WHATSAPP: "whatsapp",
  WORDPRESS: "wordpress"
};

export const TermsAndPolicies = {
  PRIVACY_POLICY: "https://reachable.to/privacy",
  TERMS_OF_SERVICE: "https://reachable.to/terms",
  COOKIE_POLICY: "https://www.reachable.ai/docs/cookie-policy",
  DISCLAIMER: "https://reachable.ai/docs/disclaimer",
  REFUND_POLICY: "https://reachable.ai/docs/refund-policy",
  GDPR_COMPLIANCE: "https://reachable.ai/docs/gdpr-compliance",
};

export const SupportLink = "https://reachable.ai/support/";
export const FAQLink = "https://reachable.ai/faq";
export const BulkContentUploadKnowlegdeLink =
  "https://reachable.ai/bulk-upload";
export const BulkEmailAccountsUploadKnowlegdeLink = 'https://docs.google.com/spreadsheets/d/1P8IhrXXyPE6IZL6F4IeiVsNybZbwTOGgDKfVHYjjQSE/edit?usp=sharing';
export const BulkCSVsUploadKnowlegdeLink = "https://docs.google.com/spreadsheets/d/1DIfNw4IjiWE47p2SqRsiAUKUSBpnG9jxXkB6MDwWHgs/edit?usp=sharing";
export const BulkWorkspacesUploadKnowlegdeLink =
  "https://reachable.ai/bulk-workspaces-upload";
export const LearnMoreLink = "https://postly.ai";
export const SocketEvents = {
  AUTHENTICATION_STATE_CHANGED_EVENT: "auth_state_change_event",
};

export const WorkSpaceRole = {
  OWNER: "owner",
  ADMIN: "admin",
  MEMBER: "member",
  EXTERNAL: "external",
  EDITOR: "editor",
  REVIEWER: "reviewer",
  APPROVER: "approver",
  VIEWER: "viewer",
};

export const PlatformData = {
  APP_NAME: "Postly",
  MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 1e9,
  TIKTOK_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 50e6,
  TWITTER_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 512e6,
  INSTAGRAM_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 100e6,
  LINKEDIN_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE: 2e8,
};

export const PanelNames = {
  PostAudience: "PostAudience",
};

export const WorkSpaceContentApprovalWorkFlow = {
  SCHEDULE_POSTS_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE:
    "schedule_posts_after_approval",
  LOCK_CONTENT_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE:
    "lock_content_after_approval",
  ACTIVE_WORKSPACE_APPROVAL_OPTION: "approval_option",
  ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL:
    "allow_owners_post_go_through_approval",
  TURN_OFF_ALL_POSTS_AND_CAMPAIGNS: "turn_off_all_posts_and_campaigns",
};

export const PERMISSION_EDIT_WORKSPACE_LOGO = "edit_workspace_logo";
export const PERMISSION_EDIT_POST = "edit_post_in_workspace";
export const PERMISSION_DELETE_WORKSPACE = "delete_workspace";
export const PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW =
  "update_workspace_approval_workflow";
export const PERMISSION_APPROVE_POST = "approve_post_in_workspace";
export const PERMISSION_PAY_FOR_WORKSPACE = "pay_for_workspace";
export const PERMISSION_UPDATE_WORKSPACE_NAME = "update_workspace_name";
export const PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE =
  "remove_member_from_workspace";
export const PERMISSION_INVITE_TEAM_MEMBER = "invite_team_members";
export const PERMISSION_SEE_PAGE_SUBTITLE = "see_page_subtitle";
export const PERMISSION_CREATE_MORE_WORKSPACE = "create_more_workspace";
export const PERMISSION_VIEW_AND_MANAGE_PLATFORMS = "view_and_manage_platforms";
export const PERMISSION_VIEW_WORKSPACE_BILLING_DETAILS =
  "view_workspace_billing_details";
export const PERMISSION_ADD_CUSTOM_EVENT_TO_WORKSPACE =
  "add_custom_event_to_workspace";
export const PERMISSION_VIEW_PENDING_INVITES = "view_pending_invites";
export const PERMISSION_TURN_OFF_ALL_WORKSPACE_POSTS_AND_CAMPAIGNS =
  "turn_off_all_posts_and_campaigns";
export const PERMISSION_CREATE_NEW_WORKSPACES = "create_new_workspaces";

export const RolePermissions = {
  OWNER: {
    [PERMISSION_EDIT_WORKSPACE_LOGO]: true,
    [PERMISSION_EDIT_POST]: true,
    [PERMISSION_DELETE_WORKSPACE]: true,
    [PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW]: true,
    [PERMISSION_APPROVE_POST]: true,
    [PERMISSION_PAY_FOR_WORKSPACE]: true,
    [PERMISSION_UPDATE_WORKSPACE_NAME]: true,
    [PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE]: true,
    [PERMISSION_INVITE_TEAM_MEMBER]: true,
    [PERMISSION_SEE_PAGE_SUBTITLE]: true,
    [PERMISSION_CREATE_MORE_WORKSPACE]: true,
    [PERMISSION_VIEW_AND_MANAGE_PLATFORMS]: true,
    [PERMISSION_VIEW_WORKSPACE_BILLING_DETAILS]: true,
    [PERMISSION_ADD_CUSTOM_EVENT_TO_WORKSPACE]: true,
    [PERMISSION_VIEW_PENDING_INVITES]: true,
    [PERMISSION_TURN_OFF_ALL_WORKSPACE_POSTS_AND_CAMPAIGNS]: true,
    [PERMISSION_CREATE_NEW_WORKSPACES]: true,
  },
  APPROVER: {
    [PERMISSION_EDIT_WORKSPACE_LOGO]: false,
    [PERMISSION_EDIT_POST]: true,
    [PERMISSION_DELETE_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW]: false,
    [PERMISSION_APPROVE_POST]: true,
    [PERMISSION_PAY_FOR_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_NAME]: false,
    [PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE]: false,
    [PERMISSION_VIEW_AND_MANAGE_PLATFORMS]: true,
  },
  EDITOR: {
    [PERMISSION_EDIT_WORKSPACE_LOGO]: false,
    [PERMISSION_EDIT_POST]: true,
    [PERMISSION_DELETE_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW]: false,
    [PERMISSION_APPROVE_POST]: false,
    [PERMISSION_PAY_FOR_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_NAME]: false,
    [PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE]: false,
  },
  REVIEWER: {
    [PERMISSION_EDIT_WORKSPACE_LOGO]: false,
    [PERMISSION_EDIT_POST]: true,
    [PERMISSION_DELETE_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_APPROVAL_WORKFLOW]: false,
    [PERMISSION_APPROVE_POST]: false,
    [PERMISSION_PAY_FOR_WORKSPACE]: false,
    [PERMISSION_UPDATE_WORKSPACE_NAME]: false,
    [PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE]: false,
  },
};

export const AccountTabs = {
  PROFILE: "profile",
  BILLING: "billing",
  REDEEM_CODE: "redeem_code",
  INVOICE_HISTORY: "invoice_history",
};
