export const MATCH_URL_YOUTUBE = /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//
export const MATCH_URL_SOUNDCLOUD = /(?:soundcloud\.com|snd\.sc)\/[^.]+$/
export const MATCH_URL_VIMEO = /vimeo\.com\/.+/
export const MATCH_URL_FACEBOOK = /^https?:\/\/(www\.)?facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/
export const MATCH_URL_FACEBOOK_WATCH = /^https?:\/\/fb\.watch\/.+$/
export const MATCH_URL_STREAMABLE = /streamable\.com\/([a-z0-9]+)$/
export const MATCH_URL_WISTIA = /(?:wistia\.(?:com|net)|wi\.st)\/(?:medias|embed)\/(?:iframe\/)?(.*)$/
export const MATCH_URL_TWITCH_VIDEO = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/
export const MATCH_URL_TWITCH_CHANNEL = /(?:www\.|go\.)?twitch\.tv\/([a-zA-Z0-9_]+)($|\?)/
export const MATCH_URL_DAILYMOTION = /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/
export const MATCH_URL_MIXCLOUD = /mixcloud\.com\/([^/]+\/[^/]+)/
export const MATCH_URL_VIDYARD = /vidyard.com\/(?:watch\/)?([a-zA-Z0-9-]+)/
export const MATCH_URL_KALTURA = /^https?:\/\/[a-zA-Z]+\.kaltura.(com|org)\/p\/([0-9]+)\/sp\/([0-9]+)00\/embedIframeJs\/uiconf_id\/([0-9]+)\/partner_id\/([0-9]+)(.*)entry_id.([a-zA-Z0-9-_]+)$/

export const AUDIO_EXTENSIONS = /\.(m4a|mp4a|mpga|mp2|mp2a|mp3|m2a|m3a|wav|weba|aac|oga|spx)($|\?)/i
export const VIDEO_EXTENSIONS = /\.(mp4|og[gv]|webm|mov|m4v)(#t=[,\d+]+)?($|\?)/i
export const PHOTO_EXTENSIONS = /\.(jpg|jpeg|png|jfif|gif|pjpeg|apng|avif|pjp|svg|webp|bmp|ico|cur|tif|tiff)(#t=[,\d+]+)?($|\?)/i
export const HLS_EXTENSIONS = /\.(m3u8)($|\?)/i
export const DASH_EXTENSIONS = /\.(mpd)($|\?)/i
export const FLV_EXTENSIONS = /\.(flv)($|\?)/i

export function isBlobUrl(url) {
    return /^blob:/.test(url)
}

export const isVideo = url => {
    if (url instanceof Array) {
        for (const item of url) {
            if (typeof item === 'string' && isVideo(item)) {
                return true
            }
            if (isVideo(item.src)) {
                return true
            }
        }
        return false
    }
    if (isBlobUrl(url)) {
        return true
    }
    return (
        AUDIO_EXTENSIONS.test(url) ||
        VIDEO_EXTENSIONS.test(url) ||
        HLS_EXTENSIONS.test(url) ||
        DASH_EXTENSIONS.test(url) ||
        FLV_EXTENSIONS.test(url) ||
        MATCH_URL_SOUNDCLOUD.test(url) ||
        MATCH_URL_YOUTUBE.test(url) ||
        MATCH_URL_VIMEO.test(url) ||
        MATCH_URL_FACEBOOK.test(url) ||
        MATCH_URL_STREAMABLE.test(url) ||
        MATCH_URL_WISTIA.test(url) ||
        MATCH_URL_TWITCH_VIDEO.test(url) ||
        MATCH_URL_TWITCH_CHANNEL.test(url) ||
        MATCH_URL_DAILYMOTION.test(url) ||
        MATCH_URL_MIXCLOUD.test(url) ||
        MATCH_URL_VIDYARD.test(url) ||
        MATCH_URL_KALTURA.test(url)
    )
}

export const getFileType = (mediaUrl) => {
    try {
        if (isPhoto(mediaUrl)) {
            return 'PHOTO';
        }
        if (isVideo(mediaUrl)) {
            return 'VIDEO';
        }
        return 'UNKNOWN'
    } catch (e) {
        return 'UNKNOWN';
    }
}

export const isPhoto = url => {
    if (url instanceof Array) {
        for (const item of url) {
            if (typeof item === 'string' && isPhoto(item)) {
                return true
            }
            if (isPhoto(item.src)) {
                return true
            }
        }
        return false
    }
    if (isBlobUrl(url)) {
        return true
    }
    if (url?.includes('images.unsplash')) {
        return true;
    }
    return (PHOTO_EXTENSIONS.test(url))
}
