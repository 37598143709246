import React, { useState, useEffect, useContext } from 'react';
import APIService from '../../../http/api_service';
import { toast } from 'react-toastify';
import TeamMemberInviteView from './add_team_member_view';
import MemberRoleUpdateView from './member_role_update_view';
import UserManager from '../../../utils/user_manager';
import WorkSpaceManager from '../../../utils/workspace_manager';
import DataTable, { createTheme } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import {
    updateActivePageName,
    updateActivePageSubTitle,
    setOpenJobTitleDialog,
    getTitleInWorkSpace,
    setCanFetchTeam,
    refreshTeam,
    setTeam,
    getTeam,
    setSearchHint,
    getSearchTerm
} from '../../../redux/slices/workspaceslice';
import StringUtils from '../../../utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import {
    PERMISSION_INVITE_TEAM_MEMBER, PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE,
    PERMISSION_SEE_PAGE_SUBTITLE,
    PERMISSION_VIEW_PENDING_INVITES,
    RoutePaths,
    WorkSpaceRole
} from '../../../constants';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import CircularButton from 'components/common/circularbutton';
import CopyLinkButton from 'components/common/copylinkbutton';
import Flex from 'components/common/Flex';
import FreePageHeader from 'components/common/FreePageHeader';
import ConfirmModal from 'components/common/ConfirmModal';
import { useNavigate } from 'react-router-dom';

export default function TeamPage(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workSpace = WorkSpaceManager.getActiveWorkSpace();
    const loggedInUser = UserManager.getLoggedInUser() ?? {};
    const team = useSelector(getTeam);
    const [pendingInvitations, setPendingInvitations] = useState();
    const [tempTeam, setTempTeam] = useState();
    const [membersInRemoval, setMembersInRemoval] = useState([]);
    const canFetchTeam = useSelector(refreshTeam);
    const [canFetchPendingInvites, setCanFetchPendingInvites] = useState(true);
    const jobTitle = useSelector(getTitleInWorkSpace);
    const [editableMemberData, setEditableMemberData] = useState();
    const [openMemberEditDialog, setOpenMemberEditDialog] = useState();
    const [openTeamMemberInviteDialog, setOpenTeamMemberInviteDialog] = useState(false);
    const [openInvitationCancelDialog, setOpenInvitationCancelDialog] = useState(false);
    const [invitationToCancel, setInvitationToCancel] = useState();
    const { config } = useContext(AppContext);
    const searchTerm = useSelector(getSearchTerm);
    const [memberToRemove, setMemberToRemove] = useState();
    const [openMemberRemovalDialog, setOpenMemberRemovalDialog] = useState(false);

    useEffect(() => {
        dispatch(setSearchHint("Search team members"));
    }, []);

    useEffect(() => {
        handleSearchChangeListener(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        if (jobTitle) {
            dispatch(setCanFetchTeam(true));
        }
    }, [jobTitle]);

    const userHasOwnerPrivileges = () => {
        return workSpace.role.toLowerCase() === WorkSpaceRole.OWNER.toLowerCase();
    }

    const roleUpdateDoneHandler = () => {
        dispatch(setCanFetchTeam(true));
    }

    const RoleUpdateView = React.forwardRef((props, ref) => {

        const { excludedRole, memberId, memberEmail } = props;

        return (
            <CircularButton
                onClick={() => {
                    setEditableMemberData({ memberId, excludedRole, memberEmail });
                    setOpenMemberEditDialog(true);
                }}>
                <FontAwesomeIcon icon={faPen}
                    id={'member_role_updater'}
                />
            </CircularButton>
        );
    });

    useEffect(() => {
        if (config.isDark) {
            createTheme('solarized', {
                text: {
                    primary: '#9da9bb',
                },
                background: {
                    default: '',
                },
            }, 'light');
        }
    }, [config]);

    const pendingInvitationsColumn = [
        {
            name: 'Email',
            selector: row => row.owner_email,
            sortable: true
        },
        {
            name: 'Role',
            selector: row => row.data.toString(),
            sortable: true
        },
        {
            name: 'Invited Since',
            selector: row => new Date(row.created_at).toDateString(),
            sortable: true
        },
        {
            name: 'Invitation Link',
            selector: row => <CopyLinkButton
                size='sm'
                linkToCopy={`https://app.reachable.to/invitations?token=${encodeURIComponent(row.token)}`}
                variant={'outline-info'}
            />
        },
        {
            cell: (row) =>
                <div style={{ display: 'flex' }}>
                    <div>
                        <Button
                            size='sm'
                            variant={'outline-danger'}
                            onClick={() => {
                                setInvitationToCancel(row);
                                setOpenInvitationCancelDialog(true);
                            }}>CANCEL INVITE
                        </Button>
                        <span>{' '}</span>
                    </div>
                    <span>{' '}</span>
                </div>
        }
    ];

    const teamMemberColumns = [
        {
            name: 'Member',
            selector: row => row.member,
            sortable: true,
        },
        {
            name: "Title",
            selector: row => <div style={{ display: 'flex', alignItems: 'center', gap: 10, color: config.isDark ? '#9da9bb' : 'black' }}><span>{row.title}</span></div>,
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}><span>{StringUtils.capitalizeString(row.role)}</span>{userHasOwnerPrivileges() && row.user_id._id !== loggedInUser._id && <RoleUpdateView memberId={row.user_id._id} excludedRole={row.role} memberEmail={row.email} />}</div>,
            sortable: true,
        },
        {
            cell: (row) => {
                if (row.user_id._id !== loggedInUser._id) {
                    if (row.user_id._id !== workSpace.owner_id) {
                        if (WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_REMOVE_MEMBER_FROM_WORKSPACE)) {
                            return (
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <Button
                                            size='sm'
                                            isWorking={membersInRemoval.includes(row.user_id._id)}
                                            variant={'outline-danger'}
                                            onClick={() => {
                                                setMemberToRemove(row);
                                                setOpenMemberRemovalDialog(true);
                                            }}>Remove
                                        </Button>
                                        <span>{' '}</span>
                                    </div>
                                    <span>{' '}</span>
                                </div>
                            );
                        }
                    }
                } else {
                    if (row.user_id._id !== workSpace.owner_id) {
                        return (
                            <Button
                                size='sm'
                                isWorking={membersInRemoval.includes(row.user_id._id)}
                                variant={'danger'}
                                onClick={() => {
                                    setMemberToRemove(row);
                                    setOpenMemberRemovalDialog(true);
                                }}>Leave
                            </Button>
                        );
                    }
                }
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    const themedGreen = () => {
        if (!config.isDark) {
            return 'green';
        }
        return '#C8E6C9';
    }

    const themedRed = () => {
        if (!config.isDark) {
            return 'red';
        }
        return '#EF9A9A';
    }

    const TeamPrivilegesTable = React.forwardRef((props, ref) => {
        let teamPrivilegesColumn = [
            {
                name: 'Privilege',
                grow: 2,
                selector: row => row.name
            },
            {
                name: 'Owner',
                selector: row => <FontAwesomeIcon icon={row.owner ? faCheck : null}
                    style={{
                        color: row.owner ? themedGreen() : themedRed()
                    }}
                />
            },
            {
                name: 'Approver',
                selector: row => <FontAwesomeIcon icon={row.approver ? faCheck : null}
                    style={{
                        color: row.approver ? themedGreen() : themedRed()
                    }}
                />
            },
            {
                name: 'Reviewer',
                selector: row => <FontAwesomeIcon icon={row.reviewer ? faCheck : null}
                    style={{
                        color: row.reviewer ? themedGreen() : themedRed()
                    }}
                />
            },
            {
                name: 'Editor',
                selector: row => <FontAwesomeIcon icon={row.editor ? faCheck : null}
                    style={{
                        color: row.editor ? themedGreen() : themedRed()
                    }}
                />
            }
        ];
        let teamPrivilegesRows = [
            {
                name: 'Draft Posts',
                owner: true,
                approver: true,
                reviewer: true,
                editor: true
            },
            {
                name: 'Review Posts',
                owner: true,
                approver: true,
                reviewer: true,
                editor: false
            },
            {
                name: 'Approve Posts',
                owner: true,
                approver: true,
                reviewer: false,
                editor: false
            },
            {
                name: 'Create more Workspaces',
                owner: true,
                approver: false,
                reviewer: false,
                editor: false
            },
            {
                name: 'Invite Team members or Clients to Workspace',
                owner: true,
                approver: false,
                reviewer: false,
                editor: false
            },
            {
                name: 'View and manage Platforms',
                owner: true,
                approver: true,
                reviewer: false,
                editor: false
            },
            {
                name: 'Modify Approval Workflow process',
                owner: true,
                approver: false,
                reviewer: false,
                editor: false
            },
            {
                name: 'Remove Team Members',
                owner: true,
                approver: false,
                reviewer: false,
                editor: false
            }
        ];
        return (
            <DataTable
                columns={teamPrivilegesColumn}
                data={teamPrivilegesRows}
                theme={config.isDark ? "solarized" : null}
            />
        );
    });

    const TeamMembersTable = React.forwardRef((props, ref) => {
        return (
            <DataTable
                columns={teamMemberColumns}
                data={team}
                theme={config.isDark ? "solarized" : null}
            />
        )
    })

    const PendingInvitesTable = React.forwardRef((props, ref) => {
        return (
            <DataTable
                columns={pendingInvitationsColumn}
                data={pendingInvitations}
                theme={config.isDark ? "solarized" : null}
            />
        )
    })

    useEffect(() => {
        dispatch(updateActivePageName("Team"));
        if (WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_SEE_PAGE_SUBTITLE)) {
            dispatch(updateActivePageSubTitle("Manage your team here. Invite members to your workspace."));
        } else {
            dispatch(updateActivePageSubTitle(""));
        }
        return () => {
            dispatch(updateActivePageName(" "));
            dispatch(updateActivePageSubTitle(null));
        }
    }, []);

    useEffect(() => {
        if (canFetchTeam) {
            APIService.fetchTeam(workSpace['_id'], (response, error) => {
                dispatch(setCanFetchTeam(false));
                if (error) {
                    toast.error(error, { theme: 'colored' });
                    return;
                }
                let data = response['data'];
                data = data.filter(x => x.user_id);
                data = data.map((x) => {
                    if (!x.user_id.first_name || !x.user_id.last_name) {
                        x.user_id.first_name = 'Reachable';
                        x.user_id.last_name = 'User'
                    }
                    let name = `${x.user_id.first_name} ${x.user_id.last_name}`;
                    x.member = name;
                    if (x.user_id._id === loggedInUser['_id']) {
                        let member = x.member;
                        member = `${member}(You)`;
                        x.member = member;
                    }
                    x.email = x.user_id.email;
                    return x;
                });
                dispatch(setTeam(data));
                setTempTeam(data);
            });
        }
    }, [canFetchTeam]);

    useEffect(() => {
        if (canFetchPendingInvites) {
            if (WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_PENDING_INVITES)) {
                APIService.fetchPendingTeamInvites(workSpace._id, (response, error) => {
                    setCanFetchPendingInvites(false);
                    if (error) {
                        toast.error(error, { theme: 'colored' });
                        return;
                    }
                    let { data } = response;
                    setPendingInvitations(data);
                });
            }
        }
    }, [canFetchPendingInvites]);

    const handleSearchChangeListener = (searchString) => {
        let membersInSearch = tempTeam?.filter((member) => member.user_id.first_name.toLowerCase().includes(searchString.toLowerCase()) || member.user_id.last_name.toLowerCase().includes(searchString.toLowerCase()) || member.user_id.email.toLowerCase().includes(searchString.toLowerCase()));
        if (searchString) {
            dispatch(setTeam([...membersInSearch]));
        } else {
            if (tempTeam) {
                dispatch(setTeam([...tempTeam]));
            }
        }
    }

    const handleInvitationCancel = (invitation) => {
        toast.info("Cancelling invitation", { theme: 'colored' });
        APIService.cancelPendingTeamInvite(workSpace._id, invitation.token, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            toast.success("Invitation Cancelled successfully", { theme: 'colored' });
            setCanFetchPendingInvites(true);
        });
    }

    const handleMemberRemoval = (member) => {
        toast.info(member?.user_id?._id === loggedInUser?._id ? "Leaving workspace..." : "Removing member", { theme: 'colored' });
        setMembersInRemoval((prevMembersInRemoval) => {
            if (!prevMembersInRemoval.includes(member.user_id.id)) {
                prevMembersInRemoval.push(member.user_id.id);
            }
            return [...prevMembersInRemoval];
        });
        APIService.removeTeamMember(workSpace['_id'], member.user_id._id, (response, error) => {
            setMembersInRemoval((prevMembersInRemoval) => {
                if (prevMembersInRemoval.includes(member.user_id.id)) {
                    let indexOfMemberInRemoval = prevMembersInRemoval.indexOf(member.user_id.id);
                    if (indexOfMemberInRemoval !== -1) {
                        prevMembersInRemoval.splice(indexOfMemberInRemoval, 1);
                    }
                }
                return [...prevMembersInRemoval];
            });
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let message = response['message'];
            if (member.user_id._id === loggedInUser._id) {
                WorkSpaceManager.deleteActiveWorkSpace();
                navigate(RoutePaths.REDIRECT);
                window.location.reload();
                return;
            } else {
                toast.success(message, { theme: 'colored' });
                dispatch(setCanFetchTeam(true));
            }
        });
    }

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                marginTop: 10,
                margin: 20,
                flexDirection: 'column'
            }}>
            {
                !team &&
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '70vh',
                    width: '100%'
                }}>
                    <Spinner animation={'border'} size={30} />
                </div>
            }
            {
                team &&
                <div
                    style={{
                        width: '100%',
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5
                        }}>
                        <FreePageHeader
                            titleTag="h5"
                            className="mb-3">
                            <Flex
                                className={'gap-2'}
                                alignItems={'center'}>
                                <h5>Team</h5>
                                <div style={{ flex: 1 }}></div>
                                {
                                    WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_INVITE_TEAM_MEMBER) &&
                                    <Button
                                        onClick={() => setOpenTeamMemberInviteDialog(true)}
                                        id={'add_member'}
                                        variant="outline-info"
                                    >Invite Team Member
                                    </Button>
                                }
                            </Flex>
                            <p className="fs--1 mt-1">Reachable is fun with teams, invite team members to your workspace</p>
                        </FreePageHeader>
                        <Card>
                            <TeamMembersTable />
                        </Card>
                    </div>
                </div>
            }
            {
                (pendingInvitations ?? []).length > 0 && WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_PENDING_INVITES) &&
                <div
                    style={{
                        marginTop: 40,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <span style={{
                        fontSize: 20,
                        marginBottom: 10,
                    }}>Pending Invitations
                    </span>
                    <Card>
                        <PendingInvitesTable />
                    </Card>
                </div>
            }
            {/* {
                team &&
                <div style={{
                    marginTop: 40,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span style={{
                        fontSize: 20
                    }}>Role Privileges</span>
                    <Card>
                        <TeamPrivilegesTable />
                    </Card>
                </div>
            } */}
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={openMemberEditDialog}
                size={'lg'}
                onExit={() => setOpenMemberEditDialog(false)}
                onHide={() => setOpenMemberEditDialog(false)}
            >
                <Modal.Body>
                    <MemberRoleUpdateView
                        excludedRole={editableMemberData?.excludedRole}
                        memberId={editableMemberData?.memberId}
                        memberEmail={editableMemberData?.memberEmail}
                        updateDoneCallback={roleUpdateDoneHandler}
                        cancelHandler={() => {
                            setOpenMemberEditDialog(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={openTeamMemberInviteDialog}
                size={'lg'}
                onExit={() => {
                    setOpenTeamMemberInviteDialog(false);
                }}
                onHide={() => setOpenTeamMemberInviteDialog(false)}
            >
                <Modal.Body>
                    <TeamMemberInviteView
                        cancelHandler={(success) => {
                            if (success) {
                                setCanFetchPendingInvites(true);
                            }
                            setOpenTeamMemberInviteDialog(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <ConfirmModal
                open={openInvitationCancelDialog}
                title="Cancel Invitation"
                message={`Are you sure about cancelling the invitation sent to <b>${invitationToCancel?.owner_email}</b>?`}
                cancelText="NO"
                confirmText="YES, CANCEL"
                onCancel={() => {
                    setOpenInvitationCancelDialog(false);
                }}
                onConfirm={() => {
                    setOpenInvitationCancelDialog(false);
                    handleInvitationCancel(invitationToCancel);
                }}
            />
            <ConfirmModal
                open={openMemberRemovalDialog}
                title={memberToRemove?.user_id?._id === loggedInUser?._id ? "Leave Workspace" : "Remove Member"}
                message={memberToRemove?.user_id?._id === loggedInUser?._id ? "Are you sure about leaving this workspace?" : `Are you sure about removing <b>${memberToRemove?.email}</b> from this Workspace?`}
                cancelText="NO"
                confirmText={memberToRemove?.user_id?._id === loggedInUser?._id ? "YES, LEAVE" : "YES, REMOVE"}
                onCancel={() => {
                    setOpenMemberRemovalDialog(false);
                }}
                onConfirm={() => {
                    setOpenMemberRemovalDialog(false);
                    handleMemberRemoval(memberToRemove);
                }}
            />
        </div>
    );
}