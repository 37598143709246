import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Dropdown,
  Col,
  Form,
  Card,
  Spinner,
  Offcanvas,
  OverlayTrigger,
  Row, 
  Tooltip,
} from "react-bootstrap"
import APIService from "http/api_service"
import WorkSpaceManager from "utils/workspace_manager"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { faListCheck } from "@fortawesome/free-solid-svg-icons"
import StringUtils from "utils/string"
import ReactLoading from "react-loading"
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper"
import AdvanceTable from "components/common/advance-table/AdvanceTable"
import Flex from "components/common/Flex"
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox"
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter"
import CardDropdown from "components/common/CardDropdown"
import { RoutePaths } from "../../../constants"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import EmailAccountSettings from "./EmailAccountSettings"
import { faEnvelope, faCog, faTrash } from "@fortawesome/free-solid-svg-icons"
import Lottie from "lottie-react"
import ConnectEmailAnimation from "assets/animations/connect-email.json"
import ConnectEmailAccount from "./connect_email_account"
import IconButton from 'components/common/IconButton';
import ConfirmModal from "components/common/ConfirmModal"
import { Helmet } from "react-helmet";

const EmailAccounts2 = ({ ...rest }) => {
  const navigate = useNavigate()
  const [workspace] = useState(() => WorkSpaceManager.getActiveWorkSpace())
  const [connectedAccounts, setConnectedEmailAccounts] = useState([])
  const [loadingConnectedAccounts, setLoadingConnectedAccounts] = useState(true)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [isLoading, setIsLoading] = useState([])
  const [accountsUnderWarmUp, setAccountsUnderWarmUp] = useState({})
  const [actionableAccount, setActionableAccount] = useState()
  const [openAccountDeletePrompt, setOpenAccountDeletePrompt] = useState(false)

  const [lists, setLists] = useState([])
  const [searchText, setSearchText] = useState("")
  const [activeWorkSpace] = useState(WorkSpaceManager.getActiveWorkSpace())
  const [bulkActionSelectedOption, setBulkActionsSelectedOption] = useState("")
  const [performingBulkOperation, setPerformingBulkOperation] = useState(false)
  const [openListRenameDialog, setOpenListRenameDialog] = useState(false)
  const [selectedList, setSelectedList] = useState()
  const [listName, setListName] = useState()
  const [active, setActive] = useState(null)
  const [
    openEmailAccountConnectionDialog,
    setOpenEmailAccountConnectionDialog,
  ] = useState()
  const [verifyingEmailDomain, setVerifyingEmailDomain] = useState(false);

  useEffect(() => {
    if (workspace) {
      fetchConnectedEmailAccounts()
    }
  }, [workspace])

  const fetchConnectedEmailAccounts = async () => {
    setLoadingConnectedAccounts(true)
    try {
      let { data } = await APIService.fetchConnectedEmailAccounts(workspace._id)
      setConnectedEmailAccounts(data)
    } catch (e) {
      console.log(e)
    }
    setLoadingConnectedAccounts(false)
  }
  const processWarmUpRequest = (accId, done) => {
    setAccountsUnderWarmUp((prevAccs) => {
      if (done) {
        delete prevAccs[accId]
      } else {
        prevAccs[accId] = true
      }
      return { ...prevAccs }
    })
  }
  const updateAccountInList = (acc) => {
    const fPrintIndex = connectedAccounts.findIndex((x) => x._id === acc._id)
    if (fPrintIndex !== -1) {
      setConnectedEmailAccounts((prevAccs) => {
        prevAccs[fPrintIndex] = acc
        return [...connectedAccounts]
      })
    }
  }
  const warmUpConnectedEmailAccount = async (warm_up, acc) => {
    processWarmUpRequest(acc._id, false)
    setIsLoading((prev) => [...prev, acc._id])
    try {
      let { data, message } = await APIService.warmUpConnectedEmailAccount({
        warm_up,
        email_account_id: acc._id,
      })
      processWarmUpRequest(acc._id, true)
      toast.success(message, { theme: "colored" });
      if (message.includes('prepared for warm up')) {
        toast.info('Fresh warmup will kick off anytime between 1 to 6 hours.', {
          theme: 'colored'
        })
      }
      updateAccountInList(data)
    } catch (e) {
      processWarmUpRequest(acc._id, true)
      toast.error(e, { theme: "colored" })
    } finally {
      setIsLoading((prev) => prev.filter((item) => item !== acc._id))
    }
  }
  const connectNewEmailAccount = () => {
    navigate(RoutePaths.CONNECT_EMAIL_ACCOUNT)
  }

  const deleteConnectedAccount = async (account) => {
    let backupConnectedAccounts = [...connectedAccounts]
    setConnectedEmailAccounts([])
    setLoadingConnectedAccounts(true)
    APIService.removeConnectedEmailAccount(
      workspace._id,
      account._id,
      (res, err) => {
        if (err) {
          setConnectedEmailAccounts(backupConnectedAccounts)
          toast.error(err, { theme: "colored" })
          return
        }
        fetchConnectedEmailAccounts()
      },
    )
  }

  const ConnectedEmailTextAndIconOptionContent = ({
    iconColor,
    icon,
    text,
    textColor,
    customIcon,
    onClick,
  }) => {
    return (
      <div className="cursor-pointer" onClick={onClick}>
        <Flex className={"gap-1"} alignItems={"center"}>
          {customIcon ? (
            customIcon
          ) : (
            <FontAwesomeIcon
              className={iconColor}
              icon={icon}
              color={iconColor}
            />
          )}
          <span
            style={{
              color: textColor,
            }}
            className={`fw-medium ${textColor}`}
          >
            {text}
          </span>
        </Flex>
      </div>
    )
  }

  const CustomDropdownMenuItem = ({ title, icon }) => {
    return (
      <Flex alignItems={"center"} className={"gap-3"}>
        <FontAwesomeIcon className="text-600" icon={icon} />
        <span className="fw-medium" style={{ fontSize: 16 }}>
          {title}
        </span>
      </Flex>
    )
  }

  const ConnectedEmailTextAndIconOption = ({
    icon,
    iconColor = "text-400",
    text,
    textColor = "text-600",
    customIcon,
    dropDownOptions,
    toolTip,
    onClick,
  }) => {
    return (
      <OverlayTrigger overlay={<Tooltip>{toolTip}</Tooltip>}>
        <span>
          {dropDownOptions ? (
            <Dropdown>
              <Dropdown.Toggle
                as={"span"}
                className="p-0 m-0 no_caret cursor-pointer"
              >
                <ConnectedEmailTextAndIconOptionContent
                  icon={icon}
                  iconColor={iconColor}
                  text={text}
                  textColor={textColor}
                  customIcon={customIcon}
                />
              </Dropdown.Toggle>
              {dropDownOptions}
            </Dropdown>
          ) : (
            <ConnectedEmailTextAndIconOptionContent
              icon={icon}
              iconColor={iconColor}
              text={text}
              textColor={textColor}
              customIcon={customIcon}
              onClick={onClick}
            />
          )}
        </span>
      </OverlayTrigger>
    )
  }

  const GetStartedView = React.forwardRef((props, ref) => {
    return (
      <Flex
        className={"gap-2"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "70vh",
        }}
      >
        <Lottie
          animationData={ConnectEmailAnimation}
          height={150}
          width={150}
          style={{
            width: 180,
            height: 180,
          }}
        />
        <h4>👋 Add an email account to get started</h4>
        <Button
          style={{
            marginTop: "2rem",
          }}
          variant={"primary"}
          onClick={() => {
            setOpenEmailAccountConnectionDialog(true)
            // connectNewEmailAccount()
          }}
        >
          <Flex alignItems={"center"} className={"gap-1"}>
            <div
              style={{ position: "relative", width: "24px", height: "24px" }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-primary"
                size="lg"
              />
              <FontAwesomeIcon
                icon={faCog}
                className="text-white"
                // size="1x" // Adjust the size to "xs" for smaller icon
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  top: "50%", // Center vertically by setting top to 50%
                  left: "50%", // Center horizontally by setting left to 50%
                  transform: "translate(-50%, -50%)", // Translate back to center position
                }}
              />
            </div>
            <span>Add new</span>
          </Flex>
        </Button>
      </Flex>
    )
  })



  const columns = [{
    Header: "Email account",
    accessor: "email",
    Cell: ({ row }) => {
      const { email } = row.original
      return (
        <span
          className=" border-2x btn-lg mb-3 fs-0 cursor-pointer"
          onClick={() => {
            setOpenDrawer(true)
            setActive(row.original)
          }}
        >
          {StringUtils.highlightMatchingText(email, searchText)}
        </span>
      )
    },
  },
  {
    Header: "Email sent",
    accessor: "contacts",
    Cell: ({ row }) => {
      const { email_warm_up_sent_dates } = row.original
      // Get the array of keys from the object and determine its length
      const count = email_warm_up_sent_dates
        ? email_warm_up_sent_dates?.length
        : 0

      // return <>{count} of <b>{workspace?.active_plans?.sales_platform?.MonthlyEmailSendingLimit.toLocaleString()}</b></>
      return <>{count}</>
    },
  },
  {
    Header: "warmup emails",
    accessor: "created_at",
    Cell: ({ row }) => {
      const { email_warm_up_sent_dates } = row.original
      // Get the array of keys from the object and determine its length
      const count = email_warm_up_sent_dates
        ? email_warm_up_sent_dates?.length
        : 0
      return <>{count}</>
    },
  },
  {
    Header: "",
    accessor: "activate_warmup",
    disableSortBy: true,
    cellProps: {
      className: "text-start",
    },
    Cell: ({ row }) => {
      const { can_be_warmed_up, _id } = row.original
      return (
        <ConnectedEmailTextAndIconOption
          toolTip={
            <Flex direction="column">
              <span>
                {can_be_warmed_up ? "Disable Warmup" : "Enable Warmup"}
              </span>
            </Flex>
          }
          onClick={() =>
            warmUpConnectedEmailAccount(!can_be_warmed_up, row.original)
          }
          customIcon={
            isLoading.includes(_id) || accountsUnderWarmUp[_id] ? (
              <ReactLoading
                type="spinningBubbles"
                width={30}
                height={30}
                color="rgb(46, 202, 139)"
              />
            ) : (
              <svg
                stroke={
                  can_be_warmed_up ? "rgb(46, 202, 139)" : "currentColor"
                }
                fill={
                  can_be_warmed_up ? "rgb(46, 202, 139)" : "currentColor"
                }
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 23a7.5 7.5 0 0 1-5.138-12.963C8.204 8.774 11.5 6.5 11 1.5c6 4 9 8 3 14 1 0 2.5 0 5-2.47.27.773.5 1.604.5 2.47A7.5 7.5 0 0 1 12 23z"></path>
                </g>
              </svg>
            )
          }
        />
      )
    },
  },
  {
    Header: "",
    accessor: "none",
    disableSortBy: true,
    cellProps: {
      className: "text-start",
    },
    Cell: ({ row }) => {
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item
              onClick={() => {
                setActionableAccount(
                  row.original,
                )
                setOpenAccountDeletePrompt(true)
              }}
            >
              <CustomDropdownMenuItem
                icon={faTrash}
                title={"Remove account"}
              />
            </Dropdown.Item>
          </div>
        </CardDropdown>
      )
    },
  },]

  const handleSelectChange = (e) => {
    setBulkActionsSelectedOption(e.target.value)
  }

  const handleDomainVerification = async () => {
    let unstableEmail = []
    try {
      setVerifyingEmailDomain(true);
      const { data } = await APIService.verifyDomainRecords(
        activeWorkSpace?._id,
        { test: 'result' },
      )
      for (let i = 0; i < data.length; i++) {
        let query = data[i]
        if (query?.isAllRecordsOkay) {
          continue
        }
        // unstableEmail.push({
        //   email: query?.email,
        //   affectedRecords: query?.affectedRecords,
        // })
        unstableEmail.push(query?.email)
      }
      if (!unstableEmail?.length) {
        toast.success('All emails are verified successfully', { theme: 'colored' })
        setVerifyingEmailDomain(false);
        return;
      }
      let errorMsg = `The following emails are not verified: \n ${unstableEmail.join(
        '\n',
      )}. 
      \n
      Check their MX, SPF, DMARC records and try again.
      `
      toast.error(errorMsg, { theme: 'colored' })
      setVerifyingEmailDomain(false);
    } catch (error) {
      setVerifyingEmailDomain(false);
      console.log("🚀 ~ handleDomainVerification ~ error:", error)
    }
  }

  const applyBulkAction = (selectedRowIndices) => {
    if (selectedRowIndices?.length === 0) {
      return
    }
    switch (bulkActionSelectedOption) {
      case "delete":
        let updatedLists = lists.filter(
          (_, index) => !selectedRowIndices.includes(`${index}`),
        )
        setPerformingBulkOperation(true)
        const listsToDelete = lists
          .filter((_, index) => selectedRowIndices.includes(`${index}`))
          .map((lead) => lead._id)
        APIService.batchDeleteLists(
          activeWorkSpace._id,
          listsToDelete,
          (res, error) => {
            setPerformingBulkOperation(false)
            if (error) {
              toast.error(error, { theme: "colored" })
              return
            }
            let { message } = res
            toast.success(message, { theme: "colored" })
            setLists(updatedLists)
          },
        )
        break
      default:
        return
    }
  }

  function BulkAction({ selectedRowIds }) {
    return (
      <Row className="flex-between-center mb-3">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {Object.keys(selectedRowIds).length > 0
              ? "You have selected " +
              Object.keys(selectedRowIds).length +
              " rows"
              : `Email accounts (${connectedAccounts?.length})`}
          </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select
                value={bulkActionSelectedOption}
                size="sm"
                aria-label="Bulk actions"
                onChange={handleSelectChange}
              >
                <option value={"bulk-actions"}>Bulk Actions</option>
                <option value="delete">Delete</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                disabled={
                  !bulkActionSelectedOption ||
                  bulkActionSelectedOption === "bulk-actions" ||
                  performingBulkOperation
                }
                className="ms-2"
                onClick={(e) => {
                  applyBulkAction(Object.keys(selectedRowIds))
                }}
              >
                {performingBulkOperation ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Apply</span>
                )}
              </Button>
            </div>
          ) : (
            <Flex
              alignItems={"center"}
              className={"gap-3"}
              justifyContent={"center"}
            >
              {rest.refreshingLeads ? (
                <ReactLoading
                  className="mt-4"
                  width={30}
                  type="spinningBubbles"
                  color="teal"
                />
              ) : (
                <>
                  {
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      className="ms-3"
                      style={{ fontSize: '15px' }}
                      icon={faListCheck}
                      onClick={() => {
                        handleDomainVerification();
                      }}
                      disabled={verifyingEmailDomain}
                    >
                      {
                        verifyingEmailDomain ?
                          'Verifying your emails, please wait...' :
                          'Test email records'
                      }
                    </IconButton>
                  }
                  <Button
                    variant="primary"
                    size="sm"
                    transform="shrink-3"
                    onClick={() => {
                      setOpenEmailAccountConnectionDialog(true)
                      // connectNewEmailAccount()
                    }}
                  >
                    <Flex alignItems={"center"} justifyContent={"center"}>
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="d-none d-sm-inline-block ms-1">
                        Add new
                      </span>
                    </Flex>
                  </Button>
                </>
              )}
            </Flex>
          )}
        </Col>
      </Row>
    )
  }

  return (
    <>

      <Helmet>
        <title>Email Accounts - {workspace.name} - Reachable</title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reachableonline " />
        <meta name="twitter:creator" content="@reachableonline " />
        <meta
          property="og:description"
          content="Reachable CRM"
        />
        <meta
          content="https://twitter.com/reachableonline"
          property="og:see_also"
        />
        <meta name="twitter:title" content="Dashboard - Reachable" />
        <meta name="twitter:description" content="" />
        {/* <meta name="twitter:image" content="https://promoteapp.s3.amazonaws.com/promote-twitter-card.jpeg" /> */}
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachableonline.co/" rel="canonical " />
        <link href="https://reachableonline.co/" rel="home " />
      </Helmet>
      {connectedAccounts?.length < 1 && loadingConnectedAccounts && (
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          direction={"column"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
          >
            <ReactLoading type={"bubbles"} color={"#0052cc"} />
          </Flex>
        </Flex>
      )}
      {!loadingConnectedAccounts && connectedAccounts?.length < 1 && (
        <GetStartedView />
      )}
      {connectedAccounts?.length > 0 && (
        <Card>
          <Card.Body>
            <AdvanceTableWrapper
              columns={columns}
              data={connectedAccounts}
              sortable
              pagination
              perPage={50}
              selection
              selectionColumnWidth={30}
            >
              <BulkAction table />
              <AdvanceTableSearchBox
                table
                placeholder="Search list"
                onSearchTextChanged={(changedSearchText) => {
                  setSearchText(changedSearchText)
                }}
              />
              <div className="mb-3"></div>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  striped: true,
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={lists?.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          </Card.Body>
        </Card>
      )}
      <EmailAccountSettings
        // thread={thread}
        // updateHandler={({ body, subject }) => {
        //   thread["title"] = subject;
        //   thread["body"] = body;
        // }}
        // onSave={updateThread}
        id={active}
        open={openDrawer}
        updateAcn={updateAccountInList}
        closeDrawer={() => {
          setOpenDrawer(false)
          setActive(null)
        }}
      />

      <Offcanvas
        style={{
          width: "100%",
        }}
        fullscreen={true}
        placement="end"
        show={openEmailAccountConnectionDialog}
        onHide={() => setOpenEmailAccountConnectionDialog(false)}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="dark"
        // className="bg-shape settings-panel-header pl-4 pr-14"
        >
          <Offcanvas.Title>Connect a new email account</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ConnectEmailAccount />
        </Offcanvas.Body>
      </Offcanvas>

      <ConfirmModal
        open={openAccountDeletePrompt}
        message={`Are you sure about deleting the <b>${actionableAccount?.email}</b> account?`}
        title={"Delete account?"}
        confirmText={"DELETE"}
        cancelText={"CANCEL"}
        onConfirm={() => {
          deleteConnectedAccount(actionableAccount)
          setOpenAccountDeletePrompt(false)
        }}
        onCancel={() => {
          setOpenAccountDeletePrompt(false)
          setActionableAccount(undefined)
        }}
      />
    </>
  )
}
export default EmailAccounts2
