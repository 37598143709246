import React from 'react';
import { Col, Row } from 'react-bootstrap';
import MonthlyYearlySwitch from './MonthlyYearlySwitch';

const PricingDefaultHeader = () => {

  // const billedYearly = useSelector(getBilledYearly);

  return (
    <Col xs={12} className="mb-3">
      <Row className="justify-content-center">
        <MonthlyYearlySwitch />
        <Col sm="auto" className="text-center">
          {/* <h5 className="d-inline-block">Billed {billedYearly ? "Annually" : "Monthly"}</h5> */}
          {/* {
            billedYearly &&
            <SoftBadge bg="success"  pill>
              Save up to 30%
            </SoftBadge>
          } */}
        </Col>
        
      </Row>
    </Col>
  );
};

export default PricingDefaultHeader;
