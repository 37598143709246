import {
    faCheckCircle,
    faChevronLeft, faChevronRight, faDownload, faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import UserManager from "utils/user_manager";
import ReactLoading from 'react-loading';
import { toast } from "react-toastify";
import WorkSpaceManager from "utils/workspace_manager";
import APIService from "http/api_service";
import { useNavigate } from "react-router-dom";
import IconAlert from "components/common/IconAlert";
import './smtp_and_imap.css';

export const IMAPAndSMTPConnectionScreen = ({ feedForwardData = {}, prompt, promptHandler }) => {

    const [animateOut, setAnimateOut] = useState(false);
    const [animateIn, setAnimateIn] = useState(false);
    const [canDisplayImapConnectionSuccess, setCanDisplayIMAPConnectionSuccess] = useState(false);

    const [formData, setFormData] = useState({
        ...feedForwardData,
        username: feedForwardData?.email,
        imap_host: '',
        imap_port: 993,
        smtp_host: '',
        smtp_port: 465,
        password: ''
    });

    const activeWorkspace = WorkSpaceManager.getActiveWorkSpace();
    const [processing, setProcessing] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const navigate = useNavigate();

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setAnimateOut(false);
        setAnimateIn(false);
    }

    const handleSubmit = e => {
        e?.preventDefault();
        setProcessing(true);
        setErrorMsg(undefined);
        if (formData.successful_imap_connection) {
            const toastId = toast(formData.successful_imap_connection ? "Testing SMTP..." : "Testing IMAP...", {
                position: 'top-center',
                autoClose: true,
                theme: 'dark'
            });
            toast.update(toastId);
        }
        APIService.connectEmailAccount(activeWorkspace._id, formData, (res, err) => {
            toast.dismiss();
            if (err) {
                setProcessing(false);
                toast.error(err, { theme: 'colored', position: 'top-center' });
                setErrorMsg(err);
                return;
            }
            let { message } = res;
            if (formData.successful_imap_connection) {
                toast.success(message, { theme: 'colored', position: 'top-center' });
            }
            if (!formData.successful_imap_connection) {
                setCanDisplayIMAPConnectionSuccess(true);
                setProcessing(false);
                setTimeout(() => {
                    setAnimateOut(true);
                    setTimeout(() => {
                        setAnimateOut(false);
                        setAnimateIn(true);
                        setFormData({
                            ...formData,
                            successful_imap_connection: true
                        });
                    }, 100);
                }, 1000);
            } else {
                setTimeout(() => {
                    setProcessing(false);
                    navigate(-1);
                }, 1000);
            }
        });
    }

    useEffect(() => {
        if (animateIn) {
            const timerId = setTimeout(() => {
                setAnimateIn(false);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [animateIn]);

    useEffect(() => {
        if (canDisplayImapConnectionSuccess) {
            const timerId = setTimeout(() => {
                setCanDisplayIMAPConnectionSuccess(false);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [canDisplayImapConnectionSuccess]);

    return (
        <Flex direction={'column'}>
            <Flex direction={'column'}>
                <Flex
                    className={'gap-3 px-1'}
                    alignItems={'center'}
                >
                    <FontAwesomeIcon icon={formData.successful_imap_connection ? faUpload : faDownload} size={"2x"} />
                    <Flex direction={'column'}>
                        <h2
                            className="p-0 m-0 fw-900"
                            style={{
                                fontSize: '1.4rem'
                            }}>{formData.successful_imap_connection ? 'SMTP' : 'IMAP'}
                        </h2>
                        <span className="text-500 fw-medium">Configure {formData.successful_imap_connection ? 'SMTP' : 'IMAP'} Settings</span>
                    </Flex>
                </Flex>
            </Flex>
            <Form onSubmit={handleSubmit} className="pt-3">
                <Flex direction={'column'} className="gap-3 mb-2">
                    {
                        formData?.provider === 'zoho' &&
                        <b>
                            <a href={"https://www.zoho.com/mail/help/imap-access.html"} target="_blank">
                                See instructions from Zoho
                            </a>
                        </b>
                    }
                    <Form.Group as={Col} sm={12}>
                        <Form.Label>Username<span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            required
                            disabled={processing}
                            placeholder={'IMAP Username'}
                            value={formData.username}
                            name="username"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm={12}>
                        <Form.Label>Password<span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            required
                            disabled={processing}
                            placeholder={'Password'}
                            value={formData.password}
                            name="password"
                            onChange={handleFieldChange}
                            type="password"
                        />
                        {
                            formData?.provider === 'zoho' &&
                            <IconAlert className={'mt-3 p-1 px-2'} variant="info" dismissible={false}>
                                Use an app password for a more stable connection
                            </IconAlert>
                        }
                    </Form.Group>
                </Flex>
                {
                    !formData.successful_imap_connection &&
                    <Row className={`g-2 mb-3 ${animateOut ? "animate-out" : ""}`}>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>IMAP Host<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                required
                                disabled={processing}
                                placeholder={'imap.website.com'}
                                value={formData.imap_host}
                                name="imap_host"
                                onChange={handleFieldChange}
                                pattern="^(imap\.)?([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$"
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>IMAP Port<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                required
                                disabled={processing}
                                placeholder={'IMAP Port'}
                                value={formData.imap_port}
                                name="imap_port"
                                onChange={handleFieldChange}
                                type="number"
                            />
                        </Form.Group>
                    </Row>
                }
                {
                    canDisplayImapConnectionSuccess &&
                    <Flex
                        style={{
                            color: 'white'
                        }} className={'bg-success gap-2 mb-2 rounded-pill'} alignItems={'center'} justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span>IMAP Connection Successful!</span>
                    </Flex>
                }
                {
                    formData.successful_imap_connection &&
                    <Flex
                        style={{ border: '2px dashed #ccc', padding: '20px' }}
                        direction={'column'}
                        className={`mb-3 gap-3 ${animateIn ? "animate-in" : ""} ${animateIn ? 'new-section' : ''}`}>
                        <h6>Next, let's configure your SMTP Settings</h6>
                        <Row className={`g-2`}>
                            <Form.Group as={Col} sm={6}>
                                <Form.Label>SMTP Host<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    required
                                    disabled={processing}
                                    placeholder={'smtp.website.com'}
                                    value={formData.smtp_host}
                                    name="smtp_host"
                                    onChange={handleFieldChange}
                                    pattern="^(smtp\.)?([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$"
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={6}>
                                <Form.Label>SMTP Port<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    required
                                    disabled={processing}
                                    placeholder={'SMTP Port'}
                                    value={formData.smtp_port}
                                    name="smtp_port"
                                    onChange={handleFieldChange}
                                    type="number"
                                />
                            </Form.Group>
                        </Row>
                    </Flex>
                }
                <Flex
                    style={{
                        width: '100%'
                    }}
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={'gap-2 mt-3'}>
                    <Button
                        disabled={processing}
                        onClick={() => {
                            promptHandler(Math.max(0, prompt - 1))
                        }}
                        variant="falcon-default"
                        style={{
                            width: '40%',
                            fontSize: 19
                        }}>
                        <Flex
                            alignItems={'center'}
                            justifyContent={'center'}
                            className={'gap-1'}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <strong>Back</strong>
                        </Flex>
                    </Button>
                    <Button
                        disabled={processing}
                        type="submit"
                        variant="success"
                        style={{
                            width: '60%',
                            fontSize: 19
                        }}>
                        {
                            processing ?
                                <Flex className={'gap-2'}
                                    alignItems={'center'}
                                    justifyContent={'center'}>
                                    <ReactLoading width={30} height={30} type={'bubbles'} />
                                    <span style={{ fontSize: 15 }}>{`${formData.successful_imap_connection ? "Testing SMTP..." : "Testing IMAP..."}`}</span>
                                </Flex>
                                :
                                <Flex
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    className={'gap-1'}>
                                    <strong>Next</strong>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </Flex>
                        }
                    </Button>
                </Flex>
                {
                    errorMsg &&
                    <Flex
                        className={'p-2'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <span
                            className="text-danger"
                            style={{
                                fontSize: '.9rem',
                                maxWidth: 250
                            }}>{errorMsg}
                        </span>
                    </Flex>
                }
            </Form>
        </Flex>
    );
}

export const IMAPAndSMTPBasicInformationScreen = ({ feedForwardDataHandler, promptHandler, prompt, provider, connectionHeader }) => {

    const loggedInUser = UserManager.getLoggedInUser();

    const [formData, setFormData] = useState({
        first_name: loggedInUser?.first_name ?? '',
        last_name: loggedInUser?.last_name ?? '',
        email: '',
        provider
    });

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.email) {
            toast.error(`Please enter your email`, { theme: 'colored' });
            return;
        }
        feedForwardDataHandler(formData);
        setTimeout(() => {
            promptHandler(prompt + 1);
        }, 100);
    }

    return (
        <Flex
            direction={'column'}>
            {connectionHeader}
            <Form onSubmit={handleSubmit} className="pt-4">
                <Row className="g-2 mb-3">
                    <Form.Group as={Col} sm={6}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            placeholder={'First Name'}
                            value={formData.first_name}
                            name="first_name"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm={6}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            placeholder={'Last Name'}
                            value={formData.last_name}
                            name="last_name"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Row>
                <Form.Group>
                    <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                    <Form.Control
                        required
                        placeholder={'Email address to connect'}
                        value={formData.email}
                        name="email"
                        onChange={handleFieldChange}
                        type="email"
                    />
                </Form.Group>
                <Button
                    type="submit"
                    variant="success"
                    className="mt-4"
                    style={{
                        width: '100%',
                        fontSize: 19
                    }}>
                    <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={'gap-1'}>
                        <strong>Next</strong>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Flex>
                </Button>
            </Form>
        </Flex>
    );
}
