import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import UserManager from 'utils/user_manager';
import GeneralCardLayout from 'layouts/GeneralCardLayout2';
import {
    RoutePaths,
    WorkSpaceContentApprovalWorkFlow
} from '../../../constants';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import WorkSpaceManager from 'utils/workspace_manager';
import { useNavigate } from 'react-router-dom';
import UpgradeAccountAnimation from 'assets/animations/update-to-premium.json';
import Lottie from "lottie-react";
import { useDispatch } from 'react-redux';
import { cachePosts } from '../../../redux/slices/postslice';

export const UpgradeToPremiumAccountCard = React.forwardRef((props, ref) => {

    const navigate = useNavigate();
    const {
        subTitle = "This feature requires account upgrade or you need to buy Extra Workspaces",
        message,
        upgradeBtnText = "See Plans and Pricing"
    } = props;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 50,
                    paddingTop: 10,
                    gap: 10
                }}>
                <Lottie
                    animationData={UpgradeAccountAnimation}
                    loop={false}
                    style={{
                        width: 140,
                        maxWidth: 140,
                        height: 140
                    }}
                />
                <h5
                    style={{
                        textAlign: 'center'
                    }}>{subTitle}
                </h5>
                <span>{message}</span>
            </div>
            <Button
                onClick={() => {
                    navigate(`${RoutePaths.ACCOUNT}?t=billing`);
                }}
                variant={'primary'}>
                {upgradeBtnText}
            </Button>
        </div>
    );
});

const NewWorkspace = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedInUser = UserManager.getLoggedInUser() ?? {};
    const [attributes, setAttributes] = useState();
    const [openUpgradeCard, setOpenUpgradeCard] = useState(false);
    const [creatingWorkspace, setCreatingWorkspace] = useState(false);
    const [formData, setFormData] = useState({
        first_name: attributes?.first_name ?? loggedInUser?.first_name ?? "",
        last_name: attributes?.last_name ?? loggedInUser?.last_name ?? "",
        workspace_name: ""
    });

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let receivedAttributes = params.get("attributes");
        if (receivedAttributes) {
            setAttributes(JSON.parse(decodeURIComponent(receivedAttributes)));
        }
        // if (UserManager.isUserFreeAccount() && !receivedAttributes) {
        //     navigate(`${RoutePaths.MANDATORY_PLAN_UPGRADE}?self=true`);
        // }
    }, []);

    useEffect(() => {
        if (attributes) {
            setFormData({
                ...formData,
                first_name: attributes?.first_name,
                last_name: attributes?.last_name
            });
        }
    }, [attributes]);

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        let workSpaceApprovalOption = localStorage.getItem(WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION) ?? 0;
        let lockPostsOnApproval = localStorage.getItem(WorkSpaceContentApprovalWorkFlow.LOCK_CONTENT_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE);
        let allowOwnersPostToGoThroughApproval = localStorage.getItem(WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL) ?? false;
        let scheduleContentImmediatelyAfterApproval = localStorage.getItem(WorkSpaceContentApprovalWorkFlow.SCHEDULE_POSTS_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE);
        let workSpaceSettings = {
            [WorkSpaceContentApprovalWorkFlow.LOCK_CONTENT_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE]: lockPostsOnApproval,
            [WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION]: parseInt(workSpaceApprovalOption),
            [WorkSpaceContentApprovalWorkFlow.SCHEDULE_POSTS_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE]: scheduleContentImmediatelyAfterApproval,
            [WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL]: allowOwnersPostToGoThroughApproval
        }
        formData.settings = workSpaceSettings;
        attemptNewWorkSpaceCreation(formData);
    };

    const attemptNewWorkSpaceCreation = (values) => {
        setCreatingWorkspace(true);
        let settings = values.settings;
        APIService.createNewWorkSpace(values.workspace_name, values.first_name, values.last_name, settings, attributes, (response, error) => {
            if (error) {
                setCreatingWorkspace(false);
                if (error.toLowerCase().includes('Pay For Extra Workspace'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                    return;
                }
                if (error.toLowerCase().includes('upgrade'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            let createdWorkSpace = response['data'];
            WorkSpaceManager.setActiveWorkSpace(createdWorkSpace);
            localStorage.removeItem(WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION);
            localStorage.removeItem(WorkSpaceContentApprovalWorkFlow.LOCK_CONTENT_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE);
            localStorage.removeItem(WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL);
            let loggedInUser = UserManager.getLoggedInUser();
            loggedInUser['has_workspaces'] = true;
            UserManager.setLoggedInUser(loggedInUser);
            dispatch(cachePosts([]));
            setTimeout(() => {
                navigate(RoutePaths.REDIRECT);
                window.location.reload();
            }, 1000);
        });
    }

    return (
        <GeneralCardLayout>
            <h4 className="mb-4">{`Create a new organization ${attributes ? `for ${attributes?.first_name} ${attributes?.last_name}` : ``}`}</h4>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="first_name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="First Name"
                            value={formData.first_name}
                            name="first_name"
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="last_name">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Last Name"
                            value={formData.last_name}
                            name="last_name"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="workspace_name">
                    <Form.Label>Workspace Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name of new workspace"
                        value={formData.workspace_name}
                        name="workspace_name"
                        onChange={handleChange}
                    />
                </Form.Group>
                {/* <h5 className="mb-0 mt-3">Approval Workflow
                    <TooltipBadge
                        tooltip="This settings would be applied when you schedule or publish posts under this workspace"
                        icon="question-circle"
                    /></h5> */}
                {/* <WorkSpaceApprovalWorkFlow handleApprovalOptionSelectionHandler={(selection, allowOwnersToGoThroughApproval) => {
                    localStorage.setItem(WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION, selection);
                    localStorage.setItem(WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL, allowOwnersToGoThroughApproval);
                }}
                /> */}
                <div className="text-end">
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={creatingWorkspace || !formData.workspace_name || !formData.first_name || !formData.last_name}>
                        {creatingWorkspace ? "Please wait..." : "Create"}
                    </Button>
                </div>
            </Form>
            <Modal
                show={openUpgradeCard}
                onHide={() => setOpenUpgradeCard(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <UpgradeToPremiumAccountCard />
                </Modal.Body>
            </Modal>
        </GeneralCardLayout>
    );
};
export default NewWorkspace;