import React from "react";
import Background from "components/common/Background";
import Flex from "components/common/Flex";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import { RoutePaths } from "../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Link } from "react-router-dom";
import createMarkup from "helpers/createMarkup";
import corner1 from "assets/img/icons/spot-illustrations/corner-3.png";
import SoftBadge from 'components/common/SoftBadge';

const SalesPlatformSubscriptionCard = ({ notifications }) => {

  const openCrisp = (event) => {
    event.preventDefault();
    window.$crisp.push(["do", "chat:open"]);
  };

  return (
    <>
      <Card className="bg-transparent-50 overflow-hidden" style={{ boxShadow: "none" }}>
        <Card.Header className="position-relative bg-white">
          <Background
            image={corner1}
            className="d-none d-md-block bg-card z-index-1 rounded-soft"
            // style={{
            //   backgroundSize: "230px",
            //   backgroundPosition: "right bottom",
            //   zIndex: "-1",
            // }}
          />
          <div className="position-relative z-index-2">
            <div>
              <h5 className="text-dark mb-1">Build Reachable with us</h5>
            </div>

            
              <Row className="g-2 h-100 align-items-end mt-4 mb-4">
                <Col sm={6} md={5}>
                  <Flex className="position-relative">
                    <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                      <FontAwesomeIcon
                        icon="envelope"
                        className={`text-info`}
                      />
                    </div>
                    <div className="flex-1" onClick={openCrisp}>
                      <Link to="#!" className="stretched-link text-800">
                        <h5 className="text-primary mb-0">Contact customer support <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    /></h5>
                      </Link>
                      <p className="mb-0 fs--1 text-500 ">
                        If you have any questions or need assistance with your
                        Reachable account, please reach out to us.
                      </p>
                    </div>
                  </Flex>
                </Col>
                <Col sm={6} md={5}>
                  <Flex className="position-relative">
                    <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                      <FontAwesomeIcon
                        icon="users"
                        className={`text-primary`}
                      />
                    </div>
                    <div className="flex-1">
                      <a href="https://web.facebook.com/groups/782902003838357" className="stretched-link text-800" target="_blank"
                            rel="noreferrer">
                        <h5 className="text-primary mb-0">Join our community <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    /></h5>
                      </a>
                      <p className="mb-0 fs--1 text-500 ">
                      Join for effective cold email templates and lead strategies that supercharge your outreach.
                        {/* Join our community to learn effective cold email templates and lead generation strategies that supercharge your outreach efforts for better results. */}
                      </p>
                    </div>
                  </Flex>
                </Col>
              </Row>
              <h5 className="text-dark">Changelog 📮</h5>
          </div>
        </Card.Header>
        <Card.Body className="pt-0 pe-0 pb-0 ps-0">
          <ul className="mb-0 list-unstyled">
          <li
              className={classNames(
                "alert mb-0 rounded-0 py-3 px-card",
                "border-top",
                {
                  // 'alert-warning': type === 'warning',
                  // 'border-0': isLast,
                  // 'border-x-0 border-top-0': !isLast
                }
              )}>
              <Row className="flex-between-center">
              <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ color: "green" }}
                      className={classNames("mt-1 fs--2", {
                        // 'text-primary': !type
                      })}
                    />
                    <Link
                    to="/changelog#november-2023"
                    className="alert-link fs--1 fw-medium ps-2 mb-0"
                  >
                    {`Contact verification (2024-03-11)`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                  </Flex>
                </Col>
              </Row>
            </li>
          <li
              className={classNames(
                "alert mb-0 rounded-0 py-3 px-card",
                "border-top",
                {
                  // 'alert-warning': type === 'warning',
                  // 'border-0': isLast,
                  // 'border-x-0 border-top-0': !isLast
                }
              )}>
              <Row className="flex-between-center">
              <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ color: "green" }}
                      className={classNames("mt-1 fs--2", {
                        // 'text-primary': !type
                      })}
                    />
                    <Link
                    to="/changelog#november-2023"
                    className="alert-link fs--1 fw-medium ps-2 mb-0"
                  >
                    {`Lead Finder beta release (2024-02-01)`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                  </Flex>
                </Col>
              </Row>
            </li>
            <li
              className={classNames(
                "alert mb-0 rounded-0 py-3 px-card",
                "border-top",
                {
                  // 'alert-warning': type === 'warning',
                  // 'border-0': isLast,
                  // 'border-x-0 border-top-0': !isLast
                }
              )}>
              <Row className="flex-between-center">
              <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ color: "green" }}
                      className={classNames("mt-1 fs--2", {
                        // 'text-primary': !type
                      })}
                    />
                    <Link
                    to="/changelog#november-2023"
                    className="alert-link fs--1 fw-medium ps-2 mb-0"
                  >
                    {`Simplified Step Creation (2024-01-24)`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                  </Flex>
                </Col>
              </Row>
            </li>
            <li
              className={classNames(
                "alert mb-0 rounded-0 py-3 px-card",
                "border-top",
                {
                  // 'alert-warning': type === 'warning',
                  // 'border-0': isLast,
                  // 'border-x-0 border-top-0': !isLast
                }
              )}>
              <Row className="flex-between-center">
              <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ color: "green" }}
                      className={classNames("mt-1 fs--2", {
                        // 'text-primary': !type
                      })}
                    />
                    <Link
                    to="/changelog#november-2023"
                    className="alert-link fs--1 fw-medium ps-2 mb-0"
                  >
                    {`Warmup ugrade (2024-12-29)`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                  </Flex>
                </Col>
              </Row>
            </li>
          <li
              className={classNames(
                "alert mb-0 rounded-0 py-3 px-card",
                "border-top",
                {
                  // 'alert-warning': type === 'warning',
                  // 'border-0': isLast,
                  // 'border-x-0 border-top-0': !isLast
                }
              )}>
              <Row className="flex-between-center">
              <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ color: "green" }}
                      className={classNames("mt-1 fs--2", {
                        // 'text-primary': !type
                      })}
                    />
                    <Link
                    to="/changelog#november-2023"
                    className="alert-link fs--1 fw-medium ps-2 mb-0"
                  >
                    {`Unified inbox beta release (2023-11-26)`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                  </Flex>
                </Col>
              </Row>
            </li>
          <li
              className={classNames(
                "alert mb-0 rounded-0 py-3 px-card",
                "border-top",
                {
                  // 'alert-warning': type === 'warning',
                  // 'border-0': isLast,
                  // 'border-x-0 border-top-0': !isLast
                }
              )}>
              <Row className="flex-between-center">
              <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="circle"
                      style={{ color: "green" }}
                      className={classNames("mt-1 fs--2", {
                        // 'text-primary': !type
                      })}
                    />
                    <Link
                    to="/changelog#november-2023"
                    className="alert-link fs--1 fw-medium ps-2 mb-0"
                  >
                    {`Add an 'Unsubscribe' link to your emails (2023-11-20)`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                  </Flex>
                </Col>
              </Row>
            </li>
          
          </ul>
        </Card.Body>
      </Card>
    </>
  );
};

SalesPlatformSubscriptionCard.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
};

export default SalesPlatformSubscriptionCard;
