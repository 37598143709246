import React from "react";
import SearchBox from "./SearchBox";
import { Nav } from "react-bootstrap";
import { topNavbarBreakpoint } from "config";

const NavbarTopDropDownMenus = () => {
  return (
    <>

      <Nav
          navbar
          className={`align-items-center d-none d-${topNavbarBreakpoint}-block ml-9`}
          as="ul"
        >
        <Nav.Item as="li">
          <SearchBox />
        </Nav.Item>
      </Nav>
    </>
  );
};

export default NavbarTopDropDownMenus;
