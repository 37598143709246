import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import UserManager from "utils/user_manager";
import Flex from "components/common/Flex";
import SalesPlatformSubscriptionCard from "./sales-platform/SalesPlatformSubscriptionCard";
import LeadFinderPlatformSubscriptionCard from "./lead-finder/LeadFinderSubscriptionCard";
import VerificationPlatformSubscriptionCard from "./verification/VerificationSubscriptionCard";
import ConfirmModal from "components/common/ConfirmModal";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { RoutePaths } from "../../../constants";
import { useSelector } from "react-redux";
import { getBilledYearly } from "../../../redux/slices/workspaceslice";
import WorkSpaceManager from "utils/workspace_manager";
// import socket from "socket_io";
import { Checkmark } from "react-checkmark";
import { useQuery } from "utils/url";

const PricingDefault = () => {
  const workspace = WorkSpaceManager.getActiveWorkSpace();
  const billedYearly = useSelector(getBilledYearly);
  const [upgradingIndex, setupgradingIndex] = useState(-1);
  const [paymentForNewWorkspace] = useState(false);

  const loggedInUser = UserManager.getLoggedInUser();
  const [activePlan, setActivePlan] = useState(loggedInUser.active_plan);
  const [openSubscriptionCancelPrompt, setOpenSubscriptionCancelPrompt] =
    useState(false);
  const [
    openAIWriterSubscriptionCancelPrompt,
    setOpenAIWriterSubscriptionCancelPrompt,
  ] = useState(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [cancellingAIWriterSubscription, setCancellingAIWriterSubscription] =
    useState(false);
  const [openCheckoutCompletedPopup, setOpenCheckoutCompletedPopup] =
    useState(false);
  const [checkoutCompletedMessage, setCheckoutCompletedMessage] = useState();
  const [processingPayment, setProcessingPayment] = useState(false);
  const [
    openJoinedAIWriterCancellationPrompt,
    setOpenJoinedAIWriterCancellationPrompt,
  ] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();

  const refreshLoggedInUserData = () => {
    APIService.fetchLatestMe((latestMeResponse, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        if (
          error
            .toLowerCase()
            .includes("Invalid credentials provided".toLowerCase())
        ) {
          window.localStorage.clear();
          setTimeout(() => {
            navigate(RoutePaths.AUTHENTICATION);
          }, 100);
        }
        return;
      }
      let latestUserData = latestMeResponse.data;
      setActivePlan(latestUserData.active_plan);
      UserManager.setLoggedInUser(latestUserData);
    });
  };

  const handleSubscription = (item, index) => {
    setupgradingIndex(index);
    APIService.billUser(
      billedYearly,
      paymentForNewWorkspace,
      null,
      false,
      item.plan,
      (response, error) => {
        if (error) {
          setupgradingIndex(-1);
          toast.error(error);
          return;
        }
        let { data } = response;
        window.location = data;
        setupgradingIndex(-1);
      }
    );
  };

  const openCrisp = (event) => {
    event.preventDefault();
    window.$crisp.push(["do", "chat:open"]);
  };

  useEffect(() => {
    let paymentFor = query.get("payment_for");
    if (paymentFor) {
      try {
        let data = JSON.parse(decodeURIComponent(paymentFor));
        let { pricingData, coupon, user } = data;
        if (coupon) {
          pricingData.coupon = coupon;
        }
        if (pricingData) {
          console.log(pricingData);
          handleSubscription(pricingData, user);
        }
      } catch (e) {}
    }
  }, [query]);

  useEffect(() => {
    refreshLoggedInUserData();
  }, []);

  useEffect(() => {
    let checkoutSessionListener = (data) => {
      let { error, one_off, cancellation, message, plan = "Premium" } = data;
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      if (!cancellation) {
        setProcessingPayment(false);
        setCheckoutCompletedMessage(
          one_off
            ? message
            : `You have successfully subscribed to our ${plan} plan`
        );
        setOpenCheckoutCompletedPopup(true);
      } else {
        navigate(RoutePaths.DEFAULT);
        window.location.reload();
      }
    };
    // socket.on(`${loggedInUser?._id}-billing-status`, checkoutSessionListener);
    // return () => {
    //   socket.off(
    //     `${loggedInUser?._id}-billing-status`,
    //     checkoutSessionListener
    //   );
    // };
  }, []);

  const cancelSubscription = (e) => {
    setCancellingSubscription(true);
    let activePlan = workspace.active_plan;
    if (activePlan.cancel_url) {
      window.location = activePlan.cancel_url;
    } else {
      APIService.cancelSubscription((response, error) => {
        if (error) {
          setCancellingSubscription(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        let { message, data } = response;
        UserManager.setLoggedInUser(data);
        toast.success(message, { theme: "colored" });
        setCancellingSubscription(false);
        setTimeout(() => {
          window.location = window.location.href.split("?")[0];
          window.location.reload();
        }, 500);
      });
    }
  };

  const cancelAIWriterSubscription = (e) => {
    let activeSubscription = loggedInUser.active_ai_writer_subscription;
    if (activeSubscription.tied_to_plan) {
      setOpenJoinedAIWriterCancellationPrompt(true);
      return;
    }
    if (activePlan.cancel_url) {
      window.location = activePlan.cancel_url;
    } else {
      setCancellingAIWriterSubscription(true);
      APIService.cancelAIWriterSubscription((response, error) => {
        if (error) {
          setCancellingAIWriterSubscription(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        let { message, data } = response;
        UserManager.setLoggedInUser(data);
        toast.success(message, { theme: "colored" });
        setCancellingAIWriterSubscription(false);
        setTimeout(() => {
          window.location = window.location.href.split("?")[0];
          window.location.reload();
        }, 500);
      });
    }
  };

  const hashUpdatableUrl = () => {
    let cancelUrl = activePlan.cancel_url;
    let updateUrl = activePlan.update_url;
    if (updateUrl) {
      return updateUrl;
    }
    if (cancelUrl) {
      return cancelUrl.replace("cancel", "update");
    }
    return null;
  };

  const updatableUrl = hashUpdatableUrl();

  return (
    <Card className="p-2 shadow-none" >
      <Row className="g-3">
        <Col lg={6}>
          <SalesPlatformSubscriptionCard />
        </Col>
        <Col lg={6}>
          <LeadFinderPlatformSubscriptionCard />
        </Col>
      </Row>
      <Row className="g-2 mt--4" style={{ marginTop: -25 }}>
        <Col lg={6}>
          <VerificationPlatformSubscriptionCard />
        </Col>
      </Row>

      
      <ConfirmModal
        open={openJoinedAIWriterCancellationPrompt}
        onCancel={() => {
          setOpenJoinedAIWriterCancellationPrompt(false);
        }}
        title="Cancel your subscription?"
        message="Your AI Writer Unlimited Subscription is currently tied to your active plan. Kindly use the Cancel Subscription option on your active plan instead"
        cancelText="CLOSE"
        confirmText="OKAY"
        onConfirm={() => {
          setOpenJoinedAIWriterCancellationPrompt(false);
        }}
      />
      <ConfirmModal
        open={openSubscriptionCancelPrompt}
        onCancel={() => {
          setOpenSubscriptionCancelPrompt(false);
        }}
        title="Cancel your subscription?"
        message="This will cancel your current subscription and downgrade your account to the free plan."
        cancelText="NO"
        confirmText="CONTINUE"
        onConfirm={() => {
          setOpenSubscriptionCancelPrompt(false);
          cancelSubscription();
        }}
      />
      <ConfirmModal
        open={openAIWriterSubscriptionCancelPrompt}
        onCancel={() => {
          setOpenAIWriterSubscriptionCancelPrompt(false);
        }}
        title="Cancel your AI Writer subscription"
        message="This will cancel your current AI Writer subscription and downgrade your AI Writer quota to the free plan."
        cancelText="NO"
        confirmText="CONTINUE"
        onConfirm={() => {
          setOpenAIWriterSubscriptionCancelPrompt(false);
          cancelAIWriterSubscription();
        }}
      />
      <Modal
        show={openCheckoutCompletedPopup}
        onHide={() => {
          setOpenCheckoutCompletedPopup(false);
          navigate(RoutePaths.DEFAULT);
          window.location.reload();
        }}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Subscription Successful!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex
            alignItems={"center"}
            className={"gap-2"}
            justifyContent={"center"}
            direction={"column"}
          >
            <Checkmark size="96px" />
            <span>{checkoutCompletedMessage}</span>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setOpenCheckoutCompletedPopup(false);
              navigate(RoutePaths.DEFAULT);
              window.location.reload();
            }}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={processingPayment}
        onHide={() => {
          setProcessingPayment(false);
          toast.info("Your new plan will reflect after verification", {
            theme: "colored",
          });
        }}
        onExit={() => {
          setProcessingPayment(false);
        }}
        centered
      >
        <Modal.Body>
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Flex
              className={"gap-2"}
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
            >
              <Spinner animation="border" />
              <span>Verifying. Please wait...</span>
            </Flex>
          </Flex>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default PricingDefault;
