import { createSlice } from '@reduxjs/toolkit';
import { Strings } from '../../constants';
import { canLoggedInUserBeDemo } from 'utils/user_utils';

const initialState = {
    logged_in_user: null,
    alt_users: [],
    alt_user: null,
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setLoggedInUser: (state, action) => {
            let payload = action.payload;
            let latestUserState = payload;
            if (latestUserState) {
                if (payload.hasOwnProperty('message') && payload.hasOwnProperty('statusCode')) {
                    latestUserState = payload['data'];
                }
                let user = { ...latestUserState };
                state.logged_in_user = user;
                localStorage.setItem(Strings.LOGGED_IN_USER, JSON.stringify(user));
            } else {
                state.logged_in_user = null;
                localStorage.removeItem(Strings.LOGGED_IN_USER);
            }
        },
        setAltUsers: (state, action) => {
            let users = action.payload;
            if (users) {
                state.alt_users = [...users];
                localStorage.setItem(Strings.LOGGED_IN_ALT_USERS, JSON.stringify(users));
            } else {
                state.alt_users = [];
                localStorage.removeItem(Strings.LOGGED_IN_ALT_USERS);
            }
        },
        setAltUser: (state, action) => {
            let user = action.payload;
            if (user) {
                state.alt_user = { ...user };
                localStorage.setItem(Strings.ALTABLE_USER, JSON.stringify(user));
            } else {
                state.alt_user = null;
                localStorage.removeItem(Strings.ALTABLE_USER);
            }
        }
    }
});

export let getLoggedInUser = (state) => {
    let realTimeLoggedInUser = state?.users?.logged_in_user;
    if (realTimeLoggedInUser) {
        return realTimeLoggedInUser;
    }
    let localStorageUserString = localStorage.getItem(Strings.LOGGED_IN_USER);
    if (localStorageUserString) {
        return JSON.parse(localStorageUserString);
    }
    return null;
};
export let getAltUsers = (_state) => {
    let localStorageAltUsersString = localStorage.getItem(Strings.LOGGED_IN_ALT_USERS);
    if (localStorageAltUsersString) {
        return JSON.parse(localStorageAltUsersString);
    }
    return [];
};
export let getAltUser = (_state) => {
    let localStorageAltUserString = localStorage.getItem(Strings.ALTABLE_USER);
    if (localStorageAltUserString) {
        return JSON.parse(localStorageAltUserString);
    }
    return null;
};
export let isDemoAccount = (state) => canLoggedInUserBeDemo(getLoggedInUser(state));
export let isLTDUserAndRequiresUpgrade = (state) => {
    let loggedInUser = getLoggedInUser(state);
    let activePlan = loggedInUser.active_plans;
    let upgraded = activePlan.Upgraded ?? false;
    if (activePlan.LTD) {
        return true;
    }
    if ((activePlan.Name || activePlan.Key).toLowerCase().includes('appsumo')) {
        return true && !upgraded;
    }
    if ((activePlan.Name || activePlan.Key).toLowerCase().includes('pitchground')) {
        return true && !upgraded;
    }
    return false;
}

export const getLoggedInUserFullName = (state) => {
    let loggedInUser = getLoggedInUser(state);
    let firstName = loggedInUser?.first_name;
    let lastName = loggedInUser?.last_name ?? "";
    if (!firstName) {
        firstName = loggedInUser?.email.split("@")[0]
    }
    return `${firstName} ${lastName}`;
};
export const getLoggedInUserAvatar = (state) => getLoggedInUser(state).avatar;
export let {
    setLoggedInUser,
    setAltUsers,
    setAltUser,
} = usersSlice.actions;
export default usersSlice.reducer;