import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'; // Import required icons
import WorkSpaceManager from "utils/workspace_manager";
import Avatar from 'components/common/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { canOpenWorkSpaceConnectedEmailSearch, searchEmail } from '../../../redux/slices/workspaceslice';
import { useSelector } from 'react-redux';
import EmailSearch from '../email-search/EmailSearch';

const UnifiedInboxHeader = (props) => {
  const { loadingMore, filter, setFilter } = props;
  const activeWorkSpace = WorkSpaceManager.getActiveWorkSpace();
  const dispatch = useDispatch();
  const openWorkSpaceConnectedEmailSearch = useSelector(canOpenWorkSpaceConnectedEmailSearch);

  const truncatedWorkSpaceName = (name) => {
    let validLength = 20;
    if (name.length > validLength) {
      return name.substring(0, validLength).concat('...');
    }
    return name;
  }

  return (
    <Row className="gx-0 kanban-header rounded-2 px-x1 py-2">
      <Col className="d-flex align-items-center">
        {/* <h5 className="mb-0">Unified inbox</h5> */}
        <IconButton
          variant="falcon-default"
          size="sm"
          className="ms-1 shadow-none"
          icon={faCheckSquare}
        />
        <div className="vertical-line vertical-line-400 position-relative h-100 mx-3"></div>
        <span
          style={{ fontSize: '16px' }}
          size="sm"
          onClick={() => {
            dispatch(searchEmail(true));
          }}
        >
          <div
            className="cursor-pointer"
            style={{
              display: 'flex',
              gap: 1, alignItems: 'center',
              fontWeight: 'normal',
              // color: !config.isDark && '#060606',
            }}>

            {activeWorkSpace?.avatar ?
              <Avatar src={activeWorkSpace?.avatar} size='s' />
              :
              <Avatar name={activeWorkSpace.name ?? "U"} size='s' />
            }
            <span className="d-sm-inline-block ms-1 me-1">{truncatedWorkSpaceName(filter === 'all' ? 'All mails' : filter?.email)}</span>
            <FontAwesomeIcon icon={'caret-down'} />
          </div>
        </span>
      </Col>
      <Col xs="auto" as={Flex} alignItems="center">
        {loadingMore && <span className="text-muted">Loading more mails...</span>}
      </Col>
      <Modal
        show={openWorkSpaceConnectedEmailSearch}
        onHide={() => dispatch(searchEmail(false))}
        onExit={() => {
          dispatch(searchEmail(false));
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        {/* <EmailsCard /> */}
        <EmailSearch
          filter={filter}
          setFilter={setFilter}
        />
      </Modal>
    </Row>
  );
};

export default UnifiedInboxHeader;
