import logo from 'assets/img/logos/reachable_symbol_logo.png';

const FullLogo = ({ width = 200 }) => {
    return (
        <img className="me-2" 
            src={logo}
            alt="App Logo"
            width={width}
            style={{
                marginTop: 50
            }}
        />
    )
}
export default FullLogo;