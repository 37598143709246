import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    results: []
}
export const aiWriterSlice = createSlice({
    name: 'aiwriter',
    initialState,
    reducers: {
        setAIWriterResults: (state, action) => {
            state.results = action.payload;
        }
    }
});
export let getAIWriterResults = (state) => state.aiwriter.results;
export let {
    setAIWriterResults
} = aiWriterSlice.actions;
export default aiWriterSlice.reducer;