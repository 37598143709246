import Flex from 'components/common/Flex';
import { ChatContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Modal, Tab } from 'react-bootstrap';
import ChatProvider from './ChatProvider';
import ChatContent from './content/ChatContent';
import ChatSidebar from './sidebar/ChatSidebar';
import { useDispatch } from 'react-redux';
import { setSearchHint } from '../../../redux/slices/workspaceslice';
import WorkSpaceManager from 'utils/workspace_manager';
import MessagingAccountsConnector from './setup/MessagingAccountsConnector';
import localforage from 'localforage';
import { Strings } from '../../../constants';
import KanbanHeader from './UnifiedInboxHeader';
import APIService from 'http/api_service';
import Lottie from 'lottie-react';
import FetchingDocs from 'assets/animations/fetching_doc.json';

const ChatTab = () => {

  const dispatch = useDispatch();
  const workSpace = WorkSpaceManager.getActiveWorkSpace();
  const [chatSettings, setChatSettings] = useState(workSpace.chat_settings ?? {});
  const [fetchingMails, setFetchingMails] = useState(false);
  const [allInboxes, setAllInboxes] = useState([]);
  const [nextMailListToken, setNextMailListToken] = useState('undefined');
  const [loadingMore, setLoadingMore] = useState(false);
  const [filter, setFilter] = useState('all');
  const [originalInboxes, setOriginalInboxes] = useState([]);
  const [noMoreMailsToFetch, setNoMoreMailsToFetch] = useState(true);

  const {
    setIsOpenThreadInfo,
    threadsDispatch,
    threads,
    setCurrentThread,
    setScrollToBottom
  } = useContext(ChatContext);

  const [hideSidebar, setHideSidebar] = useState(false);
  const [openAccountsConnector, setOpenAccountsConnector] = useState(false);
  const [chats, setChats] = useState([]);

  const initChats = async () => {
    try {
      let cachedChats = await localforage.getItem(`${Strings.CHATS}_for_${workSpace._id}`);
      if (cachedChats) {
        setChats(cachedChats);
      }
    } catch (e) { }
  }

  useEffect(() => {
    if (filter === 'all') {
      setAllInboxes(originalInboxes);
    } else {
      let filteredMails = originalInboxes.filter(inbox => inbox?.owner === filter?.email);
      setAllInboxes(filteredMails);
    }
  }, [filter])


  const reloadPage = () => {
    setOpenAccountsConnector(false);
    let latestChatSettings = WorkSpaceManager.getActiveWorkSpace()?.chat_settings;
    setChatSettings({ ...latestChatSettings });
  }

  const connectAccounts = () => {
    setOpenAccountsConnector(true);
  }

  useEffect(() => {
    dispatch(setSearchHint("Search contacts..."));
    initChats();
  }, []);

  const handleSelect = e => {
    setHideSidebar(false);
    setIsOpenThreadInfo(false);
    const thread = threads.find(thread => thread.id === parseInt(e));
    setCurrentThread(thread);
    threadsDispatch({
      type: 'EDIT',
      id: thread?.id,
      payload: { ...thread, read: true }
    });
    setScrollToBottom(true);
  }

  const getAllUnifiedInbox = async (loadMore) => {
    try {
      if (loadMore) {
        setLoadingMore(true);
      } else {
        setFetchingMails(true);
      }
      let { data } = await APIService.getUnifiedInbox(workSpace._id, nextMailListToken);
      if (!data?.canFetchMoreMails) {
        setNoMoreMailsToFetch(true);
      } else {
        setNextMailListToken(data?.next_page_token ?? null);
        setNoMoreMailsToFetch(false);
      }
      setAllInboxes([...allInboxes, ...data?.emails])
      setOriginalInboxes([...originalInboxes, ...data?.emails])

      if (loadMore) {
        setLoadingMore(false);
      } else {
        setFetchingMails(false);
      }
    } catch (e) {
      if (loadMore) {
        setLoadingMore(false);
      } else {
        setFetchingMails(false);
      }
      console.log(e);
    }
  }

  useEffect(() => {
    getAllUnifiedInbox();
  }, [])


  return (
    <Tab.Container
      id="social-inbox-tab"
      defaultActiveKey="0"
      onSelect={handleSelect}
    >

      <Card className="card-chat overflow-hidden ">
        <div className='pt-0 ps-1 pe-1'>
          <KanbanHeader
            filter={filter}
            setFilter={setFilter}
            loadingMore={loadingMore}
          />
        </div>

        {
          !chatSettings.ready_for_messaging && (
            fetchingMails ?
              <Card.Body as={Flex} className="p-0 h-100">
                <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                  <Lottie animationData={FetchingDocs} />
                  <h6 className="mt-4">Fetching emails from your leads...</h6>
                </div>
              </Card.Body>
              :
              <Card.Body as={Flex} className="p-0 h-100">
                <ChatSidebar
                  allInboxes={allInboxes}
                  connectAccounts={connectAccounts}
                  hideSidebar={hideSidebar}
                  getAllUnifiedInbox={getAllUnifiedInbox}
                  noMoreMailsToFetch={noMoreMailsToFetch}
                />
                <ChatContent allInboxes={allInboxes} setHideSidebar={setHideSidebar} />
              </Card.Body>
          )
        }
      </Card>
      <Modal
        show={openAccountsConnector}
        onHide={() => { reloadPage() }}
        onExit={() => { reloadPage() }}
        centered
        size='lg'>
        <Modal.Header>
          <Modal.Title>
            Connect Accounts to Inbox
          </Modal.Title>
        </Modal.Header>
        <MessagingAccountsConnector />
      </Modal>
    </Tab.Container>
  );
};

const Chat = () => {
  return (
    <ChatProvider>
      <ChatTab />
    </ChatProvider>
  );
};

export default Chat;
