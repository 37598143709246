import { createSlice } from '@reduxjs/toolkit';
import WorkSpaceManager from 'utils/workspace_manager';
import UserManager from '../../utils/user_manager';
import { Strings } from '../../constants';

const loggedInUser = UserManager.getLoggedInUser() ?? {};

const initialState = {
    page: 'Welcome',
    pageSubtitle: '',
    openSearchWorkSpace: false,
    openEmailWorkSpace: false,
    headerSubContent: null,
    headerBackContent: null,
    unreadNotificationsCount: 0,
    allNotificationsCount: 0,
    refreshNotificationsCount: false,
    notifications: [],
    openSideBar: false,
    openJobTitleDialog: false,
    titleInWorkSpace: null,
    openCustomDayCreator: false,
    workspaceLogo: null,
    telegramBotLogo: null,
    loggedInUserName: `${loggedInUser['first_name'] ?? loggedInUser['email']} ${loggedInUser['last_name'] ?? ''}`,
    selectedAccountTab: 0,
    fetchTeam: true,
    team: null,
    searchHint: 'Search...',
    searchTerm: '',
    rightBarItemPositions: WorkSpaceManager.getRightBarSectionsPositions(),
    openPopularDays: false,
    panelVisibilityChoices: WorkSpaceManager.getPanelVisibilityChoices(),
    billedYearly: false,
    loggedInUserAvatar: loggedInUser?.avatar,
    loggedInUserAccountSlug: loggedInUser?.slug,
    openBulkWorkspaceCreator: false,
    reloadPage: false,
    bulkWorkSpacesToCreate: [],
    bulkEmailAccountsToCreate: [],
    bulkLeads: [],
    postEmailNotificationChoices: WorkSpaceManager.getPostEmailNotificationChoices(),
    socketIOEventListeners: [],
    socketIO: null
};

export const workSpaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        updateActivePageName: (state, action) => {
            state.page = action.payload;
        },
        updateActivePageSubTitle: (state, action) => {
            state.pageSubtitle = action.payload;
        },
        searchWorkSpace: (state, action) => {
            state.openSearchWorkSpace = action.payload;
        },
        searchEmail: (state, action) => {
            state.openEmailWorkSpace = action.payload;
        },
        setHeaderSubContent: (state, action) => {
            state.headerSubContent = action.payload;
        },
        updateHeaderBackContent: (state, action) => {
            state.headerBackContent = action.payload;
        },
        updateUnreadNotificationsCount: (state, action) => {
            state.unreadNotificationsCount = action.payload;
        },
        updateAllNotificationsCount: (state, action) => {
            state.allNotificationsCount = action.payload;
        },
        setRefreshNotificationsCount: (state, action) => {
            state.refreshNotificationsCount = action.payload;
        },
        updateNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        setOpenSideBar: (state, action) => {
            state.openSideBar = action.payload;
        },
        setLoggedInUserName: (state, action) => {
            state.loggedInUserName = action.payload;
        },
        setOpenJobTitleDialog: (state, action) => {
            state.openJobTitleDialog = action.payload;
        },
        setTitleInWorkSpace: (state, action) => {
            state.titleInWorkSpace = action.payload;
        },
        setOpenCustomDayCreator: (state, action) => {
            state.openCustomDayCreator = action.payload;
        },
        setWorkspaceLogo: (state, action) => {
            state.workspaceLogo = action.payload;
        },
        setSelectedAccountTab: (state, action) => {
            state.selectedAccountTab = action.payload;
        },
        setTelegramBotLogo: (state, action) => {
            state.telegramBotLogo = action.payload;
        },
        setCanFetchTeam: (state, action) => {
            state.fetchTeam = action.payload;
        },
        setTeam: (state, action) => {
            state.team = action.payload;
        },
        setOpenAICharAllocator: (state, action) => {
            state.openAICharAllocator = action.payload;
        },
        setSearchHint: (state, action) => {
            state.searchHint = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setRightBarItemPositions: (state, action) => {
            state.rightBarItemPositions = action.payload;
            WorkSpaceManager.updateRightBarSectionsPositions(action.payload);
        },
        setOpenPopularDays: (state, action) => {
            state.openPopularDays = action.payload;
        },
        setPanelVisibilityChoices: (state, action) => {
            state.panelVisibilityChoices = action.payload;
        },
        setPostEmailNotificationChoices: (state, action) => {
            state.postEmailNotificationChoices = action.payload;
        },
        setBilledYearly: (state, action) => {
            state.billedYearly = action.payload;
        },
        setLoggedInUserAvatar: (state, action) => {
            state.loggedInUserAvatar = action.payload;
            loggedInUser.avatar = action.payload;
            UserManager.setLoggedInUser(loggedInUser);
        },
        setLoggedInUserAccountSlug: (state, action) => {
            state.loggedInUserAccountSlug = action.payload;
            loggedInUser.slug = action.payload;
            UserManager.setLoggedInUser(loggedInUser);
        },
        setOpenBulkWorkspaceCreator: (state, action) => {
            state.openBulkWorkspaceCreator = action.payload;
        },
        setReloadPage: (state, action) => {
            state.reloadPage = action.payload;
        },
        setBulkWorkSpacesToCreate: (state, action) => {
            state.bulkWorkSpacesToCreate = action.payload;
        },
        setBulkEmailAccountsToCreate: (state, action) => {
            state.bulkEmailAccountsToCreate = action.payload;
        },
        setBulkCSVLeads: (state, action) => {
            state.bulkLeads = action.payload;
        },
        setSocketIOEventListeners: (state, action) => {
            state.socketIOEventListeners = action.payload;
        }
    }
});
export const activePageName = (state) => state.workspace.page;
export const getHeaderBackContent = (state) => state.workspace.headerBackContent;
export const activePageSubtitle = (state) => state.workspace.pageSubtitle;
export const getHeaderSubContent = (state) => state.workspace.headerSubContent;
export const canOpenWorkspaceSearch = (state) => state.workspace.openSearchWorkSpace;
export const canOpenWorkSpaceConnectedEmailSearch = (state) => state.workspace.openEmailWorkSpace;
export const canRefreshNotificationsCount = (state) => state.workspace.refreshNotificationsCount;
export const getUnreadNotificationsCount = (state) => state.workspace.unreadNotificationsCount;
export const getAllNotificationsCount = (state) => state.workspace.allNotificationsCount;
export const getNotifications = (state) => state.workspace.notifications;
export const openSideBar = (state) => state.workspace.openSideBar;
export const getLoggedInUserName = (state) => state.workspace.loggedInUserName;
export const canOpenJobTitleDialog = (state) => state.workspace.openJobTitleDialog;
export const canOpenAICharAllocator = (state) => state.workspace.openAICharAllocator;
export const getTitleInWorkSpace = (state) => state.workspace.titleInWorkSpace;
export const canOpenCustomDayCreator = (state) => state.workspace.openCustomDayCreator;
export const getWorkspaceLogo = (state) => state.workspace.workspaceLogo;
export const getSelectedAccountTab = (state) => state.workspace.selectedAccountTab;
export const getTelegramBotLogo = (state) => state.workspace.telegramBotLogo;
export const refreshTeam = (state) => state.workspace.fetchTeam;
export const getTeam = (state) => state.workspace.team;
export const getSearchHint = (state) => state.workspace.searchHint;
export const getSearchTerm = (state) => state.workspace.searchTerm;
export const getRightBarSectionsPositions = (state) => state.workspace.rightBarItemPositions;
export const canOpenPopularDays = (state) => state.workspace.openPopularDays;
export const getPanelVisibilityChoices = (state) => state.workspace.panelVisibilityChoices;
export const getPostEmailNotificationChoices = (state) => state.workspace.postEmailNotificationChoices;
export const getBilledYearly = (state) => state.workspace.billedYearly;
export const getLoggedInUserAvatar = (state) => state.workspace.loggedInUserAvatar;
export const getLoggedInUserAccountSlug = (state) => state.workspace.loggedInUserAccountSlug;
export const getOpenBulkWorkspaceCreator = (state) => state.workspace.openBulkWorkspaceCreator;
export const getReloadPage = (state) => state.workspace.reloadPage;
export const getBulkWorkSpacesToCreate = (state) => state.workspace.bulkWorkSpacesToCreate;
export const getBulkEmailAccountsToCreate = (state) => state.workspace.bulkEmailAccountsToCreate;
export const getBulkCSVLeads = (state) => state.workspace.bulkLeads;
export const getSocketIOEventListeners = (state) => state.workspace.socketIOEventListeners;
export const getActiveWorkSpace = (state) => {
    let realTimeActiveWorkspace = state.active_workspace;
    let localStorageWorkspaceString = localStorage.getItem(Strings.ACTIVE_WORKSPACE);
    if (realTimeActiveWorkspace) {
        return realTimeActiveWorkspace;
    }
    if (localStorageWorkspaceString) {
        return JSON.parse(localStorageWorkspaceString);
    }
    return null;
};
export const {
    updateActivePageName,
    updateActivePageSubTitle,
    updateHeaderBackContent,
    setHeaderSubContent,
    searchWorkSpace,
    searchEmail,
    updateUnreadNotificationsCount,
    updateAllNotificationsCount,
    setRefreshNotificationsCount,
    updateNotifications,
    setOpenSideBar,
    setLoggedInUserName,
    setOpenJobTitleDialog,
    setOpenAICharAllocator,
    setTitleInWorkSpace,
    setOpenCustomDayCreator,
    setWorkspaceLogo,
    setSelectedAccountTab,
    setTelegramBotLogo,
    setCanFetchTeam,
    setTeam,
    setSearchHint,
    setSearchTerm,
    setRightBarItemPositions,
    setOpenPopularDays,
    setPanelVisibilityChoices,
    setPostEmailNotificationChoices,
    setBilledYearly,
    setLoggedInUserAvatar,
    setLoggedInUserAccountSlug,
    setOpenBulkWorkspaceCreator,
    setReloadPage,
    setBulkWorkSpacesToCreate,
    setBulkEmailAccountsToCreate,
    setBulkCSVLeads,
    setSocketIOEventListeners
} = workSpaceSlice.actions;
export default workSpaceSlice.reducer;