import PageHeader from "components/common/PageHeader"
import { Card, ProgressBar } from "react-bootstrap"
import FalconCardHeader from "components/common/FalconCardHeader"
import FileUploader from "components/app/file-uploader"
import { useState } from "react"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { setBulkCSVLeads } from "../../../../redux/slices/workspaceslice"
import LeadBulkUploadPreviewer from "./LeadBulkUploadPreviewer"
import Flex from "components/common/Flex"
import StringUtils from "utils/string"
import { BulkCSVsUploadKnowlegdeLink } from 'constants';

const CSVLeadsImporter = (props) => {

  const sequenceUpdateHandler = props.sequenceUpdateHandler
  const [sequence] = useState(props.sequence)
  const [processing, setProcessing] = useState(false)
  const [openPreviewer, setOpenPreviewer] = useState(false)
  const dispatch = useDispatch()

  const processFile = (data) => {
    setProcessing(true)
    let dataEntries = Object.entries(data).map((entry) => entry)
    if (dataEntries.length > 0) {
      let expectedDataFormat = dataEntries[0][1]
      if (expectedDataFormat) {
        setProcessing(false)
        const transformedData = data.map((item) => {
          const newItem = {};
          const keys = Object.keys(item);
          keys.forEach((key) => {
            const snakeCaseKey = StringUtils.camelToSnake(key);
            newItem[snakeCaseKey] = item[key];
          });
          let firstName = newItem.firstName ?? newItem.first_name ?? newItem.firstname;
          let lastName = newItem.lastName ?? newItem.last_name ?? newItem.lastname;
          if (firstName && lastName) {
            newItem.name = `${firstName} ${lastName}`;
          } else if (newItem.full_name) {
            newItem.name = newItem.full_name;
          }
          return newItem;
        });
        dispatch(setBulkCSVLeads(transformedData))
        setOpenPreviewer(true)
      }
    } else {
      setProcessing(false)
      toast.error("CSV With empty columns submitted", { theme: "colored" })
    }
  }

  return (
    <Flex
      direction={"column"}
      style={{
        width: "75vw",
      }}
    >
      <PageHeader title="Upload Leads' CSV File" titleTag="h5" className="mb-3">
        {/* <p className="fs--1 mt-1">Upload a leads' CSV File</p> */}
      </PageHeader>
      {!openPreviewer && (
        <Card>
          <FalconCardHeader title="Pick CSV File" />
          <Card.Body className="bg-light">
            {!processing && (
              <FileUploader
                hint={"Select or Drag a CSV file here"}
                onFileLoaded={(data) => {
                  processFile(data)
                }}
              />
            )}
            {processing && (
              <div
                style={{
                  minHeight: 150,
                }}
              >
                <ProgressBar
                  variant="primary"
                  className="fs-1"
                  style={{ marginTop: "10%" }}
                  label="Processing File"
                  animated={true}
                  now={45}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      )}
      {openPreviewer && (
        <LeadBulkUploadPreviewer
          sequence={sequence}
          sequenceUpdateHandler={sequenceUpdateHandler}
          pickNewFile={() => {
            setOpenPreviewer(false)
            setProcessing(false)
          }}
          {...props}
        />
      )}
      <p
        style={{
          textAlign: 'center'
        }}
        className={'p-1'}>Your CSV Must adhere to
        <a href={BulkCSVsUploadKnowlegdeLink}
          target={'_blank'}
          rel='noreferrer'
          style={{
            paddingLeft: 5,
            textDecoration: 'none'
          }}>this format
        </a>
      </p>
    </Flex>
  )
}

export default CSVLeadsImporter
