import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    sequencesFromRedux: [],
    activeThread: null
};

export const sequenceSlice = createSlice({
    name: 'sequence',
    initialState,
    reducers: {
        setSequencesFromRedux: (state, action) => {
            state.sequencesFromRedux = action.payload;
        },
        setActiveThread: (state, action) => {
            state.activeThread = action.payload;
        },
    }
});

export const getSequencesFromRedux = (state) => state.sequence.sequencesFromRedux;
export const getActiveThread = (state) => state.sequence.activeThread;

export const {
    setSequencesFromRedux,
    setActiveThread
} = sequenceSlice.actions;
export default sequenceSlice.reducer;