import axios from "axios"
import EndPoints from "./endpoints"
import HttpSuccessDataHandler from "../utils/http_success_data_handler"
import HttpErrorHandler from "../utils/http_error_handler"
import { AuthMethod } from "../constants"
import UserManager from "../utils/user_manager"
import WorkSpaceManager from "../utils/workspace_manager"
import { generateQueryFromFilterOptions } from "../utils/url"
import io from "socket.io-client"
import StringUtils from "../utils/string"
// import { getStorage, ref } from 'firebase/storage';
import { promisify } from "util"
import numberToWordConverter from "number-to-words"
import { getFileType } from "../utils/file_utils"
import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default class APIService {
  static async fetchCountries(cb) {
    axios
      .get(EndPoints.SUPPORTED_COUNTRIES, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchYouTubeVideoCategories(workSpaceId, regionCode, cb) {
    axios
      .get(
        EndPoints.YOUTUBE_VIDEO_CATEGORIES.replace(
          ":workspace_id",
          workSpaceId,
        ).replace(":regionCode", regionCode),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async signUpWithMagicLink(email, cb) {
    let requestBody = { email, auth_method: AuthMethod.PASSWORD_LESS }
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async validatePasswordLessConfirmationCode(code, owner_email, cb) {
    let requestBody = { confirmation_code: code, owner_email }
    axios
      .post(EndPoints.CONFIRM_PASSWORD_LESS_CONFIRMATION_CODE, requestBody)
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async checkUserWorkSpaces(apiKey, cb) {
    let requestBody = { apiKey }
    axios
      .post(EndPoints.DOES_USER_HAVE_WORKSPACES, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchUserWorkSpaces(cb) {
    axios
      .get(EndPoints.USER_WORKSPACES, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchUserWorkSpacesSync() {
    try {
      let response = await axios.get(EndPoints.USER_WORKSPACES, {
        headers: await this.getRequestHeaders(),
      })
      return HttpSuccessDataHandler.getSuccessResponseData(response)
    } catch (e) {
      throw HttpErrorHandler.spitHttpErrorMsg(e)
    }
  }

  static async fetchTeam(workSpaceId, cb) {
    axios
      .get(EndPoints.TEAM.replace(":workspace_id", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async inviteTeamMember(workSpaceId, email, role, cb) {
    axios
      .post(
        EndPoints.INVITE_TEAM_MEMBER.replace(":workspace_id", workSpaceId),
        { email, role },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateMemberRole(workSpaceId, memberId, role, cb) {
    axios
      .post(
        EndPoints.UPDATE_MEMBER_ROLE.replace(":workspace_id", workSpaceId),
        { memberId, role },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async acceptTeamInvite(token, cb) {
    axios
      .post(
        EndPoints.ACCEPT_TEAM_INVITE,
        { token },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchPendingTeamInvites(workSpaceId, cb) {
    axios
      .get(
        EndPoints.PENDING_TEAM_INVITES.replace(":workspace_id", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async cancelPendingTeamInvite(workSpaceId, token, cb) {
    axios
      .delete(
        EndPoints.CANCEL_PENDING_TEAM_INVITE.replace(
          ":workspace_id",
          workSpaceId,
        ).replace(":token", encodeURIComponent(token)),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeTeamMember(workSpaceId, team_member_id, cb) {
    axios
      .post(
        EndPoints.REMOVE_TEAM_MEMBER.replace(":workspace_id", workSpaceId),
        { team_member_id },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchMyWorkSpaceRole(workSpaceId, cb) {
    axios
      .get(EndPoints.MY_WORKSPACE_ROLE.replace(":workspace_id", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchWorkSpaceOwner(workSpaceId, cb) {
    axios
      .get(EndPoints.WORKSPACE_OWNER.replace(":workspace_id", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchWorkSpaceDetailsFromInviteToken(token, cb) {
    axios
      .get(
        EndPoints.WORKSPACE_DETAILS_FROM_INVITE_TOKEN.replace(
          ":invite_token",
          token,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchWorkSpace(workSpaceId, cb) {
    axios
      .get(`${EndPoints.WORKSPACE}/${workSpaceId}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async logInManually(email, password, cb) {
    let requestBody = {
      email,
      auth_method: AuthMethod.PASSWORD,
      password,
    }
    axios
      .post(EndPoints.SIGN_IN, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async verifyNewEmailAddress(token, cb) {
    let requestBody = { token }
    axios
      .post(EndPoints.VERIFY_NEW_EMAIL_ADDRESS, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async logOut(cb) {
    axios
      .get(EndPoints.LOG_OUT, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async createAccountManually(email, password, displayName, cb) {
    let requestBody = {
      email,
      password,
      displayName,
      auth_method: AuthMethod.PASSWORD,
    }
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updatePersonalInfo(data, cb) {
    let requestBody = { data }
    axios
      .put(EndPoints.UPDATE_PERSONAL_INFO, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateTitleInWorkspace(data, cb) {
    let requestBody = { ...data }
    axios
      .put(EndPoints.UPDATE_TITLE_IN_WORKSPACE, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateSecurityInfo(oldPassword, newPassword, cb) {
    let requestBody = { newPassword, oldPassword }
    axios
      .put(EndPoints.UPDATE_ACCOUNT_PASSWORD, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async createGoogleAccount(email, displayName, photoURL, token, cb) {
    let requestBody = {
      email,
      displayName,
      auth_method: AuthMethod.GOOGLE_AUTH,
      photoURL,
      accessToken: token,
    }
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async createFacebookAccount(
    email,
    displayName,
    photoURL,
    token,
    uid,
    cb,
  ) {
    let requestBody = {
      email,
      displayName,
      auth_method: AuthMethod.FACEBOOK_AUTH,
      photoURL,
      accessToken: token,
      uid,
    }
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async initiatePasswordReset(email, cb) {
    let requestBody = { email }
    axios
      .post(EndPoints.INITIATE_PASSWORD_RESET, requestBody)
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async finalizePasswordReset(token, newPassword, cb) {
    let requestBody = { token, newPassword }
    axios
      .post(EndPoints.FINALIZE_PASSWORD_RESET, requestBody)
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async createNewWorkSpace(
    workSpaceName,
    firstName,
    lastName,
    workSpaceSettings,
    attributes,
    cb,
  ) {
    let requestBody = {
      name: workSpaceName,
      firstName,
      lastName,
      settings: workSpaceSettings,
      attributes,
    }
    axios
      .post(EndPoints.NEW_WORKSPACE, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateWorkSpaceName(workSpaceId, new_name, cb) {
    let requestBody = { new_name }
    axios
      .post(
        EndPoints.UPDATE_WORKSPACE_NAME.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async bulkWorkspaceNamesUpdate(data, cb) {
    axios
      .put(
        EndPoints.BULK_UPDATE_WORKSPACE_NAMES,
        { data },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateWorkspaceTheme(workSpaceId, theme, cb) {
    let requestBody = { theme }
    axios
      .post(
        EndPoints.UPDATE_WORKSPACE_THEME.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async togglePanelData(workSpaceId, panelData, cb) {
    let requestBody = { panelData }
    axios
      .post(
        EndPoints.TOGGLE_PANEL.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchSimilarHashTags(
    workSpaceId,
    referenceHashTag,
    forAIWriter,
    cb,
  ) {
    axios
      .get(
        `${EndPoints.SIMILAR_HASHTAGS.replace(
          ":workSpaceId",
          workSpaceId,
        )}?hashTag=${encodeURIComponent(
          referenceHashTag,
        )}&for_ai_writer=${forAIWriter}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchTaggableUsersOrPublicPage(workSpaceId, search, cb) {
    axios
      .get(
        `${EndPoints.TAGGABLE_USER_OR_PUBLIC_PAGE.replace(
          ":workSpaceId",
          workSpaceId,
        )}?search=${search}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updatePostEmailNotificationSettings(
    workSpaceId,
    notificationChoices,
    cb,
  ) {
    let requestBody = { notificationChoices }
    axios
      .post(
        EndPoints.POST_EMAIL_NOTIFICATIONS.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async preprocessWorkspaceTransfer(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.PREPROCESS_WORKSPACE_TRANSFER.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async finalizeWorkspaceTransfer(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.FINALIZE_WORKSPACE_TRANSFER.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async turnOffAllWorkSpacePostsAndCampaigns(workSpaceId, turnOff, cb) {
    let requestBody = { turnOff }
    axios
      .post(
        EndPoints.TURN_OFF_ALL_POSTS_AND_CAMPAIGNS.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async setDefaultTimeZoneForPublishing(
    workSpaceId,
    selectedTimeZone,
    cb,
  ) {
    let requestBody = { selectedTimeZone }
    axios
      .post(
        EndPoints.DEFAULT_TIMEZONE_FOR_PUBLISHING.replace(
          ":workspace_id",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async pausePost(workSpaceId, postId, pause, cb) {
    let requestBody = { pause }
    axios
      .post(
        EndPoints.PAUSE_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateWorkSpaceLogo(workSpaceId, requestBody, cb) {
    axios
      .put(
        EndPoints.UPDATE_WORKSPACE_LOGO.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateUserAvatar(requestBody, cb) {
    axios
      .put(EndPoints.USER_AVATAR, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateAccountLogo(requestBody, cb) {
    axios
      .put(EndPoints.ACCOUNT_LOGO, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateAccountDetails(requestBody, cb) {
    axios
      .post(EndPoints.UPDATE_ACCOUNT_DETAILS, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateWorkSpaceSettings(workSpaceId, settings, cb) {
    let requestBody = { settings }
    axios
      .post(
        EndPoints.UPDATE_WORKSPACE_SETTINGS.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateLastViewedWorkSpace(workSpaceId, cb) {
    let requestBody = { workSpaceId }
    axios
      .post(EndPoints.UPDATE_LAST_VIEWED_WORKSPACE, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteWorkSpace(workSpaceId, cb) {
    axios
      .delete(EndPoints.DELETE_WORKSPACE.replace(":workSpaceId", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async archiveWorkSpaces(workSpaceIds, archive, cb) {
    let requestBody = { workSpaceIds, archive }
    axios
      .post(EndPoints.ARCHIVE_WORKSPACES, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteWorkspaces(workSpaceIds, cb) {
    let requestBody = { workSpaceIds }
    axios
      .post(EndPoints.DELETE_WORKSPACES, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteWorkSpaceSynchronously(workSpaceId) {
    try {
      let response = await axios.delete(
        EndPoints.DELETE_WORKSPACE.replace(":workSpaceId", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      return HttpSuccessDataHandler.getSuccessResponseData(response)
    } catch (e) {
      throw e
    }
  }

  static async deleteAccount(cb) {
    axios
      .delete(EndPoints.DELETE_ACCOUNT, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async addNewWorkSpacePlatform(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.ADD_NEW_WORKSPACE_PLATFORM.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }
  static async postNewScheduleCleanUpDate(requestBody, cb) {
    axios
      .post(EndPoints.POST_NEW_SCHEDULED_RESOURCE_CLEAN_UP_DATE, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async connectPageOrGroup(
    workSpaceId,
    platform_identifier,
    user_id,
    page_or_group_id,
    requestBody,
    cb,
  ) {
    axios
      .post(
        EndPoints.CONNECT_PAGE_OR_GROUP.replace(":workSpaceId", workSpaceId)
          .replace(":platform_identifier", platform_identifier)
          .replace(":page_or_group_id", page_or_group_id)
          .replace(":user_id", user_id),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async disconnectPageOrGroup(
    workSpaceId,
    platform_identifier,
    target,
    user_id,
    page_or_group_id,
    cb,
  ) {
    axios
      .delete(
        EndPoints.DISCONNECT_PAGE_OR_GROUP.replace(":workSpaceId", workSpaceId)
          .replace(":platform_identifier", platform_identifier)
          .replace(":page_or_group_id", page_or_group_id)
          .replace(":target", target)
          .replace(":user_id", user_id),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async connectPageOrAccountForMessaging(
    workSpaceId,
    platform_identifier,
    user_id,
    page_or_account_id,
    requestBody,
    cb,
  ) {
    axios
      .post(
        EndPoints.CONNECT_PAGE_OR_ACCOUNT_FOR_MESSAGING.replace(
          ":workSpaceId",
          workSpaceId,
        )
          .replace(":platform_identifier", platform_identifier)
          .replace(":page_or_account_id", page_or_account_id)
          .replace(":user_id", user_id),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async disconnectPageOrAccountFromMessaging(
    workSpaceId,
    platform_identifier,
    target,
    user_id,
    page_or_account_id,
    cb,
  ) {
    axios
      .delete(
        EndPoints.DISCONNECT_PAGE_OR_ACCOUNT_FROM_MESSAGING.replace(
          ":workSpaceId",
          workSpaceId,
        )
          .replace(":platform_identifier", platform_identifier)
          .replace(":page_or_account_id", page_or_account_id)
          .replace(":target", target)
          .replace(":user_id", user_id),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeWorkSpacePlatform(workSpaceId, identifier, userId, cb) {
    axios
      .delete(
        EndPoints.REMOVE_WORKSPACE_PLATFORM.replace(":workSpaceId", workSpaceId)
          .replace(":platform_identifier", identifier)
          .replace(":user_id", userId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeCorruptedPlatforms(workSpaceId, corruptedPlatforms, cb) {
    axios
      .post(
        EndPoints.REMOVE_CORRUPTED_PLATFORMS.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        {
          corruptedPlatforms,
        },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        if (cb) {
          cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
        }
      })
      .catch((e) => {
        if (cb) {
          cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
        }
      })
  }

  static async fetchAllWorkSpacePosts(
    workSpaceId,
    filters,
    postStatus,
    postSearchTerm,
    skip,
    cb,
  ) {
    let requestQuery = { ...filters }
    if (postStatus) {
      requestQuery["post_status"] = postStatus
    }
    if (postSearchTerm) {
      requestQuery["search_string"] = postSearchTerm
    }
    if (skip) {
      requestQuery["page"] = 1
      requestQuery["limit"] = skip
    }
    let query = generateQueryFromFilterOptions(requestQuery)
    axios
      .get(
        `${EndPoints.ALL_POSTS.replace(":workSpaceId", workSpaceId)}?${query}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async countWorkspacePosts(workSpaceId, criteria, cb) {
    axios
      .get(
        EndPoints.COUNT_POSTS.replace(":workSpaceId", workSpaceId).replace(
          ":criteria",
          criteria,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchUpcomingPopularEvents(workSpaceId, cb) {
    axios
      .get(
        EndPoints.UPCOMING_POPULAR_EVENTS.replace(":workspace_id", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchSupportedCountries(workSpaceId, cb) {
    axios
      .get(
        EndPoints.SUPPORTED_COUNTRIES.replace(":workspace_id", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async upsertCustomEvent(workSpaceId, data, cb) {
    let requestBody = { ...data }
    axios
      .post(
        EndPoints.UPSERT_CUSTOM_EVENT_DAY.replace(":workspace_id", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteCustomEventDay(workSpaceId, eventId, cb) {
    axios
      .delete(
        EndPoints.DELETE_CUSTOM_EVENT.replace(
          ":workspace_id",
          workSpaceId,
        ).replace(":eventId", eventId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async addNewLocationsToDays(workSpaceId, data, cb) {
    let requestBody = { ...data }
    axios
      .post(
        EndPoints.ADD_NEW_DAYS_LOCATION.replace(":workspace_id", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeLocationFromDays(workSpaceId, locationName, cb) {
    axios
      .delete(
        EndPoints.REMOVE_LOCATION_FROM_WORKSPACE_DAYS.replace(
          ":workspace_id",
          workSpaceId,
        ).replace(":location", encodeURIComponent(locationName)),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchTendingTopics(workSpaceId, cb) {
    axios
      .get(
        EndPoints.GET_AVAILABLE_TRENDS.replace(":workspace_id", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchUserNotifications(cb) {
    axios
      .get(EndPoints.USER_NOTIFICATIONS, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateNotificationReadStatus(notificationId, status, cb) {
    axios
      .put(
        `${EndPoints.READ_NOTIFICATION.replace(":id", notificationId)}`,
        {
          status: status,
        },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        if (cb) {
          cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
        }
      })
      .catch((e) => {
        if (cb) {
          cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
        }
      })
  }

  static async clearAllNotifications(cb) {
    axios
      .delete(EndPoints.CLEAR_ALL_NOTIFICATIONS, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchDraftedWorkSpacePosts(workSpaceId, cb) {
    axios
      .get(`${EndPoints.DRAFTED_POSTS.replace(":workSpaceId", workSpaceId)}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchAllUserDrafts(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.ALL_USER_DRAFTS.replace(":workSpaceId", workSpaceId)}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchScheduledWorkSpacePosts(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.SCHEDULED_POSTS.replace(":workSpaceId", workSpaceId)}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchPublishedWorkSpacePosts(workSpaceId, queryString, cb) {
    axios
      .get(
        `${EndPoints.PUBLISHED_POSTS.replace(
          ":workSpaceId",
          workSpaceId,
        )}?${queryString}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchWorkSpacePopularPosts(workSpaceId, cb) {
    axios
      .get(`${EndPoints.POPULAR_POSTS.replace(":workSpaceId", workSpaceId)}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchWorkSpaceActivities(workSpaceId, cb) {
    axios
      .get(`${EndPoints.ACTIVITIES.replace(":workSpaceId", workSpaceId)}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchWorkSpacePlatforms(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.WORKSPACE_PLATFORMS.replace(":workSpaceId", workSpaceId)}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchSubredditFlairs(workSpaceId, userId, subReddit, cb) {
    axios
      .get(
        `${EndPoints.SUBREDDIT_FLAIRS.replace(":workSpaceId", workSpaceId)
          .replace(":user_id", userId)
          .replace(":subreddit", subReddit)}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchConnectedAccounts(workSpaceId, platformIdentifier, cb) {
    axios
      .get(
        EndPoints.CONNECTED_ACCOUNTS.replace(
          ":workSpaceId",
          workSpaceId,
        ).replace(":identifier", platformIdentifier),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchConnectedSocialsCount(workSpaceId, cb) {
    axios
      .get(
        EndPoints.CONNECTED_PLATFORMS_COUNT.replace(
          ":workspace_id",
          workSpaceId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchPagesAndGroups(identifier, user_id, cb, query = "") {
    let activeWorkSpace = WorkSpaceManager.getActiveWorkSpace()
    let activeWorkSpaceId = activeWorkSpace["_id"]
    let url = EndPoints.PLATFORMS_PAGES_AND_GROUPS.replace(
      ":workSpaceId",
      activeWorkSpaceId,
    )
      .replace(":platform_identifier", identifier)
      .replace(":user_id", user_id)
      .concat(query)
    axios({
      method: "GET",
      url: url,
      headers: await this.getRequestHeaders(),
      timeout: 120000,
    })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchLatestMe(cb) {
    axios
      .get(EndPoints.ME, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchLatestMeSync() {
    try {
      let response = await axios.get(EndPoints.ME, {
        headers: await this.getRequestHeaders(),
      })
      return HttpSuccessDataHandler.getSuccessResponseData(response)
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e)
    }
  }

  static async postToWorkSpace(workSpaceId, requestBody, cb) {
    axios
      .put(
        EndPoints.POST_TO_WORKSPACE.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async uploadBulkContent(workSpaceId, requestBody, cb) {
    axios
      .put(
        EndPoints.BULK_CONTENT_UPLOAD.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async uploadBulkWorkspaces(requestBody, cb) {
    axios
      .post(EndPoints.CREATE_BULK_WORKSPACES, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async uploadBulkEmailAccounts(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.CONNECT_BULK_EMAIL_ACCOUNTS.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async uploadBulkCSVLeads(workSpaceId, requestBody, listId) {
    const response = await axios.post(
      EndPoints.UPLOAD_BULK_CSV_LEADS.replace(
        ":workSpaceId",
        workSpaceId,
      ).replace(":listId", listId),
      requestBody,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }
  static async verifyDomainRecords(workSpaceId, requestBody) {
    const response = await axios.post(
      EndPoints.VERIFY_DOMAIN_RECORDS.replace(
        ":workSpaceId",
        workSpaceId,
      ),
      requestBody,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }
  static async verifySpecificDomainRecords(workSpaceId, requestBody) {
    const response = await axios.post(
      EndPoints.VERIFY_SPECIFIC_EMAILS_DOMAIN_RECORDS.replace(
        ":workSpaceId",
        workSpaceId,
      ),
      requestBody,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async countWorkspaceLeads(workSpaceId, cb) {
    axios
      .get(
        EndPoints.COUNT_WORKSPACE_LEADS.replace(":workSpaceId", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async countRecentWorkspaceLeads(workSpaceId, cb) {
    axios
      .get(
        EndPoints.COUNT_RECENT_WORKSPACE_LEADS.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async getLeadsFromGoogleSheet(
    workSpaceId,
    campaign_id,
    sheetLink,
    cb,
  ) {
    axios
      .get(
        EndPoints.LEADS_FROM_GOOGLE_SHEET.replace(":workSpaceId", workSpaceId)
          .replace(":campaign_id", campaign_id)
          .concat(`?sheet_link=${encodeURIComponent(sheetLink)}`),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updatePostTime(workSpaceId, postId, new_time, cb) {
    axios
      .put(
        EndPoints.POST_TIME.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId,
        ),
        { new_time },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updatePost(workSpaceId, postId, postData, cb) {
    axios
      .put(
        EndPoints.UPDATE_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId,
        ),
        { postData },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static getOrdinalSuffix(index) {
    if (index === 0 || index === 1) {
      return " "
    }
    return numberToWordConverter.toOrdinal(index)
  }

  static async uploadFileToFirebase(
    {
      file,
      filename,
      fileTag,
      fileIndex = 1,
      progressMessage,
      progressCallback,
      doneCallback,
      errorCallback,
      listenerEvent = "storage",
      progressKey = "content_upload_progress",
    },
    cb,
  ) {

    // Initialize Firebase Storage
    // const storage = getStorage();
    // Create a reference to the storage location
    // const storageRef = ref(storage);
    // Create a reference to a specific file using a dynamically generated path
    const fileRef = ref(
      storage,
      `${StringUtils.generateRandomString(16)}-${Date.now()}-${filename}`
    );
    // let uploadTask = fileRef.put(file)
    const uploadTask = uploadBytesResumable(fileRef, file);
    const fileType = getFileType(filename)
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        let progressText = `Uploading ${APIService.getOrdinalSuffix(
          fileIndex,
        )} ${StringUtils.capitalizeString(fileType.toLowerCase())} ${fileTag ? ` for ${fileTag}` : ""
          }`
        if (progressCallback) {
          progressCallback(progress)
        } else {
          localStorage.setItem(
            progressKey,
            JSON.stringify({
              message: progressMessage ?? progressText,
              progress: Math.round(progress),
            }),
          )
          window.dispatchEvent(new Event(listenerEvent))
        }
        if (progress >= 100) {
          localStorage.removeItem(progressKey)
        }
      },
      (error) => {
        console.log(error)
        if (errorCallback) {
          errorCallback(
            "Sorry, an error occurred while uploading your file. Please try again later",
          )
        } else {
          cb(
            {
              statusCode: 500,
              message:
                "Sorry, an error occurred while uploading your file. Please try again later",
            },
            null,
          )
        }
      },
      () => {
        // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (doneCallback) {
            doneCallback(downloadURL)
          } else {
            cb(null, {
              statusCode: 200,
              message: "Upload successful",
              data: downloadURL,
            })
          }
        })
      },
    )
  }

  static async uploadNewFile(
    _workSpaceId,
    requestBody,
    fileSize,
    fileTag,
    fileIndex,
  ) {
    const formDataObj = {}
    requestBody.forEach((value, key) => (formDataObj[key] = value))
    let filename = formDataObj.file.name
    let firebaseUpload = promisify(APIService.uploadFileToFirebase)
    try {
      let res = await firebaseUpload({
        file: formDataObj.file,
        filename,
        fileIndex,
        fileTag,
        fileSize,
      })
      return res
    } catch (e) {
      throw new Error("Sorry, an error occurred. Please try again")
    }
  }

  static transcodeSocket
  static async transcodeVideo(
    workSpaceId,
    videoRequestBody,
    platform,
    videoSize = 0,
    listeners = {},
  ) {
    let videoTag = StringUtils.generateRandomString(64)
    let transcodeBaseUrl = "https://video-transcoder.postly.ai"
    if (!APIService.transcodeSocket) {
      APIService.transcodeSocket = io(transcodeBaseUrl, {
        transports: ["websocket"],
      })
    }
    let transcodeUpdateListener = (data) => {
      if (data.upload_progress) {
        listeners?.uploadProgressListener(data.upload_progress)
      }
      if (data.transcode_progress) {
        listeners?.transcodeProgressListener(data.transcode_progress)
      }
      if (data.transcode_final_result) {
        listeners?.completionListener({ data: data.transcode_final_result })
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener)
      }
      if (data.transcode_error) {
        listeners?.errorListener(data.transcode_error)
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener)
      }
    }
    APIService.transcodeSocket.on(videoTag, transcodeUpdateListener)
    return axios({
      method: "PUT",
      url: `${transcodeBaseUrl}/v1/:workSpaceId/:platform/convert_video`
        .replace(":workSpaceId", workSpaceId)
        .replace(":platform", platform),
      data: videoRequestBody,
      headers: await this.getRequestHeaders({
        fileSize: videoSize,
        videoTag,
      }),
    })
      .then((response) => {
        listeners?.completionListener(
          HttpSuccessDataHandler.getSuccessResponseData(response),
        )
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener)
      })
      .catch((e) => {
        listeners?.errorListener(HttpErrorHandler.spitHttpErrorMsg(e))
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener)
      })
  }

  static async compressVideo(
    workSpaceId,
    videoRequestBody,
    platform,
    videoSize = 0,
    listeners = {},
  ) {
    let videoTag = StringUtils.generateRandomString(64)
    let transcodeBaseUrl = "https://video-transcoder.postly.ai"
    if (!APIService.transcodeSocket) {
      APIService.transcodeSocket = io(transcodeBaseUrl, {
        transports: ["websocket"],
      })
    }
    let transcodeUpdateListener = (data) => {
      if (data.upload_progress) {
        listeners?.uploadProgressListener(data.upload_progress)
      }
      if (data.compress_progress) {
        listeners?.compressProgressListener(data.compress_progress)
      }
      if (data.compress_final_result) {
        listeners?.completionListener({ data: data.compress_final_result })
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener)
      }
      if (data.transcode_error) {
        listeners?.errorListener(data.compress_error)
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener)
      }
    }
    APIService.transcodeSocket.on(videoTag, transcodeUpdateListener)
    return axios({
      method: "PUT",
      url: `${transcodeBaseUrl}/v1/:workSpaceId/:platform/compress_video`
        .replace(":workSpaceId", workSpaceId)
        .replace(":platform", platform),
      data: videoRequestBody,
      headers: await this.getRequestHeaders({
        fileSize: videoSize,
        videoTag,
      }),
    })
      .then((response) => {
        listeners?.completionListener(
          HttpSuccessDataHandler.getSuccessResponseData(response),
        )
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener)
      })
      .catch((e) => {
        listeners?.errorListener(HttpErrorHandler.spitHttpErrorMsg(e))
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener)
      })
  }

  static async deletePost(workSpaceId, postId, cb) {
    axios
      .delete(
        EndPoints.POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async multiDeletePosts(workSpaceId, postIds, moreData = {}, cb) {
    let requestBody = { postIds, ...moreData }
    axios
      .post(
        EndPoints.DELETE_ALL_POSTS.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteWorkSpacePostFromOnlyPostly(workSpaceId, postId, cb) {
    axios
      .delete(
        EndPoints.DELETE_POST_FROM_ONLY_POSTLY.replace(
          ":workSpaceId",
          workSpaceId,
        ).replace(":post_id", postId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async getWordpressPostCategories(siteUrl, cb) {
    axios
      .get(`${EndPoints.GET_WORDPRESS_CATEGORIES}?siteUrl=${siteUrl}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteWorkSpacePostFromOnlyPlatformsItWasPublishedTo(
    workSpaceId,
    postId,
    cb,
  ) {
    axios
      .delete(
        EndPoints.DELETE_POST_FROM_ONLY_PUBLISHED_PLATFORMS.replace(
          ":workSpaceId",
          workSpaceId,
        ).replace(":post_id", postId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deletePostBatch(workSpaceId, batchId, cb) {
    axios
      .delete(
        EndPoints.POST_BATCH.replace(":workSpaceId", workSpaceId).replace(
          ":batch_id",
          batchId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteRecurringPost(workSpaceId, postGroupIdentifier, cb) {
    axios
      .delete(
        EndPoints.RECURRING_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_group_identifier",
          postGroupIdentifier,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchRecurringPostDetails(workSpaceId, postGroupIdentifier, cb) {
    axios
      .get(
        EndPoints.RECURRING_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_group_identifier",
          postGroupIdentifier,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchPost(workSpaceId, postId, cb) {
    axios
      .get(
        EndPoints.POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchWorkSpacePostCategories(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.POST_CATEGORIES.replace(":workSpaceId", workSpaceId)}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async createNewPostCategory(workSpaceId, name, createdBy, cb) {
    let requestBody = {
      name,
      createdBy,
    }
    axios
      .post(
        `${EndPoints.NEW_POST_CATEGORY.replace(":workSpaceId", workSpaceId)}`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deletePostCategory(workSpaceId, categoryId, cb) {
    axios
      .delete(
        `${EndPoints.DELETE_POST_CATEGORY.replace(
          ":workSpaceId",
          workSpaceId,
        ).replace(":categoryId", categoryId)}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async upsertNewAudienceGroup(workSpaceId, requestBody, cb) {
    axios
      .post(
        `${EndPoints.UPSERT_AUDIENCE_GROUP.replace(
          ":workSpaceId",
          workSpaceId,
        )}`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async countAudienceGroup(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.AUDIENCE_GROUP_COUNT.replace(
          ":workSpaceId",
          workSpaceId,
        )}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchAudienceGroups(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.AUDIENCE_GROUP_FETCH.replace(
          ":workSpaceId",
          workSpaceId,
        )}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteAudienceGroup(workSpaceId, groupId, cb) {
    axios
      .delete(
        `${EndPoints.DELETE_AUDIENCE_GROUP.replace(
          ":workSpaceId",
          workSpaceId,
        ).replace(":group_id", groupId)}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async createNewPinterestBoard(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.CREATE_NEW_PINTEREST_BOARD.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async getGoogleDriveMedias(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.GET_GOOGLE_DRIVE_MEDIAS.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }
  static async disconnectGDrive(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.DISCONNECT_GOOGLE_DRIVE.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async createNewPinterestBoardSection(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.CREATE_NEW_PINTEREST_BOARD_SECTION.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async redeemVoucher(code, accountId, cb) {
    let requestBody = { code, accountId }
    axios
      .post(EndPoints.REDEEM_VOUCHER, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async billUser(
    annualBillingChecked,
    payment_for_new_workspace = false,
    workspace_owner_id,
    initiated_for_workspace_creation = false,
    plan_type,
    cb,
  ) {
    let requestBody = {
      pay_annually: annualBillingChecked,
      payment_for_new_workspace,
      workspace_owner_id,
      initiated_for_workspace_creation,
      plan_type,
    }
    axios
      .post(EndPoints.BILL_USER, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async buyMoreAIWriterQuotas(annualBillingChecked, workspace_id, cb) {
    let requestBody = {
      pay_annually: annualBillingChecked,
      plan_type: "ExtraAICharacters",
      workspace_id,
      payment_for_extra_ai_characters: true,
    }
    axios
      .post(EndPoints.BILL_USER, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async payOneOffFeeForCustomTelegramBot(workspace_id, path_param, cb) {
    let requestBody = {
      plan_type: "CustomTelegramBot",
      workspace_id,
      path_param,
      payment_for_customized_telegram_bot: true,
    }
    axios
      .post(EndPoints.BILL_USER, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchPendingBotRequests(requestBody, cb) {
    axios
      .post(EndPoints.BOT_REQUESTS, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async requestForCustomizedBot(workSpaceId, requestBody, cb) {
    axios
      .put(
        EndPoints.REQUEST_FOR_CUSTOMIZED_TELEGRAM_BOT.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async saveCopy(workSpaceId, tool, text, cb) {
    axios
      .post(
        EndPoints.SAVE_COPY.replace(":workSpaceId", workSpaceId),
        { tool, text },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeCopy(workSpaceId, copyId, cb) {
    axios
      .delete(
        EndPoints.REMOVE_COPY.replace(":workSpaceId", workSpaceId).replace(
          ":copy_id",
          copyId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteAllSavedCopies(workSpaceId, cb) {
    axios
      .delete(
        EndPoints.DELETE_ALL_SAVED_COPIES.replace(":workSpaceId", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchSavedCopies(workSpaceId, tool, cb) {
    axios
      .get(
        EndPoints.SAVED_COPIES.replace(":workSpaceId", workSpaceId).replace(
          ":tool",
          encodeURIComponent(tool),
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchAllSavedCopies(workSpaceId, cb) {
    axios
      .get(EndPoints.ALL_SAVED_COPIES.replace(":workSpaceId", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async cancelSubscription(cb) {
    axios
      .delete(EndPoints.CANCEL_SUBSCRIPTION, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async cancelAIWriterSubscription(cb) {
    axios
      .delete(EndPoints.CANCEL_AI_WRITER_SUBSCRIPTION, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async showAttachedVoucherCode(used_by, cb) {
    axios
      .get(EndPoints.FETCH_VOURCHERS_BY_ACCOUNT, {
        params: { used_by },
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchUserDraft(workSpaceId, cb) {
    axios
      .get(EndPoints.USER_DRAFT.replace(":workSpaceId", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async generateText(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.GENERATE_TEXT.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async checkAIWriterUsageResumption(workSpaceId, cb) {
    axios
      .get(
        EndPoints.CHECK_AI_WRITER_USAGE_RESUMPTION.replace(
          ":workSpaceId",
          workSpaceId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchWorkSpaceBots(workSpaceId, platformUserId, cb) {
    axios
      .get(
        EndPoints.WORKSPACE_BOTS.replace(":work_space_id", workSpaceId).replace(
          ":platform_user_id",
          platformUserId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchTaggables(query, cb) {
    axios
      .get(`${EndPoints.TAGGABLES}?q=${encodeURIComponent(query)}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async setDefaultBot(workSpaceId, platformUserId, token, cb) {
    axios
      .post(
        EndPoints.SET_DEFAULT_BOT.replace(
          ":work_space_id",
          workSpaceId,
        ).replace(":platform_user_id", platformUserId),
        {
          botToken: token,
        },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async shortenLink(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/shorten_link`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }
  static async getNewGDriveAccessToken(requestBody, cb) {
    axios
      .post(`${EndPoints.GET_GOOGLE_ACCESS_TOKEN}`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async getRequestHeaders(moreHeaders = {}) {
    let headers = {}
    let loggedInUser = UserManager.getLoggedInUser()
    if (loggedInUser) {
      headers["X-API-Key"] = loggedInUser["api_key"]
    }
    let allHeaders = { ...headers, ...moreHeaders }
    return allHeaders
  }

  static async fetchTransactionHistory(cb) {
    axios
      .get(EndPoints.TRANSACTION_HISTORY, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async connectEmailAccount(workSpaceId, connectionData, cb) {
    axios
      .post(
        EndPoints.CONNECT_EMAIL_ACCOUNT.replace(":workSpaceId", workSpaceId),
        connectionData,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchConnectedEmailAccounts(workSpaceId) {
    const response = await axios.get(
      `${EndPoints.CONNECTED_EMAIL_ACCOUNTS.replace(
        ":workSpaceId",
        workSpaceId,
      )}`,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async getUnifiedInbox(workSpaceId, nextMailListToken = "") {
    const response = await axios.get(
      `${EndPoints.GET_UNIFIED_INBOX.replace(
        ":workSpaceId",
        workSpaceId,
      ).replace(":nextMailListToken", nextMailListToken)}`,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async updateEmailAccountSettings(emailId, data) {
    const response = await axios.put(
      `${EndPoints.EMAIL_ACCOUNT_SETTINGS_UPDATE}/${emailId}`,
      data,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async warmUpConnectedEmailAccount(data = {}) {
    try {
      const response = await axios.post(
        EndPoints.WARM_UP_CONNECTED_EMAIL_ACCOUNT,
        { ...data },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      return response.data
    } catch (e) {
      throw HttpErrorHandler.spitHttpErrorMsg(e)
    }
  }

  static async createCampaign(workSpaceId, body, cb) {
    axios
      .post(
        EndPoints.CREATE_CAMPAIGN.replace(":workSpaceId", workSpaceId),
        body,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchCampaigns(workSpaceId) {
    const response = await axios.get(
      `${EndPoints.CAMPAIGNS.replace(":workSpaceId", workSpaceId)}`,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async createSequence(workSpaceId, body, cb) {
    axios
      .post(
        EndPoints.CREATE_SEQUENCE.replace(":workspace_id", workSpaceId),
        body,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchSequences(workSpaceId) {
    const response = await axios.get(
      `${EndPoints.SEQUENCES.replace(":workspace_id", workSpaceId)}`,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async fetchLeads(workSpaceId) {
    const response = await axios.get(
      `${EndPoints.LEADS.replace(":workSpaceId", workSpaceId)}`,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async fetchLeadsByFilter(workSpaceId, requestBody) {
    const response = await axios.post(
      `${EndPoints.FETCH_LEADS_BY_FILTER.replace(":workSpaceId", workSpaceId)}`,
      requestBody,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async fetchListItems(workSpaceId, listId) {
    const response = await axios.get(
      `${EndPoints.LIST_ITEMS.replace(":workSpaceId", workSpaceId).replace(
        ":listId",
        listId,
      )}`,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async fetchSequence(workSpaceId, sequenceId, cb) {
    axios
      .get(
        EndPoints.SEQUENCE.replace(":workspace_id", workSpaceId).replace(
          ":sequence_id",
          sequenceId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async sendTestEmailToSequence(
    workSpaceId,
    sequenceId,
    stepId,
    threadId,
    recipient_email,
  ) {
    try {
      let response = await axios.post(
        `${EndPoints.SEQUENCE}/steps/:step_id/threads/:thread_id/send_test_email`
          .replace(":workspace_id", workSpaceId)
          .replace(":sequence_id", sequenceId)
          .replace(":step_id", stepId)
          .replace(":thread_id", threadId),
        { recipient_email },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      return HttpSuccessDataHandler.getSuccessResponseData(response)
    } catch (e) {
      throw HttpErrorHandler.spitHttpErrorMsg(e)
    }
  }

  static async upsertList(workSpaceId, data) {
    try {
      let response = await axios.post(
        EndPoints.UPSERT_LIST.replace(`:workSpaceId`, workSpaceId),
        data,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      return HttpSuccessDataHandler.getSuccessResponseData(response)
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e)
    }
  }
  static async upsertLead(workSpaceId, id, data) {
    try {
      let response = await axios.post(
        EndPoints.UPSERT_LEAD.replace(`:workSpaceId`, workSpaceId).replace(`:lead_id`, id),
        data,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      return HttpSuccessDataHandler.getSuccessResponseData(response)
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e)
    }
  }

  static async fetchWorkspaceLists(workSpaceId) {
    try {
      let response = await axios.get(
        EndPoints.LISTS.replace(":workSpaceId", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      return HttpSuccessDataHandler.getSuccessResponseData(response)
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e)
    }
  }

  static async batchDeleteLists(workspaceId, listIds, cb) {
    axios
      .post(
        EndPoints.BATCH_LIST_DELETE.replace(":workSpaceId", workspaceId),
        { listIds },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async addNewLeads(workSpaceId, listId, data) {
    const response = await axios.post(
      `${EndPoints.BASE}/workspaces/${workSpaceId}/lists/${listId}/leads`,
      data,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async fetchSequenceLists(workSpaceId, sequenceId) {
    const response = await axios.get(
      `${EndPoints.SEQUENCE_LISTS.replace(":workspace_id", workSpaceId).replace(
        ":sequence_id",
        sequenceId,
      )}`,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async addRemoveSequenceContactList(workSpaceId, sequenceId, listId) {
    const response = await axios.patch(
      EndPoints.TOGGLE_SEQUENCE_CONTACT.replace(":workSpaceId", workSpaceId)
        .replace(":sequenceId", sequenceId)
        .replace(":listId", listId),
      null,
      {
        headers: await this.getRequestHeaders(),
      },
    )
    return response.data
  }

  static async updateSequence(workSpaceId, sequenceId, data, cb) {
    axios
      .put(
        EndPoints.SEQUENCE.replace(":workspace_id", workSpaceId).replace(
          ":sequence_id",
          sequenceId,
        ),
        { data },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async sequenceActivationStatusUpdate(workSpaceId, sequenceId, data, cb) {
    axios
      .put(
        EndPoints.SEQUENCE_ACTIVATION_STATUS.replace(":workspace_id", workSpaceId).replace(
          ":sequence_id",
          sequenceId,
        ),
        { data },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null
        )
      })
  }

  static async deleteSequence(workSpaceId, sequenceId, cb) {
    axios
      .delete(
        EndPoints.SEQUENCE.replace(":workspace_id", workSpaceId).replace(
          ":sequence_id",
          sequenceId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchCustomPromptTemplates(workSpaceId, sequenceId, cb) {
    axios
      .get(
        `${EndPoints.PROMPTS}?workspace=${workSpaceId}&sequence=${sequenceId}`,
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async upsertPromptTemplate(workSpaceId, sequenceId, data, cb) {
    axios
      .post(
        `${EndPoints.PROMPTS}/upsert?workspace=${workSpaceId}&sequence=${sequenceId}`,
        { data },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateSequenceLeads(workSpaceId, sequenceId, leads, cb) {
    axios
      .put(
        EndPoints.SEQUENCE.replace(":workspace_id", workSpaceId).replace(
          ":sequence_id",
          sequenceId,
        ),
        {
          data: {
            leads,
          },
        },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchConnectedEmailAccount(workSpaceId, connectedEmailId, cb) {
    axios
      .get(
        EndPoints.CONNECTED_EMAIL_ACCOUNT.replace(
          ":workSpaceId",
          workSpaceId,
        ).replace(":connected_account_id", connectedEmailId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeConnectedEmailAccount(workSpaceId, connectedEmailId, cb) {
    axios
      .delete(
        EndPoints.CONNECTED_EMAIL_ACCOUNT.replace(
          ":workSpaceId",
          workSpaceId,
        ).replace(":connected_account_id", connectedEmailId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchCampaign(workSpaceId, campaignId, cb) {
    axios
      .get(
        EndPoints.CAMPAIGN.replace(":workSpaceId", workSpaceId).replace(
          ":campaign_id",
          campaignId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeSequenceLead(workSpaceId, campaignId, leadId, cb) {
    axios
      .delete(
        EndPoints.REMOVE_CAMPAIGN_LEAD.replace(":workSpaceId", workSpaceId)
          .replace(":campaign_id", campaignId)
          .replace(":id", leadId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeLeadFromWorkspace(workSpaceId, leadId, cb) {
    axios
      .delete(
        `${EndPoints.LEADS}/${leadId}`
          .replace(":workSpaceId", workSpaceId)
          .replace(":lead_id", leadId)
          .replace(":id", leadId),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeLeadsFromWorkspace(workSpaceId, leadIds, cb) {
    axios
      .post(
        `${EndPoints.REMOVE_LEADS}`.replace(":workSpaceId", workSpaceId),
        { leadIds },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async removeListItems(workSpaceId, listId, listItems, cb) {
    axios
      .post(
        `${EndPoints.DELETE_LIST_ITEMS}`
          .replace(":workSpaceId", workSpaceId)
          .replace(":listId", listId),
        { listItems },
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async deleteCampaign(workSpaceId, campaignId, cb) {
    axios
      .delete(
        EndPoints.CAMPAIGN.replace(":workSpaceId", workSpaceId).replace(
          ":campaign_id",
          campaignId,
        ),
        {
          headers: await this.getRequestHeaders(),
        },
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async unsubscribeFromEmail(listIds, leadId) {
    const response = await axios.get(
      `${EndPoints.BASE}/unsubscribe-email?listIds=${listIds}&&leadId=${leadId}`,
    )
    return response.data
  }
}
