import { Alert, Button, Container } from "react-bootstrap";

const RichInfoBar = (props) => {
    const {
        title,
        message,
        actionMessage,
        action,
        variant = 'success',
        style = {}
    } = props;
    return (
        <Container>
            <Alert
                show={true}
                variant={variant}
                style={{
                    ...style
                }}>
                <Alert.Heading>{title}</Alert.Heading>
                <p dangerouslySetInnerHTML={{ __html: message }} />
                <hr />
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={() => {
                            action();
                        }}
                        variant={`outline-${variant}`}>
                        {actionMessage}
                    </Button>
                </div>
            </Alert>
        </Container>
    )
}
export default RichInfoBar;