import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CardSummary from "./CardSummary";
import Flex from "components/common/Flex";
import { RoutePaths } from "../../../constants";
import { useNavigate } from 'react-router-dom';
import APIService from 'http/api_service';
import { getActiveWorkSpace } from '../../../redux/slices/workspaceslice';
import { useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

const LeadStats = () => {

  const navigate = useNavigate();
  const workSpace = useSelector(getActiveWorkSpace);
  const [loadingRecentWorkspaceLeadsCount, setLoadingRecentWorkspaceLeadsCount] = useState(true);
  const [loadingWorkspaceLeadsCount, setLoadingWorkspaceLeadsCount] = useState(true);
  const [workspaceLeadsCount, setWorkspaceLeadsCount] = useState(0);
  const [recentworkspaceLeadsCount, setRecentWorkspaceLeadsCount] = useState(0);

  const fetchWorkspaceLeadsCounts = () => {
    APIService.countWorkspaceLeads(workSpace._id, (response, error) => {
      setLoadingWorkspaceLeadsCount(false);
      if (error) {
        return;
      }
      let { data } = response;
      setWorkspaceLeadsCount(data?.totalCount);
    });
  }

  const fetchRecentWorkspaceLeadsCounts = () => {
    APIService.countWorkspaceLeads(workSpace._id, (response, error) => {
      setLoadingRecentWorkspaceLeadsCount(false);
      if (error) {
        return;
      }
      let { data } = response;
      setRecentWorkspaceLeadsCount(data?.totalCountInThePast30Days);
    });
  }

  useEffect(() => {
    if (workSpace) {
      fetchWorkspaceLeadsCounts();
      fetchRecentWorkspaceLeadsCounts()
    }
  }, []);

  return (
    <Fragment>
      <Card style={{ boxShadow: "none" }}>
        <CardHeader className="position-relative">
          <Flex alignItems={"center"} className={"gap-2"}>
            <h5 style={{ flex: 1 }}>
              Contacts
            </h5>
            <Button
              onClick={() => {
                navigate(RoutePaths.LISTS);
              }}

              variant="outline-primary"
            >
              Add contacts
            </Button>
          </Flex>
        </CardHeader>

        <CardBody>
          <Row className="g-3">
            <Col>
              <Row className="g-3">
                <Col md={6} xxl={12}>
                  <CardSummary title="Total contacts" color="info" linkText="Show details">
                    <span className="fs-3 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">
                      {workspaceLeadsCount ? workspaceLeadsCount : '-'}
                    </span>
                  </CardSummary>
                </Col>
                <Col md={6} xxl={12}>
                  <CardSummary
                    title="New contacts over the last 30 days"
                    color="primary"
                    linkText="Show details"
                  >
                    <span className="fs-3 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">
                      {recentworkspaceLeadsCount ? recentworkspaceLeadsCount : '-'}
                    </span>
                  </CardSummary>
                </Col>
                {/* <Col md={4} xxl={12}>
              <CardSummary
                title="EARNED"
                color="success"
                linkText="Show details"
              >
                <span className="fs-3 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">
                  0
                </span>
              </CardSummary>
            </Col> */}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>



    </Fragment>
  );
};

export default LeadStats;
