import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row } from 'react-bootstrap';

const ColorSchemeForm = ({ register, errors }) => {
  return (
    <>
      <Row className="g-2 mb-3">
        <WizardInput
          type="color"
          errors={errors}
          label="Brand Primary Color"
          name="primary_color"
          placeholder="Pick Brand Primary Color"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('primary_color', {
              required: 'Please pick a Brand Primary Color'
            })
          }}
        />
        <WizardInput
          type="color"
          errors={errors}
          label="Brand Secondary Color"
          name="secondary_color"
          placeholder="Pick Brand Secondary Color"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('secondary_color', {
              required: 'Pick Brand Secondary Color'
            })
          }}
        />
      </Row>
    </>
  );
};

ColorSchemeForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default ColorSchemeForm;
