import Flex from 'components/common/Flex';
import * as PapaParse from 'papaparse'
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useEffect } from 'react';

const FileUploader = (props) => {

    const { hint,
        fileEncoding = "UTF-8",
        accept = ".csv, text/csv",
        onError,
        onFileLoaded,
        responseType = "csv"
    } = props;

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const handleChangeFile = (files) => {
        let reader = new FileReader()
        if (files.length > 0) {
            const fileInfo = {
                name: files[0].name,
                size: files[0].size,
                type: files[0].type,
            }
            if (responseType === "csv") {
                const csvParseOptions = {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
                }
                reader.onload = (_event) => {
                    const csvData = PapaParse.parse(
                        reader.result,
                        Object.assign(csvParseOptions, {
                            error: onError,
                            encoding: fileEncoding,
                        }),
                    )
                    onFileLoaded(csvData?.data ?? [], fileInfo, files[0])
                }
                reader.readAsText(files[0], fileEncoding)
            } else {
                onFileLoaded(files);
            }
        }
    }

    useEffect(() => {
        if (acceptedFiles.length) {
            handleChangeFile(acceptedFiles);
        }
    }, [acceptedFiles]);

    return (
        <div>
            <div {...getRootProps({ className: 'dropzone-area py-6' })}>
                <input
                    {...getInputProps()}
                    accept={accept}
                    onChange={(e) => {
                        const files = e.target.files
                        handleChangeFile(files);
                    }} />
                <Flex justifyContent="center">
                    <img src={cloudUpload} alt="" width={25} className="me-2" />
                    <p className="fs-0 mb-0 text-700">{hint}</p>
                </Flex>
            </div>
        </div>
    )
}
export default FileUploader;