import Flex from "components/common/Flex"
import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import LeadsAnimation from "assets/animations/import_leads.json"
import Lottie from "lottie-react"
import CreateNewList from "./new_list"
import AllList from "./all_list"
import APIService from "http/api_service"
import WorkSpaceManager from "utils/workspace_manager"
import Reactloading from "react-loading"
import { useSearchParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

const CreateFirstListView = React.forwardRef((props, ref) => {
  return (
    <Flex
      ref={ref}
      className={"gap-2"}
      alignItems={"center"}
      justifyContent={"center"}
      style={{
        height: "auto",
        width: "auto",
      }}
    >
      <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
        <Lottie
          animationData={LeadsAnimation}
          style={{
            width: 300,
            height: 300,
          }}
        />
        <h5>👋 Create a list to get started</h5>
        <Button
          style={{
            marginTop: "2rem",
          }}
          variant={"primary"}
          onClick={() => {
            props.getStartedClickHandler()
          }}
        >
          <Flex alignItems={"center"} justifyContent={"center"}>
            <FontAwesomeIcon icon={faPlus} />
            <span className="d-none d-sm-inline-block ms-1">Create a list</span>
          </Flex>
        </Button>
      </Flex>
    </Flex>
  )
})

export default function Lists(props) {

  let [searchParams, setSearchParams] = useSearchParams()
  const [sequence, setSequence] = useState(props.sequence)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [leads, setLeads] = useState(props?.sequence?.leads ?? [])
  const [loadingLeads, setLoadingLeads] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [refreshingLeads, setRefreshingLeads] = useState()
  const [workspace] = useState(WorkSpaceManager.getActiveWorkSpace())
  const activeList = searchParams.get("listId")

  const [show, setShow] = useState(false)
  const [nameOfListing, setNameOfListing] = useState("")
  const [descriptionOfListing, setDescriptionOfListing] = useState("")
  const [lists, setLists] = useState([])
  const [loadingLists, setLoadingLists] = useState(false);

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const fetchLists = async () => {
    setLoadingLists(true);
    try {
      let { data } = await APIService.fetchWorkspaceLists(workspace._id);
      setLists(data);
      setLoadingLists(false);
    } catch (e) {
      setLoadingLists(false);
    }
  }

  const fetchLeads = async () => {
    setRefreshingLeads(true)
    try {
      //switch this endpoint to retrieve only listid
      let { data } = await APIService.fetchLeads(workspace._id)
      setLeads(data)
      setLoadingLeads(false)
      setRefreshingLeads(false)
    } catch (e) {
      setLoadingLeads(false)
      setRefreshingLeads(false)
      console.log(e)
    }
  }

  const handleCreateListing = async () => {
    setIsLoading(true)
    try {
      const response = await APIService.upsertList(workspace._id, {
        name: nameOfListing,
        description: descriptionOfListing,
      })
      workspace.listings = response.data
      handleClose()
      //handle notification
    } catch (error) {
      //handle notification
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchLeads()
    fetchLists()
  }, [])

  return (
    <Flex direction={"column"}>
      {
        lists?.length > 0 && (
          <AllList
            lists={lists}
            createList={() => {
              setOpenDrawer(true)
            }}
            loadingLists={loadingLists}
            sequence={sequence}
            onSequenceChanged={(updatedSequence, updateRemotely) => {
              setSequence({ ...updatedSequence })
              if (updateRemotely) {
              }
            }}
          />
        )
      }
      {
        lists?.length < 1 && !loadingLists && (
          <CreateFirstListView
            getStartedClickHandler={() => {
              setOpenDrawer(true)
            }}
          />
        )
      }
      {
        !loadingLists && (
          <CreateNewList
            onSequenceChanged={(updatedSequence) => {
              setSequence(updatedSequence)
            }}
            sequence={sequence}
            open={openDrawer}
            closeDrawer={() => {
              setOpenDrawer(false)
              fetchLeads()
            }}
          />
        )
      }
      {
        loadingLists && (
          <Flex alignItems={"center"} justifyContent={"center"} className={"w-100 h-100 pt-5"}>
            <Flex alignItems={"center"} justifyContent={"center"} className={"w-100 h-100 pt-5"}>
              <Reactloading width={30} type="spinningBubbles" color="teal" />
            </Flex>
          </Flex>
        )
      }
    </Flex>
  )
}
