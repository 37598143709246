/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import APIService from "http/api_service";
import WorkSpaceManager from "utils/workspace_manager";
import { RoutePaths } from "../../../constants";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import Update from "./update";
import Stats from "./LeadsStats";
// import CampaignStats from "./CampaignStats";

const Dashboard = () => {
  const [connectedEmailAccounts, setConnectedEmailAccounts] = useState([]);
  const [loadingConnectedAccounts, setLoadingConnectedAccounts] =
    useState(true);
  const workspace = WorkSpaceManager.getActiveWorkSpace();

  const [sequences, setSequences] = useState([]);
  const [loadingSequences, setLoadingSequences] = useState(true);

  const fetchConnectedEmailAccounts = async () => {
    setLoadingConnectedAccounts(true);
    try {
      let { data } = await APIService.fetchConnectedEmailAccounts(
        workspace._id
      );
      setConnectedEmailAccounts(data);
    } catch (e) {
      console.log(e);
    }
    setLoadingConnectedAccounts(false);
  };

  const fetchSequences = async () => {
    setLoadingSequences(true);
    try {
      let { data } = await APIService.fetchSequences(workspace._id);
      setSequences(data);
    } catch (e) {
      console.log(e);
    }
    setLoadingSequences(false);
  };

  useEffect(() => {
    if (workspace) {
      fetchConnectedEmailAccounts();
      fetchSequences();
    }

    // window?.Intercom("boot", {
    //   api_base: "https://api-iam.intercom.io",
    //   app_id: "mcwk9big",
    //   name: loggedInUser.first_name + ' ' + loggedInUser.last_name, // Full name
    //   email: loggedInUser.email, // Email address
    //   created_at: loggedInUser.created_at // Signup date as a Unix timestamp
    // });

  }, []);




  return (
    <>
      <Helmet>
        <title>Dashboard - Reachable</title>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reachable " />
        <meta name="twitter:creator" content="@reachable " />
        <meta property="og:description" content="Reachable" />
        <meta content="https://twitter.com/reachable" property="og:see_also" />
        <meta name="twitter:title" content="Dashboard - Reachable" />
        <meta name="twitter:description" content="" />
        {/* <meta name="twitter:image" content="https://promoteapp.s3.amazonaws.com/promote-twitter-card.jpeg" /> */}
        <meta name="twitter:image:width" content="1001 " />
        <meta name="twitter:image:height" content="459 " />
        <link href="https://reachable.to/" rel="canonical " />
        <link href="https://reachable.to/" rel="home " />
      </Helmet>

      {loadingConnectedAccounts && (
        <Flex
          style={{
            width: "100%",
            height: "100vh",
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <ReactLoading color={"teal"} width={40} heieght={40} />
        </Flex>
      )}

      {connectedEmailAccounts.length < 1 || sequences.length < 1 ? (
        <Card className="shadow-none border border-1 mb-3">
          <Card.Header className="bg-light">
            <h5 className="text-600 mb-1">
              Get ready to start reaching out to your audience
            </h5>
          </Card.Header>
          <Card.Body>
            <Row className="g-3">
              {connectedEmailAccounts.length < 1 && (
                <Col lg={4} xxl={4}>

                  <Card className="shadow-none border border-danger h-100 shadow">
                    <Card.Header className="bg-white py-3 d-flex align-items-center">
                      <h6 className="mb-0">Connect your email accounts</h6>
                      <FontAwesomeIcon className="text-danger ms-auto" icon="exclamation-triangle" />
                    </Card.Header>
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <p className="card-text">
                        Connect your sender email accounts to Reachable to get started with your outreach effort
                      </p>
                      <Link to={RoutePaths.EMAIL_ACCOUNTS}>
                        <Button variant="outline-danger" className="w-100 mt-3">
                          Connect email accounts
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              )}
              {sequences.length < 1 && (
                <Col lg={4} xxl={4}>
                  <Card className="shadow-none border border-danger h-100 shadow">
                    <Card.Header className="bg-white py-3 d-flex align-items-center">
                      <h6 className="mb-0">Create your first sequence</h6>
                      <FontAwesomeIcon className="text-danger ms-auto" icon="exclamation-triangle" />
                    </Card.Header>
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <p className="card-text">
                        One step closer to launching your first successful email sequence!
                      </p>
                      <Link to={RoutePaths.SEQUENCES}>
                        <Button variant="outline-danger" className="w-100 mt-3">
                          Create your first sequence
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      ) : null}

      <Stats />
      <br />
      {/* <CampaignStats/>
      <br/> */}
      <Update />
      <br />
    </>
  );
};

export default Dashboard;
