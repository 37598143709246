import { faChevronLeft, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import SoftBadge from 'components/common/SoftBadge';
import AppContext from "context/Context";
import { SHA256 } from "crypto-js";
import APIService from "http/api_service";
import React, { useContext, useState } from "react";
import { Button, Card, Form, Modal, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import StringUtils from "utils/string";
import WorkSpaceManager from "utils/workspace_manager";
import AutomaticEmailStepContent, { defaultThread } from "./step-content/AutomaticEmailStepContent";
import "./steps_connector.css";
import { setSequencesFromRedux } from "../../../../../../redux/slices/sequence_slice";
import { useDispatch } from 'react-redux'

export const StepListItem = ({ name, icon, subTitle }) => {
  return (
    <Card className=" cursor-pointer">
      <Card.Body className="p-3">
        <Flex alignItems={"center"} className={"gap-4"} alt="">
          <div>
            <div className="icon-item icon-itm-sm rounded-circle bg-200 shadow-none">
              <FontAwesomeIcon icon={icon} className="text-primary" width={140} />
            </div>
          </div>
          <Flex direction={"column"}>
            <Card.Title className="p-0 m-0">{name}</Card.Title>
          </Flex>
        </Flex>
      </Card.Body>
    </Card>
  );
}

const UpsertStepModal = ({
  setOpenModal,
  openModal,
  sequence,
  updatableStep = {}
}) => {
  const {
    config: { isDark },
  } = useContext(AppContext);
  
  const [formData, setFormData] = useState({ ...updatableStep });
  const [selectedStepContent, setSelectedStepContent] = useState();
  const [processing, setProcessing] = useState(false);
  const workspace = WorkSpaceManager.getActiveWorkSpace();
  const [activeKey, setActiveKey] = useState("email");
  const dispatch = useDispatch()

  const thereIsOngoingEditableStep = () => {
    return Object.entries(updatableStep).length > 0
  }

  const handleSelect = (selectedKey) => {
    setActiveKey(selectedKey);
  };

  const handleClose = () => {
    setOpenModal(!openModal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.entries(formData).length === 0) {
      return;
    }
    if (updatableStep?.id) {
      formData["id"] = updatableStep?.id;
    } else {
      formData["id"] = SHA256(StringUtils.generateRandomString(365)).toString();
    }
    console.log('upsetting steps here for minor changes')
    upsertStep();
  };

  const upsertStep = () => {
    let _sequence = structuredClone(sequence)
    let steps = structuredClone(_sequence["steps"]) || []
    let stepIndex = steps.findIndex(x => x["id"] === formData["id"]);
    if (stepIndex !== -1) {
      steps[stepIndex] = formData;
    } else {
      steps.push(formData);
    }
    _sequence["steps"] = steps;
    setProcessing(true);
    APIService.updateSequence(workspace._id, _sequence._id, _sequence, (response, error) => {
      setProcessing(false);
      if (error) {
        // delete sequence["steps"];
        toast.error(error, { theme: 'colored' });
        return;
      }
      dispatch(setSequencesFromRedux(response["data"]));
      handleClose();
    });
  }

//   const populateDataBasedOffConditions = () => {
//     if(updatableStep) {
//         let threads = updatableStep["threads"] ?? [
//             defaultThread
//         ];
//         setFormData({
//             ...updatableStep,
//             "threads": threads
//         })
//     } else{
//         let threads = formData["threads"] ?? [
//             defaultThread
//         ];
//         setFormData({
//             ...formData,
//             "threads": threads
//         })
    
//     }

// }

  return (
    <Modal
      show={openModal}
      size="md"
      onHide={handleClose}
      contentClassName="border"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? "white" : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">
            {
              selectedStepContent ? (
                <Flex
                  className={"gap-2 mx-2 cursor-pointer"}
                  alignItems={"center"}
                  onClick={() => {
                    setSelectedStepContent(undefined);
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span className="fw-medium">Change step</span>
                </Flex>
              ) : (
                <p
                  style={{
                    textAlign: "left",
                  }}
                >
                  {
                    thereIsOngoingEditableStep() ? "Edit step" : "Add step"
                  }
                </p>
              )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card overflow-auto" style={{ maxHeight: '70vh' }}>
          <>
            <Nav variant="pills" activeKey={activeKey} onSelect={handleSelect}>
              <Nav.Item>
                <Nav.Link eventKey="email">Email</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
              <Nav.Link  eventKey="link-2" disabled>SMS</Nav.Link>
            </Nav.Item> */}
              {/* <Nav.Item>
              <Nav.Link  eventKey="link-3" disabled>WhatsApp</Nav.Link>
            </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="linkedin">LinkedIn <SoftBadge className="py-1 me-1 mb-1"> beta</SoftBadge></Nav.Link>
              </Nav.Item>
            </Nav>

            {activeKey === "email" && (
              <AutomaticEmailStepContent
                sequence={sequence}
                formData={formData}
                setFormData={setFormData}
                updatableStep={updatableStep}
              />
            )}

            {activeKey === "linkedin" && (
              <div className="mt-6 ml-3">
                LinkedIn
              </div>
            )}
          </>
        </Modal.Body>
        {
          !selectedStepContent && (
            <Modal.Footer className="bg-light px-card border-top-0">
              <span
                onClick={handleClose}
                className="me-3 text-600 cursor-pointer"
                style={processing ? { pointerEvents: 'none' } : {}}
              >
                Cancel
              </span>
              <Button
                variant="primary"
                type="submit"
                disabled={processing}
                className="px-4 mx-0"
              >
                {processing ? `${thereIsOngoingEditableStep() ? "Updating" : "Creating"} step...` : `${thereIsOngoingEditableStep() ? "Update step" : "Create step"}`}
              </Button>
            </Modal.Footer>
          )
        }
      </Form>
    </Modal>
  );
};

export default UpsertStepModal;
