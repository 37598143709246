import React, { useState } from "react"
import {
    Button,
    Col,
    Modal,
    Row
} from "react-bootstrap"
import { defaultThread } from "./upsert/step-content/AutomaticEmailStepContent"

const AddNewThread = (props) => {
    const { step, index, refreshStep, cloneThread, processing } = props
    const [openNewThreadDialog, setOpenNewThreadDialog] = useState(false)

    function getAlphabetEquivalent(length) {
        if (length >= 1 && length <= 26) {
            const asciiCode = length + 64
            const alphabetEquivalent = String.fromCharCode(asciiCode)
            return alphabetEquivalent
        } else {
            return "Out of range"
        }
    }

    const updateThread = () => {
        let threads = step["threads"] ?? []
        let name = getAlphabetEquivalent(step["threads"].length + 1)
        threads.push({ ...defaultThread, name })
        step["threads"] = threads
        refreshStep(step)
    }

    return (
        <Row className="flex-between-center g-0">
            <Col xs="auto">
                <Button
                    icon="crown"
                    disabled={step["threads"].length >= 26}
                    variant="outline-secondary"
                    iconClassName="me-2"
                    className="w-100"
                    onClick={() => setOpenNewThreadDialog(true)}
                >
                    A/B test this step
                </Button>
            </Col>
            <Modal
                show={openNewThreadDialog}
                size={"md"}
                centered
                onHide={() => setOpenNewThreadDialog(false)}
                onExit={() => setOpenNewThreadDialog(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add new A/B test</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to copy the content of step {index + 1}
                    {getAlphabetEquivalent(step["threads"].length)}?

                </Modal.Body>
                <Modal.Footer className="bg-light px-card border-top-0">
                    <Button
                        variant="primary"
                        onClick={() => {
                            cloneThread(step["threads"][step["threads"].length - 1])
                            setOpenNewThreadDialog(false)
                        }}
                        disabled={processing}
                        className="px-4 mx-0"
                    >
                        {processing ? "Copying..." : "Yes"}
                    </Button>
                    <div style={{ width: 10 }}></div>
                    <Button
                        variant="text"
                        onClick={() => {
                            updateThread()
                            setOpenNewThreadDialog(false)
                        }}
                        style={processing ? { pointerEvents: "none" } : {}}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>
    )
}


export default AddNewThread