import team1 from 'assets/img/team/1.jpg';
import team2 from 'assets/img/team/2.jpg';
import team3 from 'assets/img/team/3.jpg';
import team4 from 'assets/img/team/4.jpg';
import team5 from 'assets/img/team/5.jpg';
import team6 from 'assets/img/team/6.jpg';
// import kanban1 from 'assets/img/kanban/1.jpg';
// import kanban2 from 'assets/img/kanban/2.jpg';
// import kanban3 from 'assets/img/kanban/3.jpg';
// import kanban4 from 'assets/img/kanban/4.jpg';
// import beach from 'assets/video/beach.jpg';
// import beachVideo from 'assets/video/beach.mp4';

export const members = [
  { id: 1, name: 'Anna Karinina', img: team1, role: 'Member' },
  { id: 2, name: 'Antony Hopkins', img: team2, role: 'Member' },
  { id: 3, name: 'Rowan Atkinson', img: team3, role: 'Member' },
  { id: 4, name: 'John Doe', img: team4, role: 'Member' },
  { id: 5, name: 'Emily Rose', img: team5, role: 'Member' },
  { id: 6, name: 'Marry Jane', img: team6, role: 'Member' }
];

export const labels = [
  { text: 'New', type: 'success' },
  { text: 'Goal', type: 'primary' },
  { text: 'Enhancement', type: 'info' },
  { text: 'Bug', type: 'danger' },
  { text: 'Documentation', type: 'secondary' },
  { text: 'Helper', type: 'warning' }
];

export const attachments = [
  {
    id: 1,
    image: "",
    src: "kanban3",
    title: 'final-img.jpg',
    date: '2020-04-18 5:25 pm',
    type: 'image'
  },
  {
    id: 2,
    image: "kanban4",
    src: "kanban4",
    title: 'picture.png',
    date: '2020-04-20 4:34 pm',
    type: 'image'
  },
  {
    id: 3,
    src: `#!`,
    title: 'sample.txt',
    date: '2020-04-21 2:10 pm',
    type: 'txt'
  },
  {
    id: 4,
    src: `#!`,
    title: 'example.pdf',
    date: '2020-05-02 11:34 am',
    type: 'pdf'
  },
  {
    id: 5,
    image: "beach",
    src: "beachVideo",
    title: 'beach.mp4',
    date: '2020-05-10 3:40 pm',
    type: 'video'
  }
];

export const kanbanItems = [
  {
    id: 1,
    name: 'Under review',
    items: [
      
      
      
      
    ]
  },
  {
    id: 2,
    name: 'Planed',
    items: [
      {
        id: 20000001,
        title: 'Auto import from Google Sheets on change',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
        // checklist: { totalCount: 6, completed: 3 },
        // members: [{ name: 'Emma', url: team3 }]
      },
      {
        id: 10000003,
        title: 'Email validation / cleanup',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
        content: 'Ability to combine multiple emailEmail validation / cleanup accounts into a single view. Instead of needing to log in to Gmail, etc separately to check on inboxes, a unified inbox will create a single inbox that combines them all into a single universal email space.'
      },
      {
        id: 20000002,
        title: 'Deliverability checker',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
        // user: 'Rowan',
        // members: [
        //   { name: 'Shophie', url: team3 },
        //   { name: 'Rowan', url: team4 }
        // ],
        // attachments: [
        //   {
        //     type: 'image',
        //     url: "kanban2",
        //     className: 'py-8'
        //   }
        // ]
      },
      {
        id: 20000003,
        title: 'Rich HTML email editor',
        author: 'Emeka Onu',
        dueDate: '',
        // labels: [{ text: 'New', type: 'success' }],
        labels: [
          { type: 'success', text: 'New' },
          // { type: 'primary', text: 'Goal' }
        ],
        // attachments: [{ type: 'doc', name: 'test.txt' }],
        // checklist: { totalCount: 10, completed: 2 },
        // members: [{ name: 'Shophie', url: team3 }]
      },
      {
        id: 20000003,
        title: 'Unsubscribe links',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ type: 'success', text: 'New' }],
      },
      {
        id: 10000005,
        title: 'API',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
      },
      {
        id: 10000005,
        title: 'Zapier integration',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
      },
      {
        id: 10000002,
        title: 'Image personalization',
          author: 'Alex Collins',
          dueDate: '',
          labels: [{ text: 'New', type: 'success' }],
          content: 'Ability to personalize images in email sequence'
      },
      
      
      {
        id: 10000005,
        title: 'Unsubscribe links',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
        content: 'Ability to combine multiple email accounts into a single view. Instead of needing to log in to Gmail, etc separately to check on inboxes, a unified inbox will create a single inbox that combines them all into a single universal email space.'
      },
    ]
  },
  {
    id: 3,
    name: 'In development',
    items: [
      {
        id: 10000004,
        title: 'Contact List',
        author: 'Emeka Onu',
        dueDate: 'Due date: November 04, 2023',
        labels: [{ text: 'Update', type: 'danger' }],
        content: 'We are working on making leads/contacts upload as easy as possible. There has been some technical delay which has prevented us from meeting the dealine(01/01/2023). We apologize for any inconvenience caused and kindly ask for your patience. Rest assured, we are actively working to resolve this issue promptly. Thank you for your understanding. 🙏'
      },
      {
        id: 10000004,
        title: 'Warmup emails',
        author: 'Emeka Onu',
        dueDate: 'Due date: November 04, 2023',
        labels: [{ text: 'Update', type: 'danger' }, { text: 'Enhancement', type: 'info' }],
        content: ''
      },
      {
        id: 10000001,
        title: 'Unified Inbox',
        author: 'Emeka Onu',
        dueDate: 'Due date: November 30, 2023',
        labels: [{ text: 'New', type: 'success' }],
        content: 'Ability to combine multiple email accounts into a single view. Instead of needing to log in to Gmail, etc separately to check on inboxes, a unified inbox will create a single inbox that combines them all into a single universal email space.'
      },
      {
        id: 30000002,
        title: 'Custom tracking domain',
        author: 'Wan Clem',
        dueDate: 'Due date: December 02, 2023',
        labels: [{ text: 'New', type: 'success' }],
        content: 'Set up a custom tracking domain on your outreach emails to improves deliverability, prevent email blocking and spam folder issues, improves the branding of your links, increases your click-through rate, and protects you from reputation issues caused by spammers.'
      },
      {
        id: 10000004,
        title: 'Webhooks',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
        content: 'Ability to combine multiple email accounts into a single view. Instead of needing to log in to Gmail, etc separately to check on inboxes, a unified inbox will create a single inbox that combines them all into a single universal email space.'
      },
      
      {
        id: 30000001,
        title: 'AI Prompts',
        author: 'Wan Clem',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
        // checklist: { totalCount: 5, completed: 5 },
        // members: [
        //   { name: 'Sophie', url: team4 },
        //   { name: 'Antony', url: team1 },
        //   { name: 'Emma', url: team2 }
        // ]
      },
      
      {
        id: 30000002,
        title: 'Slack integration',
        author: 'Wan Clem',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
        content: 'Connected Reachable to Slack to get notified of new replies and collaboratively build replies within Slack.'
        // attachments: [
        //   { type: 'pdf', url: `sample.pdf` },
        //   { type: 'txt', url: `example.txt` }
        // ],
        // members: [{ name: 'Antony', url: team2 }]
      },
    ]
  },
  {
    id: 4,
    name: 'Completed',
    items: [
      {
        id: 10000005,
        title: 'Bulk import sending accounts',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
      },
      {
        id: 30000002,
        title: 'Email signatures',
        author: 'Wan Clem',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
        // attachments: [
        //   { type: 'pdf', url: `sample.pdf` },
        //   { type: 'txt', url: `example.txt` }
        // ],
        // members: [{ name: 'Antony', url: team2 }]
      },
      {
        id: 40000001,
        title: ' Import leads from Google Sheets',
        author: 'Emeka Onu',
        dueDate: '',
        // attachments: [
        //   {
        //     type: 'image',
        //     url: "kanban1",
        //     className: 'py-9'
        //   },
        //   { type: 'doc', name: 'test.txt' }
        // ],
        // members: [
        //   { name: 'Anna', url: team1 },
        //   { name: 'Antony', url: team2 },
        //   { name: 'Emma', url: team3 }
        // ]
      },
      {
        id: 40000002,
        title: 'Multiple workspaces',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
      },
      {
        id: 40000002,
        title: 'Duplicate sequences',
        author: 'Emeka Onu',
        labels: [{ text: 'New', type: 'success' }],
      },
      {
        id: 40000003,
        title: 'A/B Testing',
        author: 'Emeka Onu',
        dueDate: '',
        labels: [{ text: 'New', type: 'success' }],
      },
      
    ]
  }
];

export const comments = [
  {
    id: 1,
    user: {
      name: 'Rowan',
      avatar: team4
    },
    text: 'This time we should finish the task before the deadline',
    time: '23min'
  },
  {
    id: 2,
    user: {
      name: 'Emma',
      avatar: team1
    },
    text: 'We have more task to do',
    time: '2hour'
  }
];

export const activities = [
  {
    id: 1,
    user: {
      name: 'Rowan',
      avatar: team4
    },
    activity: 'Added the card',
    time: '6 hours ago'
  },
  {
    id: 2,
    user: {
      name: 'Anna',
      avatar: team3
    },
    activity: 'attached final-pic.png to this card',
    time: '4 hours ago'
  }
];
