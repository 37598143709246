import WorkSpaceManager from "utils/workspace_manager"
import {
    useEffect,
    useState
} from "react"
import {
    Button,
    Col, Form,
    Offcanvas, Row, Spinner
} from "react-bootstrap"
import "./leads.css"
import APIService from "http/api_service"
import { useSWRConfig } from "swr"
import { toast } from "react-toastify"
import EndPoints from "http/endpoints"

export default function UpdateLeadDrawer(props) {
    const { open, closeDrawer, updatableData, setUpdatableData } = props
    const { mutate } = useSWRConfig()
    const activeWorkspace = WorkSpaceManager.getActiveWorkSpace();
    const [name, setName] = useState()
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [company, setCompany] = useState();
    const [title, setTitle] = useState()
    const [showDrawer, setShowDrawer] = useState(open)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setShowDrawer(open)
    }, [open])

    const populateUpdatesOnDataChange = () => {
        if (!updatableData) {
            setFirstName(null);
            setLastName(null);
            setEmail(null);
            setCompany(null);
            setTitle(null);
            return;
        }
        setFirstName(updatableData?.first_name)
        setLastName(updatableData?.last_name)
        setEmail(updatableData?.email)
        setCompany(updatableData?.company)
        setTitle(updatableData?.title)
    }

    useEffect(() => {
        populateUpdatesOnDataChange()
    }, [updatableData])


    const handleClose = () => {
        setShowDrawer(!showDrawer)
        setUpdatableData(null)
        closeDrawer()
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const payload = { first_name: firstName, last_name: lastName, email: email, company: company, title: title }
            let { message } = await APIService.upsertLead(activeWorkspace._id, updatableData?._id, payload)
            toast(message, { theme: 'colored' });
            handleClose()
            window.location.reload()
        } catch (error) {
            toast.error(error, { theme: 'colored' });
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Offcanvas
            style={{
                width: "40%",
            }}
            fullscreen={true}
            placement="end"
            show={showDrawer}
            onHide={handleClose}
        >
            <Offcanvas.Header closeButton closeVariant="white" className="bg-shape settings-panel-header pl-4 pr-14">
                <Offcanvas.Title as="div" className="py-1 z-index-1 light">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h5 className="text-white">
                            Update {updatableData?.email}
                        </h5>
                    </div>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={12} controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                // placeholder="Enter First Name of list"
                                value={firstName}
                                name="firstName"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} lg={12} controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                // placeholder="Enter name of list"
                                value={lastName}
                                name="lastName"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} lg={12} controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                // placeholder="Enter name of list"
                                value={email}
                                name="email"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} lg={12} controlId="company">
                            <Form.Label>Company</Form.Label>
                            <Form.Control
                                type="text"
                                // placeholder="Enter name of list"
                                value={company}
                                name="company"
                                required
                                onChange={(e) => setCompany(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} lg={12} controlId="title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                // placeholder="Enter name of list"
                                value={title}
                                name="title"
                                required
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Button
                        disabled={isLoading}
                        style={{ marginTop: "30px" }}
                        className="d-inline-flex flex-center gap-1"
                        type="submit">
                        {
                            isLoading &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                        {
                            isLoading ? "Updating lead..." : "Update lead"
                        }
                    </Button>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );

}
