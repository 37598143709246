import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import {
    getBulkCSVLeads,
    setBulkCSVLeads
} from "../../../../redux/slices/workspaceslice";

const BulkContactEditBox = (props) => {

    const {
        rowIndex = 0,
        property
    } = props;
    const dispatch = useDispatch();
    const uploadableBulkContent = useSelector(getBulkCSVLeads);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const [value, setValue] = useState(bulkContentRow[property]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let uploadableBulkContentCopy = [...uploadableBulkContent];
            let updatedRow = { ...bulkContentRow };
            updatedRow[property] = value;
            uploadableBulkContentCopy[rowIndex] = updatedRow;
            dispatch(setBulkCSVLeads(uploadableBulkContentCopy));
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [value])

    return (
        <Form.Control
            type='text'
            name={property}
            value={value}
            onChange={(e) => {
                let value = e.target.value;
                if (!value) {
                    value = '';
                }
                setValue(value);
            }}
        />
    )
}
export default BulkContactEditBox;