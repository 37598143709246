import React, { useEffect, useState } from "react";
import Background from "components/common/Background";
import Flex from "components/common/Flex";
// import PropTypes from "prop-types";
import { Button, Card, Col,   OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import UserManager from "utils/user_manager";
// import { isFreeUser } from "utils/user_utils";
import ConfirmModal from "components/common/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveWorkSpace,
} from "../../../redux/slices/workspaceslice";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { getLoggedInUser, setLoggedInUser } from "../../../redux/slices/user_slice";
import { RoutePaths } from "../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkspaceStats from "../../../utils/workspace_stats";
// import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Link } from "react-router-dom";
import createMarkup from "helpers/createMarkup";
import {
  searchWorkSpace,
} from "../../../redux/slices/workspaceslice";
import Switch from "react-switch"
import Divider from "components/common/Divider";

const BetaFeatures = () => {
  const loggedInUser = UserManager.getLoggedInUser();
  const dispatch = useDispatch();
  const [activePlan, setActivePlan] = useState(loggedInUser.active_plans);
  const [openSubscriptionCancelPrompt, setOpenSubscriptionCancelPrompt] =
    useState(false);
  const workspace = useSelector(getActiveWorkSpace);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);

  // const navigate = useNavigate();
  const [workSpaceStats, setWorkSpaceStats] = useState({});

  const initWorkSpaceStats = async () => {
    try {
      let stats = await WorkspaceStats.getSync();
      setWorkSpaceStats(stats);
    } catch (e) {}
  };

  useEffect(() => {
    initWorkSpaceStats();
  }, []);

  const cancelSubscription = (e) => {
    let activePlan = workspace.active_plans;
    if (activePlan.cancel_url) {
      window.location = activePlan.cancel_url;
    } else {
      APIService.cancelSubscription((response, error) => {
        if (error) {
          setCancellingSubscription(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        let { message, data } = response;
        dispatch(setLoggedInUser({ ...data }));
        checkAndLazyUpdateActiveWorkspace(data);
        toast.success(message, { theme: "colored" });
        setCancellingSubscription(false);
        setTimeout(() => {
          window.location = window.location.href.split("?")[0];
          window.location.reload();
        }, 500);
      });
    }
  };

  const checkAndLazyUpdateActiveWorkspace = (latestUserData) => {
    if (!latestUserData) {
      return;
    }
    if (latestUserData._id === workspace.owner_id) {
    }
  };

  return (
    <>
      <Card className="bg-transparent-50 overflow-hidden mx-auto" style={{ width: '60%' }}>
        <Card.Header className="position-relative">
          <div className="position-relative z-index-2">
            <div>
              <h3 className="text-primary mb-1">Beta Features</h3>
              <p className="text-700">
                <b>Try new Reachable features early</b>
              </p>
              <Divider />
              <Row className="flex-between-center">
                <Col>
                  <Flex>
                    <p className="fs--1 mb-0"><b>Enable Beta features:</b></p>
                  </Flex>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <span className="me-2">Off</span> 
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Activate Variant</Tooltip>}
                    >
                      <Switch
                        checked=""
                        onChange={(checked, e) => {
                          e.stopPropagation()
                          // setActivateThread(checked)
                          // thread["activated"] = checked
                          // updateThread(thread)
                        }}
                        height={20} // Adjust the height as needed
                        width={40} // Adjust the height as needed
                      />
                    </OverlayTrigger>
                 
                </Col>
              </Row>
            </div>
            <Flex className="py-2">
                <p className="fs--1 fw-medium">
                  What's the catch? Share feedback with us below to help improve Reachable!
                </p>
            </Flex>
            <Flex>
                <p className=" fs--1">
                  <FontAwesomeIcon
                    icon="info-circle"
                    className="me-1 text-warning"
                  />
                  Beta features are experimental. Enabling them may lead to a less stable Reachable experience.
                </p>
            </Flex>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <ul className="mb-0 list-unstyled">
            <li
              className={classNames("alert mb-0 rounded-0 py-3 px-card", {
                // 'alert-warning': type === 'warning',
                // 'border-top': !(type === 'warning'),
                // 'border-0': isLast,
                // 'border-x-0 border-top-0': !isLast
              })}
            >
              <Row className="flex-between-center">
                <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="circle"
                      className={classNames("mt-1 fs--2", {
                        // 'text-primary': !type
                      })}
                    />
                    <p
                      className="fs--1 ps-2 mb-0"
                      dangerouslySetInnerHTML={createMarkup("Users")}
                    ></p>
                  </Flex>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <Link
                    to={RoutePaths.TEAM}
                    className="alert-link fs--1 fw-medium"
                  >
                    {`Manage users`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                </Col>
              </Row>
            </li>
            <li
              className={classNames("alert mb-0 rounded-0 py-3 px-card", 'border-top', {
                // 'alert-warning': type === 'warning',
                // 'border-0': isLast,
                // 'border-x-0 border-top-0': !isLast
              })}
            >
              <Row className="flex-between-center">
                <Col>
                  <Flex>
                    <FontAwesomeIcon
                      icon="circle"
                      className={classNames("mt-1 fs--2", {
                        // 'text-primary': !type
                      })}
                    />
                    <p
                      className="fs--1 ps-2 mb-0"
                      dangerouslySetInnerHTML={createMarkup(
                        `${workSpaceStats?.created}/${activePlan?.sales_platform["Workspace"]} workspaces`
                      )}
                    ></p>
                  </Flex>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <Link
                    to="#"
                    onClick={() => {
                      dispatch(searchWorkSpace(true));
                    }}
                    className="alert-link fs--1 fw-medium"
                  >
                    {`View workspace`}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="ms-1 fs--2"
                    />
                  </Link>
                </Col>
              </Row>
            </li>
          </ul>
        </Card.Body>
      </Card>
      <ConfirmModal
        open={openSubscriptionCancelPrompt}
        onCancel={() => {
          setOpenSubscriptionCancelPrompt(false);
        }}
        title="Cancel your subscription?"
        message="This will cancel your current subscription and downgrade your account to the free plan."
        cancelText="NO"
        confirmText="CONTINUE"
        onConfirm={() => {
          setOpenSubscriptionCancelPrompt(false);
          cancelSubscription();
        }}
      />
    </>
  );
};

export default BetaFeatures;
