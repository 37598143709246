import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { googleIcon } from "components/app/email-accounts/connect_email_account";
import Flex from "components/common/Flex";
import APIService from "http/api_service";
import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import WorkSpaceManager from "utils/workspace_manager";
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { getBulkCSVLeads, setBulkCSVLeads } from "../../../../redux/slices/workspaceslice";
import LeadBulkUploadPreviewer from "./LeadBulkUploadPreviewer";
import StringUtils from "utils/string";

export default function LeadsImporterFromGoogleSheet({ sequence, sequenceUpdateHandler }) {

    const dispatch = useDispatch();
    const activeWorkspace = WorkSpaceManager.getActiveWorkSpace();
    const [sheetLink, setSheetLink] = useState();
    const [gettingSheetData, setGettingSheetData] = useState(false);
    const leads = useSelector(getBulkCSVLeads);

    const getLeadsFromGoogleSheetLink = (e) => {
        e.preventDefault();
        if (!sheetLink) {
            toast.error("Please enter a sheet link", { theme: 'colored' });
            return;
        }
        setGettingSheetData(true);
        APIService.getLeadsFromGoogleSheet(activeWorkspace._id, sequence?._id, sheetLink, (response, error) => {
            setGettingSheetData(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            const transformedData = data.map((item) => {
                const newItem = {};
                const keys = Object.keys(item);
                keys.forEach((key) => {
                    const snakeCaseKey = StringUtils.camelToSnake(key);
                    newItem[snakeCaseKey] = item[key];
                });
                let firstName = newItem.firstName ?? newItem.first_name ?? newItem.firstname;
                let lastName = newItem.lastName ?? newItem.last_name ?? newItem.lastname;
                if (firstName && lastName) {
                    newItem.name = `${firstName} ${lastName}`;
                } else if (newItem.full_name) {
                    newItem.name = newItem.full_name;
                }
                return newItem;
            });
            dispatch(setBulkCSVLeads(transformedData));
        });
    }

    return (
        <Flex direction={'column'} className={'gap-5'}>
            <Form onSubmit={getLeadsFromGoogleSheetLink}>
                <Flex
                    style={{
                        width: '75vw'
                    }}
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <Flex className={'gap-2'} alignItems={'center'}>
                        <img src={googleIcon} width={'40px'} />
                        <h4 style={{ fontWeight: 500 }}><strong>Google Sheets</strong></h4>
                    </Flex>
                    <Flex direction={'column'} justifyContent={'center'} className={'gap-5'} style={{ width: '70%' }}>
                        <Card bg={'light'} >
                            <Card.Body style={{
                                textAlign: 'center'
                            }}>
                                <strong>💡Make sure your Google Sheet is publicly accessible</strong>
                            </Card.Body>
                        </Card>
                        <Form.Control
                            value={sheetLink}
                            onChange={(e) => {
                                setSheetLink(e.target.value);
                            }}
                            className={'py-3'}
                            placeholder="Place your Google Sheet link here"
                        />
                        <Flex justifyContent={'center'} alignItems={'center'}>
                            <Button
                                disabled={gettingSheetData}
                                type={'submit'}
                                style={{
                                    width: 200,
                                    height: 45
                                }}>
                                {
                                    gettingSheetData ?
                                        <Flex className={'gap-3'} alignItems={'center'} justifyContent={'center'}>
                                            <ReactLoading type="bubbles" color="white" width={30} height={30} />
                                            <span>Importing...</span>
                                        </Flex>
                                        : <Flex className={'gap-2'} alignItems={'center'} justifyContent={'center'}>
                                            <span>IMPORT</span>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </Flex>
                                }
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Form>
            {
                leads.length > 0 &&
                <LeadBulkUploadPreviewer
                    skipPick
                    skipLimits
                    sequenceUpdateHandler={sequenceUpdateHandler}
                    sequence={sequence}
                />
            }
        </Flex>
    );
}