import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Card,
  Col,
  Dropdown,
  Offcanvas,
  Row,
} from 'react-bootstrap';
import AppContext, { CourseContext } from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadFinderFilters from './LeadFinderFilters';
import { useBreakpoints } from 'hooks/useBreakpoints';
import LeadFinderTableHeader from '../LeadFinderTableHeader';

import ReactLoading from "react-loading"
import { customersData } from 'data/ecommerce/customersData';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import Flex from 'components/common/Flex';

const dataDecipher = (data) => {
  if (data === 'blurred_by_system') {
    return <div style={{
      position: 'relative',
      filter: 'blur(5px)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      transition: 'filter 0.5s ease'

    }}>
      Hope now
    </div>
  }
  return data
}

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, name, email, first_name } = rowData.row.original;
      return (
        <>
          <strong>{dataDecipher(name) || dataDecipher(first_name)}</strong>
        </>
      );
    }
  },
  {
    accessor: 'title',
    Header: 'Title',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { title } = rowData.row.original;
      return (
        <>
          {title}
        </>
      );
    }
  },
  {
    accessor: 'company',
    Header: 'Company',
    headerProps: { className: 'pe-7' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { company, company_name } = rowData.row.original;
      return (
        <>
          {company || company_name}
        </>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { email } = rowData.row.original;
      return (
        <>
          {
            dataDecipher(email)
          }
        </>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Phone number',
    Cell: rowData => {
      const { address, phone } = rowData.row.original;
      return (
        <>
          {dataDecipher(phone)}
        </>
      );
    }
  },
  {
    accessor: 'companySize',
    Header: 'Employees',
    headerProps: { className: 'pe-7' }
  },
  {
    accessor: 'location',
    Header: 'Location',
    headerProps: { className: 'pe-7' }
  },

  {
    accessor: 'industry',
    Header: 'Industry',
    headerProps: { className: 'pe-7' }
  },
  {
    accessor: 'keywords',
    Header: 'Keywords',
    headerProps: { className: 'pe-7' }
  },
  {
    accessor: 'status',
    Header: 'Company news',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'fs-0'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <SoftBadge
          pill
          bg={classNames({
            success: status === 'completed',
            primary: status === 'processing',
            warning: status === 'pending',
            secondary: status === 'onhold'
          })}
          className="d-block"
        >
          {status === 'completed' && 'Completed'}
          {status === 'processing' && 'Processing'}
          {status === 'pending' && 'Pending'}
          {status === 'onhold' && 'On-Hold'}
          <FontAwesomeIcon
            icon={classNames({
              check: status === 'completed',
              redo: status === 'processing',
              stream: status === 'pending',
              ban: status === 'onhold'
            })}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    }
  },
  // {
  //   accessor: 'none',
  //   Header: '',
  //   disableSortBy: true,
  //   cellProps: {
  //     className: 'text-end'
  //   },
  //   Cell: () => {
  //     return (
  //       <CardDropdown iconClassName="fs--1">
  //         <div className="py-2">
  //           <Dropdown.Item href="#!">Completed</Dropdown.Item>
  //           <Dropdown.Item href="#!">Processing</Dropdown.Item>
  //           <Dropdown.Item href="#!">On Hold</Dropdown.Item>
  //           <Dropdown.Item href="#!">Pending</Dropdown.Item>
  //           <Dropdown.Divider as="div" />
  //           <Dropdown.Item href="#!" className="text-danger">
  //             Delete
  //           </Dropdown.Item>
  //         </div>
  //       </CardDropdown>
  //     );
  //   }
  // }
];


const Courses = () => {
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const { breakpoints } = useBreakpoints();
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);
  const [_customersData, _setCustomersData] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [userHasTakenAction, setUserHasTakenAction] = useState(false);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', true);

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  if (!filtering && !_customersData?.length) {
    return <Row className="g-3">
      {breakpoints.up('xl') && (
        <Col xl={3}>
          <LeadFinderFilters
            _setCustomersData={_setCustomersData}
            customersData={customersData}
            _customersData={_customersData}
            setFiltering={setFiltering}
            filtering={filtering}
            setUserHasTakenAction={setUserHasTakenAction}
            userHasTakenAction={userHasTakenAction}
          />
        </Col>
      )}
      <Col xl={9}>
        <Row className="mb-3 g-3">
          {
            userHasTakenAction ?
              <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="fs-6 mb-3"
                    />
                    <h5>No Leads Found!</h5>
                    <p className="mb-0">
                      Your search did not match any Leads. Please try again.
                    </p>
                  </div>
                </Card.Body>
              </Card> : <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="search"
                      className="fs-6 mb-3"
                    />
                    <h5>Welcome, let's generate some leads!</h5>
                    <p className="mb-0">
                      Start your search by applying filters on the left side
                    </p>
                  </div>
                </Card.Body>
              </Card>
          }
        </Row>
      </Col>
    </Row>
  }

  return (
    <>
      <Row className="g-3">
        {breakpoints.up('xl') && (
          <Col xl={3}>
            <LeadFinderFilters
              _setCustomersData={_setCustomersData}
              setFiltering={setFiltering}
              filtering={filtering}
            />
          </Col>
        )}
        <Col xl={9}>

          {
            filtering ?
              <Flex
                justifyContent={'center'}
                alignItems={'center'}

              >
                <ReactLoading
                  className="mt-4"
                  width={30}
                  type="spinningBubbles"
                  color="teal"
                />
              </Flex>
              :
              <AdvanceTableWrapper
                columns={columns}
                data={_customersData}
                selection
                sortable
                pagination
                perPage={50}
              >
                <Card className="mb-3">
                  <Card.Header>
                    <LeadFinderTableHeader
                      leadLength={_customersData?.length}
                      setShowFilterOffcanvas={setShowFilterOffcanvas}
                      table
                    />
                  </Card.Header>
                  <Card.Body className="p-0 scrollbar">
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        size: 'sm',
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <AdvanceTablePagination table />
                  </Card.Footer>
                </Card>
              </AdvanceTableWrapper>
          }


        </Col>
      </Row>
      {breakpoints.down('xl') && (
        <Offcanvas
          show={showFilterOffcanvas}
          onHide={() => setShowFilterOffcanvas(false)}
          placement="start"
          className="offcanvas offcanvas-filter-sidebar"
        >
          <LeadFinderFilters isOffcanvas={true} setShow={setShowFilterOffcanvas} />
        </Offcanvas>
      )}
    </>
  );
};

export default Courses;
