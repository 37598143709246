import BreakingLoader from "assets/animations/ball_breaking_loader.json"
import ConfirmModal from "components/common/ConfirmModal"
import Flex from "components/common/Flex"
import { SHA256 } from "crypto-js"
import APIService from "http/api_service"
import Lottie from 'lottie-react'
import moment from "moment"
import React, { useState } from "react"
import {
    Card
} from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify"
import { getSequencesFromRedux, setSequencesFromRedux } from "../../../../../redux/slices/sequence_slice"
import StringUtils from "utils/string"
import WorkSpaceManager from "utils/workspace_manager"
import AddNewThread from "./AddNewThread"
import StepHeader from "./StepHeader"
import Threads from "./Threads"


const StepComponent = (props) => {
    const { index, step, openStepModal, setOpenStepModal, updatableStep, setUpdatableStep } = props;
    const dispatch = useDispatch()
    const [openStepDeleteModal, setOpenStepDeleteModal] = useState(false)
    const [processing, setProcessing] = useState(false)
    const workspace = WorkSpaceManager.getActiveWorkSpace()
    const sequence = useSelector(getSequencesFromRedux)
    const [threadToOpen, setThreadToOpen] = useState(null)

    function getAlphabetEquivalent(length) {
        if (length >= 1 && length <= 26) {
            // Convert length to ASCII code
            const asciiCode = length + 64
            // Convert ASCII code to alphabet character
            const alphabetEquivalent = String.fromCharCode(asciiCode)
            return alphabetEquivalent
        } else {
            return "Out of range"
        }
    }

    const getAllSecondsOfStepsUntilCurrentStep = (stepId) => {
        const steps = sequence.steps ?? []
        let seconds = 0
        for (let step of steps) {
            let time_unit = step["time_unit"]
            let time_value = step["time_value"]
            let timeInSeconds = moment.duration(time_value, time_unit).asSeconds();
            seconds += timeInSeconds
            if (step.id === stepId) {
                break;
            }
        }
        return seconds
    }

    const getTheDayFigureForStep = (step) => {
        const stepIndex = sequence.steps.findIndex((x) => x.id === step.id);
        if (stepIndex === 0) {
            return 1;
        }
        const seconds = getAllSecondsOfStepsUntilCurrentStep(step.id);
        let day = (seconds / 86400) + 1;
        const dayFigure = day.toString().split(".")[0];
        return dayFigure;
    }

    const cloneStep = () => {
        let stepClone = JSON.parse(JSON.stringify(step))
        stepClone["id"] = SHA256(StringUtils.generateRandomString(36)).toString()
        stepClone["created_at"] = new Date()
        stepClone["updated_at"] = new Date()
        let threads = stepClone["threads"] ?? []
        stepClone["threads"] = threads.map((t) => {
            t["id"] = SHA256(StringUtils.generateRandomString(365)).toString()
            t["name"] = `${t["name"]} Clone`
            return t
        })
        setProcessing(true)
        let sequenceCopy = JSON.parse(JSON.stringify(sequence))
        let steps = sequenceCopy["steps"]
        steps.push(stepClone)
        steps.sort(
            (a, b) =>
                new Date(b.updated_at ?? moment().subtract(1, "hour").toDate()) -
                new Date(a.updated_at ?? moment().subtract(1, "hour").toDate()),
        )
        sequenceCopy["steps"] = [...steps]
        APIService.updateSequence(
            workspace._id,
            sequence._id,
            sequenceCopy,
            (response, error) => {
                setProcessing(false)
                if (error) {
                    toast.error(error, { theme: "colored" })
                    return
                }
                dispatch(setSequencesFromRedux(response['data']));
            },
        )
    }

    const refreshStep = (updatedStep) => {
        let sequenceCopy = JSON.parse(JSON.stringify(sequence))
        let steps = sequenceCopy["steps"]
        steps[index] = updatedStep
        sequenceCopy["steps"] = steps
        setProcessing(true)
        APIService.updateSequence(
            workspace._id,
            sequence._id,
            sequenceCopy,
            (response, error) => {
                setProcessing(false)
                if (error) {
                    toast.error(error, { theme: "colored" })
                    return
                }
                dispatch(setSequencesFromRedux(response["data"]));
            },
        )
    }

    const removeStep = () => {
        setProcessing(true)
        let sequenceCopy = JSON.parse(JSON.stringify(sequence))
        let steps = sequenceCopy["steps"]
        steps.splice(index, 1)
        sequenceCopy["steps"] = steps
        APIService.updateSequence(
            workspace._id,
            sequence._id,
            sequenceCopy,
            (response, error) => {
                setProcessing(false)
                if (error) {
                    toast.error(error, { theme: "colored" })
                    return
                }
                dispatch(setSequencesFromRedux(response['data']));
            },
        )
    }

    if (processing) {
        return <Flex
            className={'gap-2'}
            alignItems={'center'}
            justifyContent={'center'}
            style={{
                height: 'auto',
                width: 'auto'
            }}
        >
            <Flex
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Lottie
                    animationData={BreakingLoader}
                    style={{
                        width: 200,
                        height: 200
                    }}
                />
                <h5>
                    PROCESSING, PLEASE WAIT....
                </h5>
            </Flex>
        </Flex>
    }

    const cloneThread = (thread) => {
        let _step = structuredClone(step)
        let threadClone = JSON.parse(JSON.stringify(thread))
        threadClone["id"] = SHA256(
            StringUtils.generateRandomString(36),
        ).toString()
        threadClone["created_at"] = new Date()
        threadClone["updated_at"] = new Date()
        threadClone["name"] = getAlphabetEquivalent(_step["threads"].length + 1)
        let threads = _step["threads"] ?? []
        threads.push(threadClone)
        _step["threads"] = threads
        refreshStep(_step)
    }


    const StepRemovalConfirmModal = () => {
        return (
            <>
                <ConfirmModal
                    open={openStepDeleteModal}
                    title={`Remove Step ${index + 1}?`}
                    message={`Are you certain about removing step ${index + 1}?`}
                    confirmText={`Remove Step ${index + 1}`}
                    cancelText={"Cancel"}
                    onCancel={() => {
                        setOpenStepDeleteModal(false)
                    }}
                    onConfirm={() => {
                        setOpenStepDeleteModal(false)
                        removeStep()
                    }}
                />
            </>
        )
    }

    return (
        <Card className="mt-4 shadow-none border border-200">
            <Card.Header className="d-md-flex justify-content-between border-bottom border-200 py-3 py-md-2">
                <StepHeader
                    getTheDayFigureForStep={getTheDayFigureForStep}
                    step={step}
                    index={index}
                    processing={processing}
                    cloneStep={cloneStep}
                    setOpenStepDeleteModal={setOpenStepDeleteModal}
                    setOpenStepModal={setOpenStepModal}
                    openStepModal={openStepModal}
                    updatableStep={updatableStep}
                    setUpdatableStep={setUpdatableStep}
                />
            </Card.Header>
            <Card.Body className="scrollbar overflow-y-hidden">
                <Threads
                    step={step}
                    index={index}
                    refreshStep={refreshStep}
                    threadToOpen={threadToOpen}
                    setThreadToOpen={setThreadToOpen}
                    cloneThread={cloneThread}
                    processing={processing}
                />
            </Card.Body>
            <Card.Footer className="bg-light py-2">
                <AddNewThread
                    step={step}
                    index={index}
                    refreshStep={refreshStep}
                    cloneThread={cloneThread}
                    processing={processing}
                />
            </Card.Footer>
            <StepRemovalConfirmModal />
        </Card>
    )
}

export default StepComponent