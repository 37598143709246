import React, { useEffect, useMemo, useState } from "react"
import { Button, Dropdown, Row, Col, Form, Card, Spinner, Modal } from "react-bootstrap"
import APIService from "http/api_service"
import WorkSpaceManager from "utils/workspace_manager"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import StringUtils from "utils/string"
import Reactloading from "react-loading"
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper"
import AdvanceTable from "components/common/advance-table/AdvanceTable"
import Flex from "components/common/Flex"
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox"
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter"
import CardDropdown from "components/common/CardDropdown"
import { RoutePaths } from "../../../constants"
import { Link } from "react-router-dom"

const AllList = ({ sequence, onSequenceChanged, ...rest }) => {

  const [lists, setLists] = useState([])
  const [searchText, setSearchText] = useState("")
  const [activeWorkspace] = useState(WorkSpaceManager.getActiveWorkSpace())
  const [bulkActionSelectedOption, setBulkActionsSelectedOption] = useState("")
  const [performingBulkOperation, setPerformingBulkOperation] = useState(false)
  const [loadingLists, setLoadingLists] = useState(false)
  const [openListRenameDialog, setOpenListRenameDialog] = useState(false)
  const [renamingList, setRenamingList] = useState(false)
  const [selectedList, setSelectedList] = useState()
  const [listName, setListName] = useState()

  const fetchLists = async () => {
    setLoadingLists(true)
    try {
      let { data } = await APIService.fetchWorkspaceLists(activeWorkspace._id)
      setLists(data)
      setLoadingLists(false)
    } catch (e) {
      setLoadingLists(false)
    }
  }

  const handleListRename = async (e) => {
    e.preventDefault()
    setRenamingList(true)
    try {
      let { message, data } = await APIService.upsertList(activeWorkspace._id, {
        _id: selectedList._id,
        name: listName,
      })
      setRenamingList(false)
      setOpenListRenameDialog(false)
      toast.success(message, { theme: "colored" })
      setLists((prevLists) =>
        prevLists.map((list) => {
          if (list._id === data._id) {
            return data
          } else {
            return list
          }
        }),
      )
    } catch (e) {
      setRenamingList(false)
      toast.error(e, { theme: "colored" })
    }
  }

  useEffect(() => {
    fetchLists()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          const { name, _id } = row.original
          return (
            <Link className=" border-2x btn-lg mb-3 fs-0" to={`${RoutePaths.LISTS}/${_id}?name=${name}`}>
              {StringUtils.highlightMatchingText(name, searchText)}
            </Link>
          )
        },
      },
      {
        Header: "Contacts",
        accessor: "contacts",
        Cell: ({ row }) => row.original.contacts,
      },
      {
        Header: "Creation date",
        accessor: "created_at",
        Cell: ({ row }) => new Date(row.original.created_at).toDateString(),
      },
      {
        Header: "Actions",
        accessor: "none",
        disableSortBy: true,
        cellProps: {
          className: "text-start",
        },
        Cell: ({ row }) => {
          return (
            <CardDropdown iconClassName="fs--1">
              <div className="py-2">
                <Dropdown.Item
                  as={"button"}
                  onClick={() => {
                    setSelectedList(row.original)
                    setListName(row.original.name)
                    setOpenListRenameDialog(true)
                  }}
                >
                  Rename
                </Dropdown.Item>
              </div>
            </CardDropdown>
          )
        },
      },
    ],
    [searchText],
  )

  const handleSelectChange = (e) => {
    setBulkActionsSelectedOption(e.target.value)
  }

  const applyBulkAction = (selectedRowIndices) => {
    if (selectedRowIndices?.length === 0) {
      return
    }
    switch (bulkActionSelectedOption) {
      case "delete":
        let updatedLists = lists.filter((_, index) => !selectedRowIndices.includes(`${index}`))
        setPerformingBulkOperation(true)
        const listsToDelete = lists
          .filter((_, index) => selectedRowIndices.includes(`${index}`))
          .map((lead) => lead._id)
        APIService.batchDeleteLists(activeWorkspace._id, listsToDelete, (res, error) => {
          setPerformingBulkOperation(false)
          if (error) {
            toast.error(error, { theme: "colored" })
            return
          }
          let { message } = res
          toast.success(message, { theme: "colored" })
          setLists(updatedLists)
        })
        break
      default:
        return
    }
  }

  function BulkAction({ selectedRowIds }) {
    return (
      <Row className="flex-between-center mb-3">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {Object.keys(selectedRowIds).length > 0
              ? "You have selected " + Object.keys(selectedRowIds).length + " rows"
              : "Lists"}
          </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select
                value={bulkActionSelectedOption}
                size="sm"
                aria-label="Bulk actions"
                onChange={handleSelectChange}
              >
                <option value={"bulk-actions"}>Bulk Actions</option>
                <option value="delete">Delete</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                disabled={
                  !bulkActionSelectedOption || bulkActionSelectedOption === "bulk-actions" || performingBulkOperation
                }
                className="ms-2"
                onClick={(e) => {
                  applyBulkAction(Object.keys(selectedRowIds))
                }}
              >
                {performingBulkOperation ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  <span>Apply</span>
                )}
              </Button>
            </div>
          ) : (
            <Flex alignItems={"center"} className={"gap-3"} justifyContent={"center"}>
              {rest.refreshingLeads ? (
                <Reactloading className="mt-4" width={30} type="spinningBubbles" color="teal" />
              ) : (
                <Button
                  variant="primary"
                  size="sm"
                  transform="shrink-3"
                  onClick={() => {
                    if (rest.createList) {
                      rest.createList()
                    }
                  }}
                >
                  <Flex alignItems={"center"} justifyContent={"center"}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="d-none d-sm-inline-block ms-1">Create a list</span>
                  </Flex>
                </Button>
              )}
            </Flex>
          )}
        </Col>
      </Row>
    )
  }

  return (
    <Card style={{ boxShadow: "none" }} className="border border-200">
      <Card.Body>
        {lists?.length < 1 && loadingLists && (
          <Flex
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Reactloading className="mt-4" width={30} type="spinningBubbles" color="teal" />
          </Flex>
        )}
        {lists?.length > 0 && (
          <AdvanceTableWrapper
            columns={columns}
            data={lists}
            sortable
            pagination
            perPage={50}
            selection
            selectionColumnWidth={30}
          >
            <BulkAction table />
            <AdvanceTableSearchBox
              table
              placeholder="Search list"
              onSearchTextChanged={(changedSearchText) => {
                setSearchText(changedSearchText)
              }}
            />
            <div className="mb-3"></div>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter rowCount={lists?.length} table rowInfo navButtons rowsPerPageSelection />
            </div>
          </AdvanceTableWrapper>
        )}
      </Card.Body>
      <Modal
        show={openListRenameDialog}
        size="lg"
        onHide={() => setOpenListRenameDialog(false)}
        contentClassName="border"
      >
        <Form onSubmit={handleListRename}>
          <Modal.Header closeButton className="bg-light px-card border-bottom-0">
            <Modal.Title as="h5">
              <span className="fw-medium">Rename {selectedList?.name}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-card overflow-auto" style={{ maxHeight: "70vh" }}>
            <Form.Group className="mb-3" controlId="titleInput">
              <Form.Label className="fs-0">List name</Form.Label>
              <Form.Control
                type="text"
                name="title"
                required
                defaultValue={listName}
                placeholder="Enter the new name for this list"
                onChange={(e) => {
                  setListName(e.target.value)
                }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Link
              to={"#!"}
              className="me-3 text-600"
              style={renamingList ? { pointerEvents: "none" } : {}}
              onClick={() => {
                setOpenListRenameDialog(false)
              }}
            >
              Cancel
            </Link>
            <Button variant="primary" type="submit" disabled={renamingList} className="px-4 mx-0">
              {renamingList ? "Renaming list..." : "Rename"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Card>
  )
}
export default AllList
