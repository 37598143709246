import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Form, Image, Card } from 'react-bootstrap';
import { courseFilters } from 'data/elearning/courseData';
import { slugifyText } from 'helpers/utils';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Select from 'react-select';
import { countries, companySizeOptions, industryOptions, revenueOptions } from 'data/leadFinderFilters';
import APIService from 'http/api_service';
import WorkSpaceManager from 'utils/workspace_manager';
import { customersData } from 'data/ecommerce/customersData';

const CourseFilters = ({ setShow, isOffcanvas, _setCustomersData, setFiltering, filtering, userHasTakenAction, setUserHasTakenAction }) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [companySize, setCompanySize] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [location, setLocation] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const [activeWorkspace] = useState(WorkSpaceManager.getActiveWorkSpace())
  const [companyName, setCompanyName] = useState(null);
  const [canProceedWithFiltering, setCanProceedWithFiltering] = useState(false);
  let typingTimeout;

  useEffect(() => {
    setCanProceedWithFiltering(true)
  }, [])


  const jobTitles = [
    { value: '1', label: 'CEO' },
    { value: '2', label: 'CFO' },
    { value: '3', label: 'COO' },
    { value: '4', label: 'CMO' },
    { value: '5', label: 'CTO' },
    { value: '6', label: 'CIO' },
    { value: '7', label: 'CDO' },
    { value: '8', label: 'CHRO' },
    { value: '9', label: 'CCO' },
    { value: '10', label: 'CSO' },
    { value: '11', label: 'President' },
    { value: '12', label: 'Vice President' },
    { value: '13', label: 'Director of Marketing' },
    { value: '14', label: 'Director of Sales' },
    { value: '15', label: 'Director of Human Resources' },
    { value: '16', label: 'Head of Marketing' },
    { value: '17', label: 'Head of Finance' },
  ];

  const organizerOptions = [
    { value: '1', label: 'CEO' },
    { value: '2', label: 'CFO' },
    { value: '3', label: 'COO' },
    { value: '4', label: 'CMO' },
    { value: '5', label: 'CTO' },
    { value: '6', label: 'CIO' },
    { value: '7', label: 'CDO' },
    { value: '8', label: 'CHRO' },
    { value: '9', label: 'CCO' },
    { value: '10', label: 'CSO' },
    { value: '11', label: 'President' },
    { value: '12', label: 'Vice President' },
    { value: '13', label: 'Director of Marketing' },
    { value: '14', label: 'Director of Sales' },
    { value: '15', label: 'Director of Human Resources' },
    { value: '16', label: 'Head of Marketing' },
    { value: '17', label: 'Head of Finance' },
  ];

  const handleFilterOptions = e => {
    const { type, name, value, checked } = e.target;
    if (type === 'checkbox') {
      let options = [...filterOptions];
      options = options.filter(option => option.value !== value);

      checked && options.push({ name, value });
      setFilterOptions(options);
    }

    if (type === 'radio') {
      const isExists = filterOptions.some(el => el.name === name);
      isExists
        ? setFilterOptions(
          filterOptions.map(el => (el.name === name ? { ...el, value } : el))
        )
        : setFilterOptions([...filterOptions, { name, value }]);
    }
  };

  const handleFetchLeadsByFilter = async (value) => {
    let query = {};
    if (location?.length) {
      query['country'] = { $in: location?.map(({ label }) => label) }
    }
    if (revenue?.length) {
      query['revenue'] = { $in: revenue?.map(({ label }) => label) }
    }
    if (jobTitle?.length) {
      query['title'] = { $in: jobTitles?.map(({ label }) => label) }
    }
    if (industry?.length) {
      query['industry'] = { $in: industry?.map(({ label }) => label) }
    }
    if (value) {
      query['company_name'] = { $regex: value, $options: 'i' };
    }

    if (Object.keys(query).length === 0) {
      _setCustomersData([])
      return
    }

    try {
      setFiltering(true)
      if(!userHasTakenAction){
        setUserHasTakenAction(true);
      }
      let { message, data } = await APIService.fetchLeadsByFilter(activeWorkspace._id, {
        query: JSON.stringify(query),
        fields: JSON.stringify({}),
        options: JSON.stringify({ limit: 100, skip: 0 })
      })
      _setCustomersData(data)
      setFiltering(false)
    } catch (error) {
      console.log("🚀 ~ handleFetchLeadsByFilter ~ error:", error)
      setFiltering(false)
    }
  }

  useEffect(() => {
    if (!canProceedWithFiltering) return;
    handleFetchLeadsByFilter();
  }, [location, revenue, jobTitle, industry])

  const handleTextFiltering = (value) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      handleFetchLeadsByFilter(value);
    }, 1000);
  }

  return (
    <Card className="course-filter">
      <SimpleBarReact style={{ height: '100%' }}>
        <Card.Header as={Flex} className="flex-between-center pt-card">
          <Flex className="gap-2 flex-xl-grow-1 align-items-center justify-content-xl-between">
            <h5 className="mb-0 text-700 fs-0 d-flex align-items-center">
              <FontAwesomeIcon icon="filter" className="fs--1 me-1" />
              <span>Filter
              </span>
            </h5>
            <Button
              variant="outline-secondary"
              size="sm"
              className="ms-2 mt-0 mb-0"
              style={{ fontSize: '12px' }}
              onClick={() => {
                setFilterOptions([])
                _setCustomersData([]);
              }}
            >
              <FontAwesomeIcon icon="redo-alt" className="me-1 fs--2" />
              Reset
            </Button>
          </Flex>
          {isOffcanvas && (
            <Button
              onClick={() => setShow(false)}
              className="btn-close text-reset"
              size="sm"
              variant="link"
            ></Button>
          )}
        </Card.Header>
        <Card.Body className="py-0">
          {filterOptions.length > 0 && (
            <Flex wrap="wrap" className="gap-2 mb-3">
              {filterOptions.map(tag => (
                <SoftBadge
                  key={tag.value}
                  className="text-capitalize bg-300 text-700 py-0"
                >
                  {tag.value}
                  <Button
                    size="sm"
                    variant="link"
                    className="p-0 text-700"
                    onClick={() =>
                      setFilterOptions(
                        filterOptions.filter(({ value }) => value !== tag.value)
                      )
                    }
                  >
                    <FontAwesomeIcon icon="times" className="ms-1 fs--2" />
                  </Button>
                </SoftBadge>
              ))}
            </Flex>
          )}
          <Form.Group className="mb-3">
            {/* <Form.Label>Job Titles</Form.Label> */}
            <Select
              closeMenuOnSelect={false}
              options={jobTitles}
              placeholder='Job Titles'
              isMulti
              classNamePrefix="react-select"
              value={jobTitle}
              onChange={value => setJobTitle(value)}
            />
          </Form.Group>
          <Select
            closeMenuOnSelect={false}
            options={countries}
            placeholder='Location'
            isMulti
            classNamePrefix="react-select"
            value={location}
            onChange={location => setLocation(location)}
          />
          <br />
          <Select
            closeMenuOnSelect={false}
            options={industryOptions}
            placeholder='Industry'
            isMulti
            classNamePrefix="react-select"
            value={industry}
            onChange={industry => setIndustry(industry)}
          />
          <br />
          <Select
            closeMenuOnSelect={false}
            options={companySizeOptions}
            placeholder='Company size'
            isMulti
            classNamePrefix="react-select"
            value={companySize}
            onChange={companySize => setCompanySize(companySize)}
          />
          <br />
          <Select
            closeMenuOnSelect={false}
            options={revenueOptions}
            placeholder='Revenue'
            isMulti
            classNamePrefix="react-select"
            value={revenue}
            onChange={revenue => setRevenue(revenue)}
          />
          <br />
          <Form.Control
            type="text"
            placeholder="Company Name"
            className="mb-3"
            value={companyName}
            onChange={(e) => {
              handleTextFiltering(e.target.value);
              setCompanyName(e.target.value);
            }}
          />
          {/* <Select
            closeMenuOnSelect={false}
            options={organizerOptions}
            placeholder='Keyword Filter'
            isMulti
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
          /><br />
          <Select
            closeMenuOnSelect={false}
            options={organizerOptions}
            placeholder='Lookalike Domain'
            isMulti
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
          /><br />
          <Select
            closeMenuOnSelect={false}
            options={organizerOptions}
            placeholder='Domains'
            isMulti
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
          /><br />
          <Select
            closeMenuOnSelect={false}
            options={organizerOptions}
            placeholder='Job Listing'
            isMulti
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
          />
          <br />
          <Select
            closeMenuOnSelect={false}
            options={organizerOptions}
            placeholder='Technologies'
            isMulti
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
          />
          <br />
          <Select
            closeMenuOnSelect={false}
            options={organizerOptions}
            placeholder='News'
            isMulti
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
          />
          <br />
          <Select
            closeMenuOnSelect={false}
            options={organizerOptions}
            placeholder='Funding Type'
            isMulti
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
          />
          <br />
          <Select
            closeMenuOnSelect={false}
            options={organizerOptions}
            placeholder='Department'
            isMulti
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
          />
          <br />
          <Select
            closeMenuOnSelect={false}
            options={organizerOptions}
            placeholder='Management Levels'
            isMulti
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
          /> */}
          <br />

          <br />
          <br />
          <br />
          <br />
          <br />
          {/* <ul className="list-unstyled">
            {courseFilters.map((filter, index) => (
              <FilterItem
                key={slugifyText(filter.label)}
                index={index}
                filter={filter}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                handleFilterOptions={handleFilterOptions}
              />
            ))}
          </ul> */}
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

CourseFilters.propTypes = {
  setShow: PropTypes.func,
  isOffcanvas: PropTypes.bool
};

const FilterItem = ({ filter, index, filterOptions, handleFilterOptions }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={`${courseFilters.length - 1 !== index && 'border-bottom'}`}>
      <Button
        variant="link"
        onClick={() => setOpen(!open)}
        aria-controls={`${slugifyText(filter.label)}-collapse`}
        aria-expanded={index === 0 || index === 1 ? !open : open}
        className="collapse-indicator-plus w-100 fs--2 fw-medium text-start text-600 text-decoration-none py-3 px-0"
      >
        {filter.label}
      </Button>
      <Collapse
        in={index === 0 || index === 1 ? !open : open}
        id={`${slugifyText(filter.label)}-collapse`}
      >
        <ul className="list-unstyled">
          {filter.options &&
            filter.options.map(option => (
              <li key={slugifyText(option.label)}>
                <Form.Check
                  type={option.type}
                  className="form-check d-flex ps-0"
                >
                  <Form.Check.Label
                    className="fs--1 flex-1 text-truncate"
                    htmlFor={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                  >
                    {option.icon && (
                      <FontAwesomeIcon
                        icon={option.icon}
                        className={`me-3 ${option.iconShrink ? 'fs--2' : 'fs--1'
                          }`}
                      />
                    )}
                    {option.svg && (
                      <Image
                        src={option.svg}
                        width={13}
                        alt=""
                        className="me-3"
                      />
                    )}
                    {option.label}
                  </Form.Check.Label>

                  <Form.Check.Input
                    type={option.type}
                    onChange={e => handleFilterOptions(e)}
                    checked={
                      option.type === 'checkbox'
                        ? filterOptions.some(
                          ({ value }) => option.value === value
                        )
                        : undefined
                    }
                    id={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                    name={option.name}
                    value={option.value}
                  />
                </Form.Check>
              </li>
            ))}
        </ul>
      </Collapse>
    </li>
  );
};

FilterItem.propTypes = {
  index: PropTypes.number,
  filter: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }),
  handleFilterOptions: PropTypes.func,
  filterOptions: PropTypes.array
};

export default CourseFilters;
