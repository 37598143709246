/* eslint-disable react-hooks/exhaustive-deps */
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons"
import Flex from "components/common/Flex"
import IconButton from 'components/common/IconButton'
import { RoutePaths } from "constants"
import AppContext from "context/Context"
import APIService from "http/api_service"
import { useContext, useEffect, useState } from "react"
import { Nav, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap"
import ReactLoading from "react-loading"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { getSequencesFromRedux, setSequencesFromRedux } from "../../../../redux/slices/sequence_slice"
import WorkSpaceManager from "utils/workspace_manager"
import { getSequenceName } from ".."
import SequenceRecipients from "./recipients"
import Options from "./settings"
import SequenceSteps from "./steps"

export default function Sequence() {

  const { config } = useContext(AppContext)
  const workspace = WorkSpaceManager.getActiveWorkSpace()
  const sequence = useSelector(getSequencesFromRedux)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const [sequence, setSequence] = useState()
  const { option, sequence_id } = useParams()
  const activeTab = option || "steps"
  const [loadingSequence, setLoadingSequence] = useState(true)

  const pageMaps = {
    steps: <SequenceSteps sequence={sequence} />,
    recipients: <SequenceRecipients sequence={sequence} />,
    // schedule: <Schedule sequence={sequence} />,
    settings: <Options sequence={sequence} />,
  }
  const [fetchError, setFetchError] = useState()

  const fetchSequence = () => {
    setFetchError(undefined)
    APIService.fetchSequence(workspace._id, sequence_id, (response, error) => {
      setLoadingSequence(false)
      if (error) {
        toast.error(error, { theme: "colored" })
        return
      }
      let { data } = response
      if (!data) {
        setFetchError("Sorry, we were unable to retrieve this sequence")
        return
      }
      // setSequence(data);
      dispatch(setSequencesFromRedux(data));
    })
  }

  const toggleSequenceActivation = () => {
    if (sequence.contact_lists <= 0 || !sequence.contact_lists) {
      toast.error("Please add list(s) to this sequence using the recipients tab before activating it", { theme: "colored" })
      navigate(`${RoutePaths.SEQUENCES}/${sequence._id}/recipients`);
      return
    }
    let previouslyActive = sequence.activated
    let existingSequence = JSON.parse(JSON.stringify(sequence))
    let newActivationValue = !sequence.activated
    // setSequence({ ...sequence })
    dispatch(setSequencesFromRedux({ ...sequence }));
    APIService.sequenceActivationStatusUpdate(workspace._id, sequence._id, { status: newActivationValue }, (response, error) => {
      if (error) {
        console.log("🚀 ~ file: index.js:72 ~ APIService.sequenceActivationStatusUpdate ~ error:", error)
        // setSequence(existingSequence)
        dispatch(setSequencesFromRedux(existingSequence));
        toast.error(error, { theme: "colored" })
        return
      }
      let { data } = response
      // setSequence({ ...data })
      dispatch(setSequencesFromRedux({ ...data }));
      let message = `Sequence successfully ${previouslyActive ? "deactivated" : "activated"}`
      toast.success(message, { theme: "colored" })
    })
  }

  useEffect(() => {
    fetchSequence()
  }, [])

  const handleTabChange = (eventKey) => {
    navigate(`/${workspace._id}/sequences/${sequence_id}/${eventKey}`)
  }

  return (
    <Flex direction={"column"}>
      {!loadingSequence && sequence && (
        <Flex
          className={"ps-0 pt-2"}
          style={{ background: config.isDark ? "transparent" : "white" }}
          direction={"column"}
        >
          <Flex alignItems={"left"} className={"mb-3"}>
            <Link className={"fs--1 fw-bold"} to={RoutePaths.SEQUENCES}>
              Sequences
            </Link>
            <OverlayTrigger overlay={<Tooltip>{`${sequence["name"]}`}</Tooltip>}>
              <div>
                <span className="px-2 fw-bold"> &gt; </span>
                {
                  sequence?.name &&
                  <span className="fw-bold">{getSequenceName(sequence["name"])} </span>
                }
              </div>
            </OverlayTrigger>
          </Flex>
          <Flex
            alignItems={"left"}
            style={{
              // paddingBottom: 2,
              borderBottom: ".5px solid #B0BEC5",
            }}
          >
            <Tab.Container 
              // defaultActiveKey={activeTab}
              activeKey={activeTab}
              onSelect={handleTabChange}
              >
              <Nav variant="tabs" 
                style={{
                  flex: 1,
                  borderBottom: "none",
                }}>
                <Nav.Item>
                  <Nav.Link
                    to="steps"
                    eventKey="steps"
                    className="ps-0 cursor-pointer outline-none"
                  >
                    Sequence steps
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    to="recipients"
                    eventKey="recipients"
                    className="px-2 px-md-3 cursor-pointer outline-none"
                  >
                    Recipients
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    to="settings"
                    eventKey="settings"
                    className="px-2 px-md-3 cursor-pointer outline-none"
                  >
                    Settings
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Flex alignItems={"center"} className={"gap-4 me-3 mb-1"}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{`${sequence.activated ? "Deactivate campaign" : "Activate campaign"}`}</Tooltip>}
                >
                  <IconButton
                    variant={sequence.activated ? "success" : "secondary"}
                    style={{
                      width: 'auto',
                      height: "auto",
                      cursor: "pointer",
                      marginBottom: "4px",
                      fontSize: "16px",
                    }}
                    icon={sequence.activated ? faPause : faPlay}
                    iconAlign="left"
                    iconStyle={{
                      fontSize: "124px", // Adjust the font size for the icon
                    }}
                    className={`mr-2 text-white`}
                    onClick={() => {
                      toggleSequenceActivation()
                    }}
                  >
                    {`${sequence.activated ? "Deactivate sequence" : "Activate sequence"}`}
                  </IconButton>
                </OverlayTrigger>
              </Flex>
            </Tab.Container>
            {/* <Tabs
              className="ps-0"
              style={{
                flex: 1,
                borderBottom: "none",
              }}
              activeKey={activeTab}
              onSelect={handleTabChange}
            >
              <Tab className="ps-0" eventKey="steps" title={<div className="ps-0">Sequence steps</div>} ></Tab>
              <Tab eventKey="recipients" title="Recipients" ></Tab>
              <Tab eventKey="schedule" title="Schedule" href="/schedule"></Tab>
              <Tab eventKey="settings" title="Settings" href="/settings"></Tab>
            </Tabs> */}
            
          </Flex>
        </Flex>
      )}
      {loadingSequence && (
        <Flex
          style={{
            height: "100vh",
          }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Flex alignItems={"center"} justifyContent={"center"}>
            <ReactLoading width={60} height={60} type="bubbles" color="teal" />
          </Flex>
        </Flex>
      )}
      {fetchError && (
        <Flex
          style={{
            height: "100vh",
          }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Flex alignItems={"center"} justifyContent={"center"}>
            <span className="text-danger">{fetchError}</span>
          </Flex>
        </Flex>
      )}
      {!loadingSequence && sequence && pageMaps[activeTab]}
    </Flex>
  )
}
