import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import APIService from "http/api_service";
import { useEffect, useState } from "react";
import Reactloading from 'react-loading';
import { lightTheme } from "utils/theme";
import WorkSpaceManager from "utils/workspace_manager";
import TemplateListItem from "../template-list-item";

export default function MyTemplates(props) {

    const {
        sequence,
        handleSelectedPromptTemplate,
        handleCreateNewTemplate
    } = props;
    const [templates, setTemplates] = useState(props.initial ?? []);
    const [fetchingTemplates, setFetchingTemplates] = useState(false);
    const workspace = WorkSpaceManager.getActiveWorkSpace();
    const [searchText, setSearchText] = useState("");

    const fetchCustomPromptTemplates = async () => {
        setFetchingTemplates(true);
        APIService.fetchCustomPromptTemplates(workspace._id, sequence._id, (response, error) => {
            setFetchingTemplates(false);
            if (error) {
                return;
            }
            let { data } = response;
            setTemplates(data);
        });
    }

    useEffect(() => {
        if (templates.length === 0) {
            fetchCustomPromptTemplates();
        }
    }, []);

    const GetStartedView = () => {
        return (
            <Flex
                alignItems={'center'}
                justifyContent={'center'}
                className={'w-100 pt-4'}>
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <IconButton
                        variant="outline-primary"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className={"py-2"}
                        onClick={() => {
                            handleCreateNewTemplate(templates);
                        }}
                    >
                        Create new prompt
                    </IconButton>
                    <IconButton
                        className="ms-5"
                        variant="light rounded-pill"
                    >
                        <FontAwesomeIcon className="py-2" color={lightTheme.primary} icon={faDownload} />
                    </IconButton>
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex>
            {
                fetchingTemplates &&
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={'w-100 pt-5'}>
                    <Flex
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Reactloading width={30} type="spinningBubbles" color="teal" />
                    </Flex>
                </Flex>
            }
            {
                !fetchingTemplates && templates.length < 1 &&
                <GetStartedView />
            }
            {
                !fetchingTemplates && templates.length > 0 &&
                <Flex className={'w-100'}>
                    <Flex className={'w-100'} direction={'column'}>
                        <GetStartedView />
                        {
                            templates.map(x => {
                                return (
                                    <div
                                        key={x.title}
                                        onClick={() => {
                                            handleSelectedPromptTemplate(x);
                                        }}>
                                        <TemplateListItem
                                            {...x}
                                            searchText={searchText}
                                        />
                                    </div>
                                )
                            })
                        }
                    </Flex>
                </Flex>
            }
        </Flex>
    );
}