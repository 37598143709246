import React from 'react';
import ProfileBanner from './ProfileBanner';
import coverSrc from 'assets/img/icons/spot-illustrations/authentication-corner.png';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import ChangePassword from './ChangePassword';
import DangerZone from './DangerZone';
import UserManager from 'utils/user_manager';
import AuthenticationMethodCard from './AuthenticationMethodCard';
import WorkspaceSlotsCard from './WorkspacesCard';

const UserProfile = () => {

  const loggedInUser = UserManager.getLoggedInUser();

  return (
    <>
      {/* <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={loggedInUser?.avatar}
          className="mb-8"
        />
      </ProfileBanner> */}
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings />
          <AuthenticationMethodCard />
          <WorkspaceSlotsCard />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <ChangePassword />
            <DangerZone />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserProfile;
