import { ChatContext } from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import SimpleBarReact from 'simplebar-react';
import AttachmentViewer from './AttachmentViewer';

const ChatContentBody = ({ thread }) => {
  const messagesEndRef = useRef();

  const { getUser, messages, scrollToBottom, setScrollToBottom } =
    useContext(ChatContext);
  const user = getUser(thread);
  const content = thread?.text || thread?.parsed?.textAsHtml;

  return (
    <div className="chat-content-body" style={{ display: 'inherit' }}>
      <SimpleBarReact style={{ height: '100%' }}>
        <div className="chat-content-scroll-area" dangerouslySetInnerHTML={{ __html: content }} style={{
          padding: 10
        }} />
        <div ref={messagesEndRef} />
        {
          thread.attachments?.length ?
            <AttachmentViewer attachments={thread.attachments} /> : null
        }
      </SimpleBarReact>
    </div>
  );
};

ChatContentBody.propTypes = {
  thread: PropTypes.object.isRequired
};

export default ChatContentBody;
