/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import Background from "components/common/Background";
import { Container } from "react-bootstrap";
// import platformgrid from "assets/img/illustrations/platformgrid.png";
// import LogoWhite from "components/common/LogoWhiteFull";
import { Image } from "react-bootstrap";
import AlexCollins from "assets/img/team/alex.jpeg";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import Flex from "components/common/Flex";
import halfCircle from "assets/img/illustrations/half-circle.png";
import { RoutePaths } from "../constants";
import UserManager from "utils/user_manager";
import { useQuery } from "utils/url";
import Divider from "components/common/Divider";
import Avatar from "components/common/Avatar";
import { useDispatch, useSelector } from "react-redux";
import {
  canFlipToChildPages,
  getSwitchMode,
  setFlipToChildPages,
  setSwitchMode,
} from "../redux/slices/authSlice";

const AuthSplitLayout = ({
  children,
  bgProps,
  leftSideContent,
  footer = true,
}) => {
  const navigate = useNavigate();
  const loggedInUser = UserManager.getLoggedInUser();
  const switchMode = useSelector(getSwitchMode);
  const query = useQuery();
  const [switchingTo, setSwitchingTo] = useState({});
  const dispatch = useDispatch();
  const flipToChildPages = useSelector(canFlipToChildPages);

  const SwitchView = () => {
    const [loggedInAltUsers, setLoggedInAltUsers] = useState(
      UserManager.getLoggedInAltUsers()
    );

    useEffect(() => {
      if (loggedInUser) {
        setLoggedInAltUsers((prevUsers) => {
          prevUsers = prevUsers ?? [];
          let currentUser = prevUsers.find((x) => x._id === loggedInUser._id);
          if (!currentUser) {
            prevUsers.unshift(loggedInUser);
          }
          let newData = [...prevUsers];
          UserManager.setAltableUsers(newData);
          return newData;
        });
      }
    }, []);

    return (
      <>
        {flipToChildPages && <div className="flex-grow-1">{children}</div>}
        <>
          {!flipToChildPages && (
            <>
              {!loggedInAltUsers ? (
                <div className=" flex-grow-1">{children}</div>
              ) : (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction={"column"}
                  className={"gap-2"}
                >
                  <h5 className="p-0 m-0">Switch Account</h5>
                  <Divider />
                  {loggedInAltUsers.map((x) => {
                    return (
                      <Button
                        disabled={switchingTo.hasOwnProperty(x._id)}
                        id={x._id}
                        style={{
                          width: "100%",
                          textAlign: "start",
                        }}
                        variant="falcon-default"
                        onClick={() => {
                          setSwitchingTo((prev) => {
                            prev = prev ?? {};
                            prev[x._id] = true;
                            return { ...prev };
                          });
                          UserManager.altableUser(x);
                          navigate(RoutePaths.DEFAULT);
                          window.location.reload();
                        }}
                      >
                        <Flex alignItems={"center"} className={"gap-3"}>
                          <Avatar
                            className={`${
                              x._id === loggedInUser._id ? "status-online" : ""
                            }`}
                            size="2xl"
                            src={x.avatar}
                            fallBackName={`${x.first_name ?? x.email}`}
                          />
                          {switchingTo.hasOwnProperty(x._id) ? (
                            <span>Please wait...</span>
                          ) : (
                            <Flex direction={"column"}>
                              <h5 className="p-0 m-0">{`${x.first_name} ${x.last_name}`}</h5>
                              <span className="fs--1">{x.email}</span>
                            </Flex>
                          )}
                        </Flex>
                      </Button>
                    );
                  })}
                  <Button
                    onClick={() => {
                      dispatch(setFlipToChildPages(true));
                    }}
                    variant="falcon-primary"
                    style={{ width: "100%", marginTop: 10, }}
                  >
                    Use another Acount
                  </Button>
                </Flex>
              )}
            </>
          )}
        </>
      </>
    );
  };

  const navigateToMainPage = (url, canSwitch) => {
    if (switchMode) {
      window.location = `${window.location.href}?switch=${switchMode}`;
      return;
    }
    if (
      !url.includes(RoutePaths.LOGOUT) &&
      !url.includes(RoutePaths.INVITATIONS) &&
      !url.includes(RoutePaths.TELEGRAM_LOGIN_PROXY) &&
      !url.includes(RoutePaths.FORGOT_PASSWORD) &&
      !url.includes(RoutePaths.RESET_PASSWORD) &&
      !canSwitch
    ) {
      navigate(RoutePaths.WORKSPACE);
    }
  };

  useEffect(() => {
    if (loggedInUser) {
      let url = window.location.href;
      let canSwitch = query.get("switch");
      if (canSwitch) {
        dispatch(setSwitchMode(canSwitch));
        if (canSwitch !== "true") {
          navigateToMainPage(url);
        }
      } else {
        dispatch(setSwitchMode(false));
        navigateToMainPage(url, canSwitch);
      }
    } else {
      setTimeout(() => {
        let followUpDeletionUrl = localStorage.getItem("follow_up_deletion");
        if (followUpDeletionUrl) {
          localStorage.removeItem("follow_up_deletion");
          window.location.href = followUpDeletionUrl;
        }
      }, 200);
    }
  }, [window.location]);

  return (
    <Container fluid>
      <Row className="min-vh-100 bg-100">
        <Col sm={10} md={6} className="px-sm-0 align-self-center mx-auto py-0">
          <Row className="g-0 justify-content-center">
            <Col lg={9} xl={9} className="col-xxl-6">
              <div className="text-start" style={{ marginBottom: "60px" }}>
                {/* <FullLogo  width={100} height={35} fontSize={16} /> */}
                <h6>reachable</h6>
              </div>

              {switchMode ? (
                <SwitchView />
              ) : (
                <div className="flex-grow-1">{children}</div>
              )}

              {/* <Card.Body className="p-4">{children}</Card.Body> */}
            </Col>
          </Row>
        </Col>

        <Col
          lg={5}
          xs={6}
          className="bg-shape d-none d-lg-flex flex-column justify-content-center text-center align-items-center position-relative py-0"
          style={{ backgroundColor: "#509bfc" }}
        >
          <Background
                      image={halfCircle}
                      className="bg-auth-card-shape"
                    />
            {window.location.href === `${window.location.origin}/register` ? (
              <p className="text-white white font-sans-serif fw-bolder fs-3 z-index-1 position-relative">
                Social Media Marketing Made Easy, Powered by AI
              </p>
            ) : (
              <>
                <p className="text-white white font-sans-serif fw-bolder fs-2 z-index-1 position-relative">
                  “Reachable is a great cold outreach tool, Super easy & simple
                  to use.”
                </p>
                <div className="d-flex justify-content-center">
                  {" "}
                  {/* Optional: You can remove this wrapper if you don't want to center the image */}
                  <Image
                    thumbnail
                    fluid
                    roundedCircle
                    className="mb-3 shadow-sm"
                    src={AlexCollins}
                    width={100}
                  />
                </div>
                <h6 className="mb-1 text-white white font-sans-serif fw-bolder fs-1 z-index-1 position-relative">
                  Alex Collins
                </h6>
                <p className="mb-1 text-white white font-sans-serif fs-0 z-index-1 position-relative">
                  <Link className="text-white" to="#!">
                    Founder of SpeedUp Design
                  </Link>
                </p>
              </>
            )}
         
          <br />
        </Col>
      </Row>
    </Container>
  );
};

AuthSplitLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bgProps: PropTypes.shape(Background.propTypes).isRequired,
};

export default AuthSplitLayout;
