import { useState, useEffect } from "react";
import {
    IMAPAndSMTPBasicInformationScreen,
    IMAPAndSMTPConnectionScreen
} from "./IMAPAndSMTPConnector";
import Flex from "components/common/Flex";
import { EmailProviderListItem, anyProviderIcon } from "../connect_email_account";
import { UpgradeToPremiumAccountCard } from "components/app/new-workspace";
import { Modal } from "react-bootstrap";
import WorkSpaceManager from '../../../../utils/workspace_manager';
import AnyProviderBulkUploader from "./AnyProviderBulkUploader";

const AnyProviderConnectionHeader = ({ headerIcon }) => {
    return (
        <Flex direction={'column'}>



            <Flex
                className={'gap-2 px-1'}
                alignItems={'center'}
            >
                <img src={headerIcon} width={40} alt="" />
                <Flex direction={'column'}>
                    <h2
                        className="p-0 m-0 fw-900"
                        style={{
                            fontSize: '1.4rem'
                        }}>Connect Any Provider
                    </h2>
                    <span className="text-500 fw-medium">IMAP/SMTP</span>
                </Flex>
            </Flex>
            <body className="p-3 mt-2">
                <p className="fs--1">
                    To successfully connect to any email service provider, please refer to your specific provider for any<br/> 
                     unique settings. In general, you can connect to any provider using the following information:</p>

                <div class="section fs--1"> <b> IMAP Settings: </b></div>
                <ul class="setting">
                    <li><span class="setting fs--1"><b>IMAP Host (Your IMAP Host):</b></span> For example, imap.myserver.com.</li>
                    <li><span class="setting fs--1"><b>IMAP Port (Your IMAP Port):</b></span>
                        <ul>
                            <li className="fs--1">For secure connections with TLS/SSL (IMAPS), use PORT 993.</li>
                            <li  className="fs--1">For non-secure connections, use PORT 143.</li>
                        </ul>
                    </li>
                </ul>

                <div class="section fs--1"><b>SMTP Settings:</b></div>
                <ul class="setting fs--1">
                    <li><span class="setting fs--1"><b>SMTP Host (Your SMTP Host):</b></span> For example, smtp.myserver.com.</li>
                    <li><span class="setting fs--1"><b>SMTP Port (Your SMTP Port):</b></span>
                        <ul>
                            <li className="fs--1">For secure connections with TLS/SSL (SMTPS), use PORT 465.</li>
                            <li className="fs--1">For secure connections with STARTTLS, use PORT 587.</li>
                            <li className="fs--1">For non-secure connections, use PORT 25.</li>
                        </ul>
                    </li>
                </ul>

                <p className="fs--1">With the provided information, you should be able to successfully configure your email account.</p>
            </body>
        </Flex>
    )
}

export function AnyProviderConnector({ headerIcon }) {

    const [prompt, setPrompt] = useState(0);
    const [feedForwardData, setFeedForwardData] = useState({});

    const promptHandler = (newPrompt) => {
        setPrompt(newPrompt);
    }

    const feedForwardDataHandler = (nextData = {}) => {
        setFeedForwardData({ ...nextData })
    }

    const [promptMap, setPromptMap] = useState({
        0: <IMAPAndSMTPBasicInformationScreen
            feedForwardDataHandler={feedForwardDataHandler}
            promptHandler={promptHandler}
            headerIcon={headerIcon}
            provider={'any_other_provider'}
            prompt={prompt}
            connectionHeader={<AnyProviderConnectionHeader headerIcon={headerIcon} />}
        />,
        1: <IMAPAndSMTPConnectionScreen
            feedForwardData={feedForwardData}
            promptHandler={promptHandler}
            prompt={prompt}
        />
    });

    useEffect(() => {
        setPromptMap({
            0: <IMAPAndSMTPBasicInformationScreen
                feedForwardDataHandler={feedForwardDataHandler}
                promptHandler={promptHandler}
                headerIcon={headerIcon}
                provider={'any_other_provider'}
                prompt={prompt}
                connectionHeader={<AnyProviderConnectionHeader headerIcon={headerIcon} />}
            />,
            1: <IMAPAndSMTPConnectionScreen
                feedForwardData={feedForwardData}
                promptHandler={promptHandler}
                prompt={prompt}
            />
        })
    }, [feedForwardData, prompt]);

    return (
        <Flex
            direction={'column'} className={'px-2 pb-2'}>
            {promptMap[Math.max(0, prompt)]}
        </Flex>
    );

}

export const bulkIconSvg = <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" color="#65d9a5" height="40px" width="40px" xmlns="http://www.w3.org/2000/svg" style={{ color: `rgb(101, 217, 165)` }}><path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-96 144c0 4.42-3.58 8-8 8h-8c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h8c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-8c-26.51 0-48-21.49-48-48v-32c0-26.51 21.49-48 48-48h8c4.42 0 8 3.58 8 8v16zm44.27 104H160c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h12.27c5.95 0 10.41-3.5 10.41-6.62 0-1.3-.75-2.66-2.12-3.84l-21.89-18.77c-8.47-7.22-13.33-17.48-13.33-28.14 0-21.3 19.02-38.62 42.41-38.62H200c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-12.27c-5.95 0-10.41 3.5-10.41 6.62 0 1.3.75 2.66 2.12 3.84l21.89 18.77c8.47 7.22 13.33 17.48 13.33 28.14.01 21.29-19 38.62-42.39 38.62zM256 264v20.8c0 20.27 5.7 40.17 16 56.88 10.3-16.7 16-36.61 16-56.88V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v20.8c0 35.48-12.88 68.89-36.28 94.09-3.02 3.25-7.27 5.11-11.72 5.11s-8.7-1.86-11.72-5.11c-23.4-25.2-36.28-58.61-36.28-94.09V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8zm121-159L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"></path></svg>

export default function AnyProviderSelector({ onProviderSelection, headerIcon }) {

    const [openUpgradePrompt, setOpenUpgradePrompt] = useState(false);
    const [anyProviderBulkContent, setAnyProviderBulkContent] = useState();

    const [providers] = useState([
        {
            name: "Any Provider",
            subTitle: "Bulk Import from CSV",
            provider: "bulk_any_other_provider",
            content: anyProviderBulkContent,
            svg: bulkIconSvg,
            onClick: function () {
                if (WorkSpaceManager.isFreeWorkspace()) {
                    setOpenUpgradePrompt(true);
                } else {
                    let content = <AnyProviderBulkUploader />;
                    setAnyProviderBulkContent(
                        content
                    )
                    onProviderSelection(content);
                }
            }
        },
        {
            name: "Any Provider",
            subTitle: "Single Account",
            provider: "any_other_provider",
            content: <AnyProviderConnector headerIcon={headerIcon} />,
            onClick: function () {
                onProviderSelection(this.content);
            },
            icon: anyProviderIcon
        }
    ]);

    return (
        <Flex
            direction={'column'}
            className={'px-5'}>
            <Flex
                direction={'column'}
                className={'gap-3'}>
                {
                    providers.map((x) => {
                        return (
                            <div
                                key={`${x.provider} ${x.subTitle}`}
                                onClick={() => {
                                    x?.onClick();
                                }}>
                                <EmailProviderListItem {...x} />
                            </div>
                        )
                    })
                }
            </Flex>
            <Modal
                show={openUpgradePrompt}
                onHide={() => setOpenUpgradePrompt(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title>
                        Unlock Premium Features
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpgradeToPremiumAccountCard
                        subTitle={"👋 You've discovered a premium feature!"}
                        message={"Upgrade to one of the paid plans to unlock this feature."}
                        upgradeBtnText={"Upgrade Plan"}
                    />
                </Modal.Body>
            </Modal>
        </Flex>
    )
}
