import { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { CardBody } from 'reactstrap';
import Flex from 'components/common/Flex';
import APIService from '../../../http/api_service';
import { toast } from 'react-toastify';

export default function BulkWorkspaceNamesEditor(props) {

    const updateHandler = props.updateHandler;
    const workspaces = props.workspaces;
    const [updating, setUpdating] = useState(false);
    const [formData, setFormData] = useState({});

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setUpdating(true);
        APIService.bulkWorkspaceNamesUpdate(formData, (response, error) => {
            setUpdating(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data, message } = response;
            toast.success(message, { theme: 'colored' });
            updateHandler(data);
        });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Flex direction={'column'}>
                {
                    workspaces.map((x) => {
                        return (
                            <Card className='mb-3'>
                                <CardBody>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{x.name}</Form.Label>
                                        <Form.Control
                                            placeholder={'Enter new name'}
                                            value={formData[x['_id']]}
                                            name={x._id}
                                            onChange={handleFieldChange}
                                            type="name"
                                        />
                                    </Form.Group>
                                </CardBody>
                            </Card>
                        )
                    })
                }
            </Flex>
            <div className='text-end'>
                <Button
                    disabled={updating}
                    variant="primary"
                    type="submit">
                    {updating ? "Updating..." : "Update"}
                </Button>
            </div>
        </Form>
    );
}
