import Divider from 'components/common/Divider';
import { RoutePaths } from '../../constants';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';
import APIService from 'http/api_service';
import { useDispatch } from 'react-redux';
import { updateMagicEmail } from '../../redux/slices/authSlice';
import UserManager from 'utils/user_manager';

const LoginForm = ({ hasLabel, layout }) => {

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: true
  });

  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    setProcessing(true);
    APIService.logInManually(formData.email, formData.password, (result, error) => {
      setProcessing(false);
      if (error) {
        toast.error(error, {
          theme: 'colored'
        });
        return;
      }
      let responseData = result['data'];
      if (responseData.hasOwnProperty('confirm_code')) {
        dispatch(updateMagicEmail(formData.email));
        navigate(`${RoutePaths.PASSWORD_LESS_SIGN_IN}?state=${1}`);
      } else {
        let userData = result['data'];
        let altUsers = UserManager.getLoggedInAltUsers();
        localStorage.clear();
        UserManager.setLoggedInUser(userData);
        UserManager.setAltableUsers(altUsers);
        setTimeout(() => {
          navigate(RoutePaths.REDIRECT);
        }, 1000);
      }
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={RoutePaths.FORGOT_PASSWORD}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>
      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || processing}
        >
          {processing ? 'Please wait...' : 'Log in'}
        </Button>
      </Form.Group>
      <Divider className="mt-4">or log in with</Divider>
      <SocialAuthButtons />
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
