import person2 from 'assets/img/team/2.jpg';
import person3 from 'assets/img/team/3.jpg';
import person4 from 'assets/img/team/4.jpg';
import person5 from 'assets/img/team/5.jpg';
import person6 from 'assets/img/team/6.jpg';
import person9 from 'assets/img/team/9.jpg';
import person13 from 'assets/img/team/13.jpg';
import person14 from 'assets/img/team/14.jpg';

export const customersData = [
  {
    name: 'Rick***',
    avatar: {
      name: 'Ricky Antony'
    },
    email: '***@aamranetworks.com',
    phone: '+8 ********00',
    title: 'Head of Marketing',
    company: 'Aamra Companies',
    companySize: '100 - 250',
    address: '2392 Main Avenue, Penasauka, New Jersey 02139',
    joined: '30/03/2018'
  },
  {
    name: 'Emma***',
    avatar: {
      img: person2
    },
    email: '***@coviam.com',
    phone: '(212) ******03',
    title: 'Head of Marketing',
    company: 'COVIAM Tech',
    companySize: '250 - 1000',
    address: '2289 5th Avenue, New York, New York, 10037',
    joined: '11/07/2017'
  },
  {
    name: 'Rowen***',
    avatar: {
      name: 'Rowen Atkinson'
    },
    email: '***@youinc.com.br',
    phone: '+5 *********88',
    title: 'Head of Marketing',
    company: 'You',
   companySize: '201 - 500',
    address: '112 Bostwick Avenue, Jersey City, New Jersey, 0730',
    joined: '05/04/2016'
  },
  {
    name: 'Antony***',
    avatar: {
      img: person2
    },
    email: '***@hcare.net',
    phone: '(901) 324-3127',
    title: 'Director of Marketing',
    company: 'HealthCare Strategies',
    companySize: '201 - 500',
    address: '3448 Ile De France St #242, Fort Wainwright, Alaska, 99703',
    joined: '05/04/2018'
  },
  {
    name: 'Jennifer***',
    avatar: {
      img: person3
    },
    email: '***@kappaalphatheta.org',
    phone: '+1 ********70',
    title: 'Head of Marketing',
    company: 'Kappa Alpha Theta',
    companySize: '201 - 500',
    address: '659 Hannah Street, Charlotte, NC 28273',
    joined: '17/03/2016'
  },
  {
    name: 'Raymon***',
    avatar: {
      name: 'Raymond Mims'
    },
    email: '***@silklaser.com.au',
    // phone: '(562) 468-5646',
    title: 'Head of Marketing',
    company: 'SILK Laser Clinics',
    companySize: '201 - 500',
    address: '2298 Locust Court, Artesia, CA 90701',
    joined: '12/07/2014'
  },
  {
    name: 'Micha***',
    avatar: {
      img: person4
    },
    email: '***@synapse.com',
    phone: '+1 ********00',
    title: 'Head of Marketing',
    company: 'Synapse Product Development',
    companySize: '201 - 500',
    address: '4678 Maud Street, Philadelphia, DE 19103',
    joined: '15/06/2014'
  },
  {
    name: 'Kristi***',
    avatar: {
      img: person13
    },
    email: '***@plugpower.com',
    phone: '+1 ********00',
    title: 'Chief Marketing Officer',
    company: 'Plug Power',
    companySize: '201 - 500',
    address: '3412 Crestview Manor, Indianapolis, IN 46234',
    joined: '15/04/2015'
  },
  {
    name: 'Suzan***',
    avatar: {
      name: 'Suzanne Martinez'
    },
    email: '***@sprinkles.com',
    phone: '+1 ********10',
    title: 'Chief Marketing Officer',
    company: 'Sprinkles Cupcakes',
    companySize: '201 - 500',
    address: '4895 Farnum Road, New York, NY 10004',
    joined: '15/04/2016'
  },
  {
    name: 'Marie***',
    avatar: {
      name: 'Marie Cohen'
    },
    email: '***@allstars-it.com',
    phone: '+9 *********23',
    title: 'Chief Marketing Officer',
    company: 'AllStars-IT Ukraine',
    companySize: '201 - 500',
    address: '3291 Hillside Street, Mesa, AZ 85201',
    joined: '25/08/2016'
  },
  {
    name: 'Micha***',
    avatar: {
      img: person9
    },
    email: '***@securityscorecard.com',
    phone: '+1 ********66',
    title: 'Chief Marketing Officer',
    company: 'SecurityScorecard',
    companySize: '201 - 500',
    address: '162 Hillhaven Drive, Los Angeles, CA 90063',
    joined: '20/12/2016'
  },
  {
    name: 'Kathr***',
    avatar: {
      img: person14
    },
    email: '***@omegapoint.se',
    phone: '+4 ********90',
    title: 'Chief Marketing Officer',
    company: 'Omegapoint',
    companySize: '201 - 500',
    address: '2551 Ocala Street, Orlando, FL 32805',
    joined: '12/01/2015'
  },
  {
    name: 'Estell***',
    avatar: {
      name: 'Estell Pollich'
    },
    email: '***@daxko.com',
    phone: '+1 ********86',
    title: 'Chief Marketing Officer',
    company: 'Daxko',
    companySize: '201 - 500',
    joined: '23/04/2019',
    address: '13572 Kurt Mews South Merritt, IA 52491'
  },
  {
    name: 'Ara***',
    avatar: {
      img: person2
    },
    email: '***@hiredynamics.com',
    phone: '+1 ********41',
    title: 'Chief Marketing Officer',
    company: 'Hire Dynamics',
    companySize: '201 - 500',
    joined: '23/04/2019',
    address: '91979 Kohler Place Waelchiborough, CT 41291'
  },
  {
    name: 'Lucienne',
    avatar: {
      name: 'Lucienne Blick'
    },
    email: '***@healthcatalyst.com',
    phone: '+1 ********00',
    title: 'Head of Marketing',
    company: 'Health Catalyst',
    companySize: '201 - 500',
    joined: '23/04/2019',
    address: '6757 Giuseppe Meadows Geraldinemouth, MO 48819-4970'
  },
  {
    name: 'Laverne',
    avatar: {
      img: person5
    },
    email: 'haag@example.com',
    phone: '(245) 988-1927',
    title: 'Head of Marketing',
    joined: '22/04/2019',
    address: '2327 Kaylee Mill East Citlalli, AZ 89582-3143'
  },
  {
    name: 'Brandon',
    avatar: {
      img: person6
    },
    email: 'bednar@example.com',
    phone: '(953) 589-9324 ',
    title: 'Head of Marketing',
    joined: '22/04/2019',
    address: '25156 Isaac Crossing Apt. 810 Lonborough, CO 83774-5999'
  },
  {
    name: 'Dimitri',
    avatar: {
      name: 'Dimitri Boehm'
    },
    email: 'dimitri@example.com',
    phone: '(943) 409-9147',
    title: 'Head of Marketing',
    joined: '23/04/2019',
    address: '71603 Wolff Plains Apt. 885 Johnstonton, MI 01581'
  },
  {
    name: 'Trace',
    avatar: {
      name: 'Trace Farrell'
    },
    email: 'trace@example.com',
    phone: '(442) 485-0384',
    title: 'Head of Marketing',
    joined: '26/04/2019',
    address: '431 Steuber Mews Apt. 252 Germanland, AK 25882'
  },
  {
    name: 'Estell',
    avatar: {
      img: person13
    },
    email: 'nienow@example.com',
    phone: '(505) 697-7549',
    title: 'Head of Marketing',
    joined: '26/04/2019',
    address: '4167 Laverna Manor Marysemouth, NV 74590'
  },
  {
    name: 'Daisha',
    avatar: {
      img: person14
    },
    email: 'howe@example.com',
    phone: '(337) 571-3547',
    title: 'Head of Marketing',
    joined: '25/04/2019',
    address: '829 Lavonne Valley Apt. 074 Stehrfort, RI 77914-0379'
  },
  {
    name: 'Miles Haley',
    avatar: {
      name: 'Miles Haley'
    },
    email: 'haley@example.com',
    phone: '(764) 619-4683',
    title: 'Head of Marketing',
    joined: '24/04/2019',
    address: '53150 Thad Squares Apt. 263 Archibaldfort, MO 00837'
  },
  {
    name: 'Brenda Watsica',
    avatar: {
      name: 'Brenda Watsica'
    },
    email: 'watsica@example.com',
    phone: '(961) 647-6123',
    title: 'Head of Marketing',
    joined: '24/04/2019',
    address: "9198 O'Kon Harbors Morarborough, IA 75409-7383"
  },
  {
    name: "Ellie O'Reilly",
    avatar: {
      name: "Ellie O'Reilly"
    },
    email: 'ellie@example.com',
    phone: ' (601) 688-8144',
    title: 'Head of Marketing', 
    joined: '24/04/2019',
    address: '1478 Kaitlin Haven Apt. 061 Lake Muhammadmouth, SC 35848'
  },
  {
    name: 'Garry Brainstrow',
    avatar: {
      name: 'Garry Brainstrow'
    },
    email: 'garry@example.com',
    phone: '(401) 879-9970',
    title: 'Head of Marketing',
    joined: '23/04/2019',
    address: 'Garry Brainstrow, 13572 Kurt Mews South Merritt, IA 52491'
  }
];
