import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import WorkspaceSettings from './WorkspaceSettings';
import DangerZone from './DangerZone';
import WorkSpaceManager from 'utils/workspace_manager';
import { PERMISSION_CREATE_NEW_WORKSPACES } from '../../../../constants';
import DelegatedAdditionalWorkspaceSettings from './DelegatedAdditionalWorkspaceSettings';
import { useDispatch } from 'react-redux';
import { setSearchHint } from '../../../../redux/slices/workspaceslice';
import UserManager from 'utils/user_manager';

const Settings = () => {

    const loggedInUser = UserManager.getLoggedInUser();
    const dispatch = useDispatch();
    const workSpace = WorkSpaceManager.getActiveWorkSpace();
    const [workSpaceOwnerDetails, setWorkSpaceOwnerDetails] = useState();

    useEffect(() => {
        dispatch(setSearchHint("Search..."));
    }, []);

    const stateHandler = (ownerDetails) => {
        setWorkSpaceOwnerDetails(ownerDetails);
    }

    return (
        <>
            {/* <WorkspaceBanner>
                <WorkspaceBanner.Header
                    coverSrc={coverSrc}
                />
            </WorkspaceBanner> */}
            <Row className="g-3">
                <Col lg={8}>
                    <WorkspaceSettings />
                    {/* {
                        WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_DELETE_WORKSPACE) &&
                        <React.Fragment>
                            <ApprovalSettings />
                            <PostsSettings />
                            <TimeZoneSettings />
                        </React.Fragment>
                    } */}
                    {
                        !WorkSpaceManager.isLoggedInUserOwnerOfWorkspace() && WorkSpaceManager.userCanPerformActionInWorkspace(workSpace, PERMISSION_CREATE_NEW_WORKSPACES) &&
                        <DelegatedAdditionalWorkspaceSettings stateHandler={stateHandler} />
                    }
                </Col>
                <Col lg={4}>
                    <div className="sticky-sidebar">
                        <React.Fragment>
                            {/* {
                                workSpace.owner_id === loggedInUser._id &&
                                <BillingSettings />
                            } */}
                            {/* <HomePanelSettings /> */}
                            {/* <PostEmailNotificationsSettings /> */}
                            {
                                workSpace.owner_id === loggedInUser._id &&
                                <DangerZone workSpaceOwnerDetails={workSpaceOwnerDetails} />
                            }
                        </React.Fragment>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Settings;
