export default class StringUtils {

    static substringBeforeLast(parent, str) {
        let idx = parent.lastIndexOf(str);
        if (idx !== -1) {
            return parent.substr(0, idx);
        }
        return parent;
    }

    static substringAfterLast = function (parent, str) {
        let idx = parent.lastIndexOf(str);
        if (idx !== -1) {
            return parent.substr(idx + str.length);
        }
        return parent;
    }

    static substringAfter = function (parent, str) {
        let idx = parent.indexOf(str);
        if (idx !== -1) {
            return parent.substr(idx + str.length);
        }
        return parent;
    }

    static substringBefore = function (parent, str) {
        let idx = parent.indexOf(str);
        if (idx !== -1) {
            return parent.substr(0, idx);
        }
        return parent;
    }

    static generateRandomString = function (length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    static capitalizeString = function (str) {
        return str.charAt(0).toUpperCase() + str?.slice(1);
    }

    static highlightMatchingText = (text, searchText) => {
        const indexOfMatch = text.toLowerCase().indexOf(searchText.toLowerCase());
        if (indexOfMatch !== -1) {
            const beforeMatch = text.slice(0, indexOfMatch);
            const match = text.slice(indexOfMatch, indexOfMatch + searchText.length);
            const afterMatch = text.slice(indexOfMatch + searchText.length);
            return (
                <div>
                    {beforeMatch}
                    <span style={{
                        backgroundColor: 'yellow',
                        fontWeight: 'bold'
                    }}>{match}</span>
                    {afterMatch}
                </div>
            );
        }
        return <span>{text}</span>;
    };

    static camelToSnake(str) {
        return str.replace(/([A-Z])/g, '_$1').toLowerCase().replace(/^_/, '');
    }

}
