/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import WorkSpacesTableHeader from '../workspace-search/WorkSpacesTableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import WorkSpaceManager from 'utils/workspace_manager';
import { RoutePaths } from '../../../constants';
import StringUtils from 'utils/string';
import UserManager from 'utils/user_manager';
import moment from 'moment';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { useDispatch } from 'react-redux';
import { searchEmail, setReloadPage } from '../../../redux/slices/workspaceslice';
import EmailTableHeader from './EmailTableHeader';
import APIService from 'http/api_service';

const loggedInUser = UserManager.getLoggedInUser();
const activeWorkSpace = WorkSpaceManager.getActiveWorkSpace();

const selectedState = (id) => {
    if (id === activeWorkSpace?._id) {
        return 'status-online';
    }
    return ''
}

const EmailSearch = (props) => {
    const { setFilter, filter } = props;
    const cachedWorkSpaces = WorkSpaceManager.getCachedWorkSpaces();
    const dispatch = useDispatch();
    const [workSpaces, setWorkSpaces] = useState((cachedWorkSpaces ?? []).map(workSpace => {
        return {
            ...workSpace,
            disabled: workSpace?.owner_id !== loggedInUser?._id
        }
    }).sort(x => x._id === activeWorkSpace?._id ? -1 : 1));
    const [emailAccounts, setEmailAccounts] = useState([]);

    const workSpaceUpdateHandler = (updatedWorkSpaces) => {
        setWorkSpaces([...updatedWorkSpaces]);
    }

    const columns = [
        {
            accessor: 'email',
            Header: 'Email',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { dummy, _id, name, archived, avatar, email } = rowData.row.original;
                return (
                    <Flex
                        onClick={() => {
                            dispatch(searchEmail(false));
                            setFilter(dummy ? 'all' : {_id, email});
                        }}
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                        }}>
                        <Flex alignItems="center">
                            {avatar ? (
                                <Avatar src={avatar} size="xl" className={`me-2 ${selectedState(_id)}`} />
                            ) : (
                                <Avatar size="xl" name={email} className={`me-2 ${selectedState(_id)}`} />
                            )}
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1">{dummy ? 'Show All' : email}</h5>
                            </div>
                        </Flex>
                    </Flex>
                );
            }
        },
        {
            accessor: 'provider',
            Header: 'Provider',
            Cell: rowData => {
                const { dummy, provider } = rowData.row.original;
                return <div
                    style={{
                        width: '100%',
                        cursor: 'pointer',
                    }}
                >{dummy ? '' : provider}</div>;
            }
        },
        // {
        //     accessor: 'can_be_warmed_up',
        //     Header: 'Can be warmed up',
        //     Cell: rowData => {
        //         const { dummy, can_be_warmed_up } = rowData.row.original;
        //         return <span >{StringUtils.capitalizeString(dummy ? '' : can_be_warmed_up ? 'Yes' : 'No')}</span>;
        //     }
        // },
        // {
        //     accessor: 'created_at',
        //     Header: 'Connected at',
        //     Cell: rowData => {
        //         const {dummy, created_at } = rowData.row.original;
        //         return <span>{dummy ? '' : moment(new Date(created_at)).format("LLLL")}</span>;
        //     }
        // }
        // {
        //     accessor: 'archived',
        //     Header: 'State',
        //     Cell: rowData => {
        //         const { archived } = rowData.row.original;
        //         return <span>{archived ? "Archived" : "Active"}</span>;
        //     }
        // },
    ];

    useEffect(async () => {
        let { data } = await APIService.fetchConnectedEmailAccounts(activeWorkSpace?._id)
        setEmailAccounts([{
            dummy: true,
        }, ...data])
    }, [])

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={emailAccounts}
            // selection
            // sortable
            pagination
            perPage={50}
            selectionColumnWidth={50}

        >
            <Card>
                <Card.Header>
                    <EmailTableHeader
                        table
                        workSpaces={workSpaces}
                        workSpaceUpdateHandler={workSpaceUpdateHandler}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}

                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowCount={workSpaces.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default EmailSearch;
