import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
    InputTag, TopNav,
} from "components/common/styles/repeat_option_pop_styles";
import { ButtonGroup, Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
import { SHA256 } from "crypto-js";
import StringUtils from "utils/string";
import Select from "react-select";
import IconButton from 'components/common/IconButton';

export const defaultThread = {
    "id": SHA256(StringUtils.generateRandomString(365)).toString(),
    "activated": true,
    "name": "A",
    "title": "Please change this is email subject",
    "body": "Hello, {{first_name}}, this is Email body",
    "created_at": new Date(),
    "updated_at": new Date(),
    "stats": [
        {
            "key": "scheduled",
            "name": "Scheduled",
            "value": 0,
        },
        {
            "key": "delivered",
            "name": "Delivered",
            "value": 0,
        },
        {
            "key": "opens",
            "name": "Opens",
            "value": 0,
        },
        {
            "key": "clicks",
            "name": "Clicks",
            "value": 0,
        },
        {
            "key": "replies",
            "name": "Replies",
            "value": 0,
        }
    ]
};

export default function AutomaticEmailStepContent({ sequence, formData = {}, setFormData, updatableStep }) {
    const stepsFound = (sequence) => (sequence["steps"] ?? []).length > 0;

    // Initialize timeToStart and timeUnit with default values if not provided
    const [timeToStart, setTimeToStart] = useState(updatableStep ? formData["time_value"] : formData["time_value"] ?? 60);
    const [timeUnit, setTimeUnit] = useState(updatableStep ? formData["time_unit"] : formData["time_unit"] ?? 'minutes');
    const [startOption, setStartOption] = useState(updatableStep ? (formData["start_option"] ? formData["start_option"] : 'immediately_after_previous_step') : formData["start_option"] ?? stepsFound(sequence) ? "immediately_after_previous_step" : "after_specified_time");

    useEffect(() => {
        let threads = formData["threads"] ?? [
            defaultThread
        ];
        setFormData({
            ...formData,
            "type": "automatic_email",
            "name": "Automatic email",
            "time_unit": timeUnit,
            "time_value": timeToStart,
            "start_option": startOption,
            "start_time": {
                "milliseconds": moment().add(timeToStart, timeUnit).toDate().getTime()
            },
            "threads": threads
        })
    }, [timeToStart, timeUnit]);

    const handleStartOptionChange = (event) => {
        setStartOption(event.target.value);
    };

    return (
        <Flex direction={'column'} className={'pb-2 mt-4'}>
            <Flex direction={"column"}>
                <Form.Group controlId="email">
                    <Form.Label>Email Type</Form.Label>
                    <Form.Select
                        className="me-4"
                        onChange={(e) => {
                            setTimeUnit(e.target.value);
                        }}>
                        <option key={'automatic_email'} value={'automatic_email'}>Automatic email - emails are triggered automatically</option>
                        <option key={'manual_email'} value={'manual_email'}>Manual email - emails are sent manually</option>
                    </Form.Select>
                </Form.Group>
                <h5 className="mt-4 text-bold">When to start this step:</h5>
                <p>Set the amount of time you'd like to wait between steps.</p>
                <Form onSubmit={(e) => e.preventDefault()}>
                    {
                        stepsFound(sequence) &&
                        <div style={{ marginTop: 20 }}>
                            <Form.Check
                                type="radio"
                                name="radioGroup"
                                label="Immediately after the previous step"
                                value={"immediately_after_previous_step"}
                                checked={startOption === "immediately_after_previous_step"}
                                onChange={handleStartOptionChange}
                                style={{ marginTop: 114 }}
                            />
                        </div>
                    }
                    <Row className="align-items-center">
                        <Col as={Flex} className="align-items-center me-3">
                            <Form.Check
                                type="radio"
                                id="inboxBulkSelect"
                                className="mb-0 form-check"
                                value={"after_specified_time"}
                                checked={startOption === "after_specified_time"}
                                onChange={handleStartOptionChange}
                            />
                            <InputTag
                                min={1}
                                value={timeToStart}
                                disabled={startOption !== "after_specified_time"}
                                type="number"
                                onChange={(e) => {
                                    setTimeToStart(e.target.value);
                                }}
                            />
                            <Form.Select
                                className="me-2 ms-2"
                                value={timeUnit}
                                onChange={(e) => {
                                    setTimeUnit(e.target.value);
                                }}>
                                <option key={'minutes'} value={'minutes'}>Minutes</option>
                                <option key={'hours'} value={'hours'}>Hours</option>
                                <option key={'days'} value={'days'}>Days</option>
                                <option key={'weeks'} value={'weeks'}>Weeks</option>
                            </Form.Select>
                            
                        </Col>
                        <Col xs="auto">
                            {
                                stepsFound(sequence) &&
                                <p className="mt-2 text-bold">after the previous step</p>
                            }
                            {
                                !stepsFound(sequence) &&
                                <p className=" mt-2 text-bold">after activation</p>
                            }
                        </Col>
                    </Row>
                </Form>
            </Flex>
        </Flex>
    );
}
