import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import {
    Dropdown,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap"
import Reactloading from "react-loading"

const StepHeader = (props) => {
    // const { sequence } = useSelector(getSequencesFromRedux)
    const { getTheDayFigureForStep, step, index, processing, cloneStep, setOpenStepDeleteModal, setOpenStepModal, openStepModal, setUpdatableStep } = props
    const stepDayFigure = getTheDayFigureForStep(step);
    return (
        <>
            <div className="d-flex flex-column flex-md-row">
                <div className="d-flex align-items-center">
                    <p className="mb-2 mb-md-0 py-md-2 fs-0">Step {index + 1}</p>
                    <div
                        className="bg-400 mx-2" style={{ height: "2px", width: "10px" }}
                    />
                    {/* <h6 className="ms-0 me-2 mb-md-0 fs-0">
            
          </h6> */}
                </div>
                <div className="d-flex align-items-center">
                    <div>
                        <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
                            <FontAwesomeIcon icon="envelope" className="text-primary" />
                        </div>
                    </div>
                    <h6 className="ms-1 mb-md-0 fs-0"> Send <b>{step["name"]}</b>  in <b>Day {stepDayFigure}</b></h6>
                </div>
                <Dropdown align="start" className="ms-3 mt-1 btn-reveal-trigger d-inline-block shadow-none">
                    {processing ? (
                        <Reactloading
                            type="spin"
                            color="#0052cc"
                            width={20}
                            height={20}
                        />
                    ) : (
                        <Dropdown.Toggle
                            size="sm"
                            variant="falcon-default"
                            className="dropdown-caret-none"
                        >
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </Dropdown.Toggle>
                    )}
                    <Dropdown.Menu className="border py-2">
                        <Dropdown.Item
                            onClick={() => {
                                setUpdatableStep(step)
                                setOpenStepModal(!openStepModal)
                            }}
                        >
                            Edit step {index + 1}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                cloneStep()
                            }}
                        >
                            Clone Step {index + 1}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            as={"button"}
                            onClick={() => {
                                setOpenStepDeleteModal(true)
                            }}
                            className="text-danger"
                        >
                            Remove Step {index + 1}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
                <h6 className="fs--2 mt-2 d-flex align-items-center text-muted me-md-3">
                    - Scheduled
                </h6>
                <h6 className="fs--2 mt-2 d-flex align-items-center text-muted me-md-3">
                    - Delivered
                </h6>
                <div
                    className="bg-300 mx-3 d-none d-lg-block d-xl-none"
                    style={{ width: "1px", height: "29px" }}
                ></div>
                <div id="orders-actions">
                    <div
                        className=" ms-3 btn-reveal-trigger d-inline-block"
                        style={{
                            cursor: "grab",
                            WebkitCursor: "-webkit-grab",
                            display: "inline-block",
                        }}
                    >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Hold and drag to rearrange the sequence steps.
                                </Tooltip>
                            }
                        >
                            <div>
                                <FontAwesomeIcon icon={faArrowsAlt} className="me-2" />
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StepHeader