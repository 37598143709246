import React, { useState } from 'react';
import { copyToClipboard } from '../../../utils/browser';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CopyLinkButton = (props) => {

  const {
    iconButton,
    icon = 'link',
    defaultText = 'Copy link',
    copiedText = 'Link Copied',
    handleCopy } = props;
  const [isLinkCopied, setLinkCopied] = useState(false);

  const handleLinkCopy = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
    if (handleCopy) {
      handleCopy();
    } else {
      copyToClipboard(props.linkToCopy ?? window.location.href);
    }
  };

  return (
    iconButton ?
      <FontAwesomeIcon
        icon={icon}
        onClick={() => {
          handleLinkCopy();
        }}
      /> :
      <Button
        icon={icon}
        onClick={handleLinkCopy}
        {...props}>
        {isLinkCopied ? copiedText : defaultText}
      </Button>
  );

};

export default CopyLinkButton;
