import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import APIService from 'http/api_service';

const ForgetPasswordForm = () => {

  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (email) {
      setProcessing(true);
      APIService.initiatePasswordReset(email, (response, error) => {
        setProcessing(false);
        if (error) {
          toast.error(error);
          return;
        }
        let message = response['message'];
        toast.success(message, {
          theme: 'colored'
        });
      });
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email || processing}>
          {processing ? "Please wait..." : "Send reset link"}
        </Button>
      </Form.Group>

    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
