import Flex from "components/common/Flex"
import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import LeadsAnimation from "assets/animations/import_leads.json"
import Lottie from "lottie-react"
import LeadsImporter from "./leads_importer"
import ImportedLeads from "./imported_leads"
import APIService from "http/api_service"
import WorkSpaceManager from "utils/workspace_manager"
import Reactloading from "react-loading"
import { useParams, useSearchParams } from "react-router-dom"

const GetStartedView = React.forwardRef((props, ref) => {
  return (
    <Flex
      ref={ref}
      className={"gap-2"}
      alignItems={"center"}
      justifyContent={"center"}
      style={{
        height: "auto",
        width: "auto",
      }}
    >
      <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
        <Lottie
          animationData={LeadsAnimation}
          style={{
            width: 300,
            height: 300,
          }}
        />
        <h5>👋 Add some leads to get started</h5>
        <Button
          style={{
            marginTop: "2rem",
          }}
          variant={"primary"}
          onClick={() => {
            props.getStartedClickHandler()
          }}
        >
          <Flex alignItems={"center"} className={"gap-1"}>
            <span>Add contacts</span>
          </Flex>
        </Button>
      </Flex>
    </Flex>
  )
})

export default function ListItems(props) {

  const { listId } = useParams()
  const [searchParams] = useSearchParams();
  const listName = searchParams.get("name");
  const [sequence, setSequence] = useState(props.sequence)
  const [listItems, setListItems] = useState([])
  const [loadingListItems, setLoadingListItems] = useState(true)
  const [refreshingListItems, setRefreshingListItems] = useState()
  const workspace = WorkSpaceManager.getActiveWorkSpace()
  const [openDrawer, setOpenDrawer] = useState(false)

  const fetchListItems = async () => {
    setRefreshingListItems(true)
    try {
      let { data = [] } = await APIService.fetchListItems(workspace._id, listId);
      setListItems(data);
      setLoadingListItems(false)
      setRefreshingListItems(false)
    } catch (e) {
      setLoadingListItems(false)
      setRefreshingListItems(false)
    }
  }

  useEffect(() => {
    fetchListItems()
  }, [])

  return (
    <Flex direction={"column"}>
      {
        listItems?.length > 0 && (
          <ImportedLeads
            leads={listItems.map(listItem => {
              return {
                ...listItem,
                listId,
                listItem: listItem._id
              }
            })}
            list={{ listName, listId }}
            importLeads={() => {
              setOpenDrawer(true)
            }}
            refreshingLeads={refreshingListItems}
            sequence={sequence}
            onSequenceChanged={(updatedSequence, updateRemotely) => {
              setSequence({ ...updatedSequence })
              if (updateRemotely) {
                //Run remote update
              }
            }}
          />
        )
      }
      {
        listItems?.length < 1 && !loadingListItems && (
          <GetStartedView
            getStartedClickHandler={() => {
              setOpenDrawer(true)
            }}
          />
        )
      }
      {
        !loadingListItems && (
          <LeadsImporter
            onSequenceChanged={(updatedSequence) => {
              setSequence(updatedSequence)
            }}
            list={{ listId }}
            sequence={sequence}
            open={openDrawer}
            closeDrawer={() => {
              setOpenDrawer(false)
              fetchListItems()
            }}
          />
        )
      }
      {
        loadingListItems && (
          <Flex alignItems={"center"} justifyContent={"center"} className={"w-100 h-100 pt-5"}>
            <Flex alignItems={"center"} justifyContent={"center"} className={"w-100 h-100 pt-5"}>
              <Reactloading width={30} type="spinningBubbles" color="teal" />
            </Flex>
          </Flex>
        )
      }
    </Flex>
  )
}
