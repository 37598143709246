import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import { useEffect, useRef, useState } from "react";
import { Button, FormControl, FormGroup, FormLabel } from "react-bootstrap";

export default function SelectedTemplatePreview(props) {

    const { template, onBackPressed } = props;
    const [inputs, setInputs] = useState({});
    const [variables, setVariables] = useState({});
    const variableRefs = useRef([]);

    const checkAndSetupInputs = () => {
        let templateInputs = template["inputs"];
        if (templateInputs) {
            for (let input of templateInputs) {
                setInputs((prevInputs) => {
                    let name = input["name"];
                    prevInputs[name] = input["value"];
                    return { ...prevInputs };
                });
            }
        }
    }

    const checkAndSetupVariables = () => {
        let templateVariables = template["variables"];
        if (templateVariables) {
            for (let variable of templateVariables) {
                setVariables((prevVariables) => {
                    let name = variable["name"];
                    prevVariables[name] = variable["goal"];
                    return { ...prevVariables };
                });
            }
        }
    }

    useEffect(() => {
        let promptVariables = template['variables'];
        if (promptVariables) {
            variableRefs.current = variableRefs.current.slice(0, promptVariables.length);
        }
    }, [template]);

    useEffect(() => {
        checkAndSetupInputs();
        checkAndSetupVariables();
    }, []);

    const alterVariableName = (variable, index) => {
        const variableRef = variableRefs.current[index];
        variableRef.focus();
    }

    return (
        <Flex direction={'column'} className={'mt-4'}>
            <Flex
                direction={'row'}
                alignItems={'center'}>
                <FontAwesomeIcon
                    onClick={() => {
                        onBackPressed();
                    }}
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        marginRight: 10
                    }}
                    icon={faChevronLeft}
                />
                <h1 style={{ fontSize: 16, margin: 0 }}>{template['title']}</h1>
            </Flex>
            <Divider />
            <span style={{ fontSize: 14, marginBottom: 12 }}>{template['description']}</span>
            <Flex
                direction={"column"}
            >
                {
                    template['inputs'] && template['inputs'].map((field, index) => {
                        return (
                            <FormGroup>
                                {
                                    field["type"] === "input" &&
                                    <FormLabel>{field["label"]}</FormLabel>
                                }
                                <FormControl
                                    type={field["type"]}
                                    name={field["name"]}
                                    value={inputs[field["name"]]}
                                    onChange={(e) => {
                                        setInputs((prevInputs) => {
                                            let name = field['name'];
                                            prevInputs[name] = e.target.value;
                                            return { ...prevInputs };
                                        });
                                    }}
                                />
                                <Divider
                                    hrClasses='mt-4'
                                    bottomDivClasses='m-0'
                                />
                            </FormGroup>
                        );
                    })
                }
                {
                    template['variables'] && template['variables'].map((variable, index) => {
                        return (
                            <FormGroup>
                                {
                                    variable["type"] === "input" &&
                                    <FormLabel>{variable["label"]}</FormLabel>
                                }
                                <FormControl
                                    ref={el => variableRefs.current[index] = el}
                                    type={variable["type"]}
                                    name={variable["name"]}
                                    value={variables[variable["name"]]}
                                    onChange={(e) => {
                                        setVariables((variables) => {
                                            let name = variable['name'];
                                            variables[name] = e.target.value;
                                            return { ...variables };
                                        });
                                    }}
                                />
                                <Flex alignItems={'center'} className={'gap-2 mt-2'}>
                                    <span className="text-danger">{index + 1}.</span>
                                    <Button
                                        onClick={() => {
                                            alterVariableName(variable, index);
                                        }}>+ Create variable {`"${variable["goal"]}"`}
                                    </Button>
                                </Flex>
                                {
                                    index !== template['variables'].length - 1 &&
                                    <Divider
                                        hrClasses='mt-4'
                                        bottomDivClasses='m-0'
                                    />
                                }
                            </FormGroup>
                        );
                    })
                }
                <Flex alignItems={'center'} className={'gap-2 mt-3'}>
                    <span className="text-danger">{(template['variables'] ?? []).length + 2}.</span>
                    <Button>Generate For All Leads</Button>
                </Flex>
                <div style={{ height: 10 }} />
                <Flex className={'gap-2 mt-2'} alignItems={'center'}>
                    <span className="text-danger">{(template['variables'] ?? []).length + 1}.</span>
                    <Button variant="outline-primary rounded-pill">Generate test output</Button>
                </Flex>
            </Flex>
        </Flex>
    );

}
