import React from 'react';
import { useSelector } from 'react-redux'
import { getSequencesFromRedux } from '../../../../../redux/slices/sequence_slice';
import ThreadEntry from './ThreadEntry';

const Threads = (props) => {
    const sequence = useSelector(getSequencesFromRedux)
    const { step, refreshStep, threadToOpen, setThreadToOpen, cloneThread, processing } = props;
    return (
        <>
            {step["threads"].map((thread, index) => {
                return (
                    <ThreadEntry
                        key={thread["id"]}
                        sequence={sequence}
                        index={index}
                        thread={thread}
                        step={step}
                        refreshStep={refreshStep}
                        threadToOpen={threadToOpen}
                        setThreadToOpen={setThreadToOpen}
                        cloneThread={cloneThread}
                        processing={processing}
                    />
                )
            })}
        </>
    )
}

export default Threads;
