import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';

const GeneralCardLayout = ({ children }) => {
    return (
        <Section fluid className="py-0">
            <Row className="g-0 min-vh-100 flex-center">
                <Col lg={6} xxl={6} className="py-0 position-relative">
                    <Card className="overflow-hidden z-index-1">
                        <Card.Body className="p-0">
                            <Row className="h-100 g-0">
                                <Col
                                    md={12}
                                    as={Flex}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Section>
    );
};
GeneralCardLayout.propTypes = {
    leftSideContent: PropTypes.node,
    children: PropTypes.node.isRequired,
    footer: PropTypes.bool
};
export default GeneralCardLayout;
