import { faBackward, faChevronLeft, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { useContext, useEffect, useState } from 'react';
import { Button, Card, Offcanvas } from 'react-bootstrap';
import "./leads.css";
import { googleIcon } from 'components/app/email-accounts/connect_email_account';
import { bulkIconSvg } from 'components/app/email-accounts/providers/AnyProviderSelector';
import AppContext from 'context/Context';
import ManualEmailsImporter from './manual_emails_importer';
import CSVLeadsImporter from './csv_leads_importer';
import LeadsImporterFromGoogleSheet from './leads_import_from_google_sheet';

export const LeadImportItem = ({ name, icon, svg, subTitle }) => {
    return (
        <Flex
            className="lead-shadow-card cursor-pointer">
            <Flex
                className={'px-5'}
                style={{
                    padding: '1.3rem',
                }}>
                <Flex
                    alignItems={'center'}
                    className={'gap-4'}>
                    {svg ? svg : <img width={'40px'} src={icon} />}
                    <div
                        style={{
                            height: 45,
                            width: 1,
                            background: '#CFD8DC'
                        }}>
                    </div>
                    <Flex direction={'column'}>
                        <Card.Text className="p-0 m-0">{name}</Card.Text>
                        <Card.Title className="p-0 m-0 fs-3">{subTitle}</Card.Title>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default function LeadsImporter(props) {

    const onSequenceChanged = props.onSequenceChanged;
    const [selectedImportChoice, setSelectedImportChoice] = useState();
    const { config } = useContext(AppContext);
    const { isDark } = config;
    const [sequence, setSequence] = useState(props.sequence);

    let emailIcon = <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" color="#3c3c3c" height="40px" width="40px" xmlns="http://www.w3.org/2000/svg" style={{ "color": isDark ? "white" : "rgb(60, 60, 60)" }}><path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>;
    let importOptions = [
        {
            name: "UPLOAD",
            subTitle: "CSV",
            svg: bulkIconSvg,
            content: <CSVLeadsImporter
                sequence={sequence}
                sequenceUpdateHandler={(updatedSequence) => {
                    setSequence(updatedSequence);
                    onSequenceChanged(updatedSequence);
                }}
            />,
            onClick: function () {
                setSelectedImportChoice(this.content);
            }
        },
        {
            name: "ENTER",
            subTitle: "Emails Manually",
            provider: "outlook",
            content: <ManualEmailsImporter
                sequence={sequence}
                sequenceUpdateHandler={(updatedSequence) => {
                    setSequence(updatedSequence);
                    onSequenceChanged(updatedSequence);
                }}
            />,
            onClick: function () {
                setSelectedImportChoice(this.content);
            },
            svg: emailIcon
        },
        {
            name: "USE",
            subTitle: "Google Sheets",
            content: <LeadsImporterFromGoogleSheet
                sequence={sequence}
                sequenceUpdateHandler={(updatedSequence) => {
                    setSequence(updatedSequence);
                    onSequenceChanged(updatedSequence);
                }} />,
            onClick: function () {
                setSelectedImportChoice(this.content);
            },
            icon: googleIcon
        }
    ]

    const [showDrawer, setShowDrawer] = useState(props.open);

    const handleClose = () => {
        setShowDrawer(!showDrawer);
        props?.closeDrawer();
    }

    useEffect(() => {
        setShowDrawer(props.open);
    }, [props.open]);

    return (
        <Offcanvas
            style={{
                width: '80%',
            }}
            fullscreen={true}
            placement="end"
            show={showDrawer}
            onHide={handleClose}>
            <Offcanvas.Header>
                <Offcanvas.Title>
                    <Button
                        onClick={() => {
                            if (selectedImportChoice) {
                                setSelectedImportChoice(undefined);
                            } else {
                                handleClose();
                            }
                        }}
                        icon={faBackward}
                        variant={'link'}
                        style={{
                            textDecoration: 'none'
                        }}>
                        <Flex
                            alignItems={'center'}
                            className={'gap-2'}>
                            <FontAwesomeIcon
                                color={'#B0BEC5'}
                                icon={faChevronLeft}
                            />
                            <span>{selectedImportChoice ? "Choose another method" : "Cancel"}</span>
                        </Flex>
                    </Button>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Flex
                    justifyContent={'center'}>
                    <Flex
                        direction={'column'}>
                        {/* {
                            !selectedImportChoice &&
                            <Flex
                                alignItems={'center'}
                                className={'gap-2 px-5 py-2'}
                                style={{
                                    backgroundColor: 'rgb(255, 255, 186)',
                                    borderRadius: 5,
                                }}>
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                                <span style={{ fontWeight: 600, color: 'black' }}>{`${(sequence?.leads ?? []).length} of 250 leads uploaded. 250 uploads remaining.`}</span>
                            </Flex>
                        } */}
                        {/* {
                            !selectedImportChoice &&
                            <Button
                                variant='link'
                                style={{
                                    textDecoration: 'none',
                                    marginTop: 10,
                                    fontWeight: 500
                                }}>Upgrade Plan
                            </Button>
                        } */}
                        {
                            !selectedImportChoice &&
                            <Flex
                                direction={'column'}
                                className={'pt-3'}>
                                <Flex
                                    direction={'column'}
                                    className={'gap-4'}>
                                    {
                                        importOptions.map((x) => {
                                            return (
                                                <div
                                                    key={x.name}
                                                    onClick={() => {
                                                        x?.onClick();
                                                    }}>
                                                    {
                                                        <LeadImportItem {...x} />
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </Flex>
                            </Flex>
                        }
                        {
                            selectedImportChoice
                        }
                    </Flex>
                </Flex>
            </Offcanvas.Body>
        </Offcanvas>
    );
}
