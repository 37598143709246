import { RoutePaths } from "../constants";

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Home',
      active: true,
      icon: 'home',
      to: RoutePaths.DASHBOARD,
    },
  ]
}
export const contactRoutes = {
  label: 'Contacts',
  children: [
    {
      name: 'Lists',
      icon: 'list',
      to: RoutePaths.LISTS,
      active: true,
    },
    {
      name: 'Lead Finder',
      icon: 'search',
      to: '/lead-finder',
      active: true,
      // badge: {
      //   type: 'danger',
      //   text: 'beta'
      // }
    },
    // {
    //   name: 'Contact Verification',
    //   icon: 'envelope-circle-check',
    //   to: RoutePaths.CONTACT_VERIFICATION,
    //   active: true,
    // },
    // {
    //   name: 'Signals',
    //   icon: 'fa-arrows-down-to-people',
    //   to: RoutePaths.SIGNALS,
    //   active: true,
    // }
  ]
}
export const mainMenuRoutes = {
  label: 'Engage',
  children: [
    {
      name: 'Sequences',
      to: RoutePaths.SEQUENCES,
      icon: ['far', 'paper-plane'],
      exact: true,
      active: true
    },
    // { 
    //   name: 'LinkedIn accounts', 
    //   icon: ['fab', 'linkedin-in'],
    //   to: RoutePaths.LINKEDIN_ACCOUNTS,
    //   exact: true, 
    //   active: true
    // },
    {
      // visibleToOnlyDemoAccount: true,
      name: 'Unified inbox',
      icon: 'envelope-open',
      to: RoutePaths.UNIFIED_INBOX,
      active: true,
      // badge: {
      //   type: 'danger',
      //   text: 'beta'
      // }
    },
  ]
}

export const settingsRoutes = {
  label: 'Settings',
  children: [
    {
      name: 'Profile',
      to: RoutePaths.PROFILE,
      icon: 'user',
      exact: true,
      active: true
    },
    {
      name: 'Organization',
      icon: 'building',
      active: true,
      children: [
        {
          name: 'Settings',
          to: RoutePaths.WORKSPACE_SETTINGS,
          exact: true,
          active: true
        },
        {
          name: 'Teams',
          to: RoutePaths.TEAM,
          exact: true,
          active: true
        },
        {
          name: 'Email accounts',
          // icon: 'at',
          to: RoutePaths.EMAIL_ACCOUNTS,
          iconifyName: false,
          exact: true,
          active: true
        },
        // {
        //   name: 'Sales engagement',
        //   to: RoutePaths.TEAM,
        //   exact: true,
        //   active: true
        // },

      ]
    },
    
    // {
    //   name: 'Beta features',
    //   to: RoutePaths.BETA_FEATURES,
    //   icon: 'flask',
    //   exact: true,
    //   active: true
    // },

  ]
};


export const resourceRoutes = {
  label: 'resources',
  children: [
    // {
    //   name: 'Community',
    //   icon: 'heart',
    //   to: 'https://web.facebook.com/groups/782902003838357/',
    //   target: '_blank',
    //   active: true,
    // },
    // {
    //   name: 'Roadmap',
    //   icon: 'info-circle',
    //   to: 'https://feedback.reachable.to',
    //   active: true,
    // },
    // {
    //   name: 'Affiliate Program',
    //   icon: 'dollar-sign',
    //   to: '/deals',
    //   active: true,
    // },
    {
      name: 'Changelog',
      icon: 'edit',
      to: '/changelog',
      active: true,
    },
  ]
}
const routes = [
  dashboardRoutes,
  contactRoutes,
  mainMenuRoutes,
  settingsRoutes,
  resourceRoutes
];

export default routes;