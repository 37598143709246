// firebase.js or firebaseConfig.js (whatever file you use to initialize Firebase)
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDNdErPN75jV-gCEvTsnGrI7UNQeaLOWIg",
    authDomain: "reach-able.firebaseapp.com",
    projectId: "reach-able",
    storageBucket: "reach-able.appspot.com",
    messagingSenderId: "779610735775",
    appId: "1:779610735775:web:a2c86d6b23c59bfe68ceaa",
    measurementId: "G-LX2N7D8F7H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const storage = getStorage(app);

export { app, auth, storage };
