import { Strings } from "../constants"

export const isLoggedInUserOwnerOfWorkspace = (loggedInUser, activeWorkSpace) => {
  return loggedInUser._id === activeWorkSpace.owner_id
}

export const isFreeUser = (user) => {
  let activePlan = user.active_plans
  return activePlan?.Name === "Free" || activePlan?.Key === "Free"
}

export const getLoggedInUserFromLocalStorage = () => {
  let localStorageUserString = localStorage.getItem(Strings.LOGGED_IN_USER)
  if (localStorageUserString) {
    return JSON.parse(localStorageUserString)
  }
  return null
}

export const canLoggedInUserBeDemo = (user) => {
  if (!user) {
    return false
  }
  let demoEmailAddresses = [
    "collinsrollins07@gmail.com",
    "cryptoboss41@gmail.com",
    "juoemeka@gmail.com",
    "princewhyte02@gmail.com",
    "emeka@saleshighersolutions.com",
    "emeka@saleshighersolutions.com",
  ]
  return demoEmailAddresses.includes(user.email)
}
